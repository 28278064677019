import { storeToRefs } from "pinia";
import { useCartVariantsStore } from "../useCartVariantsStore";

export default (cartVariantIds: number[]) => {
    if (!cartVariantIds.length) {
        return;
    }

    const { filter } = storeToRefs(useCartVariantsStore());
    filter.value = { id: cartVariantIds };
};

<template>
    <base-dialog v-model:is-open="isOpen">
        <div
            class="fixed inset-0 mx-0 mt-16 flex items-center justify-center tablet:m-12"
        >
            <transition-child
                appear
                as="template"
                enter="duration-300 ease-out"
                enter-from="translate-y-full tablet:translate-y-0 tablet:opacity-0 tablet:scale-95"
                enter-to="translate-y-0 tablet:opacity-100 tablet:scale-100"
                leave="duration-200 ease-in"
                leave-from="translate-y-0 tablet:opacity-100 tablet:scale-100"
                leave-to="translate-y-full tablet:translate-y-0 tablet:opacity-0 tablet:scale-95"
            >
                <base-dialog-panel
                    class="flex h-full max-h-full w-[1280px] flex-col p-4 pt-12"
                    has-close-button
                >
                    <div
                        class="divide-y divide-secondary-2 overflow-y-auto border-b border-t border-secondary-2"
                    >
                        <headlessui-disclosure
                            v-for="category in categoriesTree?.filter(
                                (category) => category.children.length,
                            )"
                            :key="category.id"
                            v-slot="{ open, close }"
                            as="div"
                        >
                            <disclosure-button
                                class="flex w-full justify-between py-3"
                                @click="
                                    handleDisclosureButtonClick(
                                        category.id,
                                        close,
                                    )
                                "
                            >
                                <span class="font-bold">
                                    {{ category.name }}
                                </span>
                                <f-a-icon
                                    :icon="open ? 'chevron-up' : 'chevron-down'"
                                    :size="FontAwesomeSize.lg"
                                    class="ml-2"
                                />
                            </disclosure-button>
                            <animated-disclosure-panel :open="open">
                                <div class="grid grid-cols-1 gap-2 pb-4">
                                    <logo-selection-sub-category
                                        v-for="subCategory in category.children"
                                        :key="subCategory.id"
                                        :sub-category="subCategory"
                                    />
                                </div>
                            </animated-disclosure-panel>
                        </headlessui-disclosure>
                    </div>
                    <div class="grow" />
                    <template v-if="activeCategories.length">
                        <div
                            class="mt-4 shrink-0 space-x-2 overflow-x-auto whitespace-nowrap"
                        >
                            <button
                                v-for="category in activeCategories"
                                :key="category.id"
                                type="button"
                                class="mb-2 inline-flex rounded-full bg-black px-4 py-2 text-white"
                                @click="logosStore.toggleCategory(category)"
                            >
                                {{ category.name }}
                                <f-a-icon
                                    icon="times"
                                    :size="FontAwesomeSize.lg"
                                    class="ml-3 inline-flex h-6 w-6 items-center justify-center rounded-full bg-white text-black"
                                />
                            </button>
                        </div>
                        <hr class="mb-4 mt-2 border border-secondary-2" />
                    </template>
                    <div class="flex space-x-2">
                        <button
                            v-if="activeCategoryIds.length"
                            class="w-full rounded-full border border-secondary-2 px-4 py-3 hover:bg-secondary-3"
                            @click="activeCategoryIds = []"
                        >
                            {{ t("clear_filters") }}
                        </button>
                        <button
                            class="w-full rounded-full bg-primary-1 px-4 py-3 text-white hover:bg-opacity-80"
                            type="button"
                            @click="isOpen = false"
                        >
                            {{ t("show_X_results", { count: resultCount }) }}
                        </button>
                    </div>
                </base-dialog-panel>
            </transition-child>
        </div>
    </base-dialog>
</template>

<script setup lang="ts">
import { useDesignerScreenSizeName } from "@/composables/useDesignerScreenSizeName";
import { ScreenName } from "@/enums/ScreenName";
import { FontAwesomeSize } from "@/enums/fontAwesome/fontAwesomeSize";
import { useLogoCategoriesStore } from "@/store/useLogoCategoriesStore";
import { useLogosStore } from "@/store/useLogosStore";
import {
    DisclosureButton,
    Disclosure as HeadlessuiDisclosure,
    TransitionChild,
} from "@headlessui/vue";
import { storeToRefs } from "pinia";
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";

const isOpen = defineModel<boolean>("open", { required: true });

const { t } = useI18n();
const logosStore = useLogosStore();
const { activeCategoryIds, activeCategories, resultCount } =
    storeToRefs(logosStore);
const { dataTree: categoriesTree } = storeToRefs(useLogoCategoriesStore());

const activeDisclosure = ref<{ key: number; close(): void }>();

function handleDisclosureButtonClick(key: number, close: () => void) {
    if (activeDisclosure.value?.key !== key) activeDisclosure.value?.close();
    activeDisclosure.value = { key, close };
}

const { screenName } = useDesignerScreenSizeName();
watch(screenName, (screenName) => {
    if (screenName !== ScreenName.MOBILE) {
        isOpen.value = false;
    }
});
</script>

<template>
    <div
        :data-testid="`logo-selection-logo-${logo.id}`"
        class="flex flex-col rounded-3xl border border-secondary-2"
    >
        <div
            class="relative cursor-pointer rounded-t-3xl bg-secondary-3 p-6"
            @click="emit('select')"
        >
            <f-a-icon
                v-if="productionMethodIcon"
                class="absolute left-2.5 top-2.5"
                :icon="productionMethodIcon"
            />
            <div
                v-if="isLoading"
                class="aspect-square animate-pulse bg-secondary-2"
            />
            <transition
                appear
                as="template"
                enter-active-class="ease-out duration-300"
                enter-from-class="opacity-0"
                enter-to-class="opacity-100"
                leave-active-class="ease-in duration-200"
                leave-from-class="opacity-100"
                leave-to-class="opacity-0"
            >
                <img
                    v-if="!isLoading"
                    :src="logo.preview_url"
                    alt="logo"
                    class="aspect-square w-full object-contain"
                />
            </transition>
        </div>
        <div class="flex grow flex-col gap-1 px-4 py-3 text-center">
            <tooltip class="mb-1 break-words text-xs font-bold">
                {{ truncate(logo.name, 60) }}
                <template v-if="logo.name.length > 60" #tooltip>
                    {{ logo.name }}
                </template>
            </tooltip>
            <div class="grow" />
            <div class="text-xs">
                {{ logo.width?.toLocaleString() }} x
                {{ logo.height?.toLocaleString() }} cm
            </div>
            <div class="flex items-center justify-center gap-1 text-xs">
                <div>
                    <img
                        src="@/assets/icons/icon-design-colors.svg"
                        class="h-4"
                    />
                </div>
                <div>
                    {{
                        t(
                            logo.color_assignments.length === 1
                                ? "mono_colored"
                                : "multi_colored",
                        )
                    }}
                </div>
            </div>
            <button
                type="button"
                class="mt-2 flex justify-center rounded-full bg-secondary-3 py-1 hover:bg-secondary-2"
                @click="emit('select')"
            >
                <f-a-icon icon="plus" class="mr-2" />
                {{ t("choose") }}
            </button>
        </div>
    </div>
</template>

<script setup lang="ts">
import { productionMethodIconMap } from "@/functions/ProductionMethodIcon";
import { truncate } from "@/lib/truncate";
import { useProductionMethodStore } from "@/store/useProductionMethodStore";
import type { LogoResourceData } from "@smakecloud/smake-use";
import { useImage } from "@vueuse/core";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const props = defineProps<{ logo: LogoResourceData }>();
const { isLoading } = useImage({ src: props.logo.preview_url });
const { t } = useI18n();

const emit = defineEmits<{
    select: [];
}>();
const { currentActiveProductionMethods } = storeToRefs(
    useProductionMethodStore(),
);

const productionMethodIcon = computed(() => {
    const productionMethod = currentActiveProductionMethods.value.find(
        (productionMethod) =>
            productionMethod.id === props.logo.production_method.id,
    );

    if (!productionMethod) {
        return undefined;
    }

    return productionMethodIconMap[productionMethod.handle];
});
</script>

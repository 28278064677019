import { BootStepException } from "@/lib/Exception/BootStepException";
import { useInitialVariantStore } from "@/store/useInitialVariantStore";
import type { BootAble } from "@/support/BootAble";
import { type VariantIdentifier } from "@/types/DesignerProps";
import { useDesignerApi, type VariantData } from "@smakecloud/smake-use";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";

import useRenderCustomizationPreview from "@/composables/useRenderCustomizationPreview";
import { useVariantChecker } from "@/composables/variants/useVariantIdentifierChecker";

export default class BootRenderInitialCustomizations implements BootAble {
    async getVariantData(
        initialVariant: VariantIdentifier,
    ): Promise<VariantData> {
        const designerApi = useDesignerApi();
        const { isVariantId, isVariantExternIdentifier } = useVariantChecker();

        if (isVariantId(initialVariant)) {
            return await designerApi.endpoints.variants.getById(
                initialVariant.id,
            );
        }

        if (isVariantExternIdentifier(initialVariant)) {
            return await designerApi.endpoints.variants.getByExternalIdentifier(
                initialVariant.external_identifier,
            );
        }

        throw Error("Variant id or external_identifier is required");
    }

    async boot(): Promise<void> {
        const { initialVariant } = storeToRefs(useInitialVariantStore());
        const { renderVariantData } = useRenderCustomizationPreview();

        const { t } = useI18n();

        if (!initialVariant.value) {
            throw new BootStepException(
                null,
                t("boot_error.variant_not_found"),
            );
        }

        await renderVariantData(initialVariant.value);
    }
}

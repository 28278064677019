<template>
    <module-button
        v-if="!isPanelOpen"
        :style-config="moduleButtonStyleConfig"
        :disabled="isDisabled"
        icon="icon-x"
        @click="showPanel(PanelState.ProductColors)"
    >
        {{ t("product_colors") }}
    </module-button>
</template>

<script setup lang="ts">
import { PanelState } from "@/enums/PanelState";
import { isAddMode } from "@/enums/mode";
import { useCurrentStore } from "@/store/useCurrentStore";
import { useMainStore } from "@/store/useMainStore";
import { usePanelStore } from "@/store/usePanelStore";
import cloneDeep from "lodash/cloneDeep";
import get from "lodash/get";
import merge from "lodash/merge";
import set from "lodash/set";
import { storeToRefs } from "pinia";
import { computed, getCurrentInstance } from "vue";
import { useI18n } from "vue-i18n";

const { settings } = useMainStore();
const currentStore = useCurrentStore();
const panelStore = usePanelStore();

const { showPanel } = panelStore;
const { isPanelOpen } = storeToRefs(panelStore);
const { currentMode } = storeToRefs(currentStore);
const instance = getCurrentInstance();
const { t } = useI18n();

const props = withDefaults(
    defineProps<{
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        styleConfig: any;
    }>(),
    {
        styleConfig: () => {
            return {};
        },
    },
);

// computed
const isActive = computed<boolean>(() => {
    return instance?.proxy?.$route.name === "productColors";
});

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const moduleButtonStyleConfig = computed<any>(() => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const baseStyleConfig = cloneDeep(props.styleConfig);
    const statePrefix = isActive.value ? "active." : "";

    [
        "styles",
        "icon.styles",
        "label.styles",
        "disabled.styles",
        "disabled.icon.styles",
        "disabled.label.styles",
    ].forEach((key) => {
        set(
            baseStyleConfig,
            key,
            merge(
                get(baseStyleConfig, key, []),
                get(
                    settings.style,
                    `productColorsButton.${statePrefix}${key}`,
                    [],
                ),
            ),
        );
    });

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return baseStyleConfig;
});

// computed
const isDisabled = computed<boolean>(() => {
    return isAddMode(currentMode.value);
});
</script>

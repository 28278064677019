import { type BootContext } from "@/lib";
import initAvailableVariants from "@/store/initializers/initAvailableVariants";
import initCartVariants from "@/store/initializers/initCartVariants";
import initConfigurations from "@/store/initializers/initConfigurations";
import initCurrent from "@/store/initializers/initCurrent";
import initFontAwesomeKitIcons from "@/store/initializers/initFontAwesomeKitIcons";
import initPositioningAreaCanvasObjects from "@/store/initializers/initPositioningAreaCanvasObjects";
import { type BootAble } from "@/support/BootAble";
import { type DesignerProps } from "@/types/DesignerProps";
import { withCtx, type ComponentInternalInstance } from "vue";

export default class BootStore implements BootAble {
    async boot(ctx: BootContext): Promise<void> {
        const designerProps = ctx.props;

        await initConfigurations(designerProps, ctx.instance);
        await this.initStores(designerProps, ctx.instance);
    }

    private async initStores(
        designerProps: DesignerProps,
        instance: ComponentInternalInstance | null,
    ): Promise<void> {
        initAvailableVariants(designerProps.availableVariants);
        initCartVariants(designerProps.cartItems);

        await Promise.all([initFontAwesomeKitIcons(), initCurrent()]);

        withCtx(initPositioningAreaCanvasObjects, instance)();
    }
}

export type CMYKColor = {
    cyan: number;
    key: number;
    magenta: number;
    yellow: number;
};

export type HSVColor = {
    hue: number;
    saturation: number;
    value: number;
};

export type RGBColor = {
    blue: number;
    green: number;
    red: number;
};

export function cmykToHsv(cmyk: CMYKColor): HSVColor {
    return rgbToHsv(cmykToRgb(cmyk));
}

export function cmykToRgb(cmyk: CMYKColor): RGBColor {
    const { cyan, key, magenta, yellow } = cmyk;

    return {
        blue: Math.round(255 * (1 - yellow / 100) * (1 - key / 100)),
        green: Math.round(255 * (1 - magenta / 100) * (1 - key / 100)),
        red: Math.round(255 * (1 - cyan / 100) * (1 - key / 100)),
    };
}

export function hexToHsv(hex: string) {
    if (!isValidHex(hex)) return;

    // Expand shorthand hex (#RGB -> #RRGGBB)
    if (hex.length === 4) {
        hex = `#${hex[1] ?? ""}${hex[1] ?? ""}${hex[2] ?? ""}${hex[2] ?? ""}${hex[3] ?? ""}${hex[3] ?? ""}`;
    }

    const rgb = hexToRgb(hex);

    if (!rgb) {
        return;
    }

    return rgbToHsv(rgb);
}

export function hexToRgb(hex: string) {
    if (!isValidHex(hex)) return;

    // Expand shorthand hex (#RGB -> #RRGGBB)
    if (hex.length === 4) {
        hex = `#${hex[1] ?? ""}${hex[1] ?? ""}${hex[2] ?? ""}${hex[2] ?? ""}${hex[3] ?? ""}${hex[3] ?? ""}`;
    }

    return {
        blue: Number.parseInt(hex.slice(5, 7), 16),
        green: Number.parseInt(hex.slice(3, 5), 16),
        red: Number.parseInt(hex.slice(1, 3), 16),
    };
}

export function hsvToCmyk(hsv: HSVColor): CMYKColor {
    const rgb = hsvToRgb(hsv);

    return rgbToCmyk(rgb);
}

export function hsvToHex(hsv: HSVColor) {
    return rgbToHex(hsvToRgb(hsv));
}

export function hsvToRgb(hsvColor: HSVColor): RGBColor {
    const { hue: hue_, saturation: saturation_, value: value_ } = hsvColor;

    const hue = hue_ / 360;
    const saturation = saturation_ / 100;
    const value = value_ / 100;

    let red = 0;
    let green = 0;
    let blue = 0;

    const index = Math.floor(hue * 6);
    const hueFraction = hue * 6 - index;
    const minValue = value * (1 - saturation);
    const decreasingValue = value * (1 - hueFraction * saturation);
    const increasingValue = value * (1 - (1 - hueFraction) * saturation);

    switch (index % 6) {
        case 0:
            red = value;
            green = increasingValue;
            blue = minValue;
            break;
        case 1:
            red = decreasingValue;
            green = value;
            blue = minValue;
            break;
        case 2:
            red = minValue;
            green = value;
            blue = increasingValue;
            break;
        case 3:
            red = minValue;
            green = decreasingValue;
            blue = value;
            break;
        case 4:
            red = increasingValue;
            green = minValue;
            blue = value;
            break;
        case 5:
            red = value;
            green = minValue;
            blue = decreasingValue;
            break;
    }

    return {
        blue: Math.round(blue * 255),
        green: Math.round(green * 255),
        red: Math.round(red * 255),
    };
}

export function isRGBEqual(rgbA: RGBColor, rgbB: RGBColor) {
    return (
        rgbA.red === rgbB.red &&
        rgbA.green === rgbB.green &&
        rgbA.blue === rgbB.blue
    );
}

export function isValidHex(hex: string) {
    return /^#([0-9A-Fa-f]{3}|[0-9A-Fa-f]{6})$/.test(hex);
}

export function rgbToCmyk(rgb: RGBColor): CMYKColor {
    const { blue: _blue, green: _green, red: _red } = rgb;

    const red = _red / 255;
    const blue = _blue / 255;
    const green = _green / 255;

    const key = 1 - Math.max(red, green, blue);

    if (key === 1)
        return {
            cyan: 0,
            key: 100,
            magenta: 0,
            yellow: 0,
        };

    return {
        cyan: ((1 - red - key) / (1 - key)) * 100,
        key: key * 100,
        magenta: ((1 - green - key) / (1 - key)) * 100,
        yellow: ((1 - blue - key) / (1 - key)) * 100,
    };
}

export function rgbToHex({ blue, green, red }: RGBColor) {
    return `#${((1 << 24) | (red << 16) | (green << 8) | blue).toString(16).slice(1).toUpperCase()}`;
}

export function rgbToHsv(rgbColor: RGBColor): HSVColor {
    const { blue: blue_, green: green_, red: red_ } = rgbColor;

    const red = red_ / 255;
    const green = green_ / 255;
    const blue = blue_ / 255;

    const cMax = Math.max(red, green, blue);
    const cMin = Math.min(red, green, blue);
    const delta = cMax - cMin;

    let hue = 0;

    if (delta !== 0) {
        switch (cMax) {
            case blue:
                hue = (red - green) / delta + 4;
                break;
            case green:
                hue = (blue - red) / delta + 2;
                break;
            case red:
                hue = (green - blue) / delta + (green < blue ? 6 : 0);
                break;
        }

        hue *= 60;
    }

    const saturation = cMax === 0 ? 0 : delta / cMax;

    return {
        hue,
        saturation: saturation * 100,
        value: cMax * 100,
    };
}

<template>
    <module-button
        icon="images"
        :style-config="moduleButtonStyleConfig"
        add-icon
        :disabled="isDisabled"
        @click="testError"
    >
        {{ t("add_logo") }}
    </module-button>
</template>

<script setup lang="ts">
import { isAddMode } from "@/enums/mode";
import { useCurrentStore } from "@/store/useCurrentStore";
import { useMainStore } from "@/store/useMainStore";
import cloneDeep from "lodash/cloneDeep";
import get from "lodash/get";
import merge from "lodash/merge";
import set from "lodash/set";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const { settings } = useMainStore();
const { t } = useI18n();
const { currentMode } = storeToRefs(useCurrentStore());

//props
const props = defineProps({
    styleConfig: {
        type: Object,
        default: () => {
            return {};
        },
    },
});

// computed
const moduleButtonStyleConfig = computed(() => {
    const baseStyleConfig = cloneDeep(props.styleConfig);

    ["styles", "icon.styles", "label.styles", "addIcon.styles"].forEach(
        (key) => {
            set(
                baseStyleConfig,
                key,
                merge(
                    get(baseStyleConfig, key, []),
                    get(settings.style, `logosButton.${key}`, []),
                ),
            );
        },
    );

    return baseStyleConfig;
});

// methods
function testError() {
    //TODO notification erzeugen
}

const isDisabled = computed<boolean>(() => {
    return isAddMode(currentMode.value);
});
</script>

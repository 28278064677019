<template>
    <button
        class="mx-1 my-1.5 flex h-8 w-8 cursor-pointer items-center justify-center rounded-md bg-secondary-3 hover:bg-secondary-2"
        type="button"
        @click="rotation = rotateAngleClockwise(rotation)"
    >
        <f-a-icon icon="rotate-right" />
    </button>
</template>

<script setup lang="ts">
const rotation = defineModel<number>({
    required: true,
});

function rotateAngleClockwise(angle: number): number {
    return (Math.round(angle / 90) * 90 + 90) % 360;
}
</script>

<template>
    <preview-card
        :name="customerLogo.name ?? customerLogo.logo.name"
        :external-identifier="
            customerLogo.logo.external_identifier ?? undefined
        "
        :image="customerLogo.logo.preview_url"
        :width="customerLogo.logo.width"
        :height="customerLogo.logo.height"
        :color-assignments="
            customerLogo.logo
                .color_assignments as unknown as ApiLogoDesign['resource']['color_assignments']
        "
        :show-rainbow-icon="customerLogo.logo.color_assignments.length === 0"
        :production-method-id="customerLogo.logo.production_method.id"
        @rename="isRenameDialogOpen = true"
        @select="emit('select')"
    />
    <base-dialog v-model:is-open="isRenameDialogOpen">
        <rename-dialog-panel
            :image="customerLogo.logo.preview_url"
            :name="customerLogo.name ?? customerLogo.logo.name"
            :error
            :mutation-status="status"
            @rename="rename({ id: customerLogo.id, name: $event })"
        />
    </base-dialog>
</template>

<script setup lang="ts">
import { apiClient, type ApiLogoDesign } from "@smakecloud/designer-core";
import { type CustomerLogoResourceData } from "@smakecloud/smake-use";
import { useMutation } from "@tanstack/vue-query";
import { ref, watchEffect } from "vue";

defineProps<{ customerLogo: CustomerLogoResourceData }>();

const emit = defineEmits<{
    select: [];
}>();

const isRenameDialogOpen = ref(false);

const {
    error,
    status,
    mutate: rename,
    reset,
} = useMutation(apiClient.customerLogos.patch.use());

watchEffect(() => {
    if (isRenameDialogOpen.value) {
        return;
    }

    reset();
});
</script>

<template>
    <div
        class="mx-4 hidden shrink-0 overflow-x-auto text-nowrap py-6 tablet:flex tablet:flex-wrap tablet:items-center tablet:gap-2"
    >
        <logo-selection-category
            v-for="category in categoriesTree"
            :key="category.id"
            :category="category"
        />
        <template v-if="activeCategoryIds.length">
            <div class="border border-secondary-2" />
            <button
                type="button"
                class="group flex items-center rounded-full border border-secondary-2 hover:bg-secondary-3"
                @click="activeCategoryIds = []"
            >
                <span class="py-2.5 pl-6">{{ t("clear_filters") }}</span>
                <f-a-icon
                    icon="times"
                    :size="FontAwesomeSize.lg"
                    class="ml-2 mr-0.5 flex h-10 w-10 shrink-0 items-center justify-center rounded-full bg-black text-white group-hover:bg-black/80"
                />
            </button>
        </template>
        <smu-feature :name="FeatureFlags.LOGO_SEARCH">
            <div class="grid grid-cols-1 items-center">
                <input
                    v-model="_searchQuery"
                    type="text"
                    class="col-start-1 row-start-1 block h-12 w-40 rounded-xl bg-white py-2 pl-6 pr-10 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-1 focus:-outline-offset-1 focus:outline-black sm:pr-9 sm:text-sm"
                    :placeholder="$t('search_logo')"
                />

                <f-a-icon
                    class="pointer-events-none col-start-1 row-start-1 mr-3 self-center justify-self-end text-black"
                    icon="magnifying-glass"
                />
            </div>
        </smu-feature>
        <div class="grow" />
        <div class="flex items-center gap-2">
            <div>
                <select-production-method
                    v-model="productionMethod"
                    :is-disabled="true"
                />
            </div>
            <span class="ml-2 self-center font-bold">
                {{ resultCount }} {{ t("icon", resultCount === 1 ? 1 : 2) }}
            </span>
        </div>
    </div>
    <div class="mt-2 flex flex-col gap-2 tablet:hidden">
        <div class="flex w-full gap-2 pb-2">
            <button
                type="button"
                class="relative flex aspect-square w-16 items-center justify-center rounded-full border"
                :class="
                    activeCategoryIds.length
                        ? 'border-black bg-black text-white hover:bg-black/80'
                        : 'border-secondary-2 hover:bg-secondary-3'
                "
                @click="logoSelectionCategoryMobileDialogOpen = true"
            >
                <f-a-icon
                    icon="sliders"
                    :class="{ 'text-black': !activeCategoryIds.length }"
                />
                <div
                    v-if="activeCategoryIds.length"
                    class="absolute right-0 top-0 aspect-square -translate-y-1/4 translate-x-1/4 rounded-full border-2 border-black bg-white px-1.5 text-black"
                >
                    {{ activeCategoryIds.length }}
                </div>
            </button>

            <smu-feature :name="FeatureFlags.LOGO_SEARCH">
                <button
                    class="flex aspect-square w-16 items-center justify-center rounded-full border"
                    :class="{
                        'border border-black bg-black text-white':
                            showMobileSearch,
                        'border-secondary-2': !showMobileSearch,
                    }"
                    @click="setShowMobileSearch(!showMobileSearch)"
                >
                    <f-a-icon icon="magnifying-glass" />
                </button>
            </smu-feature>
            <select-production-method
                v-model="productionMethod"
                class="w-full"
            />
        </div>
        <div
            v-show="showMobileSearch"
            ref="mobileSearch"
            class="grid grid-cols-1 items-center border-b border-t border-secondary-2 transition-all duration-300"
            :class="{
                'h-full': showMobileSearch,
                'h-0': !showMobileSearch,
            }"
        >
            <input
                v-model="_searchQuery"
                type="text"
                class="col-start-1 row-start-1 block h-12 w-full bg-white pl-6 pr-10 outline-none placeholder:text-gray-400 sm:pr-9 sm:text-sm"
                :placeholder="$t('search_logo')"
            />

            <f-a-icon
                class="pointer-events-none col-start-1 row-start-1 mr-3 self-center justify-self-end text-black"
                icon="magnifying-glass"
            />
        </div>
    </div>
    <logo-selection-category-mobile-dialog
        v-model:open="logoSelectionCategoryMobileDialogOpen"
    />
</template>

<script setup lang="ts">
import { FeatureFlags } from "@/enums/FeatureFlags";
import { FontAwesomeSize } from "@/enums/fontAwesome/fontAwesomeSize";
import type ProductionMethod from "@/repo/ProductionMethod";
import { useLogoCategoriesStore } from "@/store/useLogoCategoriesStore";
import { useLogosStore } from "@/store/useLogosStore";
import { SmuFeature, useAnimatedHeight } from "@smakecloud/smake-use";
import { watchDebounced } from "@vueuse/core";
import { storeToRefs } from "pinia";
import { ref, watchEffect } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const {
    dataTree: categoriesTree,
    activeProductionMethods: activeProductionMethodsCategorieTree,
} = storeToRefs(useLogoCategoriesStore());
const { activeCategoryIds, resultCount, activeProductionMethods, searchQuery } =
    storeToRefs(useLogosStore());

const _searchQuery = ref<string>();
watchDebounced(
    _searchQuery,
    () => {
        searchQuery.value = _searchQuery.value;
    },
    {
        debounce: 300,
        maxWait: 1500,
    },
);

const showMobileSearch = ref(false);
const mobileSearch = ref<HTMLDivElement>();
const { animateHeight } = useAnimatedHeight(mobileSearch);

async function setShowMobileSearch(value: boolean) {
    if (showMobileSearch.value === value) return;
    await animateHeight(() => {
        showMobileSearch.value = !showMobileSearch.value;
    });
}

const productionMethod = defineModel<ProductionMethod>("productionMethod", {
    required: true,
});

watchEffect(() => {
    activeProductionMethods.value = [productionMethod.value.id];
    activeProductionMethodsCategorieTree.value = [productionMethod.value.id];
});

const logoSelectionCategoryMobileDialogOpen = ref(false);
</script>

<template>
    <button
        class="flex w-full items-center rounded-full border border-secondary-2 p-2 enabled:hover:bg-secondary-3"
        :disabled
        type="button"
    >
        <div
            class="mr-2 aspect-square h-8 rounded-full border border-secondary-2"
            :style="{
                backgroundColor: color,
            }"
        />
        <div class="overflow-hidden text-ellipsis">{{ colorName }}</div>
        <div class="grow" />
        <f-a-icon
            v-if="!disabled"
            icon="chevron-right"
            :size="FontAwesomeSize.lg"
        />
    </button>
</template>

<script setup lang="ts">
import { FontAwesomeSize } from "@/enums/fontAwesome/fontAwesomeSize";
import { useColorStore } from "@/store/useColorStore";
import type { ApiTextDesign } from "@smakecloud/designer-core";
import { storeToRefs } from "pinia";
import { toRefs, computed } from "vue";
import { useI18n } from "vue-i18n";

const props = defineProps<{
    colorAssignment: ApiTextDesign["resource"]["lines"][number]["color_assignments"][number];
    disabled: boolean;
}>();
const { colorAssignment } = toRefs(props);

const { colorsData } = storeToRefs(useColorStore());

const color = computed<string>(() => {
    if (colorAssignment.value.color) {
        const color = colorsData.value?.find(
            (color) => color.id === colorAssignment.value.color?.id,
        );

        if (!color) {
            return "";
        }

        return `rgb(${color.red}, ${color.green}, ${color.blue})`;
    }

    return `rgb(${colorAssignment.value.red}, ${colorAssignment.value.green}, ${colorAssignment.value.blue})`;
});

const { t } = useI18n();
const colorName = computed<string>(() => {
    if (colorAssignment.value.color) {
        const color = colorsData.value?.find(
            (color) => color.id === colorAssignment.value.color?.id,
        );

        if (!color) {
            return "";
        }

        return color.display_name;
    }

    return t("custom");
});
</script>

<template>
    <module-button
        v-if="!isInternal"
        name="moduleButton"
        class="max-[1199px]:hidden"
        :icon="FontAwesomeKitIcons.Download"
        module="pdfDownload"
        :style-config="moduleButtonStyleConfig"
        :disabled="isDisabled"
        :loading="isPdfCurrentlyRendered"
        @click="storeCurrentVariantPdf"
    >
        {{ t("pdf_download") }}
    </module-button>
</template>

<script setup lang="ts">
import useCreatePdf from "@/composables/variants/useCreatePdf";
import { FontAwesomeKitIcons } from "@/enums/fontAwesome/fontAwesomeKitIcons";
import { isAddMode } from "@/enums/mode";
import { useCurrentStore } from "@/store/useCurrentStore";
import { useMainStore } from "@/store/useMainStore";
import { usePanelStore } from "@/store/usePanelStore";
import { useSettingsStore } from "@/store/useSettingsStore";
import cloneDeep from "lodash/cloneDeep";
import get from "lodash/get";
import merge from "lodash/merge";
import set from "lodash/set";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const currentStore = useCurrentStore();
const { storeCurrentVariantPdf } = useCreatePdf();
const { currentMode, currentVariantHasCustomizations } =
    storeToRefs(currentStore);
const { isPanelOpen } = storeToRefs(usePanelStore());
const { settings } = useMainStore();
const { t } = useI18n();
const { isPdfCurrentlyRendered } = storeToRefs(currentStore);
const { isInternal } = storeToRefs(useSettingsStore());

// props
const props = defineProps({
    styleConfig: {
        type: Object,
        default: () => {
            return {};
        },
    },
});

// computed
const moduleButtonStyleConfig = computed(() => {
    const baseStyleConfig = cloneDeep(props.styleConfig);

    [
        "styles",
        "icon.styles",
        "label.styles",
        "disabled.styles",
        "disabled.icon.styles",
        "disabled.label.styles",
    ].forEach((key) => {
        set(
            baseStyleConfig,
            key,
            merge(
                get(baseStyleConfig, key, []),
                get(settings.style, `pdfDownloadButton.${key}`, []),
            ),
        );
    });

    return baseStyleConfig;
});

// computed
const isDisabled = computed<boolean>(() => {
    return (
        isAddMode(currentMode.value) ||
        isPanelOpen.value ||
        isPdfCurrentlyRendered.value ||
        !currentVariantHasCustomizations.value
    );
});
</script>

<template>
    <div class="px-4">
        <!-- So it doesn't overlap with the close button -->
        <div class="float-right h-8 w-8" />
        <div class="mb-1 text-2xl font-bold">
            {{ t("icon_database").toUpperCase() }}
        </div>
        <span class="hidden tablet:block">
            {{ t("icon_database_description") }}
        </span>
    </div>
    <hr class="mx-4 mt-4 hidden border border-secondary-2 tablet:block" />
    <logo-selection-categories v-model:production-method="productionMethod" />
    <div
        v-if="isFetching || logos?.length"
        ref="logosContainer"
        v-on-scroll-end="onScrollEnd"
        class="grid grid-cols-[repeat(auto-fill,minmax(150px,1fr))] gap-4 overflow-y-auto px-4 pt-2 tablet:grid-cols-[repeat(auto-fill,minmax(180px,1fr))]"
    >
        <logo-selection-logo
            v-for="logo in logos"
            :key="logo.id"
            :logo="logo"
            @select="emit('logo-selected', cloneDeep(logo))"
        />
        <template v-if="isFetching">
            <div class="h-80 animate-pulse rounded-3xl bg-secondary-3" />
            <div class="h-80 animate-pulse rounded-3xl bg-secondary-3" />
        </template>
    </div>
    <div v-else class="flex h-full items-center justify-center text-2xl">
        <span class="max-w-lg text-center italic text-secondary-1">
            {{
                t("no_motifs", {
                    productionMethod: productionMethod.display_name,
                })
            }}
        </span>
    </div>
</template>

<script setup lang="ts">
import vOnScrollEnd from "@/directives/vOnScrollEnd";
import type ProductionMethod from "@/repo/ProductionMethod";
import { useLogosStore } from "@/store/useLogosStore";
import { useProductionMethodStore } from "@/store/useProductionMethodStore";
import { apiClient } from "@smakecloud/designer-core";
import { type LogoResourceData } from "@smakecloud/smake-use";
import { useInfiniteQuery } from "@tanstack/vue-query";
import { cloneDeep } from "lodash";
import { storeToRefs } from "pinia";
import { computed, ref, watch, type Ref } from "vue";
import { useI18n } from "vue-i18n";

const emit = defineEmits<{
    "logo-selected": [LogoResourceData];
}>();

const { t } = useI18n();
const { activeCategoryIds, searchQuery } = storeToRefs(useLogosStore());
const { currentProductionMethod } = storeToRefs(useProductionMethodStore());

const logosContainer = ref<HTMLDivElement>();
const productionMethod = ref(
    currentProductionMethod.value,
) as Ref<ProductionMethod>;

watch(activeCategoryIds, () => {
    logosContainer.value?.scroll(0, 0);
});

const filters = computed<typeof apiClient.logos.list.inputType>(() => ({
    "categories.id": activeCategoryIds.value,
    production_method: [productionMethod.value.id],
    name_or_tags:
        (searchQuery.value?.length ?? 0) > 0 ? searchQuery.value : undefined,
}));

const {
    data: logos,
    isFetching,
    fetchNextPage,
} = useInfiniteQuery({
    ...apiClient.logos.list.use(filters),
    select: (data) => data.pages.flatMap((page) => page.data),
    staleTime: 60 * 1000,
});

function onScrollEnd() {
    if (isFetching.value) return;

    void fetchNextPage();
}
</script>

import type { Plugin } from "vue";
import * as Sentry from "@sentry/vue";

const integrations = [
    Sentry.replayIntegration({
        mask: [".mask-me"],
        maskAllText: false,
        maskAllInputs: false,
        blockAllMedia: false,
        beforeErrorSampling: (event) =>
            !event.exception?.values?.every(
                (exception) => exception.type === "EndpointValidationError",
            ),
    }),
    Sentry.replayCanvasIntegration(),
    Sentry.vueIntegration(),
    Sentry.browserTracingIntegration(),
    Sentry.globalHandlersIntegration({
        onerror: false,
        onunhandledrejection: false,
    }),
    Sentry.extraErrorDataIntegration(),
];

export const SentryPlugin: Plugin = {
    install: (app) => {
        Sentry.init({
            tunnel: import.meta.env.VITE_APP_SENTRY_TUNNEL,
            app,
            dsn: import.meta.env.VITE_APP_SENTRY_DSN,
            enabled: !import.meta.env.DEV,
            integrations,
            // Session Replay
            replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
            replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        });
    },
};

import { type InnerAnchorPoints } from "@smakecloud/smake-use";

export enum PositionPartVertical {
    TOP = "t",
    CENTER = "c",
    BOTTOM = "b",
}

export enum PositionPartHorizontal {
    LEFT = "l",
    CENTER = "c",
    RIGHT = "r",
}

export function getVerticalPositionPartFromPositionAreaPosition(
    position: InnerAnchorPoints,
): PositionPartVertical {
    return position.split("")[0] as PositionPartVertical;
}

export function getHorizontalPositionPartFromPositionAreaPosition(
    position: InnerAnchorPoints,
): PositionPartHorizontal {
    return position.split("")[1] as PositionPartHorizontal;
}

import { ScreenName } from "@/enums/ScreenName";
import { useMainStore } from "@/store/useMainStore";
import { useElementSize, useMediaQuery } from "@vueuse/core";
import { defineStore, storeToRefs } from "pinia";
import { computed } from "vue";

// We want to only use the store in this file.
// The media queries will be created in a store so that they will be created
// only once and save performance.
const useScreenNameStore = defineStore("screenName", () => {
    const isDesktop = useMediaQuery("(min-width: 1280px)");
    const isTablet = useMediaQuery("(min-width: 640px)");

    const screenName = computed(() => {
        if (isDesktop.value) return ScreenName.DESKTOP;

        if (isTablet.value) return ScreenName.TABLET;

        return ScreenName.MOBILE;
    });

    return {
        screenName,
    };
});

export const useDesignerScreenSizeName = () => {
    const { designerWrapper } = storeToRefs(useMainStore());
    const { width } = useElementSize(designerWrapper);

    const { screenName } = storeToRefs(useScreenNameStore());

    return {
        screenName,
        width,
    };
};

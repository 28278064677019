import { hasVariantSupportedPositioningAreas } from "@/functions/ProductionMethodInfo";
import { useCurrentStore } from "@/store/useCurrentStore";
import { storeToRefs } from "pinia";
import { computed, toRef, type MaybeRefOrGetter } from "vue";

export function useProductionMethodInfo(
    productionMethodId: MaybeRefOrGetter<number>,
) {
    const { currentVariant } = storeToRefs(useCurrentStore());

    const productionMethodIdRef = toRef(productionMethodId);

    const hasCurrentVariantSupportedPositioningAreas = computed(() =>
        hasVariantSupportedPositioningAreas(
            currentVariant.value,
            productionMethodIdRef.value,
        ),
    );

    return {
        hasCurrentVariantSupportedPositioningAreas,
    };
}

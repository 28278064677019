import useRouteToPanel from "@/composables/useRouteToPanel";
import { type PanelShowState, PanelState } from "@/enums/PanelState";
import { defineStore } from "pinia";
import { computed, readonly, ref } from "vue";

export const usePanelStore = defineStore("panelStore", () => {
    const { routeToPanel } = useRouteToPanel();
    const currentPanelState = ref<PanelState>(PanelState.None);
    const isPanelOpen = computed<boolean>(
        () => currentPanelState.value !== PanelState.None,
    );

    async function showPanel(panelState: PanelShowState) {
        if (panelState === currentPanelState.value) {
            return;
        }

        currentPanelState.value = panelState;
        await routeToPanel(panelState);
    }

    async function hideCurrentPanel() {
        currentPanelState.value = PanelState.None;
        await routeToPanel(PanelState.None);
    }

    return {
        currentPanelState: readonly(currentPanelState),
        isPanelOpen,
        hideCurrentPanel,
        showPanel,
    };
});

import { apiClient } from "@smakecloud/designer-core";
import { useQuery } from "@tanstack/vue-query";
import { defineStore } from "pinia";
import { ref, computed } from "vue";

export const useCustomerLogoCountStore = defineStore(
    "customerLogoCount",
    () => {
        const activeProductionMethods = ref<number[] | undefined>();
        const filters = computed<
            typeof apiClient.customerLogos.count.inputType
        >(() => ({
            production_method: activeProductionMethods.value,
        }));

        const query = useQuery({
            ...apiClient.customerLogos.count.use(filters),
            staleTime: Infinity,
            retry: false,
        });

        return {
            ...query,
            activeProductionMethods,
        };
    },
);

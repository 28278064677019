<template>
    <div class="flex w-full justify-between gap-2 hover:cursor-pointer">
        <img
            class="aspect-square h-12 rounded-full object-cover"
            :src="productionMethod.preview_url"
        />
        <div
            class="flex flex-col items-center justify-center gap-1 p-1 font-semibold"
        >
            <div>
                {{ productionMethod.display_name }}
            </div>
            <div v-if="productionMethodSummary" class="text-xs">
                {{ productionMethodSummary }}
            </div>
        </div>
        <div></div>
    </div>
</template>
<script setup lang="ts">
import { useProductionMethodDescriptions } from "@/composables/useProductionMethodDescriptions";
import type { ProductionMethodData } from "@smakecloud/smake-use";
import { computed, toRefs } from "vue";

const props = defineProps<{
    productionMethod: ProductionMethodData;
    selected?: boolean;
    disabled?: boolean;
}>();

const { productionMethod } = toRefs(props);

const { productionShortDescriptionMap } = useProductionMethodDescriptions();

const productionMethodSummary = computed(() => {
    return productionShortDescriptionMap[productionMethod.value.handle];
});
</script>

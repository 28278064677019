import type { DesignImage } from "@/types/DesignImage";
import { fabric } from "fabric";

export async function fabricImageFromUrl(url: string): Promise<fabric.Image> {
    return new Promise((resolve) => {
        fabric.Image.fromURL(url, resolve);
    });
}

export async function updateDesignImageBase64(
    designImage: DesignImage,
    base64: string,
): Promise<DesignImage> {
    return new Promise((resolve) => {
        designImage.setSrc("data:image/jpeg;base64," + base64, resolve);
    });
}

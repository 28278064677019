import { type HorizontalAlignment } from "@/enums/HorizontalAlignment";
import ColorAssignment from "@/repo/ColorAssignment";
import type { FontData } from "@smakecloud/smake-use";
import { Model } from "pinia-orm";
import { NumberCast } from "pinia-orm/dist/casts";
import {
    Attr,
    Cast,
    HasManyBy,
    Num,
    Str,
    Uid,
} from "pinia-orm/dist/decorators";

export default class TextResourceLine extends Model {
    static entity = "textResourceLines";

    @Uid()
    id!: number | string;

    @Str("")
    value!: string;

    font!: FontData;

    @Num(0)
    font_size!: number;

    @Num(0)
    padding_bottom!: number;

    @Str("")
    horizontal_alignment!: HorizontalAlignment;

    @Cast(() => NumberCast)
    @Attr(0)
    bending_radius!: number;

    @Num(0)
    order_column!: number;

    @Attr([])
    color_assignment_ids!: number[];

    @HasManyBy(() => ColorAssignment, "color_assignment_ids")
    color_assignments!: ColorAssignment[];

    @Str("")
    created_at!: string;

    @Str("")
    updated_at!: string;

    @Num(0)
    offset!: number;
}

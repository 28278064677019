<template>
    <button
        type="button"
        class="flex flex-col items-center text-sm [overflow-wrap:anywhere]"
    >
        <div
            class="mb-2 rounded-full border p-1"
            :class="selected ? 'border-black' : 'border-transparent'"
        >
            <div
                :style="backgroundStyle"
                class="flex aspect-square h-12 items-center justify-center rounded-full border border-secondary-2 bg-cover bg-center bg-no-repeat"
                :class="{ 'text-white': isColorDarkColor }"
            >
                {{ label }}
            </div>
        </div>
        <slot />
    </button>
</template>

<script setup lang="ts">
import { isDarkColor } from "@/lib/color";
import { computed } from "vue";

const props = defineProps<{
    selected: boolean;
    fill: [number, number, number] | string;
    label?: string;
}>();

const backgroundStyle = computed(() => {
    const fill = props.fill;
    if (Array.isArray(fill))
        return {
            backgroundColor: `rgb(${fill[0]}, ${fill[1]}, ${fill[2]})`,
        };
    return { backgroundImage: `url(${fill})` };
});

const isColorDarkColor = computed(() => {
    if (!Array.isArray(props.fill)) return false;

    return isDarkColor(props.fill);
});
</script>

import { useSetup } from "@/lib/setup";
import type {
    ZSKLogoDesign,
    ZSKTextDesign,
} from "@/modules/core/renderer/zskEmbroidery/types/ZSKDesign";
import Customization from "@/repo/Customization";
import Variant from "@/repo/Variant";
import View from "@/repo/View";
import { useCanvasStore } from "@/store/useCanvasStore";
import { useCurrentStore } from "@/store/useCurrentStore";
import {
    isDesignElementLogo,
    isDesignElementText,
    type VariantData,
} from "@smakecloud/smake-use";
import { storeToRefs } from "pinia";
import { useRepo } from "pinia-orm";

export default () => {
    const variantsRepo = useRepo(Variant);
    const { currentVariant } = storeToRefs(useCurrentStore());
    const canvasStore = useCanvasStore();
    const { isBooting } = useSetup();

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/require-await
    async function saveVariantResponseToRepository(
        variantResponse: VariantData,
    ): Promise<VariantData> {
        const variantAttributes = {
            ...variantResponse,
            color: variantResponse.attributes.filter(
                (optionValue) => optionValue.name === "color",
            )[0],
            size: variantResponse.attributes.filter(
                (optionValue) => optionValue.name === "size",
            )[0],
            views: variantResponse.views.map((view) => {
                return {
                    ...view,
                    customizations: [],
                };
            }),
        };

        removeExistingVariantData(variantResponse.id);

        variantsRepo.save(variantAttributes);

        return variantResponse;
    }

    function saveVariantResponseWithOldCustomizatonsToRepository(
        variantResponse: VariantData,
    ): Variant {
        const variantAttributes = {
            ...variantResponse,
            color: variantResponse.attributes.filter(
                (optionValue) => optionValue.name === "color",
            )[0],
            size: variantResponse.attributes.filter(
                (optionValue) => optionValue.name === "size",
            )[0],
        };

        const oldCustomizationIds: { ids: number[]; view_handle: string }[] =
            [];

        currentVariant.value.views.forEach((view) => {
            oldCustomizationIds.push({
                ids: view.customization_ids,
                view_handle: view.handle,
            });
        });

        const variant = variantsRepo.save(variantAttributes);

        variant.views.forEach((view) => {
            view.customization_ids =
                oldCustomizationIds.find(
                    (oldCustomizationId) =>
                        oldCustomizationId.view_handle === view.handle,
                )?.ids ?? view.customization_ids;
        });

        variantsRepo.save(variant);

        // Workaround as we did not manage to get the polymorphic relation to work
        variantResponse.views.forEach((view) =>
            view.customizations.forEach((customization) => {
                if (isDesignElementLogo(customization)) {
                    useRepo(Customization).save({
                        ...customization,
                        design: {
                            logo: customization.resource,
                            logoDesignElementData: {
                                dimension: customization.dimension,
                            },
                        } satisfies ZSKLogoDesign,
                    });
                }

                if (isDesignElementText(customization)) {
                    useRepo(Customization).save({
                        ...customization,
                        design: {
                            text: customization.resource,
                        } satisfies ZSKTextDesign,
                    });
                }
            }),
        );

        return variant;
    }

    function removeExistingVariantData(variantId: number): void {
        if (isBooting.value) {
            return;
        }

        if (variantId !== currentVariant.value.id) {
            // currently cascading does not work with composite keys in pinia-orm
            variantsRepo.destroy(variantId);
            useRepo(View).where("variant_id", variantId).delete();

            return;
        }

        /**
         * If the currentVariant is saved again, we need to remove the old data first.
         * In this case we can not just simply delete the variant and the views, as we need to always have a currentVariant.
         * So we only remove the customizations
         */
        const views = useRepo(View).where("variant_id", variantId).get();

        views.forEach((view) => {
            useRepo(View).save({
                ...view,
                positioning_area_ids: [],
            });

            const customizations = useRepo(Customization)
                .query()
                .whereIn("positioningArea_id", view.positioning_area_ids)
                .get();

            customizations.forEach((customization) => {
                canvasStore.removeTextCanvasObjectByCustomization(
                    customization,
                );
            });
        });
    }

    return {
        saveVariantResponseToRepository,
        saveVariantResponseWithOldCustomizatonsToRepository,
    };
};

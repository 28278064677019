<template>
    <div
        class="w-full select-none bg-secondary-3 py-2"
        @mousedown="startDragging"
        @mousemove="onMouseMove"
        @mouseup="stopDragging"
        @mouseleave="stopDragging"
        @touchstart="startDragging"
        @touchmove="onTouchMove"
        @touchend="stopDragging"
    >
        <div
            class="c flex h-32 w-full flex-col items-center"
            :class="{
                'cursor-pointer': !isDragging,
                'cursor-grabbing': isDragging,
            }"
            @click="onSetSlideViaClick"
        >
            <div
                ref="container"
                class="relative h-full w-1 rounded-full bg-white"
            >
                <div
                    class="absolute bottom-0 left-0 w-full"
                    :style="{ height: valuePercent + '%' }"
                />
                <div
                    class="absolute left-1/2 h-4 w-4 translate-x-[-50%] translate-y-1/2 rounded-full bg-black"
                    :style="{ bottom: valuePercent + '%' }"
                    :class="{
                        'cursor-grab': !isDragging,
                        'cursor-grabbing': isDragging,
                    }"
                />
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { useCanvasStore } from "@/store/useCanvasStore";
import { clamp, debounce } from "lodash";
import { storeToRefs } from "pinia";
import { ref, watch } from "vue";

const { currentZoom, zoomData, changeCurrentZoom } =
    storeToRefs(useCanvasStore());

// data
const valuePercent = ref<number>(getPercentFromValue(currentZoom.value));
const isDragging = ref<boolean>(false);
const container = ref<HTMLElement>();

// watch
watch(
    () => currentZoom.value,
    () => {
        valuePercent.value = getPercentFromValue(currentZoom.value);
        changeCurrentZoom.value = true;
    },
);

// methods
function startDragging(): void {
    isDragging.value = true;

    if (!container.value) {
        return;
    }

    // safari workaround as otherwise the cursor will be a text-select cursor
    container.value.onselectstart = function () {
        return false;
    };
}

function stopDragging(): void {
    if (!isDragging.value) {
        return;
    }

    isDragging.value = false;

    if (!container.value) {
        return;
    }

    // safari workaround as otherwise the cursor will be a text-select cursor
    container.value.onselectstart = function () {
        return true;
    };

    currentZoom.value = getValueFromPercent(valuePercent.value);
}

function getSlideContainerRect(): DOMRect | null {
    return container.value?.getBoundingClientRect() ?? null;
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line @typescript-eslint/require-await
const onMouseMove = debounce(async (e: MouseEvent) => {
    if (!isDragging.value) {
        return;
    }

    setSliderValue(e.clientY);
}, 1);

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line @typescript-eslint/require-await
const onTouchMove = debounce(async (e: TouchEvent) => {
    if (!isDragging.value) {
        return;
    }

    setSliderValue(e.touches[0].clientY);
}, 1);

function onSetSlideViaClick(e: MouseEvent) {
    setSliderValue(e.clientY);

    currentZoom.value = getValueFromPercent(valuePercent.value);
}

function setSliderValue(verticalPosition: number): void {
    const slideContainerRect = getSlideContainerRect();

    if (!slideContainerRect) {
        return;
    }

    valuePercent.value =
        100 -
        ((clamp(
            verticalPosition,
            slideContainerRect.top,
            slideContainerRect.bottom,
        ) -
            slideContainerRect.top) /
            slideContainerRect.height) *
            100;
}

function getPercentFromValue(value: number): number {
    return (
        ((value - zoomData.value.min) /
            (zoomData.value.max - zoomData.value.min)) *
        100
    );
}

function getValueFromPercent(percent: number): number {
    return (
        ((zoomData.value.max - zoomData.value.min) * percent) / 100 +
        zoomData.value.min
    );
}
</script>

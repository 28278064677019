<script setup lang="ts">
import { useNumberInput } from "@/composables/useNumberInput";
import { toRefs, useFocus } from "@vueuse/core";
import { ref } from "vue";
const props = defineProps<{
    max?: number;
    min?: number;
    step?: number;
}>();
const { max, min, step } = toRefs(props);

const value = defineModel<number>({ required: true });

const input = ref<HTMLInputElement>();

const { focused } = useFocus(input);

const { inputValue, onBlur } = useNumberInput(value, input, { max, min, step });

defineExpose({
    focused,
});
</script>
<template>
    <input
        ref="input"
        v-model="inputValue"
        class="designer-number-input-component"
        :min
        :max
        :step
        type="number"
        @blur="onBlur"
    />
</template>

import { ProductionMethodHandle } from "@/composables/useProductionMethodFeatures";
import { type VariantData } from "@smakecloud/smake-use";
import { defineStore } from "pinia";
import { computed, ref } from "vue";

export const useInitialVariantStore = defineStore("initialVariantStore", () => {
    const initialVariant = ref<VariantData | null>(null);

    const initialvariantCustomizations = computed(
        () =>
            initialVariant.value?.views.flatMap(
                (view) => view.customizations,
            ) ?? [],
    );

    const isInitalVariantEmpty = computed(
        () => initialvariantCustomizations.value.length === 0,
    );

    const isInitialVariantDTFOnly = computed(() =>
        initialvariantCustomizations.value.every(
            (customization) =>
                (customization.positioningArea.production_method
                    .handle as ProductionMethodHandle) ===
                ProductionMethodHandle.DTF,
        ),
    );

    const isInitialVariantEmbroideryOnly = computed(() =>
        initialvariantCustomizations.value.every(
            (customization) =>
                (customization.positioningArea.production_method
                    .handle as ProductionMethodHandle) ===
                ProductionMethodHandle.EMBROIDERY,
        ),
    );

    return {
        initialVariant,

        isInitalVariantEmpty,
        isInitialVariantDTFOnly,
        isInitialVariantEmbroideryOnly,
    };
});

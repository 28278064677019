import { Model } from "pinia-orm";
import { Str, Uid } from "pinia-orm/dist/decorators";

export default class ProductionType extends Model {
    static entity = "productionTypes";

    @Uid()
    id!: number;

    @Str("")
    handle!: string;
}

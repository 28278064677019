<template>
    <div>
        <div class="relative text-gray-700">
            <input
                ref="textInput"
                v-model="line.value"
                class="block h-12 w-full rounded-md border px-6 py-2 pr-12 focus:border-black focus:outline-none sm:text-sm"
                :class="{
                    'border-black': markFocused,
                    'border-secondary-2': !markFocused,
                }"
                type="text"
                :placeholder="t('insert_text')"
                @keydown.tab.exact.prevent="emit('keydown-tab')"
                @keydown.shift.tab.exact.prevent="emit('keydown-shift-tab')"
                @keydown.enter.prevent="emit('keydown-enter')"
                @focus.prevent="emit('focus')"
                @input="emit('value-changed', line)"
            />
            <div class="absolute inset-y-0 right-3 flex items-center">
                <button
                    type="button"
                    class="h-8 w-8 rounded-full bg-secondary-3 text-xl text-black hover:bg-secondary-2 focus:outline-none disabled:text-secondary-1"
                    @click="emit('remove')"
                >
                    <f-a-icon :icon="FontAwesomeKitIcons.X" class="text-xs" />
                </button>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { FontAwesomeKitIcons } from "@/enums/fontAwesome/fontAwesomeKitIcons";
import type { TextResourceLineData } from "@smakecloud/smake-use";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";

const line = defineModel<TextResourceLineData>({ required: true });

withDefaults(
    defineProps<{
        markFocused: boolean;
    }>(),
    {
        markFocused: false,
    },
);
const emit = defineEmits<{
    "value-changed": [TextResourceLineData];
    "keydown-tab": [];
    "keydown-shift-tab": [];
    "keydown-enter": [];
    remove: [];
    focus: [];
}>();

const { t } = useI18n();
const textInput = ref<HTMLInputElement>();
const lineUuid = computed<string>(() => {
    if (!line.value.uuid) {
        throw Error("Line uuid is required");
    }

    return line.value.uuid;
});

function focus() {
    textInput.value?.focus();
}

defineExpose({
    focus,
    lineUuid,
});
</script>

<template>
    <div class="fixed inset-0 flex items-center justify-center">
        <transition-child
            appear
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
        >
            <base-dialog-panel
                class="overflow-y-auto p-8 [overflow-wrap:anywhere]"
                has-close-button
            >
                <div class="flex max-w-xl flex-col items-center gap-4">
                    <div class="w-full text-2xl font-bold uppercase">
                        <f-a-icon
                            v-if="toProductionMethodIcon"
                            class="mr-2 inline-block"
                            :icon="toProductionMethodIcon"
                        />
                        {{ toProductionMethod.display_name }}
                        {{ t("icon") }}
                    </div>
                    <div
                        class="flex items-center rounded-xl border border-secondary-2 p-3"
                    >
                        <f-a-icon
                            class="mr-3 flex shrink-0 items-center justify-center"
                            icon="circle-exclamation"
                            :size="FontAwesomeSize.lg"
                        />
                        <div class="font-semibold">
                            {{
                                t("change_production_method_info", {
                                    fromProductionMethod:
                                        currentProductionMethod.display_name,
                                })
                            }}
                        </div>
                    </div>
                    <div class="whitespace-pre-line">
                        {{
                            t("to_place_motif_change_production_method", {
                                toProductionMethod:
                                    toProductionMethod.display_name,
                            })
                        }}
                    </div>
                    <div class="w-full text-left font-bold">
                        {{ t("preview_of_possibile_positioning_areas") }}:
                    </div>
                    <available-positioning-areas-preview
                        class="w-full max-w-[369px]"
                        :selected-production-method-id="toProductionMethod.id"
                    />
                    <div
                        class="flex w-full flex-col-reverse justify-between gap-4 tablet:flex-row"
                    >
                        <button-secondary-outlined @click="isOpen = false">
                            {{ t("abort") }}
                        </button-secondary-outlined>
                        <button-primary @click="onConfirm">
                            {{
                                t("change_to_x", {
                                    x: toProductionMethod.display_name,
                                })
                            }}
                        </button-primary>
                    </div>
                </div>
            </base-dialog-panel>
        </transition-child>
    </div>
</template>

<script setup lang="ts">
import { useInjectDialogData } from "@/composables/useDialogData";
import { FontAwesomeSize } from "@/enums/fontAwesome/fontAwesomeSize";
import { productionMethodIconMap } from "@/functions/ProductionMethodIcon";
import type ProductionMethod from "@/repo/ProductionMethod";
import { useProductionMethodStore } from "@/store/useProductionMethodStore";
import { TransitionChild } from "@headlessui/vue";
import { storeToRefs } from "pinia";
import { computed, toRefs } from "vue";
import { useI18n } from "vue-i18n";

const props = defineProps<{
    toProductionMethod: ProductionMethod;
}>();
const { toProductionMethod } = toRefs(props);

const emit = defineEmits<{
    resolve: [boolean];
}>();

const { isOpen } = useInjectDialogData();
const { currentProductionMethod } = storeToRefs(useProductionMethodStore());
const { t } = useI18n();

const toProductionMethodIcon = computed(
    () => productionMethodIconMap[toProductionMethod.value.handle],
);

function onConfirm() {
    emit("resolve", true);
}
</script>

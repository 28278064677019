<template>
    <base-dialog v-model:is-open="isGlobalProductionMethodSelectionCompactOpen">
        <div class="absolute max-h-full w-full overflow-auto">
            <div
                class="mt-5 flex max-h-full w-full flex-col items-center justify-center gap-4 overflow-y-auto"
            >
                <production-method-button
                    ref="production-method-button"
                    @click="showSelection = false"
                />
                <transition-root
                    :show="showSelection"
                    as="template"
                    leave-from="opacity-100"
                    leave="duration-200 ease-in"
                    leave-to="opacity-0 -translate-y-[50px]"
                    @after-leave="
                        isGlobalProductionMethodSelectionCompactOpen = false
                    "
                >
                    <base-dialog-panel
                        stay-open-on-backdrop-click
                        class="h-full p-5"
                        :initial-focus="productionMethodButtonRef?.$el"
                    >
                        <div class="text-xl font-bold tracking-wider">
                            {{ title }}
                        </div>
                        <div>
                            <div class="flex flex-col gap-5 bg-white pt-10">
                                <select-prodution-method-button
                                    v-for="productionMethod in currentActiveProductionMethods"
                                    :key="productionMethod.id"
                                    class="w-full"
                                    :production-method="productionMethod"
                                    :is-selected="
                                        currentProductionMethod.handle ===
                                        productionMethod.handle
                                    "
                                    :readonly
                                    @click="
                                        selectProductionMethod(productionMethod)
                                    "
                                />
                            </div>
                            <button-secondary-gray
                                class="mt-10 flex w-full justify-center gap-3 font-semibold"
                                @click="openProductionMethodSelection"
                            >
                                <div>
                                    {{ t("go_to_production_method_overview") }}
                                </div>
                                <f-a-icon
                                    icon="chevron-right"
                                    :size="FontAwesomeSize.lg"
                                />
                            </button-secondary-gray>
                        </div>
                    </base-dialog-panel>
                </transition-root>
            </div>
        </div>
    </base-dialog>
</template>

<script setup lang="ts">
import { storeToRefs } from "pinia";
import type { ProductionMethodData } from "@smakecloud/smake-use";
import { computed, ref, useTemplateRef, watchEffect } from "vue";
import { FontAwesomeSize } from "@/enums/fontAwesome/fontAwesomeSize";
import { useI18n } from "vue-i18n";
import { useProductionMethodStore } from "@/store/useProductionMethodStore";
import ProductionMethodButton from "./ProductionMethodButton.vue";
import { TransitionRoot } from "@headlessui/vue";
import { useProductionMethodDialogStore } from "@/store/useProductionMethodDialogStore";

const { t } = useI18n();

const readonly = computed(() => showProductionMethodSelectionAsInfo.value);

const {
    showProductionMethodSelectionAsInfo,
    isGlobalProductionMethodSelectionCompactOpen,
    isGlobalProductionMethodSelectionOpen,
} = storeToRefs(useProductionMethodDialogStore());

const { currentActiveProductionMethods } = storeToRefs(
    useProductionMethodStore(),
);

const { changeCurrentProductionMethod } = useProductionMethodStore();
const { currentProductionMethod } = storeToRefs(useProductionMethodStore());

const title = computed(() => {
    if (showProductionMethodSelectionAsInfo.value) {
        return t("production_method").toUpperCase();
    }

    return t("choose_production_method").toUpperCase();
});

function selectProductionMethod(productionMethod: ProductionMethodData) {
    changeCurrentProductionMethod(productionMethod);

    showSelection.value = false;
}

function openProductionMethodSelection() {
    isGlobalProductionMethodSelectionOpen.value = true;
    isGlobalProductionMethodSelectionCompactOpen.value = false;
}

const productionMethodButtonRef = useTemplateRef("production-method-button");

const showSelection = ref(true);

watchEffect(() => {
    if (isGlobalProductionMethodSelectionCompactOpen.value) {
        showSelection.value = true;
    }
});
</script>

import {
    type ZSKDesign,
    type ZSKLogoDesign,
    type ZSKLogoTextDesign,
    type ZSKTextDesign,
} from "@/modules/core/renderer/zskEmbroidery/types/ZSKDesign";
import type Variant from "@/repo/Variant";

export function isZSKTextDesign(
    design: ZSKDesign | null,
): design is ZSKTextDesign {
    if (design === null) {
        return false;
    }

    if (design.logo) {
        return false;
    }

    return !!design.text;
}

export function isZSKLogoDesign(
    design: ZSKDesign | null,
): design is ZSKLogoDesign {
    if (design === null) {
        return false;
    }

    if (design.text) {
        return false;
    }

    return !!design.logo;
}

export function isZSKLogoTextDesign(
    design: ZSKDesign | null,
): design is ZSKLogoTextDesign {
    if (design === null) {
        return false;
    }

    return !!design.logo && !!design.text;
}

export function isZSKDesignWithText(
    design: ZSKDesign | null,
): design is ZSKTextDesign | ZSKLogoTextDesign {
    return isZSKLogoTextDesign(design) || isZSKTextDesign(design);
}

export function isZSKDesignWithLogo(
    design: ZSKDesign | null,
): design is ZSKLogoDesign | ZSKLogoTextDesign {
    return isZSKLogoTextDesign(design) || isZSKLogoDesign(design);
}

export function isDesignWithRenderInformation(design: ZSKDesign | null) {
    if (design === null) {
        return false;
    }

    if (isZSKDesignWithLogo(design)) {
        return true;
    }

    if (!isZSKTextDesign(design)) {
        return false;
    }

    return (
        design.text.lines.filter((line) => line.value.trim() !== "").length > 0
    );
}

export function isVariantWithRenderableCustomizations(variant: Variant) {
    return variant.views.some((view) =>
        view.customizations.some((customization) =>
            isDesignWithRenderInformation(customization.design),
        ),
    );
}

<template>
    <div
        ref="designerRoot"
        class="smake-designer relative h-full w-full leading-normal"
    >
        <template v-if="isAuthed && isInitialized">
            <suspense>
                <designer
                    v-bind="parsedProps"
                    @custom-variant-created="emitCustomVariantCreated"
                    @session-expired="emitSessionExpired"
                    @variant-changed="emitVariantChanged"
                />
                <template #fallback>
                    <div
                        class="absolute bottom-0 left-0 right-0 top-0 flex flex-col items-center justify-center gap-4 rounded-md bg-white"
                    >
                        <div
                            class="text-md flex items-center gap-2 text-primary-1"
                        >
                            <div>{{ currentProgress }}%</div>
                        </div>
                        <div
                            class="h-2.5 w-1/4 rounded-full bg-secondary-2 dark:bg-gray-700"
                        >
                            <div
                                class="h-2.5 rounded-full bg-primary-1"
                                :style="`width: ${currentProgress}%`"
                            />
                        </div>
                    </div>
                </template>
            </suspense>
        </template>
        <div
            v-else
            class="h-full w-full animate-pulse rounded-3xl bg-secondary-3 p-3"
        ></div>
    </div>
</template>

<script setup lang="ts">
import Designer from "@/Designer.vue";
import pinia from "@/store";
import { type CustomVariantCreatedEvent } from "@/types/CustomVariantCreatedEvent";
import { type DesignerLocale, type DesignerProps } from "@/types/DesignerProps";
import { useApiClientPusher, usePusherStore } from "@smakecloud/designer-core";
import {
    useDesignerApi,
    useFeatureFlags,
    type StoreVariantRequest,
} from "@smakecloud/smake-use";
import { storeToRefs } from "pinia";
import { computed, onUnmounted, provide, watchEffect } from "vue";
import { useInitDesignerApi } from "./composables/useInitDesignerApi";
import { useSetupStore } from "./store/useSetupStore";
import { useColorStore } from "./store/useColorStore";
import { useCurrentVariantStore } from "./store/useCurrentVariantStore";
import { useProductionMethodStore } from "./store/useProductionMethodStore";
import { useSettingsStore } from "./store/useSettingsStore";
import { FeatureFlags } from "./enums/FeatureFlags";
import { useDesignerRootStore } from "./store/useDesignerRootStore";
import { usePropsStore } from "./store/usePropsStore";

provide("", pinia);

const { currentProgress } = storeToRefs(useSetupStore());

const { designerRoot } = storeToRefs(useDesignerRootStore());

function parseAttributeValue<T>(value: unknown): T {
    if (typeof value === "string") {
        return JSON.parse(value) as T;
    }

    return value as T;
}

const emit = defineEmits([
    "custom-variant-created",
    "session-expired",
    "variant-changed",
]);

const props = withDefaults(defineProps<Partial<DesignerProps>>(), {
    token: () => {
        if (!import.meta.env.VITE_APP_API_TOKEN) {
            throw Error("Token is required");
        }

        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return import.meta.env.VITE_APP_API_TOKEN;
    },
    settings: null,
    variant: () => {
        if (
            !import.meta.env.VITE_APP_VARIANT_ID &&
            !import.meta.env.VITE_APP_VARIANT_EXTERNAL_IDENTIFIER
        ) {
            throw Error("Variant id or external_identifier is required");
        }

        return {
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            id: import.meta.env.VITE_APP_VARIANT_ID,
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            external_identifier: import.meta.env
                .VITE_APP_VARIANT_EXTERNAL_IDENTIFIER,
        };
    },
    user: () => {
        return {
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            external_identifier:
                import.meta.env.VITE_APP_USER_EXTERNAL_IDENTIFIER ?? undefined,
        };
    },
    pusher: () => {
        return {
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            appKey: import.meta.env.VITE_APP_PUSHER_APP_KEY,
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            channelPrefix: import.meta.env.VITE_APP_PUSHER_CHANNEL_PREFIX,
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            cluster: import.meta.env.VITE_APP_PUSHER_CLUSTER,
        };
    },
    locale: import.meta.env.VITE_APP_I18N_LOCALE as DesignerLocale,
    debug: false,
    apiBaseUrl: import.meta.env.VITE_APP_API_BASE_URL,
    availableVariants: () => [],
    cartItems: () => [],
    featureFlagLogoSearch: import.meta.env.DEV,
});

const pusher = computed(() =>
    // eslint-disable-next-line @typescript-eslint/no-base-to-string
    parseAttributeValue<DesignerProps["pusher"]>(props.pusher),
);

const user = computed(() =>
    // eslint-disable-next-line @typescript-eslint/no-base-to-string
    parseAttributeValue<DesignerProps["user"]>(props.user),
);

const variant = computed(() =>
    // eslint-disable-next-line @typescript-eslint/no-base-to-string
    parseAttributeValue<DesignerProps["variant"]>(props.variant),
);

const availableVariants = computed(() =>
    parseAttributeValue<DesignerProps["availableVariants"]>(
        props.availableVariants,
    ),
);

const cartItems = computed(() =>
    parseAttributeValue<DesignerProps["cartItems"]>(props.cartItems),
);

const parsedProps = computed<DesignerProps>(() => ({
    ...props,
    cartItems: cartItems.value,
    availableVariants: availableVariants.value,
    pusher: pusher.value,
    variant: variant.value,
    user: user.value,
}));

const { featureFlags } = useFeatureFlags();

watchEffect(() => {
    featureFlags.value.set(
        FeatureFlags.LOGO_SEARCH,
        props.featureFlagLogoSearch,
    );
});

const { isAuthed } = useInitDesignerApi(parsedProps);
const { resetGlobals } = useDesignerApi();

function emitCustomVariantCreated(event: CustomVariantCreatedEvent) {
    emit("custom-variant-created", event);
}

function emitSessionExpired() {
    emit("session-expired");
}
function emitVariantChanged(event: StoreVariantRequest | undefined) {
    emit("variant-changed", event);
}

const { appKey, cluster, channelPrefix } = storeToRefs(usePusherStore());

watchEffect(() => {
    appKey.value = pusher.value.appKey;
});

watchEffect(() => {
    cluster.value = pusher.value.cluster;
});

watchEffect(() => {
    channelPrefix.value = pusher.value.channelPrefix;
});

useApiClientPusher();

onUnmounted(resetGlobals);

const { isInitialized: areProductionMethodsInitialized } = storeToRefs(
    useProductionMethodStore(),
);

const { isInitialized: areColorsInitialized } = storeToRefs(useColorStore());

const { isInitialized: areSettingsInitialized } =
    storeToRefs(useSettingsStore());

const isInitialized = computed(
    () =>
        areProductionMethodsInitialized.value &&
        areColorsInitialized.value &&
        areSettingsInitialized.value,
);

useCurrentVariantStore();

const { customerLogoUploadInfo: customerLogoUploadInfoStore } =
    storeToRefs(usePropsStore());

watchEffect(() => {
    customerLogoUploadInfoStore.value = props.customerLogoUploadInfo;
});
</script>

<style>
@import "@/assets/css/main.css";
</style>

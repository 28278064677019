<template>
    <div
        class="flex flex-col overflow-hidden"
        :class="notificationStyle"
        :style="`opacity: ${opacityLevel}`"
    >
        <div class="flex grow justify-between">
            <f-a-icon
                :icon="getIcon()"
                :size="FontAwesomeSize.lg"
                :class="notificationIconStyle"
            />

            <span class="m-2 block self-center">
                <!-- eslint-disable-next-line vue/no-v-html -->
                <div v-html="notification.message"></div>
            </span>
            <div class="m-2 self-center">
                <button
                    v-if="notification.type === NotificationType.Info"
                    type="button"
                    @click="resolve"
                >
                    <f-a-icon
                        icon="icon-x"
                        :size="FontAwesomeSize.xs"
                        class="w-5 text-shadow"
                    />
                </button>
            </div>
        </div>
        <div
            v-if="notification.type === NotificationType.Success"
            class="h-0.5 bg-confirmation"
            :style="`width: ${displayBarPercentage}%;`"
        />
    </div>
</template>

<script setup lang="ts">
import { computed, onMounted } from "vue";
import type Notification from "@/repo/Notification";
import { useMainStore } from "@/store/useMainStore";
import { FontAwesomeSize } from "@/enums/fontAwesome/fontAwesomeSize";
import useNotification from "@/composables/useNotification";
import { NotificationType } from "@/enums/NotificationType";
import { FontAwesomeKitIcons } from "@/enums/fontAwesome/fontAwesomeKitIcons";
import useFadeOut from "@/composables/useFadeOut";

const { resolveNotification } = useNotification();
const mainStore = useMainStore();
const { settings } = mainStore;
const { startFadeOut, displayBarPercentage, opacityLevel } =
    useFadeOut(resolve);

// props
const props = defineProps<{
    notification: Notification;
}>();

// computed
const notificationStyle = computed(() => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access
    return settings.style.notification.styles;
});

const notificationTypeColors: Record<NotificationType, string> = {
    [NotificationType.Error]: "text-danger",
    [NotificationType.Info]: "text-info",
    [NotificationType.Success]: "text-confirmation",
};

const notificationIconStyle = computed(() => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
    return settings.style.notification.icon.styles.concat(
        notificationTypeColors[props.notification.type],
    );
});

function resolve() {
    resolveNotification(props.notification);
}

//methods

function getIcon() {
    if (props.notification.type === NotificationType.Success) {
        return FontAwesomeKitIcons.CiConfigAlertCheck;
    }

    return FontAwesomeKitIcons.CiConfigAlertWarning;
}

onMounted(() => {
    if (props.notification.type !== NotificationType.Success) {
        return;
    }

    startFadeOut();
});
</script>

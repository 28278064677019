<template>
    <module-button
        icon="save"
        :style-config="moduleButtonStyleConfig"
        :loading="isArchiving"
        :disabled="isDisabled"
        @click="storeCurrentVariantToArchive()"
    >
        {{ t("save_variant") }}
    </module-button>
</template>
<script setup lang="ts">
import useArchiveVariant from "@/composables/variants/useArchiveVariant";
import { isAddMode } from "@/enums/mode";
import { useCurrentStore } from "@/store/useCurrentStore";
import { useMainStore } from "@/store/useMainStore";
import { usePanelStore } from "@/store/usePanelStore";
import { cloneDeep, get, merge, set } from "lodash";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const currentStore = useCurrentStore();
const { currentMode, currentVariantHasCustomizations } =
    storeToRefs(currentStore);
const { isPanelOpen } = storeToRefs(usePanelStore());
const { settings } = useMainStore();
const { t } = useI18n();

const { storeCurrentVariantToArchive } = useArchiveVariant();

const { isPdfCurrentlyRendered, isArchiving } = storeToRefs(currentStore);

// props
const props = defineProps({
    styleConfig: {
        type: Object,
        default: () => {
            return {};
        },
    },
});

// computed
const moduleButtonStyleConfig = computed(() => {
    const baseStyleConfig = cloneDeep(props.styleConfig);

    ["styles", "icon.styles"].forEach((key) => {
        set(
            baseStyleConfig,
            key,
            merge(
                get(baseStyleConfig, key, []),
                get(settings.style, `menuModalButton.${key}`, []),
            ),
        );
    });

    return baseStyleConfig;
});

const isDisabled = computed<boolean>(() => {
    return (
        isAddMode(currentMode.value) ||
        isPanelOpen.value ||
        isPdfCurrentlyRendered.value ||
        !currentVariantHasCustomizations.value
    );
});
</script>

import Color from "@/repo/Color";
import { Model, type Element } from "pinia-orm";
import { BelongsTo, Num, Str, Uid } from "pinia-orm/dist/decorators";

export default class ColorAssignment extends Model {
    static entity = "colorAssignments";

    @Uid()
    id!: number;

    @Num(0)
    color_id!: number;

    @BelongsTo(() => Color, "color_id")
    color!: Color;

    @Str("")
    apply_to!: string;

    @Str("")
    created_at!: string;

    @Str("")
    updated_at!: string;

    get cssRgb(): string {
        const color: Element =
            this.$getRelation("color").related.$getOriginal();

        return `rgb(${color.red}, ${color.green}, ${color.blue})`;
    }
}

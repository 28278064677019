<template>
    <div class="absolute left-1/2 top-0 z-[70] -translate-x-1/2">
        <notification-box
            v-for="notification in currentNotifications"
            :key="notification.id"
            :notification="notification"
        />
    </div>
</template>

<script setup lang="ts">
import useNotification from "@/composables/useNotification";

const { currentNotifications } = useNotification();
</script>

<template>
    <div class="pointer-events-none absolute inset-0 z-10">
        <transition
            appear
            enter-active-class="ease-out duration-300"
            enter-from-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="ease-in duration-200"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
        >
            <div
                v-show="isShown"
                class="fixed inset-0 transition-opacity"
                aria-hidden="true"
                @click="closePanel"
            >
                <div />
            </div>
        </transition>

        <transition
            appear
            enter-active-class="ease-out duration-300"
            enter-from-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="ease-in duration-200"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
        >
            <div
                v-show="isShown"
                ref="panelContentRef"
                class="pointer-events-auto absolute bottom-0 h-full w-full bg-white tablet:right-0 tablet:w-[480px] tablet:bg-secondary-3 tablet:p-4"
            >
                <div class="flex h-full flex-col">
                    <div class="absolute right-3 top-3">
                        <button
                            v-if="canBeClosed"
                            class="flex h-8 w-8 items-center justify-center rounded-full border border-secondary-2 bg-white focus:outline-none tablet:hidden"
                            type="button"
                            @click="closePanel"
                        >
                            <f-a-icon icon="times" />
                        </button>
                    </div>
                    <div
                        class="min-h-12 flex-1 overflow-y-auto"
                        :style="{ scrollbarGutter: 'stable' }"
                    >
                        <slot />
                    </div>

                    <div v-if="$slots.footer" class="flex-0 pt-10">
                        <slot name="footer" />
                    </div>
                </div>

                <slot name="subPanels" />
            </div>
        </transition>
    </div>
</template>

<script setup lang="ts">
import { type PanelShowState } from "@/enums/PanelState";
import { useBoundingStore } from "@/store/useBoundingStore";
import { usePanelStore } from "@/store/usePanelStore";
import { useElementSize } from "@vueuse/core";
import { storeToRefs } from "pinia";
import { computed, onUnmounted, ref, watchEffect } from "vue";

const { panelContent } = storeToRefs(useBoundingStore());
const { hideCurrentPanel } = usePanelStore();

// setup
const panelContentRef = ref<HTMLElement | null>(null);

// props
const props = withDefaults(
    defineProps<{
        showOnPanelState: PanelShowState;
        canBeClosed?: boolean;
    }>(),
    {
        canBeClosed: true,
    },
);

// computed
const isShown = computed<boolean>(() => {
    return true;
    // return currentPanelState.value === props.showOnPanelState;
});

const { width: panelContentWidth, height: panelContentHeight } =
    useElementSize(panelContentRef);

watchEffect(() => {
    if (!panelContentRef.value) return;
    const panelContentBounding = panelContentRef.value.getBoundingClientRect();

    panelContent.value = {
        top: panelContentBounding.top,
        right: panelContentBounding.right,
        bottom: panelContentBounding.bottom,
        left: panelContentBounding.left,
        width: panelContentWidth.value,
        height: panelContentHeight.value,
        x: panelContentBounding.x,
        y: panelContentBounding.y,
    };
});

onUnmounted(() => {
    panelContent.value = {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        width: 0,
        height: 0,
        x: 0,
        y: 0,
    };
});
// methods
function closePanel() {
    if (!props.canBeClosed) {
        return;
    }

    void hideCurrentPanel();
}
</script>

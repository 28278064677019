import {
    hexToHsv,
    hsvToHex,
    hsvToRgb,
    rgbToHsv,
    cmykToHsv,
    hsvToCmyk,
    type CMYKColor,
    type HSVColor,
    type RGBColor,
    hexToRgb,
    isRGBEqual,
    cmykToRgb,
} from "@/utils/colorConversion";
import { computed, ref, type Ref } from "vue";

export function useColorConvertorHSV(color: Ref<HSVColor>) {
    const colorRGB = computed<RGBColor>({
        get() {
            return hsvToRgb(color.value);
        },
        set(rgb) {
            color.value = rgbToHsv(rgb);
        },
    });

    const _hex = ref<string>("#000");
    const colorHex = computed<string>({
        get() {
            // this ensures unnecessary updates are prevented as rgb has only one way to express a certain color
            const rgb = hexToRgb(_hex.value);

            if (!rgb) {
                throw new Error("unexpected error in hex to rgb conversion");
            }

            if (isRGBEqual(hsvToRgb(color.value), rgb)) return _hex.value;

            return hsvToHex(color.value);
        },
        set(hex) {
            const hsv = hexToHsv(hex);
            if (!hsv) return;
            _hex.value = hex;

            color.value = hsv;
        },
    });

    const _cmyk = ref<CMYKColor>({ cyan: 0, key: 0, magenta: 0, yellow: 0 });
    const colorCMYK = computed<CMYKColor>({
        get() {
            // this ensures unnecessary updates are prevented as rgb has only one way to express a certain color
            if (isRGBEqual(hsvToRgb(color.value), cmykToRgb(_cmyk.value)))
                return _cmyk.value;

            return hsvToCmyk({ ...color.value });
        },
        set(cymk) {
            _cmyk.value = { ...cymk };
            color.value = cmykToHsv({ ...cymk });
        },
    });

    return {
        colorCMYK,
        colorHex,
        colorRGB,
    };
}

<template>
    <div v-on-scroll-end="onScrollEnd" class="h-full overflow-y-auto pr-3">
        <div
            class="grid grid-cols-[repeat(auto-fill,minmax(261px,1fr))] gap-4 tablet:grid-cols-[repeat(auto-fill,minmax(355px,1fr))]"
        >
            <archive-variant-selection-group
                v-for="variant in data"
                :key="variant.id"
                :variant="variant"
            />
            <template v-if="isFetching">
                <div
                    class="h-96 animate-pulse rounded-3xl bg-secondary-3 px-5 py-8"
                />
                <div
                    class="h-96 animate-pulse rounded-3xl bg-secondary-3 px-5 py-8"
                />
            </template>
        </div>
    </div>
</template>

<script setup lang="ts">
import useArchiveVariant from "@/composables/variants/useArchiveVariant";
import vOnScrollEnd from "@/directives/vOnScrollEnd";

const { data, isFetching, fetchNextPage } = useArchiveVariant();

function onScrollEnd() {
    if (isFetching.value) {
        return;
    }
    void fetchNextPage();
}
</script>

import { ScreenName } from "@/enums/ScreenName";
import { PanelName } from "@/enums/PanelName";
import { ModuleName } from "@/enums/ModuleName";

export type LayoutConfiguration = {
    [key in ScreenName]: {
        [key in PanelName]: {
            modules: ModuleName[];
        };
    };
};

export const LayoutConfig: LayoutConfiguration = {
    [ScreenName.MOBILE]: {
        [PanelName.BUTTON_PANEL_TOP]: {
            modules: [],
        },
        [PanelName.BUTTON_PANEL_LEFT]: {
            modules: [],
        },
        [PanelName.BUTTON_PANEL_RIGHT]: {
            modules: [ModuleName.MENU],
        },
        [PanelName.BUTTON_PANEL_BOTTOM_1]: {
            modules: [
                ModuleName.PRODUCT_COLORS,
                ModuleName.TEXTS,
                ModuleName.CUSTOMIZATIONS,
                ModuleName.SHOW_AREAS,
            ],
        },
        [PanelName.BUTTON_PANEL_BOTTOM_2]: {
            modules: [ModuleName.ARCHIVE, ModuleName.STORE_VARIANT],
        },
    },
    [ScreenName.TABLET]: {
        [PanelName.BUTTON_PANEL_TOP]: {
            modules: [],
        },
        [PanelName.BUTTON_PANEL_LEFT]: {
            modules: [
                ModuleName.PRODUCT_ZOOM,
                ModuleName.PRODUCT_COLORS,
                ModuleName.SHOW_AREAS,
            ],
        },
        [PanelName.BUTTON_PANEL_RIGHT]: {
            modules: [
                ModuleName.TEXTS,
                ModuleName.CUSTOMIZATIONS,
                ModuleName.ARCHIVE,
                ModuleName.SAVE_VARIANT,
                ModuleName.PDF_DOWNLOAD,
            ],
        },
        [PanelName.BUTTON_PANEL_BOTTOM_1]: {
            modules: [],
        },
        [PanelName.BUTTON_PANEL_BOTTOM_2]: {
            modules: [ModuleName.STORE_VARIANT],
        },
    },
    [ScreenName.DESKTOP]: {
        [PanelName.BUTTON_PANEL_TOP]: {
            modules: [],
        },
        [PanelName.BUTTON_PANEL_LEFT]: {
            modules: [
                ModuleName.PRODUCT_ZOOM,
                ModuleName.PRODUCT_COLORS,
                ModuleName.SHOW_AREAS,
            ],
        },
        [PanelName.BUTTON_PANEL_RIGHT]: {
            modules: [
                ModuleName.TEXTS,
                ModuleName.CUSTOMIZATIONS,
                ModuleName.ARCHIVE,
                ModuleName.SAVE_VARIANT,
                ModuleName.PDF_DOWNLOAD,
            ],
        },
        [PanelName.BUTTON_PANEL_BOTTOM_1]: {
            modules: [],
        },
        [PanelName.BUTTON_PANEL_BOTTOM_2]: {
            modules: [ModuleName.STORE_VARIANT],
        },
    },
};

import type Variant from "@/repo/Variant";
import type { VariantData } from "@smakecloud/smake-use";

export function hasVariantSupportedPositioningAreas(
    variant: Variant,
    productionMethodId: number,
) {
    const variantPositioningAreas = variant.views.flatMap(
        (view) => view.positioning_areas,
    );

    return variantPositioningAreas.some(
        (positioningArea) =>
            positioningArea.production_method.id === productionMethodId,
    );
}

export function hasVariantDataSupportedPositioningAreas(
    variant: VariantData,
    productionMethodId: number,
) {
    const variantPositioningAreas = variant.views.flatMap(
        (view) => view.positioning_areas,
    );

    return variantPositioningAreas.some(
        (positioningArea) =>
            positioningArea.production_method.id === productionMethodId,
    );
}

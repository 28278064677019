import useConsoleLog from "@/composables/useConsoleLog";
import { bootConfig } from "@/config/boot.config";
import { LogColor } from "@/enums/LogColor";
import { BootStepException } from "@/lib/Exception/BootStepException";
import { useSetupStore } from "@/store/useSetupStore";
import { type DesignerProps } from "@/types/DesignerProps";
import { captureException } from "@sentry/vue";
import { storeToRefs } from "pinia";
import {
    getCurrentInstance,
    withCtx,
    type ComponentInternalInstance,
} from "vue";
import { useI18n } from "vue-i18n";

export interface BootContext {
    props: DesignerProps;
    instance: ComponentInternalInstance | null;
}

export const useSetup = () => {
    const { t } = useI18n();
    const { logTextDev, logErrorDev } = useConsoleLog();
    const { isBooting, bootErrorObject, finishedBootSteps, currentProgress } =
        storeToRefs(useSetupStore());
    const { startProgress, progressAdvance } = useSetupStore();
    const instance = getCurrentInstance();

    async function setupApp(props: DesignerProps) {
        startProgress(bootConfig.entries.length);

        logTextDev("Start Booting", LogColor.GREEN);

        try {
            for (const entry of bootConfig.entries) {
                logTextDev(
                    `🚀 Running ${entry.constructor.name}`,
                    LogColor.GREEN,
                );

                await withCtx(
                    () =>
                        entry.boot({
                            props,
                            instance,
                        }),
                    instance,
                )();
                progressAdvance();
                finishedBootSteps.value.push(entry.constructor.name);
            }
        } catch (e: unknown) {
            if (e instanceof BootStepException) {
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                logErrorDev(`❌ Boot Problem ${e.baseException}`);
                setReadableErrorMessage(e.readableMessage);
                throw e.baseException;
            }

            captureException(e);
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            logErrorDev(`❌ Boot Problem ${e}`);
            setReadableErrorMessage();
            throw e;
        }

        logTextDev("Finished Booting", LogColor.GREEN);

        isBooting.value = false;
    }

    function setReadableErrorMessage(specificErrorMessage?: string) {
        bootErrorObject.value = {
            headline: t("boot_error.headline"),
            errorMessage: specificErrorMessage ?? t("boot_error.unexpected"),
        };
    }

    return {
        setupApp,

        bootErrorObject,
        isBooting,
        bootingProgress: currentProgress,
    };
};

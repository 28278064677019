import Color from "@/repo/Color";
import { apiClient } from "@smakecloud/designer-core";
import { useQuery } from "@tanstack/vue-query";
import { defineStore } from "pinia";
import { useRepo } from "pinia-orm";
import { readonly, ref, watch } from "vue";

export const useColorStore = defineStore("colorStore", () => {
    const isInitialized = ref(false);

    const { data: colorsData } = useQuery({
        ...apiClient.configurations.app.get.use(),
        staleTime: Infinity,
        select: (data) => data.colors,
        retry: false,
    });

    const colorsRepo = useRepo(Color);

    watch(
        colorsData,
        () => {
            if (!colorsData.value) {
                return;
            }

            colorsRepo.save(
                colorsData.value.map((color) => {
                    return {
                        ...color,
                        production_method_ids: color.production_methods.map(
                            (productionMethod) => productionMethod.id,
                        ),
                    };
                }),
            );

            isInitialized.value = true;
        },
        { immediate: true },
    );

    return {
        isInitialized: readonly(isInitialized),
        colorsData,
    };
});

<template>
    <button
        v-if="!category.children.length"
        type="button"
        class="flex rounded-full border border-secondary-2 px-6 py-2.5"
        :class="
            activeCategories.length
                ? 'bg-black text-white hover:bg-black/80'
                : 'hover:bg-secondary-3'
        "
        @click="logosStore.toggleCategory(category)"
    >
        {{ category.name }}
    </button>
    <headlessui-popover v-else>
        <popover-button
            ref="popoverButtonRef"
            class="relative flex rounded-full border border-secondary-2 px-6 py-2.5"
            :class="
                activeCategories.length
                    ? 'bg-black text-white hover:bg-black/80'
                    : 'hover:bg-secondary-3'
            "
            @click="
                (($event as MouseEvent).target as HTMLElement | null)?.focus()
            "
        >
            {{ category.name }}
            <f-a-icon icon="chevron-down" class="ml-2" />
            <div
                v-if="activeSubCategories.length"
                class="absolute right-0 top-0 -translate-y-1/4 translate-x-1/4 rounded-full border-2 border-white bg-black px-1.5"
            >
                {{ activeSubCategories.length }}
            </div>
        </popover-button>
        <transition
            enter-active-class="transition-opacity duration-100 ease-out"
            enter-from-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="transition-opacity duration-75 ease-in"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
        >
            <popover-panel
                ref="popoverPanelRef"
                class="z-10 mt-2 overflow-hidden rounded bg-white text-sm shadow-[0px_0px_15px_0px_rgba(0,0,0,0.25)]"
                :style="floatingStyles"
            >
                <div class="grid grid-cols-2 gap-x-4 gap-y-2 p-6">
                    <logo-selection-sub-category
                        v-for="subCategory in category.children"
                        :key="subCategory.id"
                        :sub-category="subCategory"
                    />
                </div>
                <popover-button
                    class="w-full bg-primary-1 py-3 text-white hover:bg-opacity-80"
                    @click="selectCategory"
                >
                    {{
                        t("show_X_results", {
                            count: getLogoCount(
                                activeCategories.length
                                    ? activeCategories
                                    : [category],
                            ),
                        })
                    }}
                </popover-button>
            </popover-panel>
        </transition>
    </headlessui-popover>
</template>

<script setup lang="ts">
import useLogoCategoryUtils from "@/composables/useLogoCategoryUtils";
import useTreeUtils, { type WithChildren } from "@/composables/useTreeUtils";
import { useLogosStore } from "@/store/useLogosStore";
import { shift, useFloating } from "@floating-ui/vue";
import {
    Popover as HeadlessuiPopover,
    PopoverButton,
    PopoverPanel,
} from "@headlessui/vue";
import type { LogoCategoryData } from "@smakecloud/smake-use";
import { storeToRefs } from "pinia";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";

const props = defineProps<{
    category: WithChildren<LogoCategoryData>;
}>();

const { t } = useI18n();
const { forEach } = useTreeUtils();
const { getLogoCount } = useLogoCategoryUtils();
const logosStore = useLogosStore();
const { activeCategoryIds } = storeToRefs(logosStore);

const popoverButtonRef = ref<HTMLButtonElement | null>(null);
const popoverPanelRef = ref<HTMLDivElement | null>(null);
const { floatingStyles } = useFloating(popoverButtonRef, popoverPanelRef, {
    placement: "bottom-start",
    middleware: [shift()],
});

const activeSubCategories = computed(() => {
    const result: WithChildren<LogoCategoryData>[] = [];

    forEach(props.category.children, (category) => {
        if (activeCategoryIds.value.includes(category.id)) {
            result.push(category);
        }
    });

    return result;
});

const activeCategories = computed(() => {
    const result = [...activeSubCategories.value];

    if (activeCategoryIds.value.includes(props.category.id))
        result.push(props.category);

    return result;
});

function selectCategory() {
    if (activeCategories.value.length) return;

    logosStore.toggleCategory(props.category);
}
</script>

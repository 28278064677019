<template>
    <div class="fixed inset-0 flex items-center justify-center">
        <transition-child
            appear
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
        >
            <base-dialog-panel class="w-[480px] p-4" has-close-button>
                <div>
                    <div class="float-right h-8 w-8" />
                    <slot />
                </div>
                <div class="mt-4 flex w-full justify-between gap-4">
                    <button-secondary-outlined
                        class="flex-grow"
                        @click="isOpen = false"
                    >
                        {{ t("abort") }}
                    </button-secondary-outlined>
                    <button-primary class="flex-grow" @click="emit('confirm')">
                        <slot name="confirmText" />
                    </button-primary>
                </div>
            </base-dialog-panel>
        </transition-child>
    </div>
</template>

<script setup lang="ts">
import { useInjectDialogData } from "@/composables/useDialogData";
import { TransitionChild } from "@headlessui/vue";
import { useI18n } from "vue-i18n";

const emit = defineEmits(["confirm"]);
const { t } = useI18n();
const { isOpen } = useInjectDialogData();
</script>

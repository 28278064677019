<template>
    <preview-card
        :name="design.name"
        :image="design.preview_url"
        :width="design.width"
        :height="design.height"
        :color-assignments="
            getAllUniqueColorAssignments(
                ...(design.elements as unknown as DesignElement[]),
            ) as unknown as ApiTextDesign['resource']['lines'][number]['color_assignments']
        "
        :deletable="true"
        :production-method-id="design.production_method.id"
        :show-rainbow-icon
        @rename="isRenameDialogOpen = true"
        @delete="isDeleteDialogOpen = true"
        @select="onSelect"
    />
    <base-dialog v-model:is-open="isDeleteDialogOpen">
        <delete-dialog-panel
            :image="design.preview_url"
            :name="design.name"
            :delete="deleteDesign"
        />
    </base-dialog>
    <base-dialog v-model:is-open="isRenameDialogOpen">
        <rename-dialog-panel
            :image="design.preview_url"
            :name="design.name"
            :mutation-status="status"
            :error
            @rename="rename({ id: design.id, name: $event })"
        />
    </base-dialog>

    <base-dialog v-model:is-open="isSelectConfirmationOpen">
        <confirmation-dialog-panel @confirm="emit('select')">
            <f-a-icon :icon="FontAwesomeKitIcons.Info" class="inline-block" />
            {{ t("the_selected_design_will_replace_the_current_selection") }}
            <template #confirmText>
                <span class="flex items-center gap-2">
                    {{ t("replace") }}
                </span>
            </template>
        </confirmation-dialog-panel>
    </base-dialog>
</template>

<script setup lang="ts">
import { FontAwesomeKitIcons } from "@/enums/fontAwesome/fontAwesomeKitIcons";
import { apiClient, type ApiTextDesign } from "@smakecloud/designer-core";
import {
    getAllDesignElements,
    getAllUniqueColorAssignments,
    isDesignElementLogo,
    useDesignerApi,
    type DesignElement,
} from "@smakecloud/smake-use";
import { useMutation } from "@tanstack/vue-query";
import { computed, defineEmits, ref, toRefs, watchEffect } from "vue";
import { useI18n } from "vue-i18n";

const props = defineProps<{
    design: typeof apiClient.designs.favored.get.outputType;
    isSelectConfirmationRequired: boolean;
}>();

const { design } = toRefs(props);

const emit = defineEmits<{
    select: [];
}>();

const isRenameDialogOpen = ref(false);
const isDeleteDialogOpen = ref(false);
const isSelectConfirmationOpen = ref(false);

const showRainbowIcon = computed(() =>
    design.value.elements
        .flatMap((designElement) =>
            getAllDesignElements(designElement as unknown as DesignElement),
        )
        .some((designElement) => {
            if (isDesignElementLogo(designElement)) {
                return designElement.resource.color_assignments.length === 0;
            }

            return false;
        }),
);

const designerApi = useDesignerApi();
const { t } = useI18n();

async function deleteDesign() {
    await designerApi.endpoints.designs.favored.delete(props.design.id);
}

const {
    mutate: rename,
    status,
    error,
    reset,
} = useMutation({
    ...apiClient.designs.favored.patch.use(),
});

watchEffect(() => {
    if (isRenameDialogOpen.value) {
        return;
    }

    reset();
});

function onSelect() {
    if (!props.isSelectConfirmationRequired) {
        emit("select");

        return;
    }

    isSelectConfirmationOpen.value = true;
}
</script>

<template>
    <module-button
        v-if="!isInternal"
        icon="save"
        :style-config="moduleButtonStyleConfig"
        :loading="isArchiving"
        :disabled="isDisabled"
        @click="storeCurrentVariantToArchive()"
    >
        {{ t("save_variant") }}
    </module-button>
</template>

<script setup lang="ts">
import useArchiveVariant from "@/composables/variants/useArchiveVariant";
import { Mode } from "@/enums/mode";
import { useCurrentStore } from "@/store/useCurrentStore";
import { useMainStore } from "@/store/useMainStore";
import { usePanelStore } from "@/store/usePanelStore";
import { useSettingsStore } from "@/store/useSettingsStore";
import cloneDeep from "lodash/cloneDeep";
import get from "lodash/get";
import merge from "lodash/merge";
import set from "lodash/set";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const { settings } = useMainStore();
const { isArchiving, currentMode, currentVariantHasCustomizations } =
    storeToRefs(useCurrentStore());
const { isPanelOpen } = storeToRefs(usePanelStore());
const { t } = useI18n();
const { storeCurrentVariantToArchive } = useArchiveVariant();
const { isInternal } = storeToRefs(useSettingsStore());

const props = defineProps({
    styleConfig: {
        type: Object,
        default: () => {
            return {};
        },
    },
});

// computed
const moduleButtonStyleConfig = computed(() => {
    const baseStyleConfig = cloneDeep(props.styleConfig);

    [
        "styles",
        "icon.styles",
        "label.styles",
        "disabled.styles",
        "disabled.icon.styles",
        "disabled.label.styles",
    ].forEach((key) => {
        set(
            baseStyleConfig,
            key,
            merge(
                get(baseStyleConfig, key, []),
                get(settings.style, `saveVariantButton.${key}`, []),
            ),
        );
    });

    return baseStyleConfig;
});

const isDisabled = computed<boolean>(
    () =>
        isPanelOpen.value ||
        currentMode.value !== Mode.Default ||
        !currentVariantHasCustomizations.value,
);
</script>

import ProductionMethod from "@/repo/ProductionMethod";
import { Model } from "pinia-orm";
import {
    Attr,
    Bool,
    HasManyBy,
    Num,
    Str,
    Uid,
} from "pinia-orm/dist/decorators";

export default class Color extends Model {
    static entity = "colors";

    @Uid()
    id!: number;

    @Num(0)
    red!: number;

    @Num(0)
    green!: number;

    @Num(0)
    blue!: number;

    @Str("")
    display_name!: string;

    @Str("")
    mapping_handle!: string;

    @Attr([])
    production_method_ids!: number[];

    @HasManyBy(() => ProductionMethod, "production_method_ids")
    production_methods!: ProductionMethod[];

    @Str("")
    external_identifier!: string | null;

    @Bool(false)
    is_standard!: boolean;

    @Bool(false)
    is_customer_logo_color!: boolean;

    @Attr()
    color_details!: {
        id: number;
        name: string;
        code: number;
        thickness: number;
    } | null;

    get contrast(): number {
        return (this.red * 299 + this.green * 587 + this.blue * 114) / 1000;
    }

    get cssRgb(): string {
        return `rgb(${this.red}, ${this.green}, ${this.blue})`;
    }
}

<template>
    <div
        class="fixed inset-0 top-16 flex items-center justify-center tablet:inset-12"
    >
        <transition-child
            appear
            as="template"
            enter="duration-300 ease-out"
            enter-from="translate-y-full tablet:translate-y-0 tablet:opacity-0 tablet:scale-95"
            enter-to="translate-y-0 tablet:opacity-100 tablet:scale-100"
            leave="duration-200 ease-in"
            leave-from="translate-y-0 tablet:opacity-100 tablet:scale-100"
            leave-to="translate-y-full tablet:translate-y-0 tablet:opacity-0 tablet:scale-95"
        >
            <base-dialog-panel
                class="flex h-full max-h-full w-[1280px] flex-col p-4"
                has-close-button
            >
                <div>
                    <!-- So it doesn't overlap with the close button -->
                    <div class="float-right h-8 w-8" />
                    <div class="mb-4 px-4 text-2xl font-bold uppercase">
                        {{ title }}
                    </div>
                </div>
                <tabs-root v-model="currentTab" as="template">
                    <tabs-list
                        class="mb-2 flex flex-wrap gap-2 px-4"
                        :class="
                            hasCustomerLogos ? 'justify-between' : 'justify-end'
                        "
                    >
                        <div
                            v-show="hasCustomerLogos"
                            class="flex flex-wrap gap-2 max-tablet:w-full"
                        >
                            <customer-logo-tab-trigger
                                value="customerLogos"
                                :disabled="(customerLogoCount ?? 0) === 0"
                                class="text-nowrap max-tablet:grow"
                            >
                                <span class="tablet:hidden">
                                    {{ t("logos", 2) }}
                                </span>
                                <span class="hidden tablet:inline">
                                    {{ t("my_logos") }}
                                </span>
                                <span
                                    class="ml-2 text-secondary-2 data-[state=active]:text-white"
                                >
                                    {{ customerLogoCount }}
                                </span>
                            </customer-logo-tab-trigger>
                            <customer-logo-tab-trigger
                                value="designs"
                                class="text-nowrap max-tablet:grow"
                                :disabled="(favoredDesignCount ?? 0) === 0"
                            >
                                <span class="tablet:hidden">
                                    {{ t("designs", 2) }}
                                </span>
                                <span class="hidden tablet:inline">
                                    {{ t("saved_designs") }}
                                </span>
                                <span
                                    class="ml-2 text-secondary-2 data-[state=active]:text-white"
                                >
                                    {{ favoredDesignCount }}
                                </span>
                            </customer-logo-tab-trigger>
                        </div>
                        <select-production-method
                            v-model="filterProductionMethod"
                            class="max-tablet:w-full"
                            :is-disabled="true"
                        />
                    </tabs-list>
                    <div class="mt-4 h-full overflow-y-auto">
                        <tabs-content as="template" value="customerLogos">
                            <customer-logo-selection
                                class="px-4"
                                :filter-production-method
                                @logo-selected="onLogoSelected"
                            />
                        </tabs-content>
                        <tabs-content as="template" value="designs">
                            <design-logo-selection
                                class="px-4"
                                :is-select-confirmation-required
                                :filter-production-method
                                @design-selected="onDesignSelected"
                            />
                        </tabs-content>
                    </div>
                </tabs-root>
            </base-dialog-panel>
        </transition-child>
    </div>
</template>

<script setup lang="ts">
import type ProductionMethod from "@/repo/ProductionMethod";
import { useCustomerLogoCountStore } from "@/store/useCustomerLogoCountStore";
import { useFavoredDesignCountStore } from "@/store/useFavoredDesignCountStore";
import { useProductionMethodStore } from "@/store/useProductionMethodStore";
import { TabsContent, TabsRoot, TabsList } from "radix-vue";
import { TransitionChild } from "@headlessui/vue";
import {
    type DesignComplete,
    type LogoResourceData,
} from "@smakecloud/smake-use";
import { storeToRefs } from "pinia";
import { computed, ref, toRefs, watchEffect, type Ref } from "vue";
import { useI18n } from "vue-i18n";
import ChangeProductionMethodDialog from "../changeProductionMethodDialog/ChangeProductionMethodDialog.vue";
import { useInjectDialogData } from "@/composables/useDialogData";
import { useDialog } from "@/composables/useDialog";
import { useShowCustomerLogoOrDesignCardStore } from "@/store/useShowCustomerLogoOrDesignCardStore";

type CustomerLogoTab = "customerLogos" | "designs";

const props = withDefaults(
    defineProps<{
        isSelectConfirmationRequired: boolean;
        defaultTab?: "customerLogos" | "designs";
    }>(),
    { defaultTab: "customerLogos" },
);
const { defaultTab } = toRefs(props);

const productionMethodStore = useProductionMethodStore();
const { currentProductionMethod } = storeToRefs(productionMethodStore);

const filterProductionMethod = ref(
    currentProductionMethod.value,
) as Ref<ProductionMethod>;

const emit = defineEmits<{
    resolve: [
        | { type: "logo"; logo: LogoResourceData }
        | { type: "design"; design: DesignComplete },
    ];
}>();

const selectedTabIndex = ref<number>();
const currentTab = ref<CustomerLogoTab>("customerLogos");

const { t } = useI18n();
const { isOpen } = useInjectDialogData();
const { show } = useDialog();
const {
    data: customerLogoCount,
    isFetching: isFetchingCustomerLogoCount,
    activeProductionMethods: customerLogoActiveProductionMethods,
} = storeToRefs(useCustomerLogoCountStore());
const {
    data: favoredDesignCount,
    isFetching: isFetchingFavoredDesignCount,
    activeProductionMethods: favoriteDesignActiveProductionMethods,
} = storeToRefs(useFavoredDesignCountStore());

const { showCustomerLogoOrDesignCard, hasCustomerLogos } = storeToRefs(
    useShowCustomerLogoOrDesignCardStore(),
);

watchEffect(() => {
    favoriteDesignActiveProductionMethods.value = [
        filterProductionMethod.value.id,
    ];
    customerLogoActiveProductionMethods.value = [
        filterProductionMethod.value.id,
    ];
});

function selectTabWithCount(defaultTab?: "customerLogos" | "designs") {
    if (
        (!defaultTab || defaultTab === "customerLogos") &&
        customerLogoCount.value
    ) {
        selectedTabIndex.value = 0;
        currentTab.value = "customerLogos";
        return;
    }

    if ((!defaultTab || defaultTab === "designs") && favoredDesignCount.value) {
        selectedTabIndex.value = 1;
        currentTab.value = "designs";
        return;
    }

    if (isFetchingCustomerLogoCount.value || isFetchingFavoredDesignCount.value)
        return;

    if (defaultTab) {
        selectTabWithCount();
        return;
    }

    if (showCustomerLogoOrDesignCard.value) {
        return;
    }

    isOpen.value = false;
}

watchEffect(() => {
    selectTabWithCount(defaultTab.value);
});

const title = computed(() =>
    customerLogoCount.value ? t("logos_and_designs") : t("designs", 2),
);

async function onLogoSelected(logo: LogoResourceData) {
    const toProductionMethod = productionMethodStore.getById(
        logo.production_method.id,
        true,
    );
    if (
        currentProductionMethod.value.id !== toProductionMethod.id &&
        !(await show(ChangeProductionMethodDialog, { toProductionMethod }))
    ) {
        return;
    }
    emit("resolve", { type: "logo", logo });
}

async function onDesignSelected(design: DesignComplete) {
    const toProductionMethod = productionMethodStore.getById(
        design.production_method.id,
        true,
    );
    if (
        currentProductionMethod.value.id !== toProductionMethod.id &&
        !(await show(ChangeProductionMethodDialog, { toProductionMethod }))
    ) {
        return;
    }
    emit("resolve", { type: "design", design });
}
</script>

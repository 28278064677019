import useNotification from "@/composables/useNotification";
import useStoreVariant from "@/composables/variants/useStoreVariant";
import { NotificationType } from "@/enums/NotificationType";
import { useCurrentStore } from "@/store/useCurrentStore";
import { type PusherCustomVariantCustomizationsCompletedResponse } from "@/types/pusher/PusherCustomVariantCustomizationsCompletedResponse";
import { useDesignerApi } from "@smakecloud/smake-use";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";

export default () => {
    const designerApi = useDesignerApi();
    const { isPdfCurrentlyRendered, currentVariant } =
        storeToRefs(useCurrentStore());
    const { getStoreVariantRequestBody } = useStoreVariant();
    const { pushNotification } = useNotification();
    const { t } = useI18n();

    async function downloadPDF(
        variantId: number,
        idHash: string,
        variantName: string,
    ) {
        const data =
            await designerApi.endpoints.variants.getPdfByVariantId(variantId);
        const blobUrl = URL.createObjectURL(
            new Blob([data], { type: "application/pdf" }),
        );

        const downloadAnchor = document.createElement("a");
        downloadAnchor.href = blobUrl;
        downloadAnchor.download = `Design ID ${idHash} - ${variantName}.pdf`;
        downloadAnchor.click();
    }

    async function storeCurrentVariantPdf(): Promise<void> {
        if (isPdfCurrentlyRendered.value) {
            return;
        }

        isPdfCurrentlyRendered.value = true;

        try {
            await designerApi.endpoints.variants.storeVariantForPdfDownload(
                currentVariant.value.id,
                getStoreVariantRequestBody(currentVariant.value),
            );
        } catch (error) {
            isPdfCurrentlyRendered.value = false;
        }
    }

    async function onCreatingPdfCompleted(
        pusherCustomVariantCustomizationsCompletedResponse: PusherCustomVariantCustomizationsCompletedResponse,
    ): Promise<void> {
        try {
            await downloadPDF(
                pusherCustomVariantCustomizationsCompletedResponse.id,
                pusherCustomVariantCustomizationsCompletedResponse.id_hash,
                pusherCustomVariantCustomizationsCompletedResponse.name,
            );
        } catch {
            pushNotification(t("boot_error.unexpected"), NotificationType.Info);
        }

        isPdfCurrentlyRendered.value = false;
    }

    return {
        storeCurrentVariantPdf,
        onCreatingPdfCompleted,
    };
};

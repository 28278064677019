<template>
    <div>
        <div class="-mr-5 flex-1 overflow-y-auto pr-5">
            <div class="grid grid-flow-row gap-5">
                <div class="flex gap-3">
                    <div class="grow">
                        <label
                            for="font"
                            class="block uppercase"
                            :class="settings.style.input.label.styles"
                        >
                            {{ t("font") }}
                        </label>
                        <div
                            class="relative mt-1"
                            data-testid="text-line-edit-modal-open-font-selection"
                            @click="showFontModal = true"
                        >
                            <div
                                id="font"
                                class="h-12 w-full cursor-pointer rounded-full border border-secondary-2 p-2 pr-12 text-sm font-semibold hover:border-black focus:outline-none"
                            >
                                <div
                                    class="grid h-full w-full content-center justify-center rounded-full bg-secondary-3"
                                >
                                    <span class="truncate px-2">
                                        {{ currentFont?.family_name }}
                                    </span>
                                </div>
                            </div>
                            <div
                                class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-4"
                            >
                                <f-a-icon
                                    :icon="'chevron-right'"
                                    :size="FontAwesomeSize.fa1x"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="grow-0">
                        <label
                            for="color"
                            class="block uppercase"
                            :class="settings.style.input.label.styles"
                        >
                            {{ t("italic") }}
                        </label>
                        <div class="mt-2">
                            <button
                                data-testid="text-line-edit-toggle-italic"
                                :class="{
                                    'bg-secondary-3 text-black': !isFontItalic,
                                    'bg-black text-white': isFontItalic,
                                    'pointer-events-none opacity-50':
                                        !hasItalicFont,
                                }"
                                type="button"
                                class="mr-2 h-10 w-9 rounded font-bold italic"
                                @click="toggleItalic"
                            >
                                {{ t("italic_short") }}
                            </button>
                        </div>
                    </div>
                    <div class="grow-0">
                        <label
                            for="color"
                            class="block"
                            :class="settings.style.input.label.styles"
                        >
                            {{ t("color") }}
                        </label>
                        <div
                            data-testid="text-line-edit-modal-open-font-color-selection"
                            class="relative mt-1"
                            @click="showColorModal = true"
                        >
                            <div
                                id="color"
                                class="grid h-12 w-full cursor-pointer content-center rounded-full border border-secondary-2 p-2 pr-12 text-sm font-semibold hover:border-black focus:outline-none"
                            >
                                <span
                                    :style="{
                                        'background-color': lineColorStyle,
                                    }"
                                    class="block h-8 w-8 rounded-full"
                                    :class="
                                        lineColorStyle === 'rgb(255, 255, 255)'
                                            ? 'border border-secondary-2'
                                            : ''
                                    "
                                />
                            </div>
                            <div
                                class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-4"
                            >
                                <f-a-icon
                                    :icon="'chevron-right'"
                                    :size="FontAwesomeSize.fa1x"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="flex gap-3">
                    <number-input
                        ref="numberInputFontSize"
                        v-model="editedLine.font_size"
                        data-testId="text-line-edit-font-size"
                        :label="t('size').toUpperCase()"
                        :min="currentFont?.min"
                        :max="currentFont?.max"
                        @update:model-value="emitCurrentLine"
                    />
                    <number-input
                        ref="numberInputLineHeight"
                        v-model="editedLine.padding_bottom"
                        data-testId="text-line-edit-line-height"
                        :label="t('line_height').toUpperCase()"
                        :min="TextLineConfig.lineHeightMin"
                        :max="TextLineConfig.lineHeightMax"
                        @update:model-value="emitCurrentLine"
                    />
                </div>

                <div class="grid grid-cols-2 gap-3">
                    <!-- :class="currentCustomization.lines.length === 1 ? 'pointer-events-none opacity-50' : ''" -->
                    <div>
                        <label
                            for="font"
                            class="block uppercase"
                            :class="settings.style.input.label.styles"
                        >
                            {{ t("align") }}
                        </label>
                        <alignment-input
                            v-model="editedLine.horizontal_alignment"
                            class="mt-2"
                            @update:model-value="emitCurrentLine"
                        />
                    </div>
                    <number-input
                        ref="numberInputOffsetLeft"
                        v-model="editedLine.offset"
                        data-testId="text-line-edit-offset-left"
                        :disabled="
                            editedLine.horizontal_alignment !==
                            HorizontalAlignment.LEFT
                        "
                        :label="t('offset_left').toUpperCase()"
                        :min="TextLineConfig.offset.left.min"
                        :max="TextLineConfig.offset.left.max"
                        @change="emitCurrentLine"
                    />
                </div>

                <slider-input
                    id="bending-radius"
                    v-model="editedLine.bending_radius"
                    :label="t('bend_text_line')"
                    unit="%"
                    :min="-100"
                    :max="100"
                    :step="5"
                    data-testId="text-line-edit-bending-radius"
                    @update:model-value="emitCurrentLine"
                />
                <div class="mt-5 flex justify-center">
                    <button
                        type="button"
                        data-testId="text-line-edit-apply-to-all-lines"
                        :class="settings.style.dialogeSecondaryButton.styles"
                        class="h-8 w-full"
                        @click="emit('apply-to-all-lines')"
                    >
                        {{ t("apply_to_all_lines") }}
                    </button>
                </div>
            </div>
        </div>
        <font-input
            v-if="showFontModal"
            v-model="editedLine.font.id"
            :production-method-id="productionMethodId"
            class="absolute inset-0 overflow-y-auto bg-white p-7 tablet:p-10"
            @selected="onFontSelected"
        />
        <div
            v-if="showColorModal"
            ref="colorInputElement"
            class="absolute inset-0 overflow-y-auto bg-white p-4"
        >
            <color-input
                v-model="editedLine.color_assignments[0]"
                @update:model-value="showColorModal = false"
            />
        </div>
    </div>
</template>

<script setup lang="ts">
import ColorInput from "@/components/modules/texts/ColorInput.vue";
import FontInput from "@/components/modules/texts/FontInput.vue";
import NumberInput from "@/components/shared/NumberInput.vue";
import { useFonts } from "@/composables/useFonts";
import { useTextResourceLine } from "@/composables/useTextResourceLine";
import { TextLineConfig } from "@/config/text-line.config";
import { HorizontalAlignment } from "@/enums/HorizontalAlignment";
import { FontAwesomeSize } from "@/enums/fontAwesome/fontAwesomeSize";
import { useColorStore } from "@/store/useColorStore";
import { useMainStore } from "@/store/useMainStore";
import type { ApiTextDesign } from "@smakecloud/designer-core";
import { storeToRefs } from "pinia";
import { computed, ref, watch, watchEffect, toRefs } from "vue";
import { useI18n } from "vue-i18n";

type TextResourceLine = ApiTextDesign["resource"]["lines"][number];

const emit = defineEmits<{
    update: [TextResourceLine];
    "apply-to-all-lines": [];
}>();

const props = defineProps<{
    line: TextResourceLine;
    productionMethodId: number;
}>();

const { line } = toRefs(props);

const { productionMethodId } = toRefs(props);

const editedLine = ref<TextResourceLine>(props.line);

const { fonts } = useFonts(productionMethodId);

const currentFont = computed(() =>
    fonts.value?.find((font) => font.id === editedLine.value.font.id),
);

watch(
    () => props.line,
    () => {
        editedLine.value = props.line;
    },
);

const { settings } = storeToRefs(useMainStore());
const { t } = useI18n();
const { hasItalicFont, toggleItalic, isFontItalic } = useTextResourceLine(
    editedLine,
    productionMethodId,
);

const showFontModal = ref(false);
const showColorModal = ref(false);

const numberInputFontSize = ref<typeof NumberInput>();
const numberInputLineHeight = ref<typeof NumberInput>();
const numberInputOffsetLeft = ref<typeof NumberInput>();

function emitCurrentLine() {
    emit("update", editedLine.value);
}

const { colorsData } = storeToRefs(useColorStore());

// todo improve workaround. If you sue textLineEdit.color_assignments[0].color directly the type UnwrapRefSimple<ColorAssignments> is causing a typing problem with the ColorModal
const lineColorStyle = computed<string>(() => {
    const colorAssignment = line.value.color_assignments[0];

    if (colorAssignment.color) {
        const color = colorsData.value?.find(
            (color) => color.id === colorAssignment.color.id,
        );

        if (!color) {
            return "";
        }

        return `rgb(${color.red}, ${color.green}, ${color.blue})`;
    }

    return `rgb(${colorAssignment.red}, ${colorAssignment.green}, ${colorAssignment.blue})`;
});

const colorInputElement = ref<HTMLElement>();

watchEffect(() => {
    if (showColorModal.value) {
        colorInputElement.value?.scrollIntoView({ behavior: "smooth" });
    }
});

function onFontSelected() {
    showFontModal.value = false;
}
</script>

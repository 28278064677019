import initVariants from "@/repo/initializers/initVariants";
import { type DesignerProps } from "@/types/DesignerProps";
import { withCtx, type ComponentInternalInstance } from "vue";

export default async (
    designerProps: DesignerProps,
    instance: ComponentInternalInstance | null,
) => {
    // should also be added to the endpoint, therefore I already added it here
    await withCtx(() => initVariants(designerProps.variant), instance)();
};

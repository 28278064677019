<template>
    <div class="text-center">
        <f-a-icon
            icon="spinner"
            :size="FontAwesomeSize.fa2x"
            spin
            class="text-primary-1"
        />
        <span class="mt-1 block">
            {{ t("loading") }}
        </span>
    </div>
</template>

<script setup lang="ts">
import { FontAwesomeSize } from "@/enums/fontAwesome/fontAwesomeSize";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
</script>

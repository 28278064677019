<template>
    <teleport :to="portalRoot" :disabled="!portalRoot">
        <transition-root
            appear
            :show="isOpen"
            @after-enter="emit('afterOpening')"
            @after-leave="emit('afterClosing')"
        >
            <transition-child
                appear
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100"
                leave-to="opacity-0"
            >
                <div class="fixed inset-0 bg-black/40" aria-hidden="true" />
            </transition-child>
            <slot />
        </transition-root>
    </teleport>
</template>

<script setup lang="ts">
import { useProvideDialogData } from "@/composables/useDialogData";
import { useDialogStore } from "@/store/useDialogStore";
import { TransitionChild, TransitionRoot } from "@headlessui/vue";
import { storeToRefs } from "pinia";
import { onWatcherCleanup, watch } from "vue";

const emit = defineEmits<{
    afterOpening: [];
    afterClosing: [];
}>();

const isOpen = defineModel<boolean>("isOpen", { default: false });

useProvideDialogData({ isOpen });

const { portalRoot, dialogesCount } = storeToRefs(useDialogStore());

watch(
    isOpen,
    () => {
        if (!isOpen.value) return;

        dialogesCount.value++;
        onWatcherCleanup(() => {
            dialogesCount.value--;
        });
    },
    { immediate: true },
);
</script>

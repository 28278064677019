<template>
    <div ref="reference" class="group">
        <slot />
        <div
            v-if="$slots.tooltip"
            ref="floating"
            :style="floatingStyles"
            class="pointer-events-none z-10 text-nowrap rounded bg-white px-2 py-1 text-xs font-normal opacity-0 drop-shadow-md transition-opacity duration-300 ease-in-out group-hover:pointer-events-auto group-hover:opacity-100"
        >
            <slot name="tooltip" />
            <div
                ref="floatingArrow"
                class="absolute h-2 w-2 rotate-45 bg-white drop-shadow-md"
                :class="arrowClasses"
                :style="{
                    left:
                        middlewareData.arrow?.x !== undefined
                            ? `${middlewareData.arrow.x}px`
                            : undefined,
                    top:
                        middlewareData.arrow?.y !== undefined
                            ? `${middlewareData.arrow.y}px`
                            : undefined,
                }"
            />
        </div>
    </div>
</template>

<script setup lang="ts">
import { getSide } from "@floating-ui/utils";
import {
    arrow,
    autoUpdate,
    flip,
    offset,
    shift,
    useFloating,
} from "@floating-ui/vue";
import { computed, ref } from "vue";

const reference = ref<HTMLDivElement>();
const floating = ref<HTMLDivElement>();
const floatingArrow = ref<HTMLDivElement>();

const { floatingStyles, middlewareData, placement } = useFloating(
    reference,
    floating,
    {
        placement: "top",
        middleware: [
            flip(),
            shift(),
            offset(15),
            arrow({ element: floatingArrow }),
        ],
        whileElementsMounted: autoUpdate,
    },
);

const arrowClasses = computed(() => {
    switch (getSide(placement.value)) {
        case "top":
            return "rounded-tl-full";
        case "bottom":
            return "rounded-br-full";
        case "right":
            return "rounded-tr-full";
        case "left":
            return "rounded-bl-full";
        default:
            return undefined;
    }
});
</script>

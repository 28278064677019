import usePositioningAreaFunctions from "@/composables/positioningAreas/usePositioningAreaFunctions";
import useRenderCustomizationPreview from "@/composables/useRenderCustomizationPreview";
import { NotificationType } from "@/enums/NotificationType";
import { clampFontSize } from "@/functions/FontHelper";
import { clampSize } from "@/functions/SizeHelper";
import {
    isZSKDesignWithLogo,
    isZSKDesignWithText,
    isZSKTextDesign,
} from "@/modules/core/renderer/zskEmbroidery/ZSKDesignHelper";
import Customization from "@/repo/Customization";
import PositioningArea from "@/repo/PositioningArea";
import View from "@/repo/View";
import { useCurrentStore } from "@/store/useCurrentStore";
import { useProductionMethodStore } from "@/store/useProductionMethodStore";
import type { DesignCanvasObject } from "@/types/DesignCanvasObject";
import { isDesignOriginX, isDesignOriginY } from "@/types/DesignImage";
import type { PositioningAreaCanvasObject } from "@/types/PositioningAreaCanvasObject";
import type { Size } from "@smakecloud/designer-core";
import {
    InnerAnchorPoints,
    type TextResourceLineData,
} from "@smakecloud/smake-use";
import { storeToRefs } from "pinia";
import { useRepo } from "pinia-orm";
import { ref, type Ref } from "vue";
import { useI18n } from "vue-i18n";
import { getDesignFeatureMap } from "../useDesignFeatures";
import useNotification from "../useNotification";

export default (
    positioningAreaCanvasObjects: Ref<PositioningAreaCanvasObject[]>,
) => {
    const positioningAreaFunctions = usePositioningAreaFunctions();
    const { renderPreviewForCurrentCustomization } =
        useRenderCustomizationPreview();
    const currentStore = useCurrentStore();
    const { currentVariant, currentCustomization, currentView } =
        storeToRefs(currentStore);
    const { currentProductionMethod } = storeToRefs(useProductionMethodStore());
    const { pushNotification } = useNotification();
    const { t } = useI18n();

    function onScaling(
        event: fabric.IEvent,
        designCanvasObject: DesignCanvasObject,
    ) {
        if (!event.transform) {
            throw Error("Event is missing transform");
        }

        event.transform.originX = designCanvasObject.image.originX as
            | "left"
            | "right"; // todo verify type definition of fabric

        event.transform.originY = designCanvasObject.image.originY as
            | "top"
            | "bottom"; // todo verify type definition of fabric

        updatePxDimensionsFromCanvasObject(designCanvasObject);

        const positioningAreaCanvasObject =
            positioningAreaCanvasObjects.value.find(
                (positioningAreaCanvasObject) => {
                    return (
                        positioningAreaCanvasObject.id ===
                        designCanvasObject.positioningArea_id
                    );
                },
            );

        if (!positioningAreaCanvasObject) {
            return;
        }

        positioningAreaFunctions.validatePosition(
            positioningAreaCanvasObject,
            designCanvasObject,
        );
    }

    async function onScaled(
        designCanvasObject: DesignCanvasObject,
        positioningAreaCanvasObject: PositioningAreaCanvasObject,
    ) {
        updateLocalizedDimensionsFromPxDimensions(designCanvasObject);
        updatePosition(designCanvasObject);
        setToAnchorPoint(designCanvasObject);
        const customization = useRepo(Customization)
            .withAllRecursive()
            .find(designCanvasObject.customization_id);

        if (!customization?.design) {
            return;
        }

        const features = getDesignFeatureMap(customization.design);

        if (!features.resizeable) {
            return;
        }

        const scaleFactor =
            designCanvasObject.image.scaleX / designCanvasObject.start_scale;

        if (
            isZSKDesignWithLogo(customization.design) &&
            customization.design.logoDesignElementData?.dimension
        ) {
            const dimension =
                customization.design.logoDesignElementData.dimension;

            customization.design.logoDesignElementData.dimension = clampSize(
                {
                    width: dimension.width * scaleFactor,
                    height: dimension.height * scaleFactor,
                },
                {
                    width: customization.design.logo.min_width,
                    height: customization.design.logo.min_height,
                },
                {
                    width: customization.design.logo.max_width,
                    height: customization.design.logo.max_height,
                },
                {
                    onMinExceeded: () =>
                        pushNotification(
                            t("info_logo_size_was_corrected_to_min"),
                            NotificationType.Info,
                        ),
                    onMaxExceeded: () =>
                        pushNotification(
                            t("info_logo_size_was_corrected_to_max"),
                            NotificationType.Info,
                        ),
                },
            );
        }

        if (isZSKDesignWithText(customization.design)) {
            customization.design.text.lines =
                customization.design.text.lines.map(
                    (line: TextResourceLineData) => {
                        const scaledLine = {
                            ...line,
                            font_size: Math.round(line.font_size * scaleFactor),
                            padding_bottom: Math.round(
                                line.padding_bottom * scaleFactor,
                            ),
                            offset: Math.round(line.offset * scaleFactor),
                        } satisfies TextResourceLineData;

                        scaledLine.font_size = clampFontSize(
                            scaledLine.font_size,
                            scaledLine.font,
                            {
                                onMinExceeded: () =>
                                    pushNotification(
                                        t(
                                            "info_font_size_was_corrected_to_min",
                                        ),
                                        NotificationType.Info,
                                    ),
                                onMaxExceeded: () =>
                                    pushNotification(
                                        t(
                                            "info_font_size_was_corrected_to_max",
                                        ),
                                        NotificationType.Info,
                                    ),
                            },
                        );

                        return scaledLine;
                    },
                );
        }

        useRepo(Customization).save(customization);

        designCanvasObject.start_scale = designCanvasObject.image.scaleX;
        await renderPreviewForCurrentCustomization();
        positioningAreaFunctions.validatePosition(
            positioningAreaCanvasObject,
            designCanvasObject,
        );

        positioningAreaFunctions.showOrHideCanvasObject(
            positioningAreaCanvasObject,
        );
    }

    function onMoving(
        event: fabric.IEvent,
        designCanvasObject: DesignCanvasObject,
    ) {
        if (!event.pointer) {
            throw "Error Move Event is missing Pointer";
        }

        detachFromPositioningArea(designCanvasObject);
        updatePosition(designCanvasObject);

        positioningAreaCanvasObjects.value.forEach(
            (positioningAreaCanvasObject) => {
                positioningAreaCanvasObject.canvas_object._objects[0].set(
                    "fill",
                    positioningAreaFunctions.transparentFillColor(
                        positioningAreaCanvasObject,
                    ),
                );
            },
        );

        getClosestPositioningAreaOnView(event.pointer, designCanvasObject);
    }

    async function onMoved(
        designCanvasObject: DesignCanvasObject,
        positioningAreaCanvasObject: PositioningAreaCanvasObject,
    ) {
        const positioningArea = useRepo(PositioningArea).find(
            positioningAreaCanvasObject.id,
        );

        if (!positioningArea) {
            throw new Error("PositioningArea not defined");
        }

        const originX = positioningAreaCanvasObject.canvas_object.originX;
        const originY = positioningAreaCanvasObject.canvas_object.originY;

        if (!isDesignOriginX(originX)) {
            throw new Error(
                "PositioningAreaCanvas canvas_object invalid Origin X",
            );
        }

        if (!isDesignOriginY(originY)) {
            throw new Error(
                "PositioningAreaCanvas canvas_object invalid Origin Y",
            );
        }

        if (positioningAreaCanvasObject.canvas_object.top === undefined) {
            throw new Error("PositioningAreaCanvas canvas_object invalid top");
        }

        if (positioningAreaCanvasObject.canvas_object.left === undefined) {
            throw new Error("PositioningAreaCanvas canvas_object invalid left");
        }

        if (positioningAreaCanvasObject.canvas_object.angle === undefined) {
            throw new Error(
                "PositioningAreaCanvas canvas_object invalid angle",
            );
        }

        positioningAreaCanvasObject.has_customization = true;
        positioningAreaCanvasObject.canvas_object._objects[0].set(
            "fill",
            positioningAreaFunctions.transparentFillColor(
                positioningAreaCanvasObject,
            ),
        );

        designCanvasObject.image.set(
            "top",
            positioningAreaCanvasObject.canvas_object.top,
        );
        designCanvasObject.image.set(
            "left",
            positioningAreaCanvasObject.canvas_object.left,
        );
        designCanvasObject.image.set("originX", originX);
        designCanvasObject.image.set("originY", originY);

        designCanvasObject.image.set(
            "angle",
            designCanvasObject.rotation_angle +
                positioningAreaCanvasObject.canvas_object.angle +
                positioningArea.direction,
        );

        setToAnchorPoint(designCanvasObject);

        const customization = useRepo(Customization).save({
            id: designCanvasObject.customization_id,
            positioningArea_id: positioningArea.id,
            positioningArea: positioningArea,
            x: positioningArea.x,
            y: positioningArea.y,
            rotation: designCanvasObject.image.angle ?? 0,
        });

        await currentStore.resetCurrentCustomization();
        await currentStore.changeCurrentCustomization(customization);

        positioningAreaFunctions.validatePosition(
            positioningAreaCanvasObject,
            designCanvasObject,
        );
        positioningAreaFunctions.showOrHideCanvasObject(
            positioningAreaCanvasObject,
        );
    }

    function onRotated(
        designCanvasObject: DesignCanvasObject,
        positioningAreaCanvaObject: PositioningAreaCanvasObject,
    ) {
        updateRotation(designCanvasObject);

        positioningAreaFunctions.validatePosition(
            positioningAreaCanvaObject,
            designCanvasObject,
        );
    }

    function onRemoved(designCanvasObject: DesignCanvasObject) {
        remove(designCanvasObject);
    }

    function updatePxDimensionsFromCanvasObject(
        designCanvasObject: DesignCanvasObject,
    ) {
        designCanvasObject.px_width = Math.round(
            (designCanvasObject.image.width ?? 0) *
                (designCanvasObject.image.scaleX ?? 0),
        );

        designCanvasObject.px_height = Math.round(
            (designCanvasObject.image.height ?? 0) *
                (designCanvasObject.image.scaleY ?? 0),
        );
    }

    function updatePosition(textCanvasObject: DesignCanvasObject) {
        if (currentCustomization.value === null) {
            return;
        }

        currentCustomization.value.x = Math.round(
            textCanvasObject.image.left ?? 0,
        );
        currentCustomization.value.y = Math.round(
            textCanvasObject.image.top ?? 0,
        );

        useRepo(Customization).save({
            id: currentCustomization.value.id,
            x: currentCustomization.value.x,
            y: currentCustomization.value.y,
        });
    }

    function updateLocalizedDimensionsFromPxDimensions(
        designCanvasObject: DesignCanvasObject,
    ) {
        const customization = useRepo(Customization)
            .withAllRecursive()
            .find(designCanvasObject.customization_id);

        const view = useRepo(View)
            .where("handle", designCanvasObject.view_handle)
            .where("variant_id", currentVariant?.value.id)
            .first();

        if (customization === null || view === null) {
            return;
        }

        if (!isZSKTextDesign(customization.design)) {
            return;
        }

        const width = Math.round(
            designCanvasObject.px_width / view.scalefactor,
        );
        const height = Math.round(
            designCanvasObject.px_height / view.scalefactor,
        );

        customization.design.text.width = width;
        customization.design.text.height = height;
        customization.width = width;
        customization.height = height;

        useRepo(Customization).save(customization);
    }

    function updateRotation(designCanvasObject: DesignCanvasObject) {
        setRotationAngle(designCanvasObject);
        setToAnchorPoint(designCanvasObject);
    }

    function remove(designCanvasObject: DesignCanvasObject) {
        detachFromPositioningArea(designCanvasObject);

        designCanvasObject.image.off();
    }

    function getPositioningAreasOfCurrentViewAndProductionMethodWithOutCustomization() {
        return positioningAreaCanvasObjects.value.filter(
            (positioningAreaCanvasObject) => {
                return (
                    positioningAreaCanvasObject.production_method_id ===
                        currentProductionMethod.value.id &&
                    currentView.value.positioning_area_ids.includes(
                        positioningAreaCanvasObject.id,
                    ) &&
                    positioningAreaCanvasObject.has_customization === false
                );
            },
        );
    }

    function getClosestPositioningAreaOnView(
        point: { x: number; y: number },
        designCanvasObject: DesignCanvasObject,
    ) {
        let anchorDiff = 1000;
        const closestPositioningArea = ref<
            PositioningAreaCanvasObject | undefined
        >();

        getPositioningAreasOfCurrentViewAndProductionMethodWithOutCustomization().forEach(
            (positioningArea) => {
                positioningArea.canvas_object._objects[0].set(
                    "fill",
                    positioningAreaFunctions.transparentFillColor(
                        positioningArea,
                    ),
                );
                positioningArea.canvas_object.set("opacity", 1);

                const positioningAreaModel = useRepo(PositioningArea).find(
                    positioningArea.id,
                );

                if (
                    /*positioningArea.currentCustomizationHasAnOtherProductionMethod()  ||*/

                    positioningArea.has_customization
                ) {
                    return;
                }

                if (
                    positioningAreaFunctions.pointInAreaTest(
                        point,
                        positioningArea.canvas_object as fabric.Rect,
                    )
                ) {
                    closestPositioningArea.value = positioningArea;
                    anchorDiff = 0;

                    return;
                }

                if (positioningAreaModel === null) {
                    return;
                }

                const distance = Math.sqrt(
                    Math.pow(positioningAreaModel.x - point.x, 2) +
                        Math.pow(positioningAreaModel.y - point.y, 2),
                );

                if (anchorDiff > distance) {
                    anchorDiff = distance;
                    closestPositioningArea.value = positioningArea;
                }
            },
        );

        if (!closestPositioningArea.value) {
            return;
        }

        closestPositioningArea.value.canvas_object._objects[0].set(
            "fill",
            positioningAreaFunctions.highlightFillColor(
                closestPositioningArea.value,
            ),
        );

        designCanvasObject.positioningArea_id =
            closestPositioningArea.value.id ?? 0;
    }

    function setToAnchorPoint(designCanvasObject: DesignCanvasObject) {
        const customization = useRepo(Customization).find(
            designCanvasObject.customization_id,
        );

        const closestPositioningArea = positioningAreaCanvasObjects.value.find(
            (positioningArea) =>
                positioningArea.id === designCanvasObject.positioningArea_id,
        );

        if (closestPositioningArea) {
            closestPositioningArea.has_customization = true;
        }

        if (designCanvasObject.image && closestPositioningArea?.canvas_object) {
            designCanvasObject.image.left =
                closestPositioningArea.canvas_object.left ?? 0;
            designCanvasObject.image.top =
                closestPositioningArea.canvas_object.top ?? 0;
        }

        if (customization) {
            if (closestPositioningArea === undefined) {
                return;
            }

            const positioningArea = useRepo(PositioningArea).find(
                closestPositioningArea.id,
            );

            if (positioningArea === null) {
                return;
            }

            setRotatedAnchorPoint(positioningArea, designCanvasObject);
        }

        const positioningArea = useRepo(PositioningArea)
            .withAll()
            .find(closestPositioningArea?.id ?? 0);

        if (customization && positioningArea) {
            useRepo(Customization).save({
                id: customization.id,
                x: positioningArea.x,
                y: positioningArea.y,
                rotation: designCanvasObject.image.angle ?? 0,
            });
        }
    }

    function detachFromPositioningArea(designCanvasObject: DesignCanvasObject) {
        const positioningArea = useRepo(PositioningArea)
            .withAll()
            .find(designCanvasObject.positioningArea_id);

        if (positioningArea !== null) {
            const positioningAreaCanvasObject =
                positioningAreaCanvasObjects.value.find(
                    (positioningAreaCanvasObject) =>
                        positioningArea.id === positioningAreaCanvasObject.id,
                );

            if (positioningAreaCanvasObject) {
                positioningAreaCanvasObject.has_customization = false;
                positioningArea.has_object_with_invalid_position = false;
                positioningAreaFunctions.validatePosition(
                    positioningAreaCanvasObject,
                    designCanvasObject,
                );
            }
        }

        designCanvasObject.positioningArea_id = -1;
    }

    function setRotationAngle(textCanvasObject: DesignCanvasObject) {
        const positioningAreaCanvasObject =
            positioningAreaCanvasObjects.value.find(
                (positioningAreaCanvasObject) =>
                    positioningAreaCanvasObject.id ===
                    textCanvasObject.positioningArea_id,
            );

        if (!positioningAreaCanvasObject) {
            return;
        }

        const positioningArea = useRepo(PositioningArea).find(
            positioningAreaCanvasObject.id,
        );

        if (!positioningArea) {
            return;
        }

        if (positioningAreaCanvasObject.canvas_object.angle === undefined) {
            return;
        }

        let rotationAngle =
            Math.round(
                Math.round(
                    textCanvasObject.image.angle -
                        positioningAreaCanvasObject.canvas_object.angle -
                        positioningArea.direction,
                ) / 90,
            ) * 90;

        if (rotationAngle > 360) {
            rotationAngle -= 360;
        }

        textCanvasObject.rotation_angle =
            rotationAngle === 360 ? 0 : rotationAngle;
        textCanvasObject.image.angle =
            textCanvasObject.rotation_angle +
            positioningAreaCanvasObject.canvas_object.angle +
            positioningArea.direction;
        useRepo(Customization).save({
            id: textCanvasObject.customization_id,
            rotation: textCanvasObject.image.angle,
        });
    }

    function getOriginX(originX: string) {
        switch (originX) {
            case "l":
                return "left";
            case "r":
                return "right";
            case "c":
                return "center";
            default:
                return "center";
        }
    }

    function getOriginY(originY: string) {
        switch (originY) {
            case "t":
                return "top";
            case "b":
                return "bottom";
            case "c":
                return "center";
            default:
                return "center";
        }
    }

    function setRotatedAnchorPoint(
        positioningArea: PositioningArea,
        designCanvasObject: DesignCanvasObject,
    ) {
        const validationAngle =
            positioningArea.direction + designCanvasObject.rotation_angle;

        switch (positioningArea.position) {
            case InnerAnchorPoints.TOP_CENTER:
                switch (validationAngle) {
                    case 90:
                    case -270:
                        changePosition(
                            designCanvasObject,
                            InnerAnchorPoints.CENTER_LEFT,
                        );

                        break;

                    case 180:
                    case -180:
                        changePosition(
                            designCanvasObject,
                            InnerAnchorPoints.BOTTOM_CENTER,
                        );

                        break;

                    case 270:
                    case -90:
                        changePosition(
                            designCanvasObject,
                            InnerAnchorPoints.CENTER_RIGHT,
                        );

                        break;
                    default:
                        changePosition(
                            designCanvasObject,
                            InnerAnchorPoints.TOP_CENTER,
                        );

                        break;
                }
                break;

            case InnerAnchorPoints.TOP_RIGHT:
                switch (validationAngle) {
                    case 90:
                    case -270:
                        changePosition(
                            designCanvasObject,
                            InnerAnchorPoints.TOP_LEFT,
                        );

                        break;

                    case 180:
                    case -180:
                        changePosition(
                            designCanvasObject,
                            InnerAnchorPoints.BOTTOM_LEFT,
                        );

                        break;

                    case 270:
                    case -90:
                        changePosition(
                            designCanvasObject,
                            InnerAnchorPoints.BOTTOM_RIGHT,
                        );

                        break;
                    default:
                        changePosition(
                            designCanvasObject,
                            InnerAnchorPoints.TOP_RIGHT,
                        );

                        break;
                }
                break;

            case InnerAnchorPoints.TOP_LEFT:
                switch (validationAngle) {
                    case 90:
                    case -270:
                        changePosition(
                            designCanvasObject,
                            InnerAnchorPoints.BOTTOM_LEFT,
                        );

                        break;

                    case 180:
                    case -180:
                        changePosition(
                            designCanvasObject,
                            InnerAnchorPoints.BOTTOM_RIGHT,
                        );

                        break;

                    case 270:
                    case -90:
                        changePosition(
                            designCanvasObject,
                            InnerAnchorPoints.TOP_RIGHT,
                        );

                        break;
                    default:
                        changePosition(
                            designCanvasObject,
                            InnerAnchorPoints.TOP_LEFT,
                        );

                        break;
                }
                break;

            case InnerAnchorPoints.BOTTOM_CENTER:
                switch (validationAngle) {
                    case 90:
                    case -270:
                        changePosition(
                            designCanvasObject,
                            InnerAnchorPoints.CENTER_RIGHT,
                        );

                        break;

                    case 180:
                    case -180:
                        changePosition(
                            designCanvasObject,
                            InnerAnchorPoints.TOP_CENTER,
                        );

                        break;

                    case 270:
                    case -90:
                        changePosition(
                            designCanvasObject,
                            InnerAnchorPoints.CENTER_LEFT,
                        );

                        break;
                    default:
                        changePosition(
                            designCanvasObject,
                            InnerAnchorPoints.BOTTOM_CENTER,
                        );

                        break;
                }
                break;

            case InnerAnchorPoints.BOTTOM_RIGHT:
                switch (validationAngle) {
                    case 90:
                    case -270:
                        changePosition(
                            designCanvasObject,
                            InnerAnchorPoints.TOP_RIGHT,
                        );

                        break;

                    case 180:
                    case -180:
                        changePosition(
                            designCanvasObject,
                            InnerAnchorPoints.TOP_LEFT,
                        );

                        break;

                    case 270:
                    case -90:
                        changePosition(
                            designCanvasObject,
                            InnerAnchorPoints.BOTTOM_LEFT,
                        );

                        break;
                    default:
                        changePosition(
                            designCanvasObject,
                            InnerAnchorPoints.BOTTOM_RIGHT,
                        );

                        break;
                }
                break;

            case InnerAnchorPoints.BOTTOM_LEFT:
                switch (validationAngle) {
                    case 90:
                    case -270:
                        changePosition(
                            designCanvasObject,
                            InnerAnchorPoints.BOTTOM_RIGHT,
                        );

                        break;

                    case 180:
                    case -180:
                        changePosition(
                            designCanvasObject,
                            InnerAnchorPoints.TOP_RIGHT,
                        );

                        break;

                    case 270:
                    case -90:
                        changePosition(
                            designCanvasObject,
                            InnerAnchorPoints.TOP_LEFT,
                        );

                        break;
                    default:
                        changePosition(
                            designCanvasObject,
                            InnerAnchorPoints.BOTTOM_LEFT,
                        );

                        break;
                }
                break;

            case InnerAnchorPoints.CENTER_RIGHT:
                switch (validationAngle) {
                    case 90:
                    case -270:
                        changePosition(
                            designCanvasObject,
                            InnerAnchorPoints.TOP_CENTER,
                        );

                        break;

                    case 180:
                    case -180:
                        changePosition(
                            designCanvasObject,
                            InnerAnchorPoints.CENTER_LEFT,
                        );

                        break;

                    case 270:
                    case -90:
                        changePosition(
                            designCanvasObject,
                            InnerAnchorPoints.BOTTOM_CENTER,
                        );

                        break;
                    default:
                        changePosition(
                            designCanvasObject,
                            InnerAnchorPoints.CENTER_RIGHT,
                        );

                        break;
                }
                break;

            case InnerAnchorPoints.CENTER_LEFT:
                switch (validationAngle) {
                    case 90:
                    case -270:
                        changePosition(
                            designCanvasObject,
                            InnerAnchorPoints.BOTTOM_CENTER,
                        );

                        break;

                    case 180:
                    case -180:
                        changePosition(
                            designCanvasObject,
                            InnerAnchorPoints.CENTER_RIGHT,
                        );

                        break;

                    case 270:
                    case -90:
                        changePosition(
                            designCanvasObject,
                            InnerAnchorPoints.TOP_CENTER,
                        );

                        break;

                    default:
                        changePosition(
                            designCanvasObject,
                            InnerAnchorPoints.CENTER_LEFT,
                        );

                        break;
                }
                break;
            default:
                changePosition(
                    designCanvasObject,
                    InnerAnchorPoints.CENTER_CENTER,
                );

                break;
        }
    }

    function changePosition(
        designCanvasObject: DesignCanvasObject,
        position: InnerAnchorPoints,
    ) {
        designCanvasObject.image.originX = getOriginX(position[1]);
        designCanvasObject.image.originY = getOriginY(position[0]);

        useRepo(Customization).save({
            id: designCanvasObject.customization_id,
            position: position,
        });
    }
    function updatePxDimensionsFromLocalizedDimensions(
        designCanvasObject: DesignCanvasObject,
        size: Size,
    ) {
        const customization = useRepo(Customization).find(
            designCanvasObject.customization_id,
        );
        const view = useRepo(View)
            .where("handle", designCanvasObject.view_handle)
            .where("variant_id", currentVariant?.value.id)
            .first();

        if (!customization || !view) {
            return;
        }

        designCanvasObject.px_width = size.width * view.scalefactor;
        designCanvasObject.px_height = size.height * view.scalefactor;
    }

    return {
        onScaling,
        onMoving,
        onRotated,
        onScaled,
        onMoved,
        onRemoved,
        getOriginX,
        getOriginY,
        updatePxDimensionsFromLocalizedDimensions,
    };
};

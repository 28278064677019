<template>
    <div class="flex">
        <button
            type="button"
            data-testId="text-line-edit-align-left"
            class="mr-2 h-10 w-9 rounded"
            :class="{
                'bg-secondary-3 text-black':
                    alignment !== HorizontalAlignment.LEFT,
                'bg-black text-white': alignment === HorizontalAlignment.LEFT,
            }"
            @click="alignment = HorizontalAlignment.LEFT"
        >
            <f-a-icon icon="align-left" :size="FontAwesomeSize.fa1x" />
        </button>
        <button
            type="button"
            data-testId="text-line-edit-align-center"
            class="mr-2 h-10 w-9 rounded"
            :class="{
                'bg-secondary-3 text-black':
                    alignment !== HorizontalAlignment.CENTER,
                'bg-black text-white': alignment === HorizontalAlignment.CENTER,
            }"
            @click="alignment = HorizontalAlignment.CENTER"
        >
            <f-a-icon icon="align-center" :size="FontAwesomeSize.fa1x" />
        </button>
        <button
            type="button"
            data-testId="text-line-edit-align-right"
            class="mr-2 h-10 w-9 rounded"
            :class="{
                'bg-secondary-3 text-black':
                    alignment !== HorizontalAlignment.RIGHT,
                'bg-black text-white': alignment === HorizontalAlignment.RIGHT,
            }"
            @click="alignment = HorizontalAlignment.RIGHT"
        >
            <f-a-icon icon="align-right" :size="FontAwesomeSize.fa1x" />
        </button>
    </div>
</template>

<script setup lang="ts">
import { FontAwesomeSize } from "@/enums/fontAwesome/fontAwesomeSize";
import { HorizontalAlignment } from "@/enums/HorizontalAlignment";

const alignment = defineModel<HorizontalAlignment>({ required: true });
</script>

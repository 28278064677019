<template>
    <teleport v-if="modalTeleportTarget" :to="modalTeleportTarget">
        <div
            ref="modal"
            class="absolute h-full w-full"
            tabindex="-1"
            @keydown.self.esc="closeAbort()"
        >
            <!-- Backdrop !-->
            <transition
                enter-active-class="animate-fade-in"
                leave-active-class="animate-fade-out"
            >
                <div
                    v-if="isOpen"
                    ref="backdrop"
                    class="absolute z-[60] flex h-full w-full items-center justify-center"
                    :class="{
                        'bg-black bg-opacity-40': isActive,
                    }"
                    :style="backdropStyles"
                />
            </transition>
            <!-- Modal !-->
            <transition
                enter-active-class="animate-slide-in-down"
                leave-active-class="animate-slide-out-up"
            >
                <div
                    v-if="isOpen"
                    class="absolute flex h-full w-full items-center justify-center px-0 pb-6 pt-16 tablet:p-12 tablet:pt-24"
                    :style="modalStyles"
                    @click.self="clickOutside"
                >
                    <div
                        :class="modalSizeClass"
                        class="relative flex h-full w-full flex-col items-center justify-center gap-5 rounded-md bg-white p-5 drop-shadow-md tablet:h-auto tablet:max-h-full tablet:w-auto tablet:max-w-full"
                    >
                        <div class="absolute -top-12 right-3">
                            <button
                                v-if="canBeClosed"
                                :class="
                                    settings.style.basemodal.closeButton.styles
                                "
                                type="button"
                                @click="closeAbort"
                            >
                                <f-a-icon icon="times" />
                            </button>
                        </div>

                        <div
                            v-if="$slots.title"
                            class="flex w-full items-center justify-between"
                        >
                            <div class="text-lg font-bold">
                                <slot name="title" />
                            </div>
                        </div>

                        <div class="relative h-full w-full overflow-auto">
                            <slot />
                        </div>

                        <div
                            v-if="$slots.actions"
                            class="flex w-full justify-between gap-2"
                        >
                            <slot name="actions" />
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    </teleport>
</template>

<script setup lang="ts">
import useModal from "@/composables/useModal";
import { useMainStore } from "@/store/useMainStore";
import { storeToRefs } from "pinia";
import { computed, nextTick, ref, watch } from "vue";

const { settings } = storeToRefs(useMainStore());

const props = withDefaults(
    defineProps<{
        size?: string | null;
        closeOnClickOutside?: boolean;
        canBeClosed?: boolean;
    }>(),
    {
        size: null,
        closeOnClickOutside: true,
        canBeClosed: true,
    },
);

defineExpose({
    open,
    close,
});

const isOpen = ref<boolean>(false);
const modalIndex = ref<number>(0);
const { modalsCount, modalTeleportTarget } = useModal();
const modal = ref<HTMLDivElement | null>();

const emit = defineEmits(["abort"]);

const modalSizeClass = computed(() => {
    switch (props.size) {
        case "sm":
            return "w-2/6";
        case "md":
            return "w-3/6";
        case "lg":
            return "w-4/6";
        case "xl":
            return "w-full";
        default:
            return "";
    }
});

const modalStyles = computed(() => {
    return {
        zIndex: 61 + modalIndex.value,
    };
});

const backdropStyles = computed(() => {
    return {
        zIndex: 60 + modalIndex.value,
    };
});

const isActive = computed<boolean>(() => {
    return modalsCount.value === modalIndex.value;
});

watch(
    () => isActive.value,
    () => {
        if (!isActive.value) {
            return;
        }

        focusModal();
    },
);

function open() {
    if (isOpen.value) {
        return;
    }

    isOpen.value = true;
    focusModal();

    modalsCount.value++;
    modalIndex.value = modalsCount.value;
}

function close() {
    if (!props.canBeClosed) {
        return;
    }

    modalsCount.value--;
    isOpen.value = false;
}

function clickOutside() {
    if (!props.closeOnClickOutside) {
        return;
    }

    closeAbort();
}

function closeAbort() {
    emit("abort");

    close();
}

function focusModal() {
    void nextTick(() => {
        modal.value?.focus();
    });
}
</script>

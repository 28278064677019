import { PanelState } from "@/enums/PanelState";
import { defineStore, storeToRefs } from "pinia";
import { readonly, ref } from "vue";
import { usePanelStore } from "./usePanelStore";

export const useCustomizationEditModalStore = defineStore(
    "customizationEditModal",
    () => {
        const panelStore = usePanelStore();
        const { currentPanelState } = storeToRefs(panelStore);
        const skipAbort = ref(false);

        async function close(_skipAbort = false) {
            if (currentPanelState.value !== PanelState.CustomizationEdit)
                return;

            skipAbort.value = _skipAbort;
            await panelStore.hideCurrentPanel();
            skipAbort.value = false;
        }

        return { close, skipAbort: readonly(skipAbort) };
    },
);

<template>
    <div
        class="relative overflow-hidden rounded-2xl border border-secondary-2 p-3 pb-5"
    >
        <div class="flex flex-col items-center">
            <div class="flex w-full justify-between">
                <div class="mb-3 w-full text-sm font-bold">
                    {{ title.toLocaleUpperCase() }}
                </div>
                <button
                    v-if="
                        isZSKDesignWithLogo(
                            customizationPreview?.customization.design ?? null,
                        )
                    "
                    type="button"
                    class="rounded-full bg-secondary-3 hover:bg-secondary-2"
                    data-testid="delete-logo-button"
                    @click="design.logo = undefined"
                >
                    <f-a-icon
                        class="flex h-10 w-10 items-center justify-center"
                        icon="trash-alt"
                    />
                </button>
            </div>
            <customization-input-position
                v-if="customizationPreview && rotation !== undefined"
                v-model:anchor-point="anchorPoint"
                v-model:rotation="rotation"
                class="mt-2"
                :customization-preview="customizationPreview"
            />
        </div>
        <div
            v-if="design.logo?.color_assignments"
            class="flex flex-col items-center justify-center gap-4"
        >
            <button
                type="button"
                class="rounded-full bg-secondary-3 px-3 py-1 text-sm transition-all hover:bg-secondary-2"
                data-testid="change-logo-button"
                :class="collapsed ? 'mt-2' : 'mt-4'"
                @click="emit('openLogoSelection')"
            >
                {{
                    t(
                        design.logo.is_customer_logo
                            ? "choose_logo"
                            : "choose_icon",
                    )
                }}
            </button>
            <slider-input
                v-if="distanceInMm !== undefined"
                id="offset"
                v-model="distanceInMm"
                class="w-full"
                :label="t('distance_motif_text')"
                :min="-50"
                :max="50"
                :step="1"
            />
            <logo-input-colors
                v-show="!collapsed"
                v-model:selected-color-assignment-index="
                    editColorAssignmentIndex
                "
                class="w-full"
                :color-assignments="design.logo.color_assignments"
                :disabled="!(features.logo?.editable ?? true)"
            />
        </div>
        <div
            v-if="
                editColorAssignmentIndex !== undefined &&
                design.logo?.color_assignments[editColorAssignmentIndex]
            "
            ref="colorInputElement"
            class="absolute inset-0 overflow-y-auto bg-white p-3 pb-5"
        >
            <color-input
                v-model="
                    design.logo.color_assignments[editColorAssignmentIndex]
                "
                @update:model-value="editColorAssignmentIndex = undefined"
            />
        </div>

        <div
            v-if="
                features.logo?.resizeable &&
                isZSKDesignWithLogo(design) &&
                design.logoDesignElementData?.dimension &&
                minDimension &&
                maxDimension
            "
            class="mt-4 w-full flex-col"
        >
            <size-input
                v-model="design.logoDesignElementData.dimension"
                :min="minDimension"
                :max="maxDimension"
            />
        </div>
    </div>
</template>

<script setup lang="ts">
import { useDesignFeatures } from "@/composables/useDesignFeatures";
import type { ZSKDesign } from "@/modules/core/renderer/zskEmbroidery/types/ZSKDesign";
import {
    isZSKDesignWithLogo,
    isZSKLogoTextDesign,
} from "@/modules/core/renderer/zskEmbroidery/ZSKDesignHelper";
import type Customization from "@/repo/Customization";
import { useSettingsStore } from "@/store/useSettingsStore";
import type { Size } from "@smakecloud/designer-core";
import { storeToRefs } from "pinia";
import { computed, ref, watchEffect } from "vue";
import { useI18n } from "vue-i18n";

const emit = defineEmits<{
    openLogoSelection: [];
}>();

defineProps<{
    title: string;
    customizationPreview?: {
        customization: Customization;
        isValid: boolean;
    };
}>();

const { t } = useI18n();

const rotation = defineModel<number>("rotation", {
    required: true,
});
const design = defineModel<ZSKDesign>("design", { required: true });

const minDimension = computed(() => {
    const logo = design.value.logo;

    if (!logo) {
        return;
    }

    return {
        width: logo.min_width,
        height: logo.min_height,
    } satisfies Size;
});

const maxDimension = computed(() => {
    const logo = design.value.logo;

    if (!logo) {
        return;
    }

    return {
        width: logo.max_width,
        height: logo.max_height,
    } satisfies Size;
});

const features = useDesignFeatures(design);

const { lengthUnitRatio } = storeToRefs(useSettingsStore());
const distanceInMm = computed({
    get: () =>
        isZSKLogoTextDesign(design.value)
            ? design.value.alignment.distance * lengthUnitRatio.value
            : undefined,
    set: (value) => {
        if (!isZSKLogoTextDesign(design.value)) {
            return;
        }

        if (!value) {
            return;
        }

        design.value.alignment.distance = value / lengthUnitRatio.value;
    },
});

const anchorPoint = computed({
    get: () =>
        isZSKLogoTextDesign(design.value)
            ? design.value.alignment.anchor
            : undefined,
    set: (value) => {
        if (!isZSKLogoTextDesign(design.value)) {
            return;
        }

        if (!value) {
            return;
        }

        design.value.alignment.anchor = value;
    },
});

const editColorAssignmentIndex = ref<number>();
const colorInputElement = ref<HTMLElement>();

watchEffect(() => {
    if (editColorAssignmentIndex.value) {
        colorInputElement.value?.scrollIntoView({ behavior: "smooth" });
    }
});

const collapsed = ref(false);
</script>

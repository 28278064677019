import {
    isProductionMethodHandle,
    ProductionMethodsFeatures,
} from "@/composables/useProductionMethodFeatures";
import type PositioningArea from "@/repo/PositioningArea";
import type { LogoResourceData } from "@smakecloud/smake-use";

type Size = {
    width: number;
    height: number;
};

export function calcMinSize(value: Size, min: Size): Size {
    const scale = Math.min(min.width / value.width, min.height / value.height);

    if (scale >= 1) {
        return value;
    }

    return {
        width: value.width * scale,
        height: value.height * scale,
    };
}

export function clampSize(
    value: Size,
    min: Size,
    max: Size,
    options?: { onMinExceeded?: () => void; onMaxExceeded?: () => void },
): Size {
    const minScale = Math.max(
        min.width / value.width,
        min.height / value.height,
    );

    const maxScale = Math.min(
        max.width / value.width,
        max.height / value.height,
    );

    const scale = Math.min(Math.max(minScale, 1), maxScale);

    if (scale > 1) {
        if (options?.onMinExceeded) {
            options.onMinExceeded();
        }
    }

    if (scale < 1) {
        if (options?.onMaxExceeded) {
            options?.onMaxExceeded();
        }
    }

    return {
        width: value.width * scale,
        height: value.height * scale,
    };
}

export function getInitalLogoDimension(
    logo: LogoResourceData,
    positioningArea: PositioningArea,
) {
    const handle = logo.production_method.handle;

    if (!isProductionMethodHandle(handle)) {
        return;
    }

    const features = ProductionMethodsFeatures[handle];

    if (!features.logo?.resizeable) {
        return;
    }

    return clampSize(
        calcMinSize(
            {
                width: logo.width,
                height: logo.height,
            },
            {
                width: positioningArea.localized_width,
                height: positioningArea.localized_height,
            },
        ),
        {
            width: logo.min_width,
            height: logo.min_height,
        },
        {
            width: logo.max_width,
            height: logo.max_height,
        },
    );
}

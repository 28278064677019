import { defineStore } from "pinia";
import { ref } from "vue";
import {
    type VariantIdentifier,
    type VariantIdentifierComplete,
} from "@/types/DesignerProps";
import { useVariantChecker } from "@/composables/variants/useVariantIdentifierChecker";

export const useAvailableVariantsStore = defineStore(
    "availableVariantsStore",
    () => {
        const { isVariantId } = useVariantChecker();

        const availableVariants = ref<VariantIdentifier[]>([]);

        function isVariantAvailable(
            variantIdentifierComplete: VariantIdentifierComplete,
        ): boolean {
            if (availableVariants.value.length === 0) {
                return true;
            }

            return availableVariants.value.some(
                (availableVariant: VariantIdentifier): boolean => {
                    if (isVariantId(availableVariant)) {
                        return (
                            availableVariant.id === variantIdentifierComplete.id
                        );
                    }

                    return (
                        availableVariant.external_identifier ===
                        variantIdentifierComplete.external_identifier
                    );
                },
            );
        }

        return {
            availableVariants,
            isVariantAvailable,
        };
    },
);

import { type WithChildren } from "@/composables/useTreeUtils";
import { apiClient } from "@smakecloud/designer-core";
import { type LogoCategoryData } from "@smakecloud/smake-use";
import { useQuery } from "@tanstack/vue-query";
import { defineStore } from "pinia";
import { computed, ref } from "vue";

export const useLogoCategoriesStore = defineStore("logoCategoriesStore", () => {
    const activeProductionMethods = ref<number[] | undefined>();
    const filters = computed<typeof apiClient.logos.categories.list.inputType>(
        () => ({
            production_method: activeProductionMethods.value,
        }),
    );

    const query = useQuery({
        ...apiClient.logos.categories.list.use(filters),
        staleTime: Infinity,
    });

    function categoriesToTree(
        categories: LogoCategoryData[],
        parentId: number | null = null,
    ) {
        const result: WithChildren<LogoCategoryData>[] = [];

        for (const category of categories) {
            if (category.parent_id === parentId) {
                result.push({
                    ...category,
                    children: categoriesToTree(categories, category.id),
                });
            }
        }

        result.sort((a, b) => a.left - b.left);

        return result;
    }

    const dataTree = computed(() =>
        query.data.value ? categoriesToTree(query.data.value) : undefined,
    );

    return {
        dataTree,
        ...query,
        activeProductionMethods,
    };
});

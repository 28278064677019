import { defineStore } from "pinia";
import { type KitIcon } from "@/types/fontAwesome/KitIcon";
import { ref } from "vue";

export const useIconStore = defineStore("iconStore", () => {
    const kitIcons = ref<KitIcon[]>();

    return {
        kitIcons,
    };
});

import { storeToRefs } from "pinia";
import { modulesConfig } from "@/config/modules.config";
import { useMainStore } from "@/store/useMainStore";
import { type ModuleConfigEntry } from "@/types/ModuleConfigEntry";
import { type ScreenName } from "@/enums/ScreenName";
import { type PanelName } from "@/enums/PanelName";
import { useDesignerScreenSizeName } from "@/composables/useDesignerScreenSizeName";

export const useModules = () => {
    const { screenName } = useDesignerScreenSizeName();
    function availableEditModals(): ModuleConfigEntry[] {
        return modulesConfig.filter((module) => module.editModal);
    }

    function buttonsForPanel(panelName: PanelName): string[] {
        return availableButtonsForPanel(panelName, screenName.value)
            .map(
                (availableButtonForPanel) =>
                    availableButtonForPanel?.button ?? null,
            )
            .filter((entry) => entry !== null);
    }

    function availableButtonsForPanel(
        panel: PanelName,
        screenName: ScreenName,
    ): (ModuleConfigEntry | undefined)[] {
        const mainStore = useMainStore();
        const { settings } = storeToRefs(mainStore);

        const availableModulesConfigForCurrentScreen = modulesConfig.filter(
            (module) => module.screens.includes(screenName),
        );

        const availableModules = settings.value.layout[screenName][
            panel
        ].modules
            .map(
                (moduleName) =>
                    availableModulesConfigForCurrentScreen.find(
                        (availableModule) =>
                            availableModule.name === moduleName,
                    ) ?? null,
            )
            .filter((moduleConfigEntry) => moduleConfigEntry !== null);

        return availableModules;
    }

    return { availableEditModals, buttonsForPanel };
};

<template>
    <div :class="settings.style.stageViewNavigation.styles">
        <button
            v-for="view in currentVariant.views"
            :key="view.handle"
            :data-testid="`switch-to-${view.handle}`"
            type="button"
            class="pointer-events-auto transition duration-500 ease-in-out focus:outline-none"
            :class="stageViewButtonsStyleConfig(view)"
            :disabled="props.disabled"
            @click="changeCurrentView(view)"
        >
            {{ view.display_name }}
        </button>
    </div>
</template>

<script setup lang="ts">
import { useMainStore } from "@/store/useMainStore";
import { useCurrentStore } from "@/store/useCurrentStore";
import type View from "@/repo/View";
import { storeToRefs } from "pinia";

const { settings } = useMainStore();
const currentStore = useCurrentStore();
const { changeCurrentView } = currentStore;
const { currentView, currentVariant } = storeToRefs(currentStore);

// props
const props = defineProps({
    disabled: {
        type: Boolean,
        default: false,
    },
});

// methods
function stageViewButtonsStyleConfig(view: View) {
    if (view.handle === currentView.value.handle) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access
        return settings.style.stageViewNavigation.activeButton.styles;
    }

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access
    return settings.style.stageViewNavigation.inactiveButton.styles;
}
</script>

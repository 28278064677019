<script setup lang="ts">
import { useColorConvertorHSV } from "@/composables/colorPicker/useColorConverterHSV";
import { isLightColor } from "@/lib/color";
import type { HSVColor } from "@/utils/colorConversion";
import { computed, toRefs } from "vue";

const props = defineProps<{
    color: HSVColor;
}>();

const { color } = toRefs(props);

const { colorRGB } = useColorConvertorHSV(color);

const isColorLight = computed(() =>
    isLightColor([
        colorRGB.value.red,
        colorRGB.value.green,
        colorRGB.value.blue,
    ]),
);
</script>

<template>
    <button
        class="flex aspect-square w-10 items-center justify-center rounded-full border"
        :class="{
            'border-secondary-2': isColorLight,
            'border-transparent': !isColorLight,
        }"
        :style="{
            backgroundColor: `rgb(${colorRGB.red}, ${colorRGB.green}, ${colorRGB.blue})`,
        }"
    />
</template>

import { useDialogStore, type UnknownDialog } from "@/store/useDialogStore";
import { storeToRefs } from "pinia";
import {
    getCurrentInstance,
    markRaw,
    onUnmounted,
    ref,
    type ComponentOptionsMixin,
    type DefineComponent,
} from "vue";

export function useDialog() {
    const { dialogs } = storeToRefs(useDialogStore());

    const mountedDialogs = ref<UnknownDialog[]>([]);

    function show<Input extends Record<string, unknown>, Output>(
        component: DefineComponent<
            Input,
            unknown,
            unknown,
            NonNullable<unknown>,
            NonNullable<unknown>,
            ComponentOptionsMixin,
            ComponentOptionsMixin,
            { resolve: (output: Output) => unknown }
        >,
        ...[input = {}]: Input extends Record<string, never>
            ? []
            : [input: Input]
    ) {
        const { resolve, promise } = Promise.withResolvers<
            Output | undefined
        >();
        const dialog = {
            component: markRaw(component),
            input,
            resolve,
            isOpen: true,
        } as UnknownDialog;
        dialogs.value.push(dialog);
        mountedDialogs.value.push(dialog);

        return promise;
    }

    if (getCurrentInstance()) {
        onUnmounted(() => {
            mountedDialogs.value.forEach((dialog) => {
                dialog.isOpen = false;
            });
        });
    }

    return { show };
}

import useConsoleLog from "@/composables/useConsoleLog";
import useNotification from "@/composables/useNotification";
import { LogColor } from "@/enums/LogColor";
import { NotificationType } from "@/enums/NotificationType";
import { getInitalLogoDimension } from "@/functions/SizeHelper";
import { designElementToZSKDesign } from "@/modules/core/renderer/zskEmbroidery/ConvertDesignElementToZSKDesign";
import { ConvertZSKDesignToDesign } from "@/modules/core/renderer/zskEmbroidery/ConvertZSKDesignToDesign";
import {
    isDesignWithRenderInformation,
    isZSKDesignWithLogo,
} from "@/modules/core/renderer/zskEmbroidery/ZSKDesignHelper";
import { type ZSKDesign } from "@/modules/core/renderer/zskEmbroidery/types/ZSKDesign";
import Customization from "@/repo/Customization";
import PositioningArea from "@/repo/PositioningArea";
import View from "@/repo/View";
import { useCanvasStore } from "@/store/useCanvasStore";
import { useCurrentStore } from "@/store/useCurrentStore";
import {
    apiClient,
    apiDesignToDesign,
    designToApiDesign,
} from "@smakecloud/designer-core";
import {
    HorizontalAlignment,
    InnerAnchorPoints,
    type DesignPreviewData,
    type VariantData,
} from "@smakecloud/smake-use";
import { useQueryClient } from "@tanstack/vue-query";
import { cloneDeep } from "lodash";
import { defineStore, storeToRefs } from "pinia";
import { useRepo } from "pinia-orm";
import { getCurrentInstance, ref, withCtx } from "vue";
import { useI18n } from "vue-i18n";

type ApiDesign =
    (typeof apiClient.designs.preview.inputType)["elements"][number];

const customizationTextRenderStateStore = defineStore(
    "customizationTextRenderState",
    () => {
        const isARenderingRequestInProgress = ref<boolean>(false);

        return {
            isARenderingRequestInProgress,
        };
    },
);

export default () => {
    const currentStore = useCurrentStore();
    const { currentCustomization, currentVariant, isWaitingForRenderProcess } =
        storeToRefs(currentStore);
    const { logTextDev } = useConsoleLog();
    const { isARenderingRequestInProgress } = storeToRefs(
        customizationTextRenderStateStore(),
    );
    const notification = useNotification();
    const { t } = useI18n();
    const canvasStore = useCanvasStore();
    const { setNewTextCanvasObjectsAsActive, designCanvasObjects } =
        storeToRefs(canvasStore);
    const queryClient = useQueryClient();
    const context = getCurrentInstance();

    async function renderPreviewForCurrentCustomization(): Promise<Customization> {
        if (currentCustomization.value === null) {
            throw new Error("No current customization set");
        }

        return renderPreviewForCustomization(currentCustomization.value);
    }

    function getRotatedAnchorPoint(
        position: InnerAnchorPoints,
        rotation: number,
        positioningArea: PositioningArea,
    ) {
        const rotationAngle = ref(
            Math.round(
                Math.round(
                    rotation -
                        positioningArea.rotation -
                        positioningArea.direction,
                ) / 90,
            ) * 90,
        );

        if (rotationAngle.value >= 360) {
            rotationAngle.value -= 360;
        }

        switch (rotationAngle.value + positioningArea.direction) {
            case 90:
            case -270:
                switch (position) {
                    case InnerAnchorPoints.TOP_CENTER:
                        return InnerAnchorPoints.CENTER_LEFT;
                    case InnerAnchorPoints.TOP_RIGHT:
                        return InnerAnchorPoints.TOP_LEFT;
                    case InnerAnchorPoints.TOP_LEFT:
                        return InnerAnchorPoints.BOTTOM_LEFT;
                    case InnerAnchorPoints.BOTTOM_CENTER:
                        return InnerAnchorPoints.CENTER_RIGHT;
                    case InnerAnchorPoints.BOTTOM_RIGHT:
                        return InnerAnchorPoints.TOP_RIGHT;
                    case InnerAnchorPoints.BOTTOM_LEFT:
                        return InnerAnchorPoints.BOTTOM_RIGHT;
                    case InnerAnchorPoints.CENTER_RIGHT:
                        return InnerAnchorPoints.TOP_CENTER;
                    case InnerAnchorPoints.CENTER_LEFT:
                        return InnerAnchorPoints.BOTTOM_CENTER;
                    default:
                        return InnerAnchorPoints.CENTER_CENTER;
                }
            case 180:
            case -180:
                switch (position) {
                    case InnerAnchorPoints.TOP_CENTER:
                        return InnerAnchorPoints.BOTTOM_CENTER;
                    case InnerAnchorPoints.TOP_RIGHT:
                        return InnerAnchorPoints.BOTTOM_LEFT;
                    case InnerAnchorPoints.TOP_LEFT:
                        return InnerAnchorPoints.BOTTOM_RIGHT;
                    case InnerAnchorPoints.BOTTOM_CENTER:
                        return InnerAnchorPoints.TOP_CENTER;
                    case InnerAnchorPoints.BOTTOM_RIGHT:
                        return InnerAnchorPoints.TOP_LEFT;
                    case InnerAnchorPoints.BOTTOM_LEFT:
                        return InnerAnchorPoints.TOP_RIGHT;
                    case InnerAnchorPoints.CENTER_RIGHT:
                        return InnerAnchorPoints.CENTER_LEFT;
                    case InnerAnchorPoints.CENTER_LEFT:
                        return InnerAnchorPoints.CENTER_RIGHT;
                    default:
                        return InnerAnchorPoints.CENTER_CENTER;
                }
            case 270:
            case -90:
                switch (position) {
                    case InnerAnchorPoints.TOP_CENTER:
                        return InnerAnchorPoints.CENTER_RIGHT;
                    case InnerAnchorPoints.TOP_RIGHT:
                        return InnerAnchorPoints.BOTTOM_RIGHT;
                    case InnerAnchorPoints.TOP_LEFT:
                        return InnerAnchorPoints.TOP_RIGHT;
                    case InnerAnchorPoints.BOTTOM_CENTER:
                        return InnerAnchorPoints.CENTER_LEFT;
                    case InnerAnchorPoints.BOTTOM_RIGHT:
                        return InnerAnchorPoints.BOTTOM_LEFT;
                    case InnerAnchorPoints.BOTTOM_LEFT:
                        return InnerAnchorPoints.TOP_LEFT;
                    case InnerAnchorPoints.CENTER_RIGHT:
                        return InnerAnchorPoints.BOTTOM_CENTER;
                    case InnerAnchorPoints.CENTER_LEFT:
                        return InnerAnchorPoints.TOP_CENTER;
                    default:
                        return InnerAnchorPoints.CENTER_CENTER;
                }
            default:
                return position;
        }
    }

    async function createAndRenderNewDesign(
        targetPositioningArea: PositioningArea,
        targetViewHandle: string,
        design?: ZSKDesign,
    ): Promise<Customization | undefined> {
        const targetView = useRepo(View)
            .where("handle", targetViewHandle)
            .where("variant_id", currentVariant.value.id)
            .first();

        if (targetView === null) {
            throw new Error("No view found");
        }

        // isCreatingNewTextResource.value = true; TODO check for focus

        try {
            return createAndRenderDesign(
                targetPositioningArea,
                targetView,
                design,
            );
        } catch {
            isWaitingForRenderProcess.value = false;
            notification.pushNotification(
                t("error_pusher_rendering_failed"),
                NotificationType.Info,
            );
        }
    }

    async function createAndRenderDesign(
        targetPositioningArea: PositioningArea,
        targetView: View,
        design?: ZSKDesign,
        rotation?: number,
        setRenderedObjectAsActive = true,
    ): Promise<Customization> {
        if (!rotation) {
            rotation =
                targetPositioningArea.rotation +
                targetPositioningArea.direction;
        }

        setNewTextCanvasObjectsAsActive.value = setRenderedObjectAsActive;

        isWaitingForRenderProcess.value = true;

        if (
            design &&
            isZSKDesignWithLogo(design) &&
            !design.logoDesignElementData?.dimension
        ) {
            design = {
                ...design,
                logoDesignElementData: {
                    dimension: getInitalLogoDimension(
                        design.logo,
                        targetPositioningArea,
                    ),
                },
            };
        }

        const customizationRepo = useRepo(Customization);
        const { id } = customizationRepo.save({
            positioningArea_id: targetPositioningArea.id,
            x: targetPositioningArea.x,
            y: targetPositioningArea.y,
            position: getRotatedAnchorPoint(
                targetPositioningArea.position,
                rotation,
                targetPositioningArea,
            ),
            design,
            rotation: rotation,
        });

        let newCustomization = customizationRepo.withAll().find(id);

        if (!newCustomization) {
            throw new Error("Couldn't find created customization");
        }

        useRepo(View).save({
            handle: targetView.handle,
            variant_id: targetView.variant_id,
            customization_ids: [...targetView.customization_ids, id],
        });

        try {
            customizationRepo.save(
                await renderPreviewForCustomization(newCustomization),
            );
        } catch (e) {
            console.log(e);
        }

        newCustomization = customizationRepo.withAll().find(id);

        if (!newCustomization) {
            throw new Error("Couldn't find created customization");
        }

        return newCustomization;
    }

    function fetchDesignPreviewByZskDesign(zskDesign: ZSKDesign) {
        return withCtx(() => {
            const apiDesign = ConvertZSKDesignToDesign(zskDesign);
            return queryClient.fetchQuery({
                ...apiClient.designs.preview.use({
                    production_method: { id: apiDesign.production_method.id },
                    elements: [
                        designToApiDesign({
                            productionMethodId: apiDesign.production_method.id,
                            design: apiDesignToDesign(
                                apiDesign.elements[0] as ApiDesign,
                            ),
                        }),
                    ],
                }),
                staleTime: Infinity,
                retry: false,
            });
        }, context)() as Promise<DesignPreviewData>;
    }

    async function fetchDesignPreview(
        customization: Customization,
    ): Promise<DesignPreviewData> {
        // With the new feature we don't want placeholders anymore
        if (customization.design === null) {
            throw Error("Design is undefined");
        }

        return fetchDesignPreviewByZskDesign(customization.design);
    }

    function conditionalRemoveCustomizationRenderPreview(
        customization: Customization,
    ): boolean {
        if (isDesignWithRenderInformation(customization.design)) {
            return false;
        }

        canvasStore.removeDesignCanvasObjectByCustomizationId(customization.id);

        return true;
    }

    async function renderPreviewForCustomization(
        customization: Customization,
    ): Promise<Customization> {
        try {
            if (conditionalRemoveCustomizationRenderPreview(customization)) {
                return cloneDeep({
                    ...customization,
                    preview_base64: null,
                    width: 0,
                    height: 0,
                } as Customization);
            }

            isARenderingRequestInProgress.value = true;
            canvasStore.hideCanvasObject(customization);

            if (
                !designCanvasObjects?.value.find(
                    (textCanvasObject) =>
                        textCanvasObject.customization_id === customization.id,
                )
            ) {
                await canvasStore.createDesignCanvasObject(customization);
            }

            logTextDev(
                `🏎️ Send Update Text Render Request for design of customization: ${customization.id}`,
                LogColor.YELLOW,
            );

            const customizationForPreview = cloneDeep(customization);
            customizationForPreview.design?.text?.lines.forEach((line) => {
                if (line.horizontal_alignment !== HorizontalAlignment.LEFT) {
                    line.offset = 0;
                }
            });

            const designPreviewData = await fetchDesignPreview(
                customizationForPreview,
            );

            if (
                designPreviewData.width === 0 ||
                designPreviewData.height === 0
            ) {
                throw new Error("Text preview has no width or height");
            }

            // update customization
            const updatedCustomization = cloneDeep(customization);

            updatedCustomization.height = designPreviewData.height;
            updatedCustomization.width = designPreviewData.width;
            updatedCustomization.preview_base64 = designPreviewData.content;

            await canvasStore.renderDesignPreviewToCanvas(
                updatedCustomization,
                designPreviewData,
            );

            canvasStore.showCanvasObject(updatedCustomization);

            logTextDev(
                `🏁 Finished rendering design preview for design of customization: ${updatedCustomization.id}`,
                LogColor.YELLOW,
            );

            isARenderingRequestInProgress.value = false;

            return updatedCustomization;
        } catch (e) {
            notification.pushNotification(
                t("error_pusher_rendering_failed"),
                NotificationType.Info,
            );

            canvasStore.showCanvasObject(customization);

            isARenderingRequestInProgress.value = false;

            throw Error("Rendering Failed");
        }
    }

    // todo Transform Customization
    async function renderVariantData(variant: VariantData) {
        for await (const view of variant.views) {
            for await (const customization of view.customizations.sort(
                (customizationA, customizationB) =>
                    customizationA.positioningArea.production_method.id <
                    customizationB.positioningArea.production_method.id
                        ? 1
                        : -1,
            )) {
                let positioningArea = useRepo(PositioningArea).find(
                    customization.positioningArea.id,
                );
                const variantView = useRepo(View)
                    .where("variant_id", variant.id)
                    .where("handle", view.handle)
                    .first();

                if (variantView === null) {
                    throw new Error("Could not find variant view");
                }

                if (
                    !positioningArea &&
                    customization.positioningArea
                        .positioning_area_identifier_handle !== null
                ) {
                    positioningArea =
                        variantView.positioning_areas.find(
                            (positioningArea) => {
                                return (
                                    positioningArea.positioning_area_identifier_handle ===
                                        customization.positioningArea
                                            .positioning_area_identifier_handle &&
                                    positioningArea.production_method ===
                                        customization.positioningArea
                                            .production_method
                                );
                            },
                        ) ?? null;
                }

                if (positioningArea !== null) {
                    const design = designElementToZSKDesign(
                        customization.design.elements[0],
                    );

                    try {
                        await createAndRenderDesign(
                            positioningArea,
                            variantView,
                            design,
                            customization.rotation,
                            false,
                        );
                    } catch {
                        notification.pushNotification(
                            t(
                                "error_pusher_rendering_failed_while_loading_variant",
                            ),
                            NotificationType.Info,
                        );
                    }
                } else {
                    notification.pushNotification(
                        t(
                            "error_pusher_rendering_failed_while_loading_variant",
                        ),
                        NotificationType.Info,
                    );
                }
            }
        }
    }

    return {
        isARenderingRequestInProgress,
        renderVariantData,
        renderPreviewForCurrentCustomization,
        createAndRenderNewDesign,
        createAndRenderDesign,
        renderPreviewForCustomization,
    };
};

import get from "lodash/get";
import isObjectLike from "lodash/isObjectLike";

import axios from "axios";

import { fontAwesomeKitConfig } from "@/config/font-awesome-kit.config";
import { useIconStore } from "@/store/useIconStore";
import { type KitIcon } from "@/types/fontAwesome/KitIcon";
import { storeToRefs } from "pinia";

const icons = fontAwesomeKitConfig.icons;
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
let FontAwesomeKitConfig: any = null;

export default async () => {
    const iconStore = useIconStore();
    const { kitIcons } = storeToRefs(iconStore);

    await init();

    async function init(): Promise<void> {
        const config = {
            id: 41338386,
            version: "6.7.2",
            token: "8280cfc532",
            method: "js",
            baseUrl: "https://ka-p.fontawesome.com",
            license: "pro",
            asyncLoading: { enabled: false },
            autoA11y: { enabled: true },
            baseUrlKit: "https://kit.fontawesome.com",
            detectConflictsUntil: null,
            iconUploads: {
                "icon-ciconfig-alert-check": { v: 1, u: "e019" },
                "icon-expander-small-close": { v: 1, u: "e011" },
                "icon-remove": { v: 1, u: "e014" },
                resize: { v: 1, u: "e003" },
                "icon-ciconfig-areaonoff": { v: 1, u: "e007" },
                "icon-close-small": { v: 1, u: "e00d" },
                "icon-expander-clos": { v: 1, u: "e00f" },
                "icon-info": { v: 1, u: "e013" },
                "icon-arrow-circle-grey-left": { v: 1, u: "e004" },
                "icon-easytransfer": { v: 3, u: "e026" },
                "icon-text-alignment": { v: 1, u: "e024" },
                "icon-close-medium": { v: 1, u: "e00c" },
                "icon-pdf": { v: 1, u: "e01f" },
                smake: { v: 1, u: "e002" },
                "icon-close-large": { v: 1, u: "e00b" },
                "icon-expander-open": { v: 1, u: "e010" },
                "icon-ciconfig-italic": { v: 1, u: "e00a" },
                "icon-ciconfig-alert-warning": { v: 1, u: "e01b" },
                "icon-size-small": { v: 1, u: "e016" },
                "icon-size-stout": { v: 1, u: "e017" },
                "icon-x": { v: 1, u: "e01a" },
                "icon-edit": { v: 1, u: "e00e" },
                "icon-size-tall": { v: 1, u: "e018" },
                "icon-zoom-in": { v: 2, u: "e01d" },
                "icon-arrow-circle-grey-right": { v: 1, u: "e005" },
                "icon-ciconfig-bar-arrow": { v: 1, u: "e008" },
                "icon-size-default": { v: 1, u: "e015" },
                "tshirt-color": { v: 3, u: "e000" },
                "icon-design-text": { v: 1, u: "e022" },
                download: { v: 1, u: "e020" },
                "icon-ciconfig-archive": { v: 1, u: "e006" },
                "icon-zoom-out": { v: 2, u: "e01c" },
                "icon-design-logo": { v: 1, u: "e023" },
                "icon-ciconfig-bar-check": { v: 1, u: "e009" },
                "icon-design-database": { v: 1, u: "e021" },
                "icon-expander-small-open": { v: 1, u: "e012" },
                "icon-save": { v: 1, u: "e01e" },
                "icon-direkteinstickung": { v: 2, u: "e025" },
                "tshirt-size": { v: 1, u: "e001" },
            },
            minify: { enabled: true },
            v4FontFaceShim: { enabled: true },
            v4shim: { enabled: true },
            v5FontFaceShim: { enabled: true },
            uploadsUrl: "https://kit-uploads.fontawesome.com",
        };

        if (isInvalidConfig(config)) {
            throw Error("FontAwesomeKit - No valid configuration found");
        }

        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        FontAwesomeKitConfig = config;

        kitIcons.value = await Promise.all(
            icons.map((icon) => loadKitIconFromFontAwesome(icon)),
        );
    }

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function isInvalidConfig(fontAwesomeKitConfig: any): boolean {
        if (!isObjectLike(fontAwesomeKitConfig)) {
            return true;
        }

        return !isObjectLike(get(fontAwesomeKitConfig, "iconUploads"));
    }

    async function loadKitIconFromFontAwesome(
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        kitIconName: any,
    ): Promise<KitIcon> {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const kitIcon = get(
            FontAwesomeKitConfig,
            `iconUploads.${kitIconName}`,
            null,
        );

        if (!kitIcon) {
            console.log(`Icon not found in KitIcons`);
        }

        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        let url = `${FontAwesomeKitConfig.uploadsUrl}/`;
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        url += `${FontAwesomeKitConfig.token}/icons/`;
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        url += `${kitIconName}-${kitIcon.v}.svg`;

        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        kitIcon.name = kitIconName;

        try {
            const response = await axios.get(url);
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
            kitIcon.svg = response.data;
        } catch (error) {
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            console.error(`Error loading icon: ${error}`);
        }

        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return kitIcon;
    }
};

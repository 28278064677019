import cs from "@/data/lang/cs.json";
import de from "@/data/lang/de.json";
import en from "@/data/lang/en.json";
import fr from "@/data/lang/fr.json";
import nl from "@/data/lang/nl.json";

export default {
    cs,
    de,
    en,
    fr,
    nl,
};

import { Model } from "pinia-orm";
import { Attr, Uid, Str, Num, BelongsTo } from "pinia-orm/dist/decorators";
import PositioningArea from "@/repo/PositioningArea";
import { type InnerAnchorPoints } from "@smakecloud/smake-use";
import type { ZSKDesign } from "@/modules/core/renderer/zskEmbroidery/types/ZSKDesign";

export default class Customization extends Model {
    static entity = "customizations";

    @Uid()
    id!: number;

    @Num(0)
    x!: number;

    @Num(0)
    y!: number;

    @Attr(null)
    design!: ZSKDesign | null;

    @Num(0)
    width!: number;

    @Num(0)
    height!: number;

    @Attr(null)
    preview_base64!: string | null;

    @Str("")
    price!: string;

    @Str("")
    position!: InnerAnchorPoints;

    @Num(0)
    rotation!: number;

    @Str("")
    calculation_type!: string;

    @Num(0)
    calculation_value!: number;

    @Num(0)
    order!: number;

    @Str("")
    hash!: string;

    @Num(0)
    positioningArea_id!: number;

    @BelongsTo(() => PositioningArea, "positioningArea_id")
    positioning_area!: PositioningArea;

    @Str("")
    background_color!: string;

    @Str("")
    created_at!: string;

    @Str("")
    updated_at!: string;
}

import ArchiveModal from "@/components/modules/archive/ArchiveModal.vue";
import CustomizationEditModal from "@/components/modules/customizations/CustomizationEditModal.vue";
import PdfDownloadModal from "@/components/modules/pdfDownload/PdfDownloadModal.vue";
import ProductColorsModal from "@/components/modules/productColors/ProductColorsModal.vue";
import { defineComponent, h } from "vue";
import { type RouteRecordRaw } from "vue-router";

export const routes: RouteRecordRaw[] = [
    {
        path: "/",
        name: "noModal",
        component: defineComponent(() => () => h("div")),
    },
    {
        path: "/archive",
        name: "archiveModal",
        component: ArchiveModal,
    },
    {
        path: "/pdf-download-modal",
        name: "pdfDownloadModal",
        component: PdfDownloadModal,
    },
    {
        path: "/product-colors-modal",
        name: "productColorsModal",
        component: ProductColorsModal,
    },
    {
        path: "/customization-edit-modal",
        name: "customizationEditModal",
        component: CustomizationEditModal,
    },
];

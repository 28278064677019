<template>
    <div v-on-scroll-end="onScrollEnd" class="h-full overflow-y-auto pr-3">
        <div
            class="grid grid-cols-[repeat(auto-fill,minmax(261px,1fr))] gap-4 tablet:grid-cols-[repeat(auto-fill,minmax(355px,1fr))]"
        >
            <variant-selection-group
                v-for="variant in data"
                :key="variant.id"
                :variant="variant.resource"
                :id-prefix="`collection-${collectionName}`"
            />
            <template v-if="isFetching">
                <div
                    class="h-96 animate-pulse rounded-3xl bg-secondary-3 px-5 py-8"
                />
                <div
                    class="h-96 animate-pulse rounded-3xl bg-secondary-3 px-5 py-8"
                />
            </template>
        </div>
    </div>
</template>

<script setup lang="ts">
import { useVariantsCollection } from "@/composables/variants/useVariantsCollection";
import vOnScrollEnd from "@/directives/vOnScrollEnd";
import { toRefs } from "vue";

const props = defineProps<{ collectionName: string }>();
const { collectionName } = toRefs(props);

const { data, isFetching, fetchNextPage } =
    useVariantsCollection(collectionName);

function onScrollEnd() {
    if (isFetching.value) {
        return;
    }
    void fetchNextPage();
}
</script>

import {
    type RGBColor,
    type HSVColor,
    hsvToRgb,
    rgbToHsv,
} from "@/utils/colorConversion";
import { type Ref, computed, ref } from "vue";

function isRGBEqual(rgbA: RGBColor, rgbB: RGBColor) {
    return (
        rgbA.red === rgbB.red &&
        rgbA.green === rgbB.green &&
        rgbA.blue === rgbB.blue
    );
}

export function useColorConvertorRGB(color: Ref<RGBColor>) {
    const _hsv = ref<HSVColor>({ hue: 0, saturation: 0, value: 0 });

    const colorHSV = computed<HSVColor>({
        set(hsv) {
            _hsv.value = { ...hsv };
            color.value = hsvToRgb({ ...hsv });
        },
        get() {
            if (isRGBEqual(color.value, hsvToRgb(_hsv.value)))
                return _hsv.value;

            return rgbToHsv({ ...color.value });
        },
    });

    return {
        colorHSV,
    };
}

import type { apiClient } from "@smakecloud/designer-core";
import { FontStyle, type FontData } from "@smakecloud/smake-use";
import { clamp } from "@vueuse/core";

export function clampFontSize(
    size: number,
    fontData: FontData,
    options?: { onMinExceeded?: () => void; onMaxExceeded?: () => void },
): number {
    const clampedSize = clamp(size, fontData.min, fontData.max);

    if (size === clampedSize) {
        return size;
    }

    if (clampedSize === fontData.min) {
        if (options?.onMinExceeded) {
            options.onMinExceeded();
        }

        return clampedSize;
    }

    if (options?.onMaxExceeded) {
        options?.onMaxExceeded();
    }

    return clampedSize;
}

type Font = (typeof apiClient.configurations.app.get.outputType)["fonts"][0];

export function isItalicFont(font: Font) {
    return (
        font.style === (FontStyle.Italic as string) ||
        font.style === (FontStyle.BoldItalic as string) ||
        font.style === (FontStyle.BlackItalic as string) ||
        font.style === "Demi Bold Italic"
    );
}

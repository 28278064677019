export enum ModuleName {
    VIEWS = "views",
    PDF_DOWNLOAD = "pdfDownload",
    ARCHIVE = "archive",
    LOGOS = "logos",
    PRODUCT_ZOOM = "productZoom",
    PRODUCT_COLORS = "productColors",
    SHOW_AREAS = "showAreas",
    SAVE_VARIANT = "saveVariant",
    TEXTS = "texts",
    CUSTOMIZATIONS = "customizations",
    ADD_TO_CART = "addToCart",
    STORE_VARIANT = "storeVariant",
    STAGE = "stage",
    MENU = "menu",
}

<!-- eslint-disable vue/no-deprecated-v-on-native-modifier -->
<template>
    <div>
        <module-button
            :icon="moduleButtonIcon"
            :style-config="moduleButtonStyleConfig"
            :loading="isARenderingRequestInProgress"
            :disabled="
                currentPanelState !== PanelState.None ||
                isARenderingRequestInProgress
            "
            add-icon
            @click.native="addNewText"
        >
            {{ textButtonMessage }}
        </module-button>
    </div>
</template>

<script setup lang="ts">
import { useDesignerScreenSizeName } from "@/composables/useDesignerScreenSizeName";
import useNotification from "@/composables/useNotification";
import useRenderCustomizationPreview from "@/composables/useRenderCustomizationPreview";
import { NotificationType } from "@/enums/NotificationType";
import { PanelState } from "@/enums/PanelState";
import { ScreenName } from "@/enums/ScreenName";
import { Mode, isAddTextMode } from "@/enums/mode";
import { useCurrentStore } from "@/store/useCurrentStore";
import { useMainStore } from "@/store/useMainStore";
import { usePanelStore } from "@/store/usePanelStore";
import cloneDeep from "lodash/cloneDeep";
import get from "lodash/get";
import merge from "lodash/merge";
import set from "lodash/set";
import { storeToRefs } from "pinia";
import { computed, ref, watch } from "vue";
import { useI18n } from "vue-i18n";

const { settings } = useMainStore();
const { currentMode, hasCurrentViewFreePositioningAreas } =
    storeToRefs(useCurrentStore());
const { currentPanelState } = storeToRefs(usePanelStore());
const { t } = useI18n();
const { pushNotification, resolveNotificationWithMessage } = useNotification();
const { isARenderingRequestInProgress } = useRenderCustomizationPreview();
// data
const isLoading = ref(false);
const { screenName } = useDesignerScreenSizeName();

const moduleButtonIcon = computed<"text" | "plus">(() => {
    if (screenName.value === ScreenName.MOBILE) {
        return "plus";
    }

    return "text";
});

// props
const props = defineProps({
    styleConfig: {
        type: Object,
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        default: () => {},
    },
});

// computed
const moduleButtonStyleConfig = computed(() => {
    const baseStyleConfig = cloneDeep(props.styleConfig);

    ["styles", "icon.styles", "label.styles", "addIcon.styles"].forEach(
        (key) => {
            set(
                baseStyleConfig,
                key,
                merge(
                    get(baseStyleConfig, key, []),
                    get(settings.style, `textsButton.${key}`, []),
                ),
            );
        },
    );

    return baseStyleConfig;
});

watch(
    () => currentMode.value,
    () => {
        if (currentMode.value === Mode.Default) {
            isLoading.value = false;
        }
    },
);

// methods
function addNewText() {
    if (!hasCurrentViewFreePositioningAreas.value) {
        pushNotification(
            t("all_positioning_areas_are_occupied"),
            NotificationType.Info,
        );
        resolveNotificationWithMessage(t("choose_a_positioning_area"));

        return;
    }

    if (isAddTextMode(currentMode.value)) {
        currentMode.value = Mode.Default;
        resolveNotificationWithMessage(t("choose_a_positioning_area"));

        return;
    }

    currentMode.value = Mode.AddNewText;
    pushNotification(t("choose_a_positioning_area"), NotificationType.Info);
    resolveNotificationWithMessage(t("all_positioning_areas_are_occupied"));
}

const textButtonMessage = computed(() => {
    return isAddTextMode(currentMode.value) ? t("abort") : t("add_text");
});
</script>

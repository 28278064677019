<template>
    <div>
        <div ref="animatedElement">
            <div
                class="relative flex flex-col gap-4 overflow-hidden rounded-2xl border border-secondary-2 p-3 pb-5"
            >
                <div class="relative flex justify-between">
                    <div class="mb-3 w-full text-sm font-bold">
                        {{ t("texts").toLocaleUpperCase() }}
                    </div>
                    <button
                        type="button"
                        data-testid="delete-text-button"
                        class="rounded-full bg-secondary-3 hover:bg-secondary-2"
                        @click="emit('delete')"
                    >
                        <f-a-icon
                            class="flex h-10 w-10 items-center justify-center"
                            icon="trash-alt"
                        />
                    </button>
                </div>
                <transition-group
                    tag="div"
                    class="relative flex flex-col gap-3"
                    enter-active-class="animate-grow-in"
                    move-class="transition-all ease-in"
                    leave-to-class="translate-x-[100px] scale-90"
                    leave-active-class="transition-all duration-200 ease-linear absolute"
                >
                    <text-resource-line-input
                        v-for="(line, lineIndex) in resource.lines"
                        :key="line.uuid"
                        :ref="
                            (textLineInput) =>
                                (textLineInputs[lineIndex] =
                                    textLineInput as InstanceType<
                                        typeof TextResourceLineInput
                                    >)
                        "
                        v-model="resource.lines[lineIndex]"
                        :data-testid="`text-line-${lineIndex}`"
                        :mark-focused="
                            collapsed === false &&
                            lineIndex === chosenTextLineIndex
                        "
                        @value-changed="emit('update:modelValue', resource)"
                        @keydown-tab="focusRotateInput(lineIndex + 1)"
                        @keydown-shift-tab="focusRotateInput(lineIndex - 1)"
                        @keydown-enter="addNewTextLine(lineIndex)"
                        @remove="removeTextLine(lineIndex)"
                        @focus="chooseTextLine(lineIndex)"
                    />
                </transition-group>

                <template v-if="!collapsed">
                    <div class="flex w-full justify-center text-xs">
                        <button
                            type="button"
                            class="group flex w-full items-center justify-center gap-2"
                            data-testid="addNewTextLine"
                            :disabled="!canAddNewTextLine"
                            @click="
                                addNewTextLine(
                                    resource
                                        ? resource.lines.length - 1
                                        : undefined,
                                )
                            "
                        >
                            <div
                                class="flex h-8 w-8 items-center justify-center rounded-full"
                                :class="
                                    resource.lines.length >= 9
                                        ? 'bg-secondary-3 text-secondary-1'
                                        : 'bg-black text-white group-hover:bg-secondary-1'
                                "
                            >
                                <f-a-icon icon="plus" class="text-xl" />
                            </div>
                            <div>{{ t("add_text_line") }}</div>
                        </button>
                    </div>

                    <text-resource-line-detail-input
                        v-if="chosenTextLine && customizationProductionMethodId"
                        :line="chosenTextLine as unknown as ApiTextLine"
                        :production-method-id="customizationProductionMethodId"
                        @apply-to-all-lines="applyToAllLines"
                    />
                </template>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import TextResourceLineInput from "@/components/modules/texts/TextResourceLineInput.vue";
import { useTextresourceLineFactory } from "@/composables/useTextResourceLineFactory";
import { useCurrentStore } from "@/store/useCurrentStore";
import { type ApiTextDesign } from "@smakecloud/designer-core";
import {
    useAnimatedHeight,
    type TextResourceData,
    type TextResourceLineData,
} from "@smakecloud/smake-use";
import cloneDeep from "lodash/cloneDeep";
import { storeToRefs } from "pinia";
import { v4 as uuid4 } from "uuid";
import { computed, nextTick, ref, watch } from "vue";
import { useI18n } from "vue-i18n";

type ApiTextLine = ApiTextDesign["resource"]["lines"][number];

const currentStore = useCurrentStore();
const { currentCustomization } = storeToRefs(currentStore);

const customizationProductionMethodId = computed(
    () => currentCustomization.value?.positioning_area.production_method_id,
);

const customizationPositioningAreaId = computed(() => {
    if (!currentCustomization.value) {
        throw new Error("Customization is missing");
    }

    return currentCustomization.value.positioning_area.id;
});

const { getNewEmptyResourceLine } = useTextresourceLineFactory(
    customizationPositioningAreaId,
);
const { t } = useI18n();

const emit = defineEmits<{
    "update:modelValue": [TextResourceData];
    "text-line-chosen": [];
    delete: [];
}>();

const props = defineProps<{
    modelValue: TextResourceData;
}>();

watch(
    () => props.modelValue,
    () => {
        resource.value = props.modelValue;
    },
);

const resource = ref(props.modelValue);

const animatedElement = ref<HTMLElement>();
const collapsed = ref(false);
const { animateHeight } = useAnimatedHeight(animatedElement);

async function setCollapsed(value: boolean) {
    if (collapsed.value === value) return;
    await animateHeight(() => {
        collapsed.value = !collapsed.value;
    });
}

const textLineInputs = ref<InstanceType<typeof TextResourceLineInput>[]>([]);
const chosenTextLineIndex = ref<number>(0);
const chosenTextLine = computed<TextResourceLineData | undefined>(
    () => resource.value?.lines[chosenTextLineIndex.value],
);

const canAddNewTextLine = computed<boolean>(() => {
    if (!resource.value) {
        return false;
    }

    return resource.value.lines.length <= 8;
});

async function removeTextLine(index: number) {
    if (chosenTextLineIndex.value === index) {
        await focusLineInput(index - 1);
    }

    resource.value.lines = resource.value.lines.filter(
        (_textLine, textLineindex) => {
            return textLineindex !== index;
        },
    );

    if (resource.value.lines.length === 0) {
        emit("delete");

        return;
    }

    emit("update:modelValue", resource.value);
}

function calcNextLineRotateIndex(index: number): number {
    if (resource.value?.lines === undefined) {
        return 0;
    }

    if (index < 0) {
        return resource.value?.lines.length - 1;
    }

    if (index > resource.value?.lines.length - 1) {
        return 0;
    }

    return index;
}

async function focusRotateInput(index: number) {
    return focusLineInput(calcNextLineRotateIndex(index));
}

async function focusLineInput(index: number) {
    if (!resource.value) {
        return;
    }

    await nextTick(); // ensure the line is already rendered

    chosenTextLineIndex.value = index;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const focusLine = textLineInputs.value[index];

    if (focusLine) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
        focusLine.focus();

        return;
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
    textLineInputs.value[0]?.focus();
}
function createNewTextLine(basedOnLine: TextResourceLineData | null = null) {
    if (basedOnLine === null) {
        if (currentCustomization.value === null) {
            throw Error("Current Customization is missing");
        }

        return getNewEmptyResourceLine();
    }

    return {
        ...cloneDeep(basedOnLine),
        uuid: uuid4(),
        value: "",
    };
}

async function addNewTextLine(index?: number) {
    if (!currentCustomization.value) {
        throw Error("Current  Customization is missing");
    }

    if (!resource.value) {
        throw Error("Resource is missing");
    }

    if (resource.value.lines.length >= 9) {
        return;
    }

    const newLineIndex = index !== undefined ? index + 1 : 0;

    const newLine = createNewTextLine(resource.value.lines[index ?? 0]);
    resource.value.lines.splice(newLineIndex, 0, newLine);

    await focusLineInput(newLineIndex);
}

function chooseTextLine(index: number) {
    chosenTextLineIndex.value = index;

    emit("text-line-chosen");
}

function applyToAllLines() {
    if (chosenTextLine.value === undefined) {
        return;
    }

    resource.value.lines.forEach((resourceLine: TextResourceLineData) => {
        const lineToApply = chosenTextLine.value!;
        if (resourceLine === lineToApply) {
            return;
        }

        resourceLine.font = cloneDeep(lineToApply.font);
        resourceLine.color_assignments = cloneDeep(
            lineToApply.color_assignments,
        );
        resourceLine.font_size = lineToApply.font_size;
        resourceLine.padding_bottom = lineToApply.padding_bottom;
        resourceLine.bending_radius = lineToApply.bending_radius;
        resourceLine.horizontal_alignment = lineToApply.horizontal_alignment;
    });

    emit("update:modelValue", resource.value);
}

defineExpose({
    setCollapsed,
});
</script>

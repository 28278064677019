export enum Mode {
    Default,
    AddNewText,
    AddArchiveText,
    AddNewCustomization,
}

export function isAddTextMode(mode: Mode): boolean {
    return mode === Mode.AddNewText || mode === Mode.AddArchiveText;
}

export function isAddMode(mode: Mode): boolean {
    return (
        mode === Mode.AddNewText ||
        mode === Mode.AddArchiveText ||
        mode === Mode.AddNewCustomization
    );
}

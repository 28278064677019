import useRenderCustomizationPreview from "@/composables/useRenderCustomizationPreview";
import Customization from "@/repo/Customization";
import Variant from "@/repo/Variant";
import initVariants from "@/repo/initializers/initVariants";
import initPositioningAreaCanvasObjects from "@/store/initializers/initPositioningAreaCanvasObjects";
import { useCurrentStore } from "@/store/useCurrentStore";
import { type VariantIdentifier } from "@/types/DesignerProps";
import { type VariantData } from "@smakecloud/smake-use";
import { storeToRefs } from "pinia";
import { useRepo } from "pinia-orm";
import { withCtx, type ComponentInternalInstance } from "vue";

export default () => {
    const currentStore = useCurrentStore();
    const { currentVariant } = storeToRefs(currentStore);
    const { renderVariantData } = useRenderCustomizationPreview();

    async function changeVariantByIdentifier(
        variantIdentifier: VariantIdentifier,
        instance: ComponentInternalInstance | null,
    ) {
        const variant = await (withCtx(
            () => initVariants(variantIdentifier),
            instance,
        )() as Promise<VariantData>);

        useRepo(Customization).flush();
        if (variant.id === currentVariant.value.id) {
            await renderVariantData(variant);

            return;
        }

        const variantRepo = useRepo(Variant).find(variant.id);

        if (!variantRepo) {
            throw Error(`Variant with id ${variant.id} not found`);
        }

        currentStore.changeCurrentVariant(variantRepo);
        withCtx(initPositioningAreaCanvasObjects, instance)();
        await renderVariantData(variant);
    }

    async function changeVariantById(
        variantId: number,
        instance: ComponentInternalInstance | null,
    ) {
        await changeVariantByIdentifier({ id: variantId }, instance);
    }

    return {
        changeVariantByIdentifier,
        changeVariantById,
    };
};

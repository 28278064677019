<template>
    <base-modal ref="modal" @abort="abort">
        <div
            class="z-400 relative grid min-h-[650px] rounded-md border border-white bg-white p-5 tablet:min-w-[390px]"
        >
            <div class="relative mt-3 items-center justify-between bg-white">
                <div>
                    <div>
                        <p class="text-xl">
                            {{ t("views_preview_headlide") }}
                        </p>
                    </div>
                    <div>
                        <p class="text-xl font-bold">
                            {{ currentView.display_name }}
                        </p>
                    </div>
                </div>
                <div class="mt-10 w-full">
                    <div class="relative flex">
                        <img
                            :src="imgSrc"
                            class="max-h-96 w-full object-contain object-center"
                        />
                        <div
                            class="absolute left-0 top-0 flex h-full w-20 items-center justify-center"
                        >
                            <button
                                type="button"
                                class="absolute h-16 w-16 rounded-full bg-secondary-3 p-4"
                                @click="changeToPreviousHandle"
                            >
                                <f-a-icon
                                    icon="chevron-left"
                                    :size="FontAwesomeSize.lg"
                                />
                            </button>
                        </div>
                        <div
                            class="absolute right-0 top-0 flex h-full w-20 items-center justify-center"
                        >
                            <button
                                type="button"
                                class="absolute h-16 w-16 rounded-full bg-secondary-3 p-4"
                                @click="changeToNextHandle"
                            >
                                <f-a-icon
                                    icon="chevron-right"
                                    :size="FontAwesomeSize.lg"
                                />
                            </button>
                        </div>
                    </div>
                    <hr
                        v-if="currentView.customizations.length > 0"
                        class="border-t-1 my-6 border border-secondary-2"
                    />
                    <div class="flex flex-col gap-5">
                        <div
                            v-for="customization in currentView.customizations"
                            :key="customization.id"
                            class="flex gap-4"
                        >
                            <div
                                class="flex h-20 w-20 items-center justify-center rounded-lg"
                                :style="`background-color: ${customization.background_color}`"
                            >
                                <img
                                    v-if="customization.resource.preview_url"
                                    :src="customization.resource.preview_url"
                                    class="h-4/5 w-4/5 object-contain"
                                    :style="{
                                        rotate: `${customization.rotation}deg`,
                                    }"
                                />
                            </div>
                            <div class="flex flex-col">
                                <div class="text-[14px] font-bold">
                                    {{
                                        customization.resource.production_method
                                            .display_name
                                    }}
                                </div>
                                <template
                                    v-for="designElement in getAllDesignElements(
                                        customization,
                                    )"
                                    :key="designElement.resource.id"
                                >
                                    <div
                                        v-if="
                                            isDesignElementLogo(designElement)
                                        "
                                        class="text-[12px] font-bold"
                                    >
                                        {{
                                            t(
                                                designElement.resource
                                                    .is_customer_logo
                                                    ? "logos"
                                                    : "icon",
                                            )
                                        }}:
                                        {{ designElement.resource.name }}
                                    </div>
                                    <div
                                        v-if="
                                            isDesignElementText(designElement)
                                        "
                                        class="text-[12px] font-bold"
                                    >
                                        {{ t("texts") }}:
                                        {{
                                            designElement.resource.lines
                                                .map((line) => line.value)
                                                .join(" ")
                                        }}
                                    </div>
                                </template>
                                <div class="text-[12px] text-secondary-2">
                                    {{ calculateSize(customization) }}
                                    {{ unit.length }}²
                                    <span
                                        v-if="
                                            customization.positioningArea
                                                ?.positioning_area_identifier
                                        "
                                    >
                                        |
                                        {{
                                            customization.positioningArea
                                                .positioning_area_identifier
                                                .name
                                        }}
                                        ({{
                                            customization.positioningArea
                                                .positioning_area_identifier
                                                .handle
                                        }})
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </base-modal>
</template>

<script setup lang="ts">
import BaseModal from "@/components/shared/modal/BaseModal.vue";
import { FontAwesomeSize } from "@/enums/fontAwesome/fontAwesomeSize";
import { useSettingsStore } from "@/store/useSettingsStore";
import {
    getAllDesignElements,
    isDesignElementLogo,
    isDesignElementText,
    useNumberFormat,
    type ArchiveVariantData,
    type ArchiveViewData,
    type CustomizationData,
    type VariantData,
    type ViewData,
} from "@smakecloud/smake-use";
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";

const { unit } = useSettingsStore();
const { t, locale } = useI18n();
const numberFormat = useNumberFormat();

//data
const modal = ref<typeof BaseModal>();
const currentHandle = ref<string>("");

//props
const props = defineProps<{
    archiveVariant: VariantData | ArchiveVariantData;
    handle: string;
}>();

//emit
const emits = defineEmits(["resolve-detail-view-preview"]);

//mounted
onMounted(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    modal.value?.open();
    currentHandle.value = props.handle;
});

// computed
const currentView = computed<ArchiveViewData | ViewData>(() => {
    return props.archiveVariant.views.filter(
        (view) => view.handle === currentHandle.value,
    )[0];
});

const imgSrc = computed<string>(() => {
    if (
        "lg" in currentView.value.mockup_urls &&
        currentView.value.mockup_urls.lg
    ) {
        return currentView.value.mockup_urls.lg;
    }
    if (
        "md" in currentView.value.mockup_urls &&
        currentView.value.mockup_urls.md
    ) {
        return currentView.value.mockup_urls.md;
    }

    return currentView.value.image_urls.md;
});

//methods
function calculateSize(customization: CustomizationData): string {
    return numberFormat.decimalFormat(
        customization.width * customization.height,
        locale.value,
    );
}

function abort() {
    emits("resolve-detail-view-preview");
}

function changeToNextHandle() {
    const currentViewIndex: number = props.archiveVariant.views.findIndex(
        (view) => {
            return view.handle === currentHandle.value;
        },
    );

    if (currentViewIndex === props.archiveVariant.views.length - 1) {
        currentHandle.value = props.archiveVariant.views[0].handle;

        return;
    }

    currentHandle.value =
        props.archiveVariant.views[currentViewIndex + 1].handle;
}

function changeToPreviousHandle() {
    const currentViewIndex: number = props.archiveVariant.views.findIndex(
        (view) => {
            return view.handle === currentHandle.value;
        },
    );

    if (currentViewIndex === 0) {
        currentHandle.value =
            props.archiveVariant.views[
                props.archiveVariant.views.length - 1
            ].handle;

        return;
    }

    currentHandle.value =
        props.archiveVariant.views[currentViewIndex - 1].handle;
}
</script>

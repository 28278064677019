import {
    type VariantIdentifier,
    type VariantExternalIdentifier,
    type VariantId,
} from "@/types/DesignerProps";

export const useVariantChecker = () => {
    function isVariantExternIdentifier(
        variantIdentifier: VariantIdentifier,
    ): variantIdentifier is VariantExternalIdentifier {
        return (
            (variantIdentifier as VariantExternalIdentifier)
                .external_identifier !== undefined
        );
    }

    function isVariantId(
        variantIdentifier: VariantIdentifier,
    ): variantIdentifier is VariantId {
        return (variantIdentifier as VariantId).id !== undefined;
    }

    return {
        isVariantExternIdentifier,
        isVariantId,
    };
};

<template>
    <div class="pointer-events-none absolute inset-0 z-50">
        <transition
            appear
            enter-active-class="ease-out duration-300"
            enter-from-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="ease-in duration-200"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
        >
            <div
                v-show="isShown"
                class="fixed inset-0 transition-opacity"
                aria-hidden="true"
                @click="closePanel"
            >
                <div :class="panelStyleConfig.backdrop.styles" />
            </div>
        </transition>

        <transition
            appear
            enter-active-class="ease-out duration-300"
            enter-from-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="ease-in duration-200"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
        >
            <div
                v-show="isShown"
                ref="panelContentRef"
                :class="panelStyleConfig.styles"
                class="pointer-events-auto"
            >
                <div class="flex h-full flex-col">
                    <div class="absolute right-3 top-3">
                        <button
                            v-if="canBeClosed"
                            :class="panelStyleConfig.closeButton.styles"
                            type="button"
                            @click="closePanel"
                        >
                            <f-a-icon icon="times" />
                        </button>
                    </div>
                    <div
                        class="-mr-5 min-h-12 flex-1 overflow-y-auto"
                        :style="{ scrollbarGutter: 'stable' }"
                    >
                        <slot />
                    </div>

                    <div v-if="$slots.footer" class="flex-0 pt-10">
                        <slot name="footer" />
                    </div>
                </div>

                <slot name="subPanels" />
            </div>
        </transition>
    </div>
</template>

<script setup lang="ts">
import { type PanelShowState } from "@/enums/PanelState";
import { useBoundingStore } from "@/store/useBoundingStore";
import { useMainStore } from "@/store/useMainStore";
import { usePanelStore } from "@/store/usePanelStore";
import { useElementSize } from "@vueuse/core";
import cloneDeep from "lodash/cloneDeep";
import get from "lodash/get";
import set from "lodash/set";
import { storeToRefs } from "pinia";
import { computed, onUnmounted, ref, watchEffect } from "vue";

const { panelContent } = storeToRefs(useBoundingStore());
const mainStore = useMainStore();
const { settings } = mainStore;
const { hideCurrentPanel } = usePanelStore();

// setup
const panelContentRef = ref<HTMLElement | null>(null);

// data

// props
const props = withDefaults(
    defineProps<{
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        styleConfig: any; // todo make typing more specific
        showOnPanelState: PanelShowState;
        canBeClosed?: boolean;
    }>(),
    {
        styleConfig: () => ({}),
        canBeClosed: true,
    },
);

// computed
const isShown = computed<boolean>(() => {
    return true;
    // return currentPanelState.value === props.showOnPanelState;
});

const panelStyleConfig = computed(() => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const baseStyleConfig = cloneDeep(props.styleConfig);

    ["styles", "backdrop.styles", "closeButton.styles"].forEach((key) => {
        set(
            baseStyleConfig,
            key,
            get(baseStyleConfig, key, get(settings.style, `panel.${key}`, [])),
        );
    });

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return baseStyleConfig;
});

const { width: panelContentWidth, height: panelContentHeight } =
    useElementSize(panelContentRef);

watchEffect(() => {
    if (!panelContentRef.value) return;
    const panelContentBounding = panelContentRef.value.getBoundingClientRect();

    panelContent.value = {
        top: panelContentBounding.top,
        right: panelContentBounding.right,
        bottom: panelContentBounding.bottom,
        left: panelContentBounding.left,
        width: panelContentWidth.value,
        height: panelContentHeight.value,
        x: panelContentBounding.x,
        y: panelContentBounding.y,
    };
});

onUnmounted(() => {
    panelContent.value = {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        width: 0,
        height: 0,
        x: 0,
        y: 0,
    };
});

// methods
function closePanel() {
    if (!props.canBeClosed) {
        return;
    }

    void hideCurrentPanel();
}
</script>

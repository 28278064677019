export enum PusherEvent {
    Error = "error",
    TextPreviewFileCompleted = "text.previewfile.completed",
    TextPreviewFileFailed = "text.previewfile.failed",
    CustomVariantCustomizationsCompleted = "customVariant.customizations.completed",
    CustomVariantCustomizationsCompletedAfterAssignedToArchive = "customVariant.customizationsCompletedAfterAssignedToArchive",
    CustomVariantAddedToFavorites = "customVariant.addedToFavorites",
    FavoriteDeleted = "favorite.deleted",
    CustomVariantCustomizationsCompletedForPDFDownload = "customVariant.customizationsCompletedForPDFDownload",
    CustomVariantDeleteFromArchiveCompleted = "customVariant.deleteFromArchive.completed",
    FavoredDesignUpdated = "favoredDesign.updated",
    FavoredDesignCreated = "favoredDesign.created",
    CustomerLogoUpdated = "customerLogo.updated",
    FavoredDesignDeleted = "favoredDesign.deleted",
}

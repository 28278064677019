<script setup lang="ts">
import { useFavoriteColorsStore } from "@/composables/useFavoriteColors";
import { FontAwesomeKitIcons } from "@/enums/fontAwesome/fontAwesomeKitIcons";
import { isLightColor } from "@/lib/color";
import { useColorPickerStore } from "@/store/useColorPickerStore";
import { useDesignerRootStore } from "@/store/useDesignerRootStore";
import { rgbToHsv, type RGBColor } from "@/utils/colorConversion";
import { storeToRefs } from "pinia";
import {
    PopoverArrow,
    PopoverClose,
    PopoverContent,
    PopoverPortal,
    PopoverRoot,
    PopoverTrigger,
} from "radix-vue";
import {
    computed,
    nextTick,
    ref,
    toRefs,
    useTemplateRef,
    watchEffect,
} from "vue";

const { add } = useFavoriteColorsStore();

const props = defineProps<{ markSelected?: boolean; startColor: RGBColor }>();

const { startColor } = toRefs(props);
const color = ref<{
    red: number;
    green: number;
    blue: number;
}>(startColor.value);

const isColorLight = computed(() =>
    isLightColor([color.value.red, color.value.green, color.value.blue]),
);

const { designerRoot } = storeToRefs(useDesignerRootStore());
const { open: anyColorPickerOpen } = storeToRefs(useColorPickerStore());
const open = ref(false);
const selectColorButton = useTemplateRef("selectColorButton");

watchEffect((onCleanup) => {
    anyColorPickerOpen.value = open.value;

    onCleanup(() => {
        anyColorPickerOpen.value = false;
    });
});

watchEffect(() => {
    if (!selectColorButton.value) return;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
    requestAnimationFrame(() => selectColorButton.value?.$el.focus());
});

const emit = defineEmits<{
    selectedColor: [RGBColor];
    isOpen: [boolean];
}>();

async function selectColor() {
    add(rgbToHsv({ ...color.value }));

    await nextTick();

    emit("selectedColor", {
        red: Math.round(color.value.red),
        green: Math.round(color.value.green),
        blue: Math.round(color.value.blue),
    } satisfies RGBColor);

    open.value = false;
}

watchEffect(() => {
    emit("isOpen", open.value);
});
</script>

<template>
    <popover-root v-model:open="open">
        <div class="flex flex-col items-center justify-center gap-2 text-sm">
            <popover-trigger class="group">
                <div
                    class="rounded-full border p-1"
                    :class="{
                        'border-black': markSelected,
                        'border-transparent group-data-[state=open]:border-black':
                            !markSelected,
                    }"
                >
                    <div
                        class="relative aspect-square h-12 rounded-full bg-[conic-gradient(red,yellow,lime,aqua,blue,magenta,red)]"
                    >
                        <div
                            class="absolute inset-0 flex items-center justify-center"
                        >
                            <div
                                class="aspect-square h-6 rounded-full bg-white"
                            />
                        </div>
                    </div>
                </div>
            </popover-trigger>
            <div>{{ $t("custom") }}</div>
        </div>
        <popover-portal :to="designerRoot">
            <popover-content
                class="z-50 rounded-2xl border border-secondary-1 bg-white p-5"
                side="bottom"
                align="start"
                @interact-outside="$event.preventDefault()"
            >
                <div class="flex flex-grow flex-col gap-2.5 bg-white">
                    <div class="flex flex-grow justify-between gap-6">
                        <div
                            class="aspect-square h-10 rounded-full border"
                            :class="{
                                'border-secondary-2': isColorLight,
                                'border-transparent': !isColorLight,
                            }"
                            :style="{
                                backgroundColor: `rgb(${color.red},${color.green},${color.blue})`,
                            }"
                        />
                        <div class="flex items-center gap-2">
                            <div class="grow"></div>
                            <button-primary
                                ref="selectColorButton"
                                size="small"
                                class="flex items-center gap-2 rounded-full px-3 py-2 text-xs"
                                @click="selectColor"
                            >
                                {{ $t("select_color") }}
                                <f-a-icon icon="check" class="text-xs" />
                            </button-primary>
                            <popover-close as="template">
                                <button-secondary-gray
                                    size="small"
                                    class="h-9 w-9 cursor-pointer rounded-full bg-secondary-3 p-1"
                                >
                                    <f-a-icon
                                        :icon="FontAwesomeKitIcons.X"
                                        class="text-xs"
                                    />
                                </button-secondary-gray>
                            </popover-close>
                        </div>
                    </div>
                    <color-picker v-model="color" />
                    <popover-arrow
                        class="h-5 w-5 -translate-y-2.5 rotate-45 border-b border-r border-secondary-1 bg-white"
                        as="div"
                    ></popover-arrow>
                </div>
            </popover-content>
        </popover-portal>
    </popover-root>
</template>

import useNotification from "@/composables/useNotification";
import { NotificationType } from "@/enums/NotificationType";
import { Style } from "@/enums/Style";
import { isAddMode, type Mode } from "@/enums/mode";
import PositioningArea from "@/repo/PositioningArea";
import { useCurrentStore } from "@/store/useCurrentStore";
import { useMainStore } from "@/store/useMainStore";
import type { DesignCanvasObject } from "@/types/DesignCanvasObject";
import { type PositioningAreaCanvasObject } from "@/types/PositioningAreaCanvasObject";
import { type fabric } from "fabric";
import { type FastAverageColorResult } from "fast-average-color";
import { get } from "lodash";
import { storeToRefs } from "pinia";
import { useRepo } from "pinia-orm";
import { ref } from "vue";
import { useI18n } from "vue-i18n";

export default () => {
    const { t } = useI18n();

    function areaPoints(canvasObject: fabric.Rect) {
        const points = Object.values(canvasObject.aCoords ?? {});
        const firstPoint = points[0];

        points[0] = points[1];
        points[1] = firstPoint;

        points.push(points[0]);

        return points;
    }

    function pointInAreaTest(
        point: { x: number; y: number },
        canvasObject: fabric.Rect,
    ) {
        const points = areaPoints(canvasObject);

        let temp = -1;

        for (let i = 0; i < points.length - 1; i++) {
            temp = temp * crossProdTest(point, points[i], points[i + 1]);

            if (temp === 0) {
                i = points.length;
            }
        }

        if (temp === -1) {
            return false;
        }

        return true;
    }

    function crossProdTest(
        pointA: { x: number; y: number },
        pointB: { x: number; y: number },
        pointC: { x: number; y: number },
    ) {
        if (pointA.y === pointB.y && pointB.y === pointC.y) {
            if (
                (pointB.x <= pointA.x && pointA.x <= pointC.x) ||
                (pointC.x <= pointA.x && pointA.x <= pointB.x)
            ) {
                return 0;
            }

            return 1;
        }

        if (pointA.y === pointB.y && pointA.x === pointB.x) {
            return 0;
        }

        if (pointB.y > pointC.y) {
            const temp = pointB;
            pointB = pointC;
            pointC = temp;
        }

        if (pointA.y <= pointB.y || pointA.y > pointC.y) {
            return 1;
        }

        const delta =
            (pointB.x - pointA.x) * (pointC.y - pointA.y) -
            (pointB.y - pointA.y) * (pointC.x - pointA.x);

        if (delta > 0) {
            return -1;
        }

        if (delta < 0) {
            return 1;
        }

        return 0;
    }

    function baseFillColor(
        positioningAreaCanvasObject: PositioningAreaCanvasObject | "default",
    ) {
        const { settings } = useMainStore();
        const style =
            positioningAreaCanvasObject === "default"
                ? "light"
                : positioningAreaCanvasObject.style;
        const colorData = settings.areaStyles[style].fill.base;

        return `rgba(${colorData.r}, ${colorData.g}, ${colorData.b}, ${colorData.a})`;
    }

    function highlightFillColor(
        positioningAreaCanvasObject: PositioningAreaCanvasObject | "default",
    ) {
        const { settings } = useMainStore();
        const style =
            positioningAreaCanvasObject === "default"
                ? "light"
                : positioningAreaCanvasObject.style;
        const colorData = settings.areaStyles[style].fill.highlight;

        return `rgba(${colorData.r}, ${colorData.g}, ${colorData.b}, ${colorData.a})`;
    }

    function transparentFillColor(
        positioningAreaCanvasObject: PositioningAreaCanvasObject | "default",
    ) {
        const { settings } = useMainStore();
        const style =
            positioningAreaCanvasObject === "default"
                ? "light"
                : positioningAreaCanvasObject.style;
        const colorData = settings.areaStyles[style].fill.base;

        return `rgba(${colorData.r}, ${colorData.g}, ${colorData.b}, 0)`;
    }

    function baseStrokeColor(
        positioningAreaCanvasObject: PositioningAreaCanvasObject | "default",
    ) {
        const { settings } = useMainStore();
        const style =
            positioningAreaCanvasObject === "default"
                ? "light"
                : positioningAreaCanvasObject.style;
        const colorData = ref(settings.areaStyles[style].stroke.base);

        if (positioningAreaCanvasObject === "default") {
            return `rgba(${colorData.value.r}, ${colorData.value.g}, ${colorData.value.b}, ${colorData.value.a})`;
        }

        if (positioningAreaCanvasObject.has_object_with_invalid_position) {
            colorData.value = settings.areaStyles[style].stroke.error;
        }

        return `rgba(${colorData.value.r}, ${colorData.value.g}, ${colorData.value.b}, ${colorData.value.a})`;
    }

    function highlightStrokeColor(
        positioningAreaCanvasObject: PositioningAreaCanvasObject | "default",
    ) {
        const { settings } = useMainStore();
        const style =
            positioningAreaCanvasObject === "default"
                ? "light"
                : positioningAreaCanvasObject.style;
        const colorData = settings.areaStyles[style].stroke.highlight;

        return `rgba(${colorData.r}, ${colorData.g}, ${colorData.b}, ${colorData.a})`;
    }

    function transparentStrokeColor(
        positioningAreaCanvasObject: PositioningAreaCanvasObject | "default",
    ) {
        const { settings } = useMainStore();
        const style =
            positioningAreaCanvasObject === "default"
                ? "light"
                : positioningAreaCanvasObject.style;
        const colorData = settings.areaStyles[style].stroke.base;

        return `rgba(${colorData.r}, ${colorData.g}, ${colorData.b}, 0)`;
    }

    function strokeWidth() {
        const { settings } = useMainStore();

        return get(settings.areaStyles, "light.strokeWidth", 1);
    }

    function strokeDashArray() {
        const { settings } = useMainStore();

        return get(settings.areaStyles, "light.strokeDashArray", []);
    }

    function validatePosition(
        positioningAreaCanvasObject: PositioningAreaCanvasObject,
        designCanvasObject: DesignCanvasObject,
    ) {
        const notifications = useNotification();

        if (!positioningAreaCanvasObject.has_customization) {
            positioningAreaCanvasObject.has_object_with_invalid_position =
                false;
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
            positioningAreaCanvasObject.canvas_object._objects[0].set(
                "stroke",
                baseStrokeColor(positioningAreaCanvasObject),
            );
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
            positioningAreaCanvasObject.canvas_object.canvas?.requestRenderAll();

            return;
        }

        // NOTE: Dies ist eine Übergangslösung und gilt nur wenn die Motive in den Gebieten einrasten

        if (
            dimensionsOfCurrentCustomizationAreBiggerThanArea(
                positioningAreaCanvasObject,
                designCanvasObject,
            )
        ) {
            positioningAreaCanvasObject.has_object_with_invalid_position = true;
            notifications.pushNotification(
                t("element_outside_placement_area"),
                NotificationType.Error,
            );

            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
            positioningAreaCanvasObject.canvas_object._objects[0].set(
                "stroke",
                baseStrokeColor(positioningAreaCanvasObject),
            );
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
            positioningAreaCanvasObject?.canvas_object?.canvas?.requestRenderAll();

            // Vue.$smakeDesigner.$emit('notification','Element außerhalb Platzierungsfläche.', 'danger');

            return;
        }

        positioningAreaCanvasObject.has_object_with_invalid_position = false;
        notifications.resolveNotificationWithMessage(
            t("element_outside_placement_area"),
        );
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
        positioningAreaCanvasObject.canvas_object._objects[0].set(
            "stroke",
            baseStrokeColor(positioningAreaCanvasObject),
        );
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
        positioningAreaCanvasObject.canvas_object.canvas?.requestRenderAll();

        // Vue.$smakeDesigner.$emit('closeNotification');

        return;

        // const coords = Vue.$smakeDesigner.currentCustomization.canvasObject.aCoords;

        // this.hasObjectWithInvalidPosition = false;

        // Object.values(coords).forEach(coord => {
        //     if (! this.pointInAreaTest(coord)) {
        //         this.hasObjectWithInvalidPosition = true;
        //     }
        // });

        // this.canvasObject._objects[0].set('stroke', this.baseStrokeColor());
        // this.canvasObject.canvas.requestRenderAll();
    }

    function dimensionsOfCurrentCustomizationAreBiggerThanArea(
        positioningAreaCanvasObject: PositioningAreaCanvasObject,
        designCanvasObject: DesignCanvasObject,
    ) {
        const positioningArea = useRepo(PositioningArea).find(
            positioningAreaCanvasObject.id,
        );

        if (!positioningArea) {
            throw new Error("PositioningArea is not defined");
        }

        let rotation =
            designCanvasObject.rotation_angle + positioningArea.direction;

        // Fix rotation beween 0 (inclusive) and 360 (exclusive) (E.g. all those will result to 90: 90, 450, -270)
        rotation = ((rotation % 360) + 360) % 360;

        if ([0, 180].includes(rotation)) {
            return (
                (positioningAreaCanvasObject.canvas_object.width !==
                    undefined &&
                    designCanvasObject.px_width >
                        positioningAreaCanvasObject.canvas_object.width) ||
                (positioningAreaCanvasObject.canvas_object.height !==
                    undefined &&
                    designCanvasObject.px_height >
                        positioningAreaCanvasObject.canvas_object.height)
            );
        }

        if ([90, 270].includes(rotation)) {
            return (
                (positioningAreaCanvasObject.canvas_object.height !==
                    undefined &&
                    designCanvasObject.px_width >
                        positioningAreaCanvasObject.canvas_object.height) ||
                (positioningAreaCanvasObject.canvas_object.width !==
                    undefined &&
                    designCanvasObject.px_height >
                        positioningAreaCanvasObject.canvas_object.width)
            );
        }

        throw new Error("Invalid rotation");
    }

    function showOrHideCanvasObject(
        positioningAreaCanvasObject: PositioningAreaCanvasObject,
    ) {
        const { showAreas } = storeToRefs(useMainStore());
        const { currentCustomization } = storeToRefs(useCurrentStore());

        if (
            positioningAreaCanvasObject.has_object_with_invalid_position ||
            currentCustomization.value?.positioningArea_id ===
                positioningAreaCanvasObject.id
        ) {
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
            positioningAreaCanvasObject.canvas_object.set("opacity", 1);
            showCanvasObject(positioningAreaCanvasObject);

            return;
        }

        if (positioningAreaCanvasObject.has_customization) {
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
            positioningAreaCanvasObject.canvas_object._objects[1].set(
                "opacity",
                0,
            );
        } else {
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
            positioningAreaCanvasObject.canvas_object._objects[1].set(
                "opacity",
                1,
            );
        }

        if (showAreas.value && !currentCustomization.value) {
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
            positioningAreaCanvasObject.canvas_object.set("opacity", 1);
            showCanvasObject(positioningAreaCanvasObject);

            return;
        }

        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
        positioningAreaCanvasObject.canvas_object.set("opacity", 0);
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
        positioningAreaCanvasObject?.canvas_object?.canvas?.requestRenderAll();
    }
    function showCanvasObject(
        positioningAreaCanvasObject: PositioningAreaCanvasObject,
    ) {
        changeStrokeAndFillColorForCurrentMode(positioningAreaCanvasObject);

        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
        positioningAreaCanvasObject.canvas_object.canvas?.requestRenderAll();
    }

    function changeStrokeAndFillColor(
        positioningAreaCanvasObject: PositioningAreaCanvasObject,
        fillColor: string,
        strokeColor: string,
    ) {
        positioningAreaCanvasObject.has_customization
            ? // TODO: Fix this the next time the file is edited.
              // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
              positioningAreaCanvasObject.canvas_object._objects[1].set(
                  "fill",
                  transparentStrokeColor(positioningAreaCanvasObject),
              )
            : // TODO: Fix this the next time the file is edited.
              // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
              positioningAreaCanvasObject.canvas_object._objects[1].set(
                  "fill",
                  strokeColor,
              );
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
        positioningAreaCanvasObject.canvas_object._objects[0].set(
            "fill",
            fillColor,
        );
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
        positioningAreaCanvasObject.canvas_object._objects[0].set(
            "stroke",
            strokeColor,
        );
    }

    function changeStrokeAndFillColorForCurrentMode(
        positioningAreaCanvasObject: PositioningAreaCanvasObject,
    ) {
        const { currentMode } = storeToRefs(useCurrentStore());

        changeStrokeAndFillColorByMode(
            positioningAreaCanvasObject,
            currentMode.value,
        );
    }

    function changeStrokeAndFillColorByMode(
        positioningAreaCanvasObject: PositioningAreaCanvasObject,
        mode: Mode,
    ) {
        if (
            isAddMode(mode) &&
            !positioningAreaCanvasObject.has_object_with_invalid_position &&
            positioningAreaCanvasObject.has_customization === false
        ) {
            changeStrokeAndFillColor(
                positioningAreaCanvasObject,
                highlightFillColor(positioningAreaCanvasObject),
                highlightStrokeColor(positioningAreaCanvasObject),
            );

            return;
        }

        changeStrokeAndFillColor(
            positioningAreaCanvasObject,
            baseFillColor(positioningAreaCanvasObject),
            baseStrokeColor(positioningAreaCanvasObject),
        );
    }

    function setStyleForColor(
        color: FastAverageColorResult,
        positioningAreaCanvasObject: PositioningAreaCanvasObject,
        viewBrightness: Style,
    ) {
        //
        /**
         * https://www.w3.org/TR/AERT/#color-contrast
         *
         * Normalerweise ist 128 der Schwellwert, dieser wurde hier etwas angepasst,
         * damit länger eine wei0e Fläche genutzt wird
         */
        const brightness =
            (color.value[0] * 299 +
                color.value[1] * 587 +
                color.value[2] * 114) /
            1000;

        if (brightness > 130) {
            positioningAreaCanvasObject.style = viewBrightness;
        }

        if (brightness < 130) {
            positioningAreaCanvasObject.style = viewBrightness;
        }

        if (brightness > 180) {
            positioningAreaCanvasObject.style = Style.dark;
        }

        if (brightness < 80) {
            positioningAreaCanvasObject.style = Style.light;
        }

        showOrHideCanvasObject(positioningAreaCanvasObject);
    }

    function resolveNotificationsForPositioningArea(positioningAreaId: number) {
        const positioningArea =
            useRepo(PositioningArea).find(positioningAreaId);

        if (positioningArea === null) {
            return;
        }

        const notification = useNotification();
        positioningArea.annotations.forEach((annotation) => {
            notification.resolveNotificationWithMessage(annotation.description);
        });
        notification.resolveNotificationWithMessage(
            t("element_outside_placement_area"),
        );
    }

    function pushNotifcationsForPositioningArea(positioningAreaId: number) {
        const positioningArea =
            useRepo(PositioningArea).find(positioningAreaId);

        if (positioningArea === null) {
            return;
        }

        const notification = useNotification();
        positioningArea.annotations.forEach((annotation) => {
            notification.pushNotification(
                annotation.description,
                NotificationType.Info,
            );
        });
    }

    return {
        areaPoints,
        pointInAreaTest,
        baseFillColor,
        highlightFillColor,
        transparentFillColor,
        baseStrokeColor,
        highlightStrokeColor,
        transparentStrokeColor,
        strokeWidth,
        strokeDashArray,
        validatePosition,
        showOrHideCanvasObject,
        showCanvasObject,
        setStyleForColor,
        resolveNotificationsForPositioningArea,
        pushNotifcationsForPositioningArea,
        changeStrokeAndFillColorByMode,
    };
};

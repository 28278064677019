import { messages } from "@/data";
import { routes } from "@/router";
import { DesignerCorePlugin } from "@smakecloud/designer-core";
import { VueQueryPlugin } from "@tanstack/vue-query";
import { type Plugin } from "vue";
import { createI18n } from "vue-i18n";
import VueKonva from "vue-konva";
import { createMemoryHistory, createRouter } from "vue-router";
import { FontAwesomePlugin } from "./FontAwesomePlugin";
import { SentryPlugin } from "./SentryPlugin";
import { PiniaPlugin } from "./PiniaPlugin";

export const DesignerPlugin: Plugin = {
    install(app) {
        app.use(SentryPlugin);
        app.use(FontAwesomePlugin);
        app.use(PiniaPlugin);
        app.use(
            createI18n({
                legacy: false,
                locale: "de",
                fallbackLocale: "de",
                messages,
            }),
        );
        app.use(VueQueryPlugin);
        app.use(
            createRouter({
                history: createMemoryHistory(),
                routes,
            }),
        );
        app.use(VueKonva as Plugin);
        app.use(DesignerCorePlugin);
    },
};

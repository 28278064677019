import useNotification from "@/composables/useNotification";
import useStoreVariant from "@/composables/variants/useStoreVariant";
import { NotificationType } from "@/enums/NotificationType";
import { useCurrentStore } from "@/store/useCurrentStore";
import { type CustomVariantDeleteFromArchiveCompletedResponse } from "@/types/pusher/CustomVariantDeleteFromArchiveCompletedResponse";
import { type PusherCustomVariantCustomizationsCompletedResponse } from "@/types/pusher/PusherCustomVariantCustomizationsCompletedResponse";
import {
    useDesignerApi,
    type ArchiveVariantListData,
} from "@smakecloud/smake-use";
import {
    useInfiniteQuery,
    useQueryClient,
    type InfiniteData,
} from "@tanstack/vue-query";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";

export default () => {
    const designerApi = useDesignerApi();
    const { isArchiving, currentVariant } = storeToRefs(useCurrentStore());
    const { getStoreVariantRequestBody } = useStoreVariant();
    const { pushNotification, resolveNotificationWithMessage } =
        useNotification();
    const { t } = useI18n();
    const queryClient = useQueryClient();

    const query = useInfiniteQuery({
        queryKey: ["smake-use.designerApi.variants.listArchived"],
        queryFn: ({ pageParam }) =>
            designerApi.endpoints.variants.listArchived(pageParam),
        select: (data) => data.pages.flatMap((page) => page.data),
        initialPageParam: undefined as number | undefined,
        getNextPageParam: (lastPage) =>
            lastPage.links.next === null
                ? undefined
                : lastPage.meta.current_page + 1,
        staleTime: Infinity,
    });

    async function storeCurrentVariantToArchive(): Promise<void> {
        if (isArchiving.value) {
            return;
        }

        isArchiving.value = true;
        resolveNotificationWithMessage(t("error_failed_to_archive_variant"));

        try {
            await designerApi.endpoints.variants.storeVariantToArchive(
                currentVariant.value.id,
                getStoreVariantRequestBody(currentVariant.value),
            );

            pushNotification(
                t("archived_variant_successfully"),
                NotificationType.Success,
            );
        } catch (error) {
            pushNotification(
                t("error_failed_to_archive_variant"),
                NotificationType.Info,
            );
            isArchiving.value = false;
        }
    }

    function onArchiveEntryDeleted(
        customVariantDeleteFromArchiveCompletedResponse: CustomVariantDeleteFromArchiveCompletedResponse,
    ): void {
        if (
            !queryClient.getQueryData<
                InfiniteData<ArchiveVariantListData, number | undefined>
            >(["smake-use.designerApi.variants.listArchived"])
        ) {
            return;
        }

        queryClient.setQueryData(
            ["smake-use.designerApi.variants.listArchived"],
            (
                oldData: InfiniteData<
                    ArchiveVariantListData,
                    number | undefined
                >,
            ) => ({
                ...oldData,
                pages: oldData.pages.map((page) => ({
                    ...page,
                    data: page.data.filter(
                        (variant) =>
                            variant.id !==
                            customVariantDeleteFromArchiveCompletedResponse.id,
                    ),
                })),
            }),
        );
    }

    async function onArchivingCompleted(
        pusherCustomVariantCustomizationsCompletedResponse: PusherCustomVariantCustomizationsCompletedResponse,
    ): Promise<void> {
        if (
            !queryClient
                .getQueryData<
                    InfiniteData<ArchiveVariantListData, number | undefined>
                >(["smake-use.designerApi.variants.listArchived"])
                ?.pages.every((page) =>
                    page.data.every(
                        (variant) =>
                            variant.id !==
                            pusherCustomVariantCustomizationsCompletedResponse.id,
                    ),
                )
        ) {
            return;
        }

        const newVariant = await designerApi.endpoints.variants.getArchivedById(
            pusherCustomVariantCustomizationsCompletedResponse.id,
        );
        queryClient.setQueryData(
            ["smake-use.designerApi.variants.listArchived"],
            (
                oldData: InfiniteData<
                    ArchiveVariantListData,
                    number | undefined
                >,
            ) => ({
                ...oldData,
                pages: [
                    {
                        ...oldData.pages[0],
                        data: [newVariant, ...oldData.pages[0].data],
                    },
                    ...oldData.pages.slice(1),
                ],
            }),
        );
        isArchiving.value = false;
    }

    return {
        ...query,
        storeCurrentVariantToArchive,
        onArchivingCompleted,
        onArchiveEntryDeleted,
    };
};

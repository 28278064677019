import useSaveVariant from "@/composables/variants/useVariantToORM";
import { type VariantIdentifier } from "@/types/DesignerProps";
import { useDesignerApi, type VariantData } from "@smakecloud/smake-use";
import { useVariantChecker } from "@/composables/variants/useVariantIdentifierChecker";
import { BootStepException } from "@/lib/Exception/BootStepException";
import { useInitialVariantStore } from "@/store/useInitialVariantStore";
import { type AxiosError } from "axios";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";

function isVariantNotFound(error: unknown): boolean {
    if (!isAxiosError(error)) {
        return false;
    }

    if (
        error.message.includes("No variant found for the external_identifier")
    ) {
        return true;
    }

    return error.response?.status === 404;
}

function isAxiosError(error: unknown): error is AxiosError {
    return (
        (error as AxiosError)?.message !== undefined ||
        (error as AxiosError)?.response !== undefined
    );
}

export default async (
    initialVariant: VariantIdentifier,
): Promise<VariantData> => {
    const designerApi = useDesignerApi();
    const { saveVariantResponseToRepository } = useSaveVariant();
    const { t } = useI18n();
    const { initialVariant: initialVariantStored } = storeToRefs(
        useInitialVariantStore(),
    );
    const { isVariantId, isVariantExternIdentifier } = useVariantChecker();

    try {
        if (isVariantId(initialVariant)) {
            initialVariantStored.value =
                await designerApi.endpoints.variants.getById(initialVariant.id);

            return await saveVariantResponseToRepository(
                initialVariantStored.value,
            );
        }

        if (isVariantExternIdentifier(initialVariant)) {
            initialVariantStored.value =
                await designerApi.endpoints.variants.getByExternalIdentifier(
                    initialVariant.external_identifier,
                );

            return await saveVariantResponseToRepository(
                initialVariantStored.value,
            );
        }
    } catch (error: unknown) {
        if (!isVariantNotFound(error)) {
            throw error;
        }

        throw new BootStepException(error, t("boot_error.variant_not_found"));
    }

    throw Error("Variant id or external_identifier is required");
};

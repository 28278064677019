<template>
    <panel-new
        data-testid="CustomizationEditModal"
        :show-on-panel-state="PanelState.CustomizationEdit"
        :can-be-closed="false"
    >
        <div
            class="flex min-h-full flex-col items-center gap-7 rounded-lg bg-white pb-2 desktop:gap-10"
            :class="{ 'pointer-events-none opacity-20': isTextLineChosen }"
        >
            <div class="flex w-full flex-col gap-4 rounded-lg px-4 py-5">
                <div class="flex w-full items-center justify-between">
                    <div class="font-bold">{{ panelHeadline }}</div>
                    <button-secondary-outlined
                        v-if="canSaveDesign"
                        class="flex justify-between gap-2 px-4 text-sm"
                        size="small"
                        :disabled="isCreatingDesign || isCurrentDesignSaved"
                        @click="createCurrentDesign"
                    >
                        <div v-if="isCreatingDesign">
                            <f-a-icon icon="spinner" spin />
                        </div>
                        {{ t("save") }}
                    </button-secondary-outlined>
                </div>
                <design-input
                    v-if="currentCustomization"
                    ref="designInputRef"
                    v-model:design="design"
                    v-model:rotation="rotation"
                    :customization-preview="customizationPreview"
                    :positioning-area-id="
                        currentCustomization?.positioningArea_id
                    "
                />
            </div>
            <template
                v-if="
                    customerLogoUploadInfo &&
                    currentProductionMethod.handle === 'dtf'
                "
            >
                <div class="grow"></div>
                <div
                    class="flex flex-col items-center gap-2 px-3 text-secondary-2"
                >
                    <f-a-icon
                        :icon="FontAwesomeKitIcons.Info"
                        :size="FontAwesomeSize.fa2x"
                    />
                    <div class="whitespace-pre-line text-center">
                        {{ customerLogoUploadInfo }}
                    </div>
                </div>
            </template>
        </div>

        <template #footer>
            <div class="grid grid-cols-2 gap-3 px-4 pb-5">
                <button
                    type="button"
                    :class="
                        allowedToCancel
                            ? settings.style.secondaryButton.styles
                            : settings.style.secondaryButton.styles.concat([
                                  'opacity-50',
                              ])
                    "
                    :disabled="!allowedToCancel"
                    data-testid="text-edit-modal-abort"
                    @click="abort()"
                >
                    <f-a-icon
                        v-if="waitForAbort"
                        icon="spinner"
                        :size="FontAwesomeSize.fa2x"
                        spin
                        class="absolute self-center justify-self-center text-black"
                    />
                    <template v-if="!waitForAbort">
                        <div
                            class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
                        >
                            <f-a-icon
                                icon="chevron-left"
                                :size="FontAwesomeSize.fa1x"
                            />
                        </div>
                        <span>
                            {{ t("abort") }}
                        </span>
                    </template>
                </button>
                <button-primary
                    data-testid="text-edit-modal-place"
                    class="text-xs"
                    :disabled="!allowedToPlace"
                    @click="confirm()"
                >
                    {{ t("place") }}
                </button-primary>
            </div>
        </template>
    </panel-new>
</template>

<script setup lang="ts">
import DesignInput from "@/components/modules/customizations/DesignInput.vue";
import { useDeleteCustomization } from "@/components/modules/customizations/composables/useDeleteCustomization";
import usePositioningAreaFunctions from "@/composables/positioningAreas/usePositioningAreaFunctions";
import useNotification from "@/composables/useNotification";
import useRenderCustomizationPreview from "@/composables/useRenderCustomizationPreview";
import { NotificationType } from "@/enums/NotificationType";
import { PanelState } from "@/enums/PanelState";
import { FontAwesomeKitIcons } from "@/enums/fontAwesome/fontAwesomeKitIcons";
import { FontAwesomeSize } from "@/enums/fontAwesome/fontAwesomeSize";
import { Mode } from "@/enums/mode";
import {
    ConvertZSKDesignToDesign,
    stripEmptyTextElement,
} from "@/modules/core/renderer/zskEmbroidery/ConvertZSKDesignToDesign";
import {
    isDesignWithRenderInformation,
    isZSKDesignWithLogo,
    isZSKDesignWithText,
    isZSKLogoTextDesign,
} from "@/modules/core/renderer/zskEmbroidery/ZSKDesignHelper";
import type {
    ZSKDesign,
    ZSKLogoDesign,
} from "@/modules/core/renderer/zskEmbroidery/types/ZSKDesign";
import Customization from "@/repo/Customization";
import Notification from "@/repo/Notification";
import { useCanvasStore } from "@/store/useCanvasStore";
import { useColorPickerStore } from "@/store/useColorPickerStore";
import { useCurrentStore } from "@/store/useCurrentStore";
import { useCustomizationEditModalStore } from "@/store/useCustomizationEditModalStore";
import { useMainStore } from "@/store/useMainStore";
import { useProductionMethodStore } from "@/store/useProductionMethodStore";
import { usePropsStore } from "@/store/usePropsStore";
import {
    isLaravel422,
    useDesignerApi,
    type DesignComplete,
} from "@smakecloud/smake-use";
import { useMutation } from "@tanstack/vue-query";
import { watchDebounced } from "@vueuse/core";
import { cloneDeep } from "lodash";
import { storeToRefs } from "pinia";
import { useRepo } from "pinia-orm";
import { computed, nextTick, onUnmounted, ref, watch, watchEffect } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const currentStore = useCurrentStore();
const { currentCustomization, currentMode } = storeToRefs(currentStore);
const { isARenderingRequestInProgress, renderPreviewForCustomization } =
    useRenderCustomizationPreview();
const { settings } = storeToRefs(useMainStore());
const { skipAbort } = storeToRefs(useCustomizationEditModalStore());
const { discardActiveObject, updateDesignCanvasObjectRotation } =
    useCanvasStore();
const { resolveNotificationsForPositioningArea } =
    usePositioningAreaFunctions();
const { deleteCurrentCustomizationAndGoToDefaultMode } =
    useDeleteCustomization();
const { open: anyColorPickerOpen } = storeToRefs(useColorPickerStore());

const { customerLogoUploadInfo } = storeToRefs(usePropsStore());
const { currentProductionMethod } = storeToRefs(useProductionMethodStore());

const isCustomizationValid = computed<boolean>(() => {
    return (
        useRepo(Notification)
            .query()
            .where("message", t("element_outside_placement_area"))
            .get().length === 0
    );
});

const isDesignDataRendered = computed<boolean>(() =>
    isDesignDataEqual(design.value, currentCustomization.value?.design ?? null),
);

const customizationPreview = computed(() => {
    if (currentCustomization.value === null) {
        return undefined;
    }

    return {
        customization: currentCustomization.value,
        isValid: isCustomizationValid.value,
    };
});

// data
const isTextLineChosen = ref(false);
const waitForAbort = ref(false);
const designInputRef = ref<InstanceType<typeof DesignInput>>();
const isAnAdditionalRenderingRequestRequired = ref<boolean>(false);
const isClosing = ref(false);

const designerApi = useDesignerApi();
const { pushNotification } = useNotification();

const designBackup: ZSKDesign | null = (() => {
    if (currentCustomization.value === null) {
        throw Error("Customization is Missing");
    }

    return cloneDeep(currentCustomization.value.design);
})();

const design = ref<ZSKDesign | null>(currentCustomization.value?.design);

const rotation = ref<number>(currentCustomization.value?.rotation ?? 0);

const lastValidDesignBackup = ref<ZSKDesign | null>(
    (() => {
        if (currentCustomization.value === null) {
            throw Error("Customization is Missing");
        }

        return cloneDeep(currentCustomization.value.design);
    })(),
);

watchEffect(() => {
    if (isDesignWithRenderInformation(design.value)) {
        return;
    }

    lastValidDesignBackup.value = cloneDeep(design.value);
});

const { mutate: createDesign, isPending: isCreatingDesign } = useMutation({
    mutationFn: designerApi.endpoints.designs.favored.create,
    onSuccess: (newDesign: DesignComplete) => {
        pushNotification(
            t("saved_design_successfully", {
                name: `<strong>${newDesign.name}</strong>`,
            }),
            NotificationType.Success,
        );

        // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
        designInputRef.value?.openCustomerLogoModal("designs");
    },
    onError: (error: unknown) => {
        isCurrentDesignSaved.value = false;

        if (isLaravel422(error) && error.response?.data.message) {
            pushNotification(
                error.response.data.message,
                NotificationType.Info,
            );
        }

        pushNotification(t("boot_error.unexpected"), NotificationType.Info);
    },
});

const isCurrentDesignSaved = ref(false);

watch(
    design,
    () => {
        isCurrentDesignSaved.value = false;
    },
    { deep: true },
);

watch(currentCustomization, () => {
    updateText();
    updateRotation();
});

function updateText() {
    if (currentCustomization.value === null) {
        return;
    }

    if (
        isDesignDataEqual(
            currentCustomization.value.design,
            lastValidDesignBackup.value,
        )
    ) {
        return;
    }

    design.value = currentCustomization.value.design;
}

function updateRotation() {
    if (currentCustomization.value === null) {
        return;
    }

    if (currentCustomization.value.rotation === rotation.value) {
        return;
    }

    rotation.value = currentCustomization.value.rotation;
}

function isDesignDataEqual(
    designA: ZSKDesign | null,
    designB: ZSKDesign | null,
) {
    const designAWithoutUUID = {
        ...cleanUpEmptyTextResourceLines(designA),
        uuid: undefined,
    };

    const designBWithoutUUID = {
        ...cleanUpEmptyTextResourceLines(designB),
        uuid: undefined,
    };

    return (
        JSON.stringify(designAWithoutUUID) ===
        JSON.stringify(designBWithoutUUID)
    );
}

const allowedToPlace = computed(() => {
    if (anyColorPickerOpen.value) {
        return false;
    }

    if (!isDesignDataRendered.value) {
        return false;
    }

    if (!isDesignWithRenderInformation(design.value)) {
        return false;
    }

    return (
        !isARenderingRequestInProgress.value &&
        useRepo(Notification).where("type", "error").get().length === 0
    );
});

const allowedToCancel = computed(() => {
    if (anyColorPickerOpen.value) {
        return false;
    }

    return !isARenderingRequestInProgress.value;
});

const strippedDesign = computed<ZSKDesign | null>(() => {
    if (design.value === null) {
        return null;
    }
    try {
        return stripEmptyTextElement(cloneDeep(design.value));
    } catch {
        return null;
    }
});

const panelHeadline = computed(() => {
    if (canSaveDesign.value) {
        return t("new_design");
    }

    return t("new_customization");
});

const canSaveDesign = computed<boolean>(() => {
    return isDesignWithRenderInformation(design.value);
});

function createCurrentDesign() {
    if (!design.value) {
        return;
    }

    isCurrentDesignSaved.value = true;
    createDesign(ConvertZSKDesignToDesign(design.value));
}

// TODO: Refactor in https://smake.atlassian.net/browse/STR-2616
const prevStrippedDesign = ref<ZSKDesign | null>(null);

watchDebounced(
    [strippedDesign, design],
    ([newStrippedDesign, newDesign]) => {
        if (
            !isZSKDesignWithText(newDesign) &&
            !isZSKDesignWithLogo(newDesign)
        ) {
            void deleteAndClose();
            return;
        }

        if (
            prevStrippedDesign.value !== null &&
            isDesignDataEqual(newStrippedDesign, prevStrippedDesign.value)
        ) {
            return;
        }

        prevStrippedDesign.value = cloneDeep(newStrippedDesign);
        void saveDesignToCustomizationAndRender(cloneDeep(newStrippedDesign));
    },
    { deep: true, debounce: 750 },
);

watch(rotation, () => {
    if (currentCustomization.value === null) {
        return;
    }

    updateDesignCanvasObjectRotation(
        currentCustomization.value.id,
        rotation.value,
    );
});

async function saveDesignToCustomizationAndRender(
    designToSave: ZSKDesign | null = null,
) {
    if (currentCustomization.value === null) {
        throw new Error("Current Customization is required");
    }

    if (isARenderingRequestInProgress.value) {
        isAnAdditionalRenderingRequestRequired.value = true;

        return;
    }

    try {
        const renderedCustomization = await renderPreviewForCustomization({
            ...currentCustomization.value,
            design: designToSave,
        } as Customization);

        useRepo(Customization).save({
            ...renderedCustomization,
        });

        lastValidDesignBackup.value = renderedCustomization.design;
    } catch {
        design.value = lastValidDesignBackup.value;
    }
}

watch(isARenderingRequestInProgress, async () => {
    if (isARenderingRequestInProgress.value) {
        return;
    }

    if (isAnAdditionalRenderingRequestRequired.value === false) {
        return;
    }

    isAnAdditionalRenderingRequestRequired.value = false;

    await saveDesignToCustomizationAndRender(design.value);
});

//methods

async function confirm() {
    if (!allowedToPlace.value) {
        return;
    }

    const cleanedUpDesign = cleanUpEmptyTextResourceLines(design.value);

    if (cleanedUpDesign === null) {
        return;
    }

    await saveAndCloseModal(cleanedUpDesign);
}

async function saveAndCloseModal(design: ZSKDesign) {
    if (currentCustomization.value === null) {
        throw Error("Customization is Missing");
    }

    isClosing.value = true;

    useRepo(Customization).save({
        ...currentCustomization.value,
        design,
    });

    resolveNotificationsForPositioningArea(
        currentCustomization.value.positioningArea_id,
    );

    discardActiveObject();

    await currentStore.resetCurrentCustomization();

    useCanvasStore().showOrHidePositioningAreaCanvasObjects();

    currentMode.value = Mode.Default;
}

async function deleteAndClose() {
    isClosing.value = true;
    await deleteCurrentCustomizationAndGoToDefaultMode();
}

async function abort(hidePanel = true) {
    isClosing.value = true;

    if (designBackup === null) {
        await deleteCurrentCustomizationAndGoToDefaultMode(hidePanel);
        return;
    }

    if (currentCustomization.value === null) {
        throw Error("Customization is Missing");
    }

    await saveDesignToCustomizationAndRender(designBackup);

    await nextTick();

    if (useRepo(Notification).where("type", "error").get().length !== 0) {
        return;
    }

    resolveNotificationsForPositioningArea(
        currentCustomization.value.positioningArea_id,
    );

    discardActiveObject();

    await currentStore.resetCurrentCustomization(hidePanel);

    useCanvasStore().showOrHidePositioningAreaCanvasObjects();

    currentMode.value = Mode.Default;
}

function cleanUpEmptyTextResourceLines(
    design: ZSKDesign | null,
): ZSKDesign | null {
    const cleanedUpDesign = cloneDeep(design);

    if (!isZSKDesignWithText(cleanedUpDesign)) {
        return cleanedUpDesign;
    }

    cleanedUpDesign.text.lines = cleanedUpDesign.text.lines.filter(
        (line) => line.value.trim() !== "",
    );

    if (cleanedUpDesign.text.lines.length !== 0) {
        return cleanedUpDesign;
    }

    if (isZSKLogoTextDesign(cleanedUpDesign)) {
        return {
            logo: cleanedUpDesign.logo,
            logoDesignElementData: cleanedUpDesign.logoDesignElementData,
        } satisfies ZSKLogoDesign;
    }

    return null;
}

onUnmounted(async () => {
    if (isClosing.value || skipAbort.value) return;

    try {
        await abort(false);
    } catch (e) {
        // Don't handle error case
    }
});
</script>

<template>
    <div class="flex w-full justify-between gap-2">
        <div
            :class="{
                'bg-white text-black': selected,
                'bg-secondary-3 text-black': !selected,
                'opacity-30': disabled,
            }"
            class="flex aspect-square w-8 items-center justify-center rounded-full p-1"
        >
            <f-a-icon v-if="icon" :icon />
        </div>
        <div class="p-1">
            {{ productionMethod.display_name }}
        </div>
        <div></div>
    </div>
</template>
<script setup lang="ts">
import { productionMethodIconMap } from "@/functions/ProductionMethodIcon";
import type { ProductionMethodData } from "@smakecloud/smake-use";
import { computed } from "vue";

const props = defineProps<{
    productionMethod: ProductionMethodData;
    selected?: boolean;
    disabled?: boolean;
}>();

const icon = computed(
    () => productionMethodIconMap[props.productionMethod.handle],
);
</script>

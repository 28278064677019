<template>
    <div class="flex flex-col gap-4">
        <customization-input
            v-if="
                isDesignWithRenderInformation(designModel ?? null) &&
                designModel
            "
            v-model:design="designModel"
            v-model:rotation="rotation"
            :customization-preview="customizationPreview"
            :title
            style="order: 1"
            @open-logo-selection="openMatchingLogoModal"
        />

        <div :style="`order: ${inputOrder.customerLogo}`">
            <add-design-component-button
                v-if="showLogoOrDesignCard"
                data-testid="add-logo-or-design"
                :title="logoOrDesignsCardTitle"
                :icon="FontAwesomeKitIcons.DesignUserLogo"
                :is-loading="showLogoOrDesignCardSkeleton"
                @click="openCustomerLogoModal()"
            />
        </div>

        <add-design-component-button
            v-if="!designModel?.logo || designModel?.logo.is_customer_logo"
            data-testid="add-logo"
            :title="t('icon_database')"
            :icon="FontAwesomeKitIcons.DesignIconDb"
            :style="`order: ${inputOrder.logo}`"
            @click="openLogoModal"
        />

        <div :style="`order: ${inputOrder.text}`">
            <add-design-component-button
                v-if="!isZSKDesignWithText(designModel ?? null)"
                :icon="FontAwesomeKitIcons.DesignText"
                :title="t('texts')"
                data-testid="add-text"
                @click="createTextResource"
            />
            <text-resource-input
                v-if="designModel && designModel.text"
                ref="textResourceInputRef"
                v-model="designModel.text"
                @delete="removeTextFromDesign"
            />
        </div>
    </div>
</template>

<script setup lang="ts">
import CustomerLogoModal from "@/components/modules/customerLogos/CustomerLogoModal.vue";
import TextResourceInput from "@/components/modules/texts/TextResourceInput.vue";
import { useDialog } from "@/composables/useDialog";
import usePlaceDesign from "@/composables/usePlaceDesign";
import { useTextresourceLineFactory } from "@/composables/useTextResourceLineFactory";
import { FontAwesomeKitIcons } from "@/enums/fontAwesome/fontAwesomeKitIcons";
import { getInitalLogoDimension } from "@/functions/SizeHelper";
import {
    designElementToZSKDesign,
    offsetToDistance,
} from "@/modules/core/renderer/zskEmbroidery/ConvertDesignElementToZSKDesign";
import { productionMethodToProductionMethodData } from "@/modules/core/renderer/zskEmbroidery/ConvertZSKDesignToDesign";
import {
    isDesignWithRenderInformation,
    isZSKDesignWithLogo,
    isZSKDesignWithText,
    isZSKLogoTextDesign,
    isZSKTextDesign,
} from "@/modules/core/renderer/zskEmbroidery/ZSKDesignHelper";
import type {
    ZSKAlignment,
    ZSKDesign,
    ZSKLogoDesign,
    ZSKTextDesign,
} from "@/modules/core/renderer/zskEmbroidery/types/ZSKDesign";
import type Customization from "@/repo/Customization";
import PositioningArea from "@/repo/PositioningArea";
import { useCurrentStore } from "@/store/useCurrentStore";
import { useProductionMethodStore } from "@/store/useProductionMethodStore";
import { useShowCustomerLogoOrDesignCardStore } from "@/store/useShowCustomerLogoOrDesignCardStore";
import {
    isAnchorOuter,
    OuterAnchorPoints,
    ResourceType,
    type Anchor,
    type DesignComplete,
    type LogoResourceData,
    type TextResourceData,
} from "@smakecloud/smake-use";
import { storeToRefs } from "pinia";
import { useRepo } from "pinia-orm";
import { computed, ref, toRefs, type ModelRef } from "vue";
import { useI18n } from "vue-i18n";
import LogoModal from "./LogoModal.vue";

const props = defineProps<{
    customizationPreview?: {
        customization: Customization;
        isValid: boolean;
    };
    positioningAreaId: number;
}>();

const { positioningAreaId } = toRefs(props);

const designModel = defineModel<ZSKDesign | null>("design");
const rotation = defineModel<number>("rotation", {
    required: true,
});

const { t } = useI18n();
const { show } = useDialog();
const productionMethodStore = useProductionMethodStore();
const { changeCurrentProductionMethod } = productionMethodStore;
const { currentProductionMethod } = storeToRefs(productionMethodStore);
const { selectPositioningArea } = usePlaceDesign();
const positioningAreaRepo = useRepo(PositioningArea);

const positioningArea = computed(() => {
    const area = positioningAreaRepo.find(positioningAreaId.value);

    if (!area) {
        throw new Error("Missing PositioningArea");
    }

    return area;
});

// data
const textResourceInputRef = ref<InstanceType<typeof TextResourceInput>>();

const title = computed<string>(() => {
    if (
        isZSKTextDesign(
            props.customizationPreview?.customization.design ?? null,
        )
    ) {
        return t("preview");
    }

    if (designModel.value?.logo?.is_customer_logo) {
        return t("logo");
    }

    return t("icon");
});

const inputOrder = computed<{
    text: number;
    logo: number;
    customerLogo: number;
}>(() => {
    if (isZSKDesignWithLogo(designModel.value ?? null))
        return designModel.value?.logo?.is_customer_logo
            ? { text: 3, logo: 4, customerLogo: 2 }
            : { text: 3, logo: 2, customerLogo: 4 };

    if (isZSKDesignWithText(designModel.value ?? null))
        return { text: 2, logo: 4, customerLogo: 3 };

    return { text: 4, logo: 3, customerLogo: 2 };
});

const { showCustomerLogoOrDesignCard, isFetching, hasCustomerLogos } =
    storeToRefs(useShowCustomerLogoOrDesignCardStore());

const showLogoOrDesignCardSkeleton = computed(() => isFetching.value);

const showLogoOrDesignCard = computed<boolean>(() => {
    if (!showCustomerLogoOrDesignCard.value) {
        return false;
    }

    return !designModel.value?.logo?.is_customer_logo;
});

const logoOrDesignsCardTitle = computed<string>(() => {
    if (!hasCustomerLogos.value) {
        return t("designs", 2);
    }

    return t("logos_and_designs");
});

async function setLogoAsCurrent(selectedLogo: LogoResourceData) {
    const fromProductionMethod = currentProductionMethod.value;
    const toProductionMethod = productionMethodStore.getById(
        selectedLogo.production_method.id,
        true,
    );

    if (fromProductionMethod.id !== toProductionMethod.id) {
        changeCurrentProductionMethod(toProductionMethod);
        await selectPositioningArea(
            { resource_type: ResourceType.LOGO, resource: selectedLogo },
            () => {
                changeCurrentProductionMethod(fromProductionMethod);
            },
        );

        return;
    }

    if (!designModel.value) {
        designModel.value = {
            logo: selectedLogo,
            logoDesignElementData: {
                dimension: getInitalLogoDimension(
                    selectedLogo,
                    positioningArea.value,
                ),
            },
        } satisfies ZSKLogoDesign;

        return;
    }

    designModel.value.logo = selectedLogo;

    if (isZSKDesignWithLogo(designModel.value)) {
        designModel.value.logoDesignElementData = {
            dimension: getInitalLogoDimension(
                selectedLogo,
                positioningArea.value,
            ),
        };
    }

    if (!isZSKLogoTextDesign(designModel.value)) {
        return;
    }

    ensureAlignment(designModel);
}

async function onDesignSelected(design: DesignComplete) {
    const fromProductionMethod = currentProductionMethod.value;
    const toProductionMethod = productionMethodStore.getById(
        design.production_method.id,
        true,
    );
    if (fromProductionMethod.id !== toProductionMethod.id) {
        changeCurrentProductionMethod(toProductionMethod);
        await selectPositioningArea(design.elements[0], () => {
            changeCurrentProductionMethod(fromProductionMethod);
        });
        return;
    }

    designModel.value = designElementToZSKDesign(design.elements[0]);
}

async function openLogoModal() {
    const selectedLogo = await show(LogoModal);
    if (!selectedLogo) return;
    await setLogoAsCurrent(selectedLogo);
}

async function openCustomerLogoModal(
    defaultTab: "customerLogos" | "designs" = "customerLogos",
) {
    const result = await show(CustomerLogoModal, {
        isSelectConfirmationRequired: designModel.value !== null,
        defaultTab,
    });
    if (!result) return;
    switch (result.type) {
        case "logo":
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            await setLogoAsCurrent(result.logo);
            break;
        case "design":
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            await onDesignSelected(result.design);
            break;
    }
}

async function openMatchingLogoModal() {
    if (designModel.value?.logo?.is_customer_logo) {
        await openCustomerLogoModal();
        return;
    }

    await openLogoModal();
}

const { getNewEmptyResourceLine } =
    useTextresourceLineFactory(positioningAreaId);
const { currentCustomization } = storeToRefs(useCurrentStore());

function createTextResource() {
    if (!currentCustomization.value) {
        return;
    }

    if (isZSKDesignWithText(designModel.value ?? null)) {
        return;
    }

    const textResource = {
        // id: uuid4(),
        // base_text_id: 8,
        production_method: productionMethodToProductionMethodData(
            currentCustomization.value.positioning_area.production_method,
        ),
        lines: [getNewEmptyResourceLine()],
        preview_url: "",
        width: 0,
        height: 0,
        created_at: "",
        updated_at: "",
    } satisfies TextResourceData;

    if (!designModel.value) {
        designModel.value = {
            text: textResource,
        } satisfies ZSKTextDesign;

        return;
    }

    designModel.value.text = textResource;

    ensureAlignment(designModel);
}

function retrieveAlignment(logo: LogoResourceData): ZSKAlignment {
    const defaultAlignment = {
        anchor: OuterAnchorPoints.BOTTOM_CENTER,
        distance: 5,
    } satisfies ZSKAlignment;

    if (!logo.design_settings.next_element_alignment) {
        return defaultAlignment;
    }

    const logoAnchor = {
        value: logo.design_settings.next_element_alignment.anchor_value,
        type: logo.design_settings.next_element_alignment.anchor_type,
    } as Anchor;

    if (!isAnchorOuter(logoAnchor)) {
        return defaultAlignment;
    }

    return {
        anchor: logoAnchor.value,
        distance: offsetToDistance(logo.design_settings.next_element_alignment),
    };
}

function ensureAlignment(
    designToEnsure: ModelRef<ZSKDesign | null | undefined>,
) {
    if (!designToEnsure.value) {
        return;
    }

    if (!isZSKLogoTextDesign(designToEnsure.value)) {
        return;
    }

    designToEnsure.value.alignment = retrieveAlignment(
        designToEnsure.value.logo,
    );
}

function removeTextFromDesign() {
    delete designModel.value?.text;
}

defineExpose({ openCustomerLogoModal });
</script>

<script setup lang="ts">
import { useColorConvertorHSV } from "@/composables/colorPicker/useColorConverterHSV";
import { hexToHsv, type HSVColor } from "@/utils/colorConversion";
import { useFocus } from "@vueuse/core";
import { computed, ref, useTemplateRef, watchEffect } from "vue";

const color = defineModel<HSVColor>({ required: true });

const { colorHex } = useColorConvertorHSV(color);
const _hexRaw = ref(colorHex.value);
const hexRaw = computed<string>({
    set(value) {
        colorHex.value = "#" + value;
        _hexRaw.value = value;
    },
    get() {
        if (focused.value) return _hexRaw.value;

        return colorHex.value.replace("#", "");
    },
});

const input = useTemplateRef("input");
const { focused } = useFocus(input);

const isValid = computed(() => hexToHsv("#" + _hexRaw.value) !== undefined);

function onPaste(event: ClipboardEvent) {
    event.preventDefault();
    const pastedHex = event.clipboardData?.getData("text").replace("#", "");

    if (!pastedHex) return;

    hexRaw.value = pastedHex;
}

watchEffect(() => {
    if (focused.value) return;

    _hexRaw.value = colorHex.value.replace("#", "");
});
</script>

<template>
    <div
        class="flex items-center gap-2 overflow-clip rounded border px-2"
        :class="{
            'border-danger': !isValid,
            'border-secondary-2': isValid,
        }"
    >
        <div class="-ml-2 flex h-full items-center bg-secondary-3 px-2">#</div>
        <input
            ref="input"
            v-model="hexRaw"
            class="w-full focus:outline-none"
            maxlength="6"
            @paste="onPaste"
        />
    </div>
</template>

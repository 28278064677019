<template>
    <div class="relative flex h-full w-full flex-col overflow-hidden">
        <button-panel-top v-if="!isPanelOpen" />
        <div class="flex grow justify-center">
            <div class="flex w-full max-w-6xl flex-row">
                <button-panel-left v-if="!isPanelOpen" />
                <div
                    class="pointer-events-none relative z-[60] flex grow flex-col"
                >
                    <stage-view-buttons
                        v-if="showViewButtons"
                        :disabled="disableStageElements"
                    />

                    <div
                        ref="stageContentCenter"
                        data-testid="stage-content"
                        class="relative mt-[88px] grow"
                    >
                        <div
                            v-if="showStageContentCenter"
                            class="z-10 grid h-full w-full content-center"
                        />
                    </div>

                    <div ref="stageContentBottom" class="grow-0">
                        <div
                            v-if="showStageContentBottom"
                            class="grid w-full grid-flow-row justify-center gap-10 self-end pb-3 pt-5"
                        >
                            <stage-view-navigation
                                v-if="showViewNavigation"
                                :disabled="disableStageElements"
                            />
                            <stage-zoom
                                v-if="showZoomSlider"
                                :disabled="disableStageElements"
                            />
                        </div>
                    </div>
                </div>
                <button-panel-right v-if="!isPanelOpen" />
            </div>
            <stage-canvas v-if="showStage" />
        </div>
        <button-panel-bottom1 v-if="!isPanelOpen" />
        <button-panel-bottom2 v-if="!isPanelOpen" />

        <component
            :is="editModal"
            v-for="editModal in editModals"
            :key="editModal"
            class="z-20"
        />
    </div>
</template>

<script setup lang="ts">
import { useBoundingStore } from "@/store/useBoundingStore";
import { useMainStore } from "@/store/useMainStore";
import { usePanelStore } from "@/store/usePanelStore";
import { useModules } from "@/support/modules";
import { useResizeObserver } from "@vueuse/core";
import { storeToRefs } from "pinia";
import { computed, nextTick, onMounted, ref, watch } from "vue";

const mainStore = useMainStore();
const { settings, designerWrapper } = storeToRefs(mainStore);
const { stageContent } = storeToRefs(useBoundingStore());
const { isPanelOpen } = storeToRefs(usePanelStore());

// data
const stageContentCenter = ref<HTMLElement>();
const editModals = ref<string[]>(
    useModules()
        .availableEditModals()
        .map((availableEditModal) => availableEditModal.editModal)
        .filter((editModal) => editModal !== undefined),
);
const showStageElements = ref(true);
const disableStageElements = ref(false);
const showStage = ref(false);

const showStageContentCenter = computed(() => {
    return showStageElements.value && showViewButtons;
});

const showStageContentBottom = computed(() => {
    return (
        showStageElements.value && (showViewNavigation.value || showZoomSlider)
    );
});

const showViewButtons = computed(() => {
    return settings.value.stage.showViewButtons;
});

const showViewNavigation = computed(() => {
    return settings.value.stage.showViewNavigation;
});

const showZoomSlider = computed(() => {
    return settings.value.stage.showZoomSlider;
});

useResizeObserver(stageContentCenter, setStageContentBounding);

onMounted(async () => {
    await nextTick();

    useResizeObserver(designerWrapper, setStageContentBounding);

    showStage.value = true;
    setStageContentBounding();
});

// watch
watch(
    () => isPanelOpen.value,
    () => {
        if (isPanelOpen.value) {
            showStageElements.value = false;
            disableStageElements.value = true;
        } else {
            showStageElements.value = true;
            disableStageElements.value = false;
        }
    },
);

// methods
function setStageContentBounding() {
    const stageContentBounding =
        stageContentCenter.value?.getBoundingClientRect();
    const stageViewNavigationBounding = showViewNavigation.value ? 132 : 0;

    stageContent.value = {
        top: stageContentBounding?.top ?? 0,
        right:
            stageContentBounding?.right ?? 0 - stageViewNavigationBounding / 2,
        bottom: stageContentBounding?.bottom ?? 0,
        left: stageContentBounding?.left ?? 0 + stageViewNavigationBounding / 2,
        width: stageContentBounding?.width ?? 0 - stageViewNavigationBounding,
        height: stageContentBounding?.height ?? 0,
        x: stageContentBounding?.x ?? 0 + stageViewNavigationBounding / 2,
        y: stageContentBounding?.y ?? 0,
    };
}
</script>

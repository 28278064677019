import ProductionType from "@/repo/ProductionType";
import { Model } from "pinia-orm";
import { BelongsTo, Num, Str, Uid, Bool } from "pinia-orm/dist/decorators";

export default class ProductionMethod extends Model {
    static entity = "productionMethods";

    @Uid()
    id!: number;

    @Str("")
    handle!: string;

    @Str("")
    display_name!: string;

    @Str("")
    description!: string;

    @Str("")
    preview_url!: string;

    @Num(0)
    production_type_id!: number;

    @BelongsTo(() => ProductionType, "production_type_id")
    production_type!: ProductionType;

    @Bool(false)
    is_using_color_picker!: boolean;
}

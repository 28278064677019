import useLogoCategoryUtils from "@/composables/useLogoCategoryUtils";
import type { WithChildren } from "@/composables/useTreeUtils";
import useTreeUtils from "@/composables/useTreeUtils";
import { apiClient } from "@smakecloud/designer-core";
import { type LogoCategoryData } from "@smakecloud/smake-use";
import { useQuery } from "@tanstack/vue-query";
import { defineStore, storeToRefs } from "pinia";
import { computed, ref, watch } from "vue";
import { useLogoCategoriesStore } from "./useLogoCategoriesStore";

export const useLogosStore = defineStore("logosStore", () => {
    const { dataTree: categoriesTree } = storeToRefs(useLogoCategoriesStore());
    const { forEach } = useTreeUtils();
    const { getLogoCount } = useLogoCategoryUtils();

    const activeCategoryIds = ref<number[]>([]);
    const searchQuery = ref<string>();

    const activeCategories = computed(() => {
        const activeCategories: WithChildren<LogoCategoryData>[] = [];

        forEach(categoriesTree.value ?? [], (category) => {
            if (activeCategoryIds.value.includes(category.id)) {
                activeCategories.push(category);
            }
        });

        return activeCategories;
    });

    function getActiveCategories(categories: WithChildren<LogoCategoryData>[]) {
        return computed(() => {
            const result: WithChildren<LogoCategoryData>[] = [];

            forEach(categories, (category) => {
                if (activeCategoryIds.value.includes(category.id)) {
                    result.push(category);
                }
            });

            return result;
        });
    }

    function toggleCategory(category: WithChildren<LogoCategoryData>) {
        if (activeCategoryIds.value.includes(category.id)) {
            activeCategoryIds.value = activeCategoryIds.value.filter(
                (activeCategoryId) => activeCategoryId !== category.id,
            );
            return;
        }

        let parents: number[] = [];

        forEach(
            categoriesTree.value ?? [],
            (currentCategory, currentParents) => {
                if (currentCategory.id === category.id) {
                    parents = currentParents.map((parent) => parent.id);
                    return true;
                }
                return false;
            },
        );

        const children: number[] = [];

        forEach(category.children, (child) => {
            children.push(child.id);
        });

        activeCategoryIds.value = activeCategoryIds.value.filter(
            (activeCategoryId) =>
                !parents.includes(activeCategoryId) &&
                !children.includes(activeCategoryId),
        );

        activeCategoryIds.value.push(category.id);
        activeCategoryIds.value.sort();
    }

    const activeProductionMethods = ref<number[] | undefined>();
    watch(activeProductionMethods, () => {
        activeCategoryIds.value = [];
    });

    const trimmedSearchQuery = computed(() => {
        if (!searchQuery.value) return;

        const trimmedQuery = searchQuery.value.trim();

        if (trimmedQuery.length === 0) return;

        return trimmedQuery;
    });

    const countFilters = computed<typeof apiClient.logos.count.inputType>(
        () => ({
            production_method: activeProductionMethods.value,
            name_or_tags: trimmedSearchQuery.value,
        }),
    );

    const countQuery = useQuery({
        ...apiClient.logos.count.use(countFilters),
        staleTime: Infinity,
    });

    const resultCount = computed(() =>
        activeCategories.value.length
            ? getLogoCount(activeCategories.value)
            : (countQuery.data.value ?? 0),
    );

    return {
        activeCategoryIds,
        searchQuery,
        activeCategories,
        activeProductionMethods,
        getActiveCategories,
        toggleCategory,
        resultCount,
    };
});

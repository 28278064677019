<template>
    <div
        v-if="isFetching || customerLogos?.length"
        v-on-scroll-end="onScrollEnd"
        class="grid grid-cols-[repeat(auto-fill,minmax(150px,1fr))] gap-4 tablet:grid-cols-[repeat(auto-fill,minmax(180px,1fr))]"
    >
        <customer-logo-card
            v-for="customerLogo in customerLogos"
            :key="customerLogo.id"
            :customer-logo
            @select="emit('logo-selected', cloneDeep(customerLogo.logo))"
        />
        <template v-if="isFetching">
            <div class="h-80 animate-pulse rounded-3xl bg-secondary-3" />
            <div class="h-80 animate-pulse rounded-3xl bg-secondary-3" />
        </template>
    </div>
    <div v-else class="-mt-8 flex h-full items-center justify-center text-lg">
        <span class="max-w-3xl text-center italic text-secondary-1">
            {{
                t("no_customer_logos", {
                    productionMethod: filterProductionMethod.display_name,
                    contact: phone,
                })
            }}
        </span>
    </div>
</template>

<script setup lang="ts">
import vOnScrollEnd from "@/directives/vOnScrollEnd";
import type ProductionMethod from "@/repo/ProductionMethod";
import { type LogoResourceData } from "@smakecloud/smake-use";
import { useInfiniteQuery } from "@tanstack/vue-query";
import { cloneDeep } from "lodash";
import { computed, toRefs } from "vue";
import { useI18n } from "vue-i18n";
import { apiClient } from "@smakecloud/designer-core";
import { useSettingsStore } from "@/store/useSettingsStore";
import { storeToRefs } from "pinia";

const emit = defineEmits<{
    "logo-selected": [LogoResourceData];
}>();

const props = defineProps<{
    filterProductionMethod: ProductionMethod;
}>();

const { filterProductionMethod } = toRefs(props);
const { t } = useI18n();

const filters = computed<typeof apiClient.customerLogos.list.inputType>(() => ({
    production_method: [filterProductionMethod.value.id],
}));

const { phone } = storeToRefs(useSettingsStore());

const {
    isFetching,
    data: customerLogos,
    fetchNextPage,
} = useInfiniteQuery({
    ...apiClient.customerLogos.list.use(filters),
    select: (data) => data.pages.flatMap((page) => page.data),
    staleTime: 60 * 1000,
});

function onScrollEnd() {
    if (isFetching.value) return;

    void fetchNextPage();
}
</script>

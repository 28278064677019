<template>
    <base-dialog v-model:is-open="isGlobalProductionMethodSelectionOpen">
        <div
            ref="productionMethodModalContainer"
            class="absolute max-h-full w-full overflow-auto"
            @click.self.prevent
        >
            <div
                class="mt-5 flex max-h-full w-full flex-col items-center justify-center gap-4 overflow-y-auto"
            >
                <production-method-button
                    @click.prevent.self="showSelection = false"
                />
                <transition-root
                    :show="showSelection"
                    as="template"
                    leave-from="opacity-100"
                    leave="duration-200 ease-in"
                    leave-to="opacity-0 -translate-y-[50px]"
                    @after-leave="isGlobalProductionMethodSelectionOpen = false"
                >
                    <base-dialog-panel
                        stay-open-on-backdrop-click
                        class="flex h-full flex-col gap-6 p-5"
                    >
                        <div class="mt-2 px-6 text-xl font-bold tracking-wider">
                            {{ title }}
                        </div>
                        <div
                            class="flex max-w-3xl gap-6 bg-white px-6 pb-6 max-tablet:flex-col tablet:overflow-x-auto"
                            :class="{
                                'justify-center': readonly,
                            }"
                        >
                            <production-method-card
                                v-for="productionMethod in currentActiveProductionMethods"
                                :key="productionMethod.id"
                                class="shrink-0 tablet:basis-[calc(50%-12px)]"
                                :production-method="productionMethod"
                                :readonly
                                @select="
                                    selectProductionMethod(productionMethod)
                                "
                            />
                        </div>
                    </base-dialog-panel>
                </transition-root>
            </div>
        </div>
    </base-dialog>
</template>

<script setup lang="ts">
import { storeToRefs } from "pinia";
import type { ProductionMethodData } from "@smakecloud/smake-use";
import { computed, nextTick, ref, watchEffect } from "vue";
import { useProductionMethodStore } from "@/store/useProductionMethodStore";
import { useI18n } from "vue-i18n";
import { TransitionRoot } from "@headlessui/vue";
import { useProductionMethodDialogStore } from "@/store/useProductionMethodDialogStore";

const { t } = useI18n();

const readonly = computed(() => showProductionMethodSelectionAsInfo.value);

const title = computed(() => {
    if (showProductionMethodSelectionAsInfo.value) {
        return t("production_method").toUpperCase();
    }

    return t("choose_production_method").toUpperCase();
});

const {
    isGlobalProductionMethodSelectionOpen,
    showProductionMethodSelectionAsInfo,
} = storeToRefs(useProductionMethodDialogStore());

const { currentActiveProductionMethods } = storeToRefs(
    useProductionMethodStore(),
);

const { changeCurrentProductionMethod } = useProductionMethodStore();

function selectProductionMethod(productionMethod: ProductionMethodData) {
    changeCurrentProductionMethod(productionMethod);
    showSelection.value = false;
}

const showSelection = ref(true);

watchEffect(() => {
    if (isGlobalProductionMethodSelectionOpen.value) {
        showSelection.value = true;
    }
});

const productionMethodModalContainer = ref<HTMLDivElement>();

watchEffect(() => {
    if (!isGlobalProductionMethodSelectionOpen.value) {
        return;
    }

    if (!productionMethodModalContainer.value) {
        return;
    }

    void nextTick(() => productionMethodModalContainer.value?.scroll(0, 0));
});
</script>

<template>
    <div
        v-if="hasCustomizations"
        class="flex flex-col rounded-3xl bg-secondary-3 px-5 py-8"
    >
        <div class="flex items-center justify-between">
            <div>
                <div class="text-xs">
                    {{ formattedCreatedAt }} | #{{ variant.id }}
                </div>
                <div class="mt-1 text-lg font-bold">
                    {{ displayName }}
                </div>
            </div>
            <div>
                <slot
                    name="action"
                    :is-loading-current-archive-variant="
                        isLoadingCurrentArchiveVariant
                    "
                />
            </div>
        </div>

        <div
            ref="variantList"
            class="mt-2 grid grid-cols-[repeat(auto-fill,minmax(150px,1fr))] gap-4"
        >
            <variant-selection-customization
                v-for="(customization, customizationIndex) in customizations"
                :key="customizationIndex"
                v-model="selectedCustomizationId"
                :customization
                :id-prefix
                :variant
                :disabled="!enabledCustomizations.includes(customization)"
                @open-views-preview="
                    openViewsPreview(
                        customization.positioningArea.view_type_handle,
                    )
                "
            />
        </div>

        <div class="grow" />
        <div class="mt-4 grid grid-cols-1 gap-4">
            <button
                :data-testid="`${idPrefix}-apply-customization-archive-variant-${variant.id}`"
                :class="
                    settings.style.archiveModalSelectionGroupSecondaryButton
                        .styles
                "
                type="button"
                :disabled="
                    disableLoadCustomization || !selectedCustomizationResponse
                "
                @click="placeSelectedCustomization"
            >
                {{ t("archive_fetch_selected_customization") }}
            </button>
            <button
                :data-testid="`${idPrefix}-apply-archive-variant-${variant.id}`"
                :class="
                    settings.style.archiveModalSelectionGroupPrimaryButton
                        .styles
                "
                type="button"
                :disabled="disableLoadCustomization"
                @click="loadArchiveVariantWithAllCustomizations"
            >
                <div v-if="isLoadingCurrentArchiveVariant">
                    <f-a-icon
                        icon="spinner"
                        :size="FontAwesomeSize.fa2x"
                        spin
                    />
                </div>
                <div v-else>
                    {{ t("archive_fetch_variant_with_customizations") }}
                </div>
            </button>
        </div>
        <template v-if="showDetailPreview">
            <views-preview
                :archive-variant="variant"
                :handle="viewHandle"
                @resolve-detail-view-preview="closeViewsPreview"
            />
        </template>
    </div>
</template>

<script setup lang="ts">
import useChangeCurrentVariant from "@/composables/useChangeCurrentVariant";
import { useDialog } from "@/composables/useDialog";
import useNotification from "@/composables/useNotification";
import usePlaceDesign from "@/composables/usePlaceDesign";
import { NotificationType } from "@/enums/NotificationType";
import { FontAwesomeSize } from "@/enums/fontAwesome/fontAwesomeSize";
import { hasVariantSupportedPositioningAreas } from "@/functions/ProductionMethodInfo";
import { BootStepException } from "@/lib/Exception/BootStepException";
import { useArchiveVariantStore } from "@/store/useArchiveVariantStore";
import { useCurrentStore } from "@/store/useCurrentStore";
import { useMainStore } from "@/store/useMainStore";
import { usePanelStore } from "@/store/usePanelStore";
import { useProductionMethodStore } from "@/store/useProductionMethodStore";
import {
    type ArchiveVariantData,
    type ArchiveViewData,
    type CustomizationData,
    type VariantData,
    type ViewData,
} from "@smakecloud/smake-use";
import { storeToRefs } from "pinia";
import { computed, getCurrentInstance, ref, toRefs, watch } from "vue";
import { useI18n } from "vue-i18n";
import ChangeProductionMethodDialog from "../changeProductionMethodDialog/ChangeProductionMethodDialog.vue";

const props = defineProps<{
    variant: ArchiveVariantData | VariantData;
    idPrefix: string;
    disableLoadCustomization?: boolean;
}>();
const { variant } = toRefs(props);

//data
const instance = getCurrentInstance();
const showDetailPreview = ref<boolean>(false);
const viewHandle = ref<string>("");

const { t } = useI18n();
const { hideCurrentPanel } = usePanelStore();
const currentlyLoadingArchiveVariantId = ref<number | null>(null);
const { pushNotification } = useNotification();
const { settings } = storeToRefs(useMainStore());
const { changeVariantById } = useChangeCurrentVariant();
const { isLoadingArchiveVariant } = storeToRefs(useArchiveVariantStore());
const productionMethodStore = useProductionMethodStore();
const { changeCurrentProductionMethod } = productionMethodStore;
const { currentProductionMethod } = storeToRefs(productionMethodStore);
const { selectPositioningArea } = usePlaceDesign();
const { show } = useDialog();
const { currentVariant } = storeToRefs(useCurrentStore());

const customizations = computed(() =>
    variant.value.views.flatMap((view) => view.customizations),
);

const enabledCustomizations = computed(() =>
    customizations.value.filter((customization) =>
        hasVariantSupportedPositioningAreas(
            currentVariant.value,
            customization.design.production_method.id,
        ),
    ),
);

const selectedCustomizationId = ref<number | undefined>(
    enabledCustomizations.value[0]?.id,
);
const variantList = ref<HTMLDivElement | null>(null);

const displayName = computed<string>(() =>
    "display_name" in props.variant
        ? props.variant.display_name
        : props.variant.name,
);

const formattedCreatedAt = computed<string>(() =>
    new Date(props.variant.created_at).toLocaleDateString("de", {
        dateStyle: "medium",
    }),
);

const hasCustomizations = computed<boolean>(() => {
    return props.variant.views.some((view) => {
        return view.customizations.length > 0;
    });
});

const selectedCustomizationResponse = computed<CustomizationData | null>(() => {
    const viewWithCustomization: ArchiveViewData | ViewData | undefined =
        props.variant.views.find((view) => {
            return view.customizations.find(
                (customization) =>
                    customization.id === selectedCustomizationId.value,
            );
        });

    if (!viewWithCustomization) {
        return null;
    }

    return viewWithCustomization.customizations.find(
        (customization) => customization.id === selectedCustomizationId.value,
    )!;
});

const isLoadingCurrentArchiveVariant = computed<boolean>(() => {
    return currentlyLoadingArchiveVariantId.value === props.variant.id;
});

const disableLoadCustomization = computed<boolean>(() => {
    return (
        isLoadingCurrentArchiveVariant.value || props.disableLoadCustomization
    );
});

// watch

watch(
    () => currentlyLoadingArchiveVariantId.value,
    (loadingArchiveVariantId: number | null) => {
        isLoadingArchiveVariant.value = loadingArchiveVariantId !== null;
    },
);

// methods
async function placeSelectedCustomization() {
    if (!selectedCustomizationResponse.value) {
        return;
    }

    const fromProductionMethod = currentProductionMethod.value;
    const toProductionMethod = productionMethodStore.getById(
        selectedCustomizationResponse.value.resource.production_method.id,
        true,
    );
    if (
        fromProductionMethod.id !== toProductionMethod.id &&
        !(await show(ChangeProductionMethodDialog, { toProductionMethod }))
    )
        return;

    changeCurrentProductionMethod(toProductionMethod);
    await selectPositioningArea(
        selectedCustomizationResponse.value.design.elements[0],
        () => {
            changeCurrentProductionMethod(fromProductionMethod);
        },
    );
}

async function loadArchiveVariantWithAllCustomizations() {
    currentlyLoadingArchiveVariantId.value = props.variant.id;

    try {
        await changeVariantById(props.variant.id, instance);

        currentlyLoadingArchiveVariantId.value = null;
        await hideCurrentPanel();
    } catch (e: unknown) {
        currentlyLoadingArchiveVariantId.value = null;

        if (!(e instanceof BootStepException)) {
            pushNotification(t("boot_error.unexpected"), NotificationType.Info);

            return;
        }

        pushNotification(e.readableMessage, NotificationType.Info);
    }
}

function openViewsPreview(handle: string) {
    showDetailPreview.value = !showDetailPreview.value;
    viewHandle.value = handle;
}

function closeViewsPreview() {
    showDetailPreview.value = false;
}
</script>

import { TransitionPresets, useTransition } from "@vueuse/core";
import { readonly, ref, watch } from "vue";

export default (onFadeOutFinished: () => void) => {
    const baseDisplayBarPercentage = ref<number>(100);
    const baseOpacityLevel = ref<number>(1);

    function startFadeOut() {
        baseDisplayBarPercentage.value = 0;
    }
    const displayBarPercentage = useTransition(baseDisplayBarPercentage, {
        duration: 3000,
        transition: TransitionPresets.linear,
    });

    const opacityLevel = useTransition(baseOpacityLevel, {
        duration: 300,
        transition: TransitionPresets.easeOutSine,
    });

    watch(
        () => displayBarPercentage.value,
        () => {
            if (displayBarPercentage.value !== 0) {
                return;
            }

            baseOpacityLevel.value = 0;
        },
    );

    watch(
        () => opacityLevel.value,
        () => {
            if (opacityLevel.value !== 0) {
                return;
            }

            onFadeOutFinished();
        },
    );

    return {
        opacityLevel: readonly(opacityLevel),
        displayBarPercentage: readonly(displayBarPercentage),
        startFadeOut,
    };
};

<template>
    <div class="absolute" data-testid="stage-canvas">
        <canvas
            id="canvas"
            ref="canvasDom"
            class="relative"
            :width="app.width"
            :height="app.height"
        />
    </div>
    <div v-if="showBoundings" class="pointer-events-none z-50">
        <div
            id="stageVisibleCenter"
            :style="{
                border: 'red solid 1px',
                color: 'red',
                borderRadius: '100%',
                position: 'absolute',
                top: `${stageVisibleCenterY - 5}px`,
                left: `${stageVisibleCenterX - 5}px`,
                width: '10px',
                height: '10px',
            }"
        >
            stageVisibleCenter<br />
            {{ stageVisibleCenterX }}x{{ stageVisibleCenterY }}
        </div>
        <div
            id="stageCenter"
            :style="{
                border: 'green solid 1px',
                color: 'green',
                borderRadius: '100%',
                position: 'absolute',
                top: `${stageCenterY - 5}px`,
                left: `${stageCenterX - 5}px`,
                width: '10px',
                height: '10px',
            }"
        >
            stageCenter<br />
            {{ stageCenterX }}x{{ stageCenterY }}
        </div>

        <div
            id="appWrapperPreview"
            :style="{
                border: 'blue solid 2px',
                color: 'blue',
                position: 'fixed',
                top: `${app.top}px`,
                left: `${app.left}px`,
                width: `${app.width}px`,
                height: `${app.height}px`,
            }"
            class="pointer-events-none p-3"
        >
            appWrapperPreview<br />
            left: {{ app.left }}<br />
            top: {{ app.top }}<br />
            width: {{ app.width }}<br />
            height: {{ app.height }}
        </div>
        <div
            id="visibleStageAreaPreview"
            :style="{
                border: 'red solid 2px',
                color: 'red',
                position: 'fixed',
                top: `${visibleStageArea.top}px`,
                left: `${visibleStageArea.left}px`,
                width: `${visibleStageArea.width}px`,
                height: `${visibleStageArea.height}px`,
            }"
            class="pointer-events-none p-3"
        >
            visibleStageAreaPreview<br />
            left: {{ visibleStageArea.left }}<br />
            top: {{ visibleStageArea.top }}<br />
            width: {{ visibleStageArea.width }}<br />
            height: {{ visibleStageArea.height }}
        </div>
        <div
            id="stageContentPreview"
            :style="{
                border: 'green solid 2px',
                color: 'green',
                position: 'fixed',
                top: `${stageContent.top}px`,
                left: `${stageContent.left}px`,
                width: `${stageContent.width}px`,
                height: `${stageContent.height}px`,
            }"
            class="pointer-events-none p-3"
        >
            stageContentPreview<br />
            left: {{ stageContent.left }}<br />
            top: {{ stageContent.top }}<br />
            width: {{ stageContent.width }}<br />
            height: {{ stageContent.height }}
        </div>
    </div>
</template>

<script setup lang="ts">
import { useDeleteCustomization } from "@/components/modules/customizations/composables/useDeleteCustomization";
import useFabricControls from "@/composables/fabricControls/useFabricControls";
import usePositioningAreaFunctions from "@/composables/positioningAreas/usePositioningAreaFunctions";
import useImage from "@/composables/useImage";
import useRenderCustomizationPreview from "@/composables/useRenderCustomizationPreview";
import { PanelState, isCustomizationEditPanelState } from "@/enums/PanelState";
import { Style } from "@/enums/Style";
import { isAddMode } from "@/enums/mode";
import Customization from "@/repo/Customization";
import Notification from "@/repo/Notification";
import type PositioningArea from "@/repo/PositioningArea";
import { useBoundingStore } from "@/store/useBoundingStore";
import { useCanvasStore } from "@/store/useCanvasStore";
import { useCurrentStore } from "@/store/useCurrentStore";
import { useMainStore } from "@/store/useMainStore";
import { usePanelStore } from "@/store/usePanelStore";
import { useProductionMethodStore } from "@/store/useProductionMethodStore";
import { useSettingsStore } from "@/store/useSettingsStore";
import type { DesignCanvasObject } from "@/types/DesignCanvasObject";
import type { DesignImage, DesignImageBackground } from "@/types/DesignImage";
import type { PositioningAreaCanvasObject } from "@/types/PositioningAreaCanvasObject";
import { fabric } from "fabric";
import type { IEvent } from "fabric/fabric-impl";
import { FastAverageColor } from "fast-average-color";
import { differenceBy, sumBy } from "lodash";
import { storeToRefs } from "pinia";
import { useRepo } from "pinia-orm";
import { computed, nextTick, onMounted, ref, watch, watchEffect } from "vue";

type ZoomPoint = {
    zoom: number;
    x: number;
    y: number;
};

const currentStore = useCurrentStore();
const { loadFabricControls } = useFabricControls();

const {
    currentView,
    currentMode,
    currentCustomization,
    isWaitingForRenderProcess,
    isCurrentCustomizationMoving,
    currentCustomizationHasEmptyResourceLines,
} = storeToRefs(currentStore);

const { currentProductionMethod } = storeToRefs(useProductionMethodStore());

const { resetCurrentCustomization } = currentStore;
const settingsStore = useSettingsStore();
const { customization_positioning_mode } = storeToRefs(settingsStore);
const canvasStore = useCanvasStore();
const { zoomStageToObject, zoomStageTo, setViewportTransformToZoomPoint } =
    canvasStore;
const {
    positioningAreaCanvasObjects,
    designCanvasObjects,
    setNewTextCanvasObjectsAsActive,
    currentZoom,
    zoomData,
    changeCurrentZoom,
    canvasWrapper,
} = storeToRefs(canvasStore);

const positioningAreaFunctions = usePositioningAreaFunctions();
const { changeStrokeAndFillColorByMode, showOrHideCanvasObject } =
    positioningAreaFunctions;
const mainStore = useMainStore();
const { showAreas } = storeToRefs(mainStore);
const { isARenderingRequestInProgress } = useRenderCustomizationPreview();
const { stageContent, app, visibleStageArea } = storeToRefs(useBoundingStore());
const { currentPanelState, isPanelOpen } = storeToRefs(usePanelStore());
const { deleteCurrentCustomizationAndGoToDefaultMode } =
    useDeleteCustomization();
const { currentProductionMethodId } = storeToRefs(useProductionMethodStore());
const customizationRepo = useRepo(Customization);

// data
const canvasDom = ref<HTMLCanvasElement | null>(null);

const backgroundImageScale = ref<number>(0.5);
const stageCalculationWidth = ref<number>(520);
const stageCalculationHeight = ref<number>(580);
const isDragging = ref<boolean>(false);
const currentVisibleStageChangeRequest = ref<number>(0);
const lastPosX = ref<number>(0);
const lastPosY = ref<number>(0);
const oldLastPosX = ref<number>(0);
const oldLastPosY = ref<number>(0);

const currentViewImageUrl = computed(() => currentView.value.image_urls.xl);
const { data: currentViewImage } = useImage(
    "current-view-image",
    currentViewImageUrl,
);

const backgroundImage = computed(() => {
    if (!currentViewImage.value) return;

    const fabricImage = new fabric.Image(
        currentViewImage.value,
    ) as DesignImageBackground;

    fabricImage.set({
        originX: "left",
        originY: "top",
        scaleX: backgroundImageScale.value,
        scaleY: backgroundImageScale.value,
        selectable: false,
        evented: true,
        hoverCursor: "default",
        id: 0,
        smake_type: "background",
    });

    return fabricImage;
});

const showBoundings = false;
// computed
const stageVisibleCenterX = computed<number>(() => {
    return Math.round(
        visibleStageArea.value.left +
            visibleStageArea.value.width / 2 -
            app.value.left,
    );
});

const stageVisibleCenterY = computed<number>(() => {
    return Math.round(
        visibleStageArea.value.top +
            visibleStageArea.value.height / 2 -
            app.value.top,
    );
});

const stageCenterX = computed<number>(() => {
    return Math.round(
        stageContent.value.left + stageContent.value.width / 2 - app.value.left,
    );
});

const stageCenterY = computed<number>(() => {
    return Math.round(
        stageContent.value.top + stageContent.value.height / 2 - app.value.top,
    );
});

const stageWidth = computed<number>(() => {
    if (canvasWrapper.value === undefined) {
        return 0;
    }

    return Math.round(
        stageCalculationWidth.value * canvasWrapper.value?.getZoom(),
    );
});

const stageHeight = computed<number>(() => {
    if (canvasWrapper.value === undefined) {
        return 0;
    }

    return Math.round(
        stageCalculationHeight.value * canvasWrapper.value?.getZoom(),
    );
});

const stageMaxOffsetX = computed<number>(() => {
    if (canvasWrapper.value?.width === undefined) {
        return 0;
    }

    const maxOffset =
        (canvasWrapper.value.width - stageWidth.value) / 2 -
        visibleStageArea.value.left;

    return maxOffset > 0 ? 0 : Math.abs(maxOffset);
});

const stageMaxOffsetY = computed<number>(() => {
    if (canvasWrapper.value?.height === undefined) {
        return 0;
    }

    const maxOffset =
        (canvasWrapper.value.height - stageHeight.value) / 2 -
        visibleStageArea.value.top;

    return maxOffset > 0 ? 0 : Math.abs(maxOffset);
});

const absoluteDraggingValueX = computed<number>(() => {
    if (
        canvasWrapper.value?.viewportTransform === undefined ||
        backgroundImage.value === undefined
    ) {
        return 0;
    }

    return (
        canvasWrapper.value?.viewportTransform[4] -
        getVisibleCenter(backgroundImage.value, currentZoom.value).x
    );
});

const absoluteDraggingValueY = computed<number>(() => {
    if (
        canvasWrapper.value?.viewportTransform === undefined ||
        backgroundImage.value === undefined
    ) {
        return 0;
    }

    return (
        canvasWrapper.value?.viewportTransform[5] -
        getVisibleCenter(backgroundImage.value, currentZoom.value).y
    );
});

// watch

watchEffect(() => {
    designCanvasObjects.value.forEach((designCanvasObjects) => {
        const customization = customizationRepo.find(
            designCanvasObjects.customization_id,
        );

        if (!customization?.positioning_area) {
            return;
        }

        const isSelectable =
            currentProductionMethodId.value ===
            customization.positioning_area.production_method_id;

        designCanvasObjects.image.selectable = isSelectable;
        designCanvasObjects.image.evented = isSelectable;
    });
});

watch(
    [currentView, backgroundImage],
    ([newView, newBackgroundImage], [oldView, oldBackgroundImage]) => {
        if (
            oldView.handle === newView.handle &&
            oldView.variant_id === newView.variant_id &&
            newBackgroundImage === oldBackgroundImage
        ) {
            return;
        }

        canvasWrapper.value?.clear();
        addBackgroundImageToCanvas();

        if (
            !isCustomizationEditPanelState(currentPanelState.value) &&
            currentPanelState.value !== PanelState.None
        ) {
            return;
        }

        zoomStageToMinAndCenter();
    },
);

watch(
    () => app.value,
    () => {
        canvasWrapper.value?.setDimensions({
            width: app.value.width,
            height: app.value.height,
        });
        canvasWrapper.value?.renderAll();
    },
);

watch(
    () => visibleStageArea.value,
    () => {
        const visibleStageChangeRequest = Date.now();
        currentVisibleStageChangeRequest.value = visibleStageChangeRequest;

        setTimeout(() => {
            if (
                currentVisibleStageChangeRequest.value !==
                    visibleStageChangeRequest ||
                (!isCustomizationEditPanelState(currentPanelState.value) &&
                    currentPanelState.value !== PanelState.None)
            ) {
                return;
            }

            setZoomData();

            if (!canvasWrapper.value) {
                return;
            }

            const activeObject: fabric.Object | null =
                canvasWrapper.value?.getActiveObject();

            if (!activeObject && currentCustomization.value === null) {
                zoomStageToMinAndCenter();

                return;
            }

            if (isCurrentCustomizationMoving.value) {
                zoomStageToMinAndCenter();

                return;
            }

            const positioningAreaId =
                currentCustomization.value?.positioningArea_id;

            const positioningAreaCanvasObject =
                positioningAreaCanvasObjects.value.find(
                    (
                        positioningAreaCanvasObject: PositioningAreaCanvasObject,
                    ) => {
                        return (
                            positioningAreaCanvasObject.id === positioningAreaId
                        );
                    },
                );

            if (!positioningAreaCanvasObject) {
                zoomStageToMinAndCenter();

                return;
            }

            zoomStageToObject(positioningAreaCanvasObject.canvas_object);
        }, 350);
    },
);

watch(
    () => [...designCanvasObjects.value],
    (newValue, oldValue) => {
        const oldDesignCanvasObjects = differenceBy(
            oldValue,
            newValue,
            "image",
        );
        const newDesignCanvasObjects = differenceBy(
            newValue,
            oldValue,
            "image",
        );

        oldDesignCanvasObjects.forEach(
            (designCanvasObject: DesignCanvasObject) => {
                canvasWrapper.value?._objects.forEach(
                    (canvasObject: fabric.Object) => {
                        if (
                            (canvasObject as DesignImage).id ===
                                designCanvasObject.image.id &&
                            (canvasObject as DesignImage).smake_type === "text"
                        ) {
                            canvasWrapper.value?.remove(canvasObject);
                        }
                    },
                );
            },
        );

        newDesignCanvasObjects.forEach(
            (designCanvasObject: DesignCanvasObject) => {
                if (
                    designCanvasObject.view_handle !== currentView.value.handle
                ) {
                    return;
                }

                const designCanvasObjectOnCanvas =
                    canvasWrapper.value?._objects.find(
                        (canvasObject: fabric.Object) =>
                            (canvasObject as DesignImage).smake_type ===
                                "text" &&
                            (canvasObject as DesignImage).id ===
                                designCanvasObject.image.id,
                    );

                if (designCanvasObjectOnCanvas) {
                    isWaitingForRenderProcess.value = false;

                    return;
                }

                canvasWrapper.value?.add(designCanvasObject.image);

                const positioningAreaCanvasObject =
                    positioningAreaCanvasObjects.value.find(
                        (positioningAreaCanvasObject) => {
                            return (
                                positioningAreaCanvasObject.id ===
                                designCanvasObject.positioningArea_id
                            );
                        },
                    );

                if (positioningAreaCanvasObject === undefined) {
                    return;
                }

                positioningAreaFunctions.validatePosition(
                    positioningAreaCanvasObject,
                    designCanvasObject,
                );

                if (setNewTextCanvasObjectsAsActive.value) {
                    if (
                        canvasWrapper.value === undefined ||
                        backgroundImage.value === undefined
                    ) {
                        return;
                    }

                    highlightActiveObject(designCanvasObject);
                }
            },
        );

        isWaitingForRenderProcess.value = false;
    },
);

watch(
    () => changeCurrentZoom.value,
    () => {
        if (
            canvasWrapper.value?.viewportTransform === undefined ||
            backgroundImage.value === undefined ||
            !changeCurrentZoom.value
        ) {
            return;
        }

        changeCurrentZoom.value = false;

        const startVpt = {
            zoom: canvasWrapper.value.getZoom(),
            x: canvasWrapper.value.viewportTransform[4],
            y: canvasWrapper.value.viewportTransform[5],
        };

        const tmpVpt = {
            zoom: zoomData.value.min,
            x: getVisibleCenter(backgroundImage.value, zoomData.value.min).x,
            y: getVisibleCenter(backgroundImage.value, zoomData.value.min).y,
        };

        if (startVpt.zoom < currentZoom.value) {
            tmpVpt.zoom = zoomData.value.max;
            tmpVpt.x = getVisibleCenter(
                backgroundImage.value,
                zoomData.value.max,
            ).x;
            tmpVpt.y = getVisibleCenter(
                backgroundImage.value,
                zoomData.value.max,
            ).y;
        }

        if (canvasWrapper.value?.viewportTransform === undefined) {
            return;
        }

        const zoomPoint = getLinearZoomPointBetweenByNewZoom(
            startVpt,
            tmpVpt,
            currentZoom.value,
        );

        zoomStageTo(zoomPoint);
    },
);

watch(
    () => currentProductionMethod.value.production_type_id,
    () => {
        addBackgroundImageToCanvas();
    },
);

watch(currentMode, () => {
    if (isAddMode(currentMode.value)) {
        designCanvasObjects.value.forEach((designCanvasObject) => {
            designCanvasObject.image.set("selectable", false);
            designCanvasObject.image.set("evented", false);
        });

        canvasWrapper.value?.requestRenderAll();
        return;
    }

    designCanvasObjects.value.forEach((designCanvasObject) => {
        designCanvasObject.image.set("selectable", true);
        designCanvasObject.image.set("evented", true);
    });

    canvasWrapper.value?.requestRenderAll();
});

watchEffect(() => {
    if (isDragging.value) {
        return;
    }

    positioningAreaCanvasObjects.value.forEach(
        (positioningAreaCanvasObject: PositioningAreaCanvasObject) => {
            changeStrokeAndFillColorByMode(
                positioningAreaCanvasObject,
                currentMode.value,
            );

            const isClickable =
                showAreas.value &&
                positioningAreaCanvasObject.has_customization === false &&
                isPanelOpen.value === false;

            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
            positioningAreaCanvasObject.canvas_object.set(
                "evented",
                isClickable,
            );
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
            positioningAreaCanvasObject.canvas_object.set(
                "hoverCursor",
                isClickable ? "pointer" : "default",
            );

            showOrHideCanvasObject(positioningAreaCanvasObject);

            canvasWrapper.value?.requestRenderAll();
        },
    );
});

onMounted(async () => {
    await nextTick();

    mountCanvas();
    loadFabricControls();
    addBackgroundImageToCanvas();

    setZoomData();

    canvasWrapper.value?.setZoom(zoomData.value.min);

    if (canvasWrapper.value === undefined) {
        throw Error("could not set canvas");
    }
});

// methods
function mountCanvas() {
    canvasWrapper.value = new fabric.Canvas(canvasDom.value);
    canvasWrapper.value.selection = false;

    addEventListenerToCanvas();
}

function setZoomData() {
    zoomData.value = {
        min: Math.min(
            stageContent.value.width / stageCalculationWidth.value,
            stageContent.value.height / stageCalculationHeight.value,
        ),
        max: Math.max(4),
    };
}

function zoomStageToMinAndCenter() {
    if (backgroundImage.value === undefined) {
        return;
    }

    const endVpt = {
        zoom: zoomData.value.min,
        x: getVisibleCenter(backgroundImage.value, zoomData.value.min).x,
        y: getVisibleCenter(backgroundImage.value, zoomData.value.min).y,
    };

    zoomStageTo(endVpt);
}

function getLinearZoomPointBetweenByNewZoom(
    startPoint: ZoomPoint,
    endPoint: ZoomPoint,
    newZoom: number,
): ZoomPoint {
    const percentage =
        startPoint.zoom === endPoint.zoom
            ? 1
            : (startPoint.zoom - newZoom) / (startPoint.zoom - endPoint.zoom);

    const newX = startPoint.x + (endPoint.x - startPoint.x) * percentage;
    const newY = startPoint.y + (endPoint.y - startPoint.y) * percentage;

    return {
        x: newX,
        y: newY,
        zoom: newZoom,
    };
}

function addEventListenerToCanvas() {
    canvasWrapper.value?.on("selection:cleared", (event: IEvent) => {
        if (!event.deselected) {
            return;
        }

        if (!allowedToLeave() || isDragging.value) {
            canvasWrapper.value?.setActiveObject(event.deselected[0]);

            return;
        }
    });

    canvasWrapper.value?.on("mouse:down", (event) => {
        if (!canvasWrapper.value) {
            return;
        }

        isDragging.value = true;

        if (isTouchEvent(event.e)) {
            lastPosX.value = event.e.touches[0].clientX;
            lastPosY.value = event.e.touches[0].clientY;
            oldLastPosX.value = event.e.touches[0].clientX;
            oldLastPosY.value = event.e.touches[0].clientY;

            return;
        }

        lastPosX.value = event.e.clientX;
        lastPosY.value = event.e.clientY;
        oldLastPosX.value = event.e.clientX;
        oldLastPosY.value = event.e.clientY;
    });

    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    canvasWrapper.value?.on("remove", async () => {
        await deleteCurrentCustomizationAndGoToDefaultMode();
    });

    canvasWrapper.value?.on(
        "mouse:move",
        (event: fabric.IEvent<MouseEvent | TouchEvent>) => {
            if (
                isDragging.value &&
                (event.target === null || event.target?.selectable === false)
            ) {
                canvasWrapper.value?.setCursor("grabbing");
                const vpt = canvasWrapper.value?.viewportTransform;

                if (vpt === undefined || backgroundImage.value === undefined) {
                    return;
                }

                if (isTouchEvent(event.e)) {
                    if (event.e.touches.length > 1) {
                        zoom(event as IEvent<TouchEvent>);

                        return;
                    }
                }

                let offsetX = (event.e as MouseEvent).clientX - lastPosX.value;
                let offsetY = (event.e as MouseEvent).clientY - lastPosY.value;

                if (isTouchEvent(event.e)) {
                    offsetX = event.e.touches[0].clientX - lastPosX.value;
                    offsetY = event.e.touches[0].clientY - lastPosY.value;
                }

                if (
                    Math.abs(absoluteDraggingValueX.value + offsetX) >
                    stageMaxOffsetX.value
                ) {
                    offsetX = 0;
                }

                if (
                    Math.abs(absoluteDraggingValueY.value + offsetY) >
                    stageMaxOffsetY.value
                ) {
                    offsetY = 0;
                }

                canvasWrapper.value?.setViewportTransform([
                    vpt[0],
                    0,
                    0,
                    vpt[3],
                    vpt[4] + offsetX,
                    vpt[5] + offsetY,
                ]);

                if (isTouchEvent(event.e)) {
                    lastPosX.value = event.e.touches[0].clientX;
                    lastPosY.value = event.e.touches[0].clientY;

                    return;
                }

                lastPosX.value = event.e.clientX;
                lastPosY.value = event.e.clientY;
            }
        },
    );

    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    canvasWrapper.value?.on("mouse:up", async (event: fabric.IEvent) => {
        isDragging.value = false;

        if (!event.target) {
            return;
        }

        if (!("smake_type" in event.target)) {
            return;
        }

        if (event.target.smake_type !== "background") {
            return;
        }

        if (!allowedToLeave()) {
            return;
        }

        if (oldLastPosX.value !== lastPosX.value) {
            return;
        }

        if (oldLastPosY.value === lastPosY.value) {
            return;
        }

        if (currentCustomization.value) {
            positioningAreaFunctions.resolveNotificationsForPositioningArea(
                currentCustomization.value.positioningArea_id,
            );
        }

        await resetCurrentCustomization();
        useCanvasStore().showOrHidePositioningAreaCanvasObjects();
    });

    canvasWrapper.value?.on(
        "mouse:wheel",
        (event: fabric.IEvent<WheelEvent | TouchEvent>) => {
            event.e.preventDefault();

            zoom(event);
        },
    );
}

function zoom(event: fabric.IEvent<WheelEvent | TouchEvent>) {
    if (!canvasWrapper.value) {
        return;
    }

    const delta = isTouchEvent(event.e)
        ? event.e.touches[0].clientX - lastPosX.value
        : event.e.deltaY;
    const zoom = ref(canvasWrapper.value.getZoom());

    zoom.value *= 0.999 ** delta;

    if (zoom.value > zoomData.value.max) {
        zoom.value = zoomData.value.max;
    }

    if (zoom.value < zoomData.value.min) {
        zoom.value = zoomData.value.min;

        moveCanvasWrapperToVisibleCenter();
    }

    if (
        (event.target === null &&
            canvasWrapper.value?.viewportTransform !== undefined &&
            backgroundImage.value !== undefined) ||
        (isTouchEvent(event.e) &&
            canvasWrapper.value?.viewportTransform !== undefined &&
            backgroundImage.value !== undefined)
    ) {
        const endPointZoom =
            currentZoom.value > zoom.value
                ? zoomData.value.min
                : zoomData.value.max;

        const startVpt = {
            zoom: canvasWrapper.value.getZoom(),
            x: canvasWrapper.value.viewportTransform[4],
            y: canvasWrapper.value.viewportTransform[5],
        };

        const endVpt = {
            zoom: endPointZoom,
            x: getVisibleCenter(backgroundImage.value, endPointZoom).x,
            y: getVisibleCenter(backgroundImage.value, endPointZoom).y,
        };

        if (canvasWrapper.value?.viewportTransform === undefined) {
            return;
        }

        const zoomPoint = getLinearZoomPointBetweenByNewZoom(
            startVpt,
            endVpt,
            zoom.value,
        );

        setViewportTransformToZoomPoint(zoomPoint);

        currentZoom.value = zoom.value;

        return;
    }

    if (
        zoom.value < currentZoom.value &&
        canvasWrapper.value?.viewportTransform !== undefined &&
        backgroundImage.value !== undefined
    ) {
        const startVpt = {
            zoom: canvasWrapper.value.getZoom(),
            x: canvasWrapper.value.viewportTransform[4],
            y: canvasWrapper.value.viewportTransform[5],
        };

        const endVpt = {
            zoom: zoomData.value.min,
            x: getVisibleCenter(backgroundImage.value, zoomData.value.min).x,
            y: getVisibleCenter(backgroundImage.value, zoomData.value.min).y,
        };

        if (canvasWrapper.value?.viewportTransform === undefined) {
            return;
        }

        const zoomPoint = getLinearZoomPointBetweenByNewZoom(
            startVpt,
            endVpt,
            zoom.value,
        );

        setViewportTransformToZoomPoint(zoomPoint);

        currentZoom.value = zoom.value;

        return;
    }

    const zoomPoint = isTouchEvent(event.e)
        ? (event as IEvent<TouchEvent>).absolutePointer
        : { x: event.e.offsetX, y: event.e.offsetY };

    if (zoomPoint === undefined) {
        console.error("Cant calculate zoom point");

        return;
    }

    canvasWrapper.value.zoomToPoint(
        { x: zoomPoint.x, y: zoomPoint.y },
        zoom.value,
    );
    currentZoom.value = zoom.value;
    event.e.preventDefault();
    event.e.stopPropagation();
}

function addBackgroundImageToCanvas() {
    if (!backgroundImage.value) return;
    canvasWrapper.value?.add(backgroundImage.value);
    moveCanvasWrapperToVisibleCenter();
    addPositioningAreasToCanvas();
    addDesignCanvasObjectsToCanvas();
    afterBackgroundImageIsSet();
}

function moveCanvasWrapperToVisibleCenter() {
    if (!canvasWrapper.value) {
        return;
    }

    if (!backgroundImage.value) {
        return;
    }

    if (
        !isCustomizationEditPanelState(currentPanelState.value) &&
        currentPanelState.value !== PanelState.None
    ) {
        return;
    }

    const vpt = canvasWrapper.value.viewportTransform;

    if (vpt === undefined) {
        return;
    }

    const visibleCenter = getVisibleCenter(
        backgroundImage.value,
        zoomData.value.min,
    );

    canvasWrapper.value?.setViewportTransform([
        vpt[0],
        0,
        0,
        vpt[3],
        visibleCenter.x,
        visibleCenter.y,
    ]);
}

function getVisibleCenter(image: fabric.Image, zoom: number) {
    if (!canvasWrapper.value) {
        return {
            x: 0,
            y: 0,
        };
    }

    return {
        x: stageVisibleCenterX.value - (image.getScaledWidth() / 2) * zoom,
        y: stageVisibleCenterY.value - (image.getScaledHeight() / 2) * zoom,
    };
}

function afterBackgroundImageIsSet() {
    zoomStageToMinAndCenter();
    addPositioningAreasToCanvas();
    addDesignCanvasObjectsToCanvas();

    canvasWrapper.value?.discardActiveObject();
    canvasWrapper.value?.requestRenderAll();

    if (customization_positioning_mode.value === "production") {
        return;
    }

    const fac = new FastAverageColor();
    let currentViewBrightness = Style.dark;

    try {
        if (!currentViewImage.value) return;

        const color = fac.getColor(currentViewImage.value, {
            ignoredColor: [0, 0, 0, 0],
            mode: "speed",
            algorithm: "simple",
        });
        currentViewBrightness =
            (color.value[0] * 299 +
                color.value[1] * 587 +
                color.value[2] * 114) /
                1000 >
            130
                ? Style.dark
                : Style.light;

        for (const positioningArea of currentView.value.positioning_areas) {
            if (
                positioningArea.production_method_id !==
                currentProductionMethod.value.id
            ) {
                continue;
            }

            const checkingAreaSquareSize =
                getCheckingAreaSquareSize(positioningArea);
            const positioningAreaCanvasObject =
                positioningAreaCanvasObjects.value.find(
                    (positioningAreaCanvasObject) => {
                        return (
                            positioningAreaCanvasObject.id ===
                            positioningArea.id
                        );
                    },
                );

            const left =
                getCenterXByModel(positioningArea) /
                    backgroundImageScale.value -
                checkingAreaSquareSize / 2;
            const top =
                getCenterYByModel(positioningArea) /
                    backgroundImageScale.value -
                checkingAreaSquareSize / 2;

            const areaColor = fac.getColor(currentViewImage.value, {
                ignoredColor: [0, 0, 0, 0],
                mode: "speed",
                algorithm: "simple",
                left: left,
                top: top,
                width: checkingAreaSquareSize,
                height: checkingAreaSquareSize,
            });

            if (positioningAreaCanvasObject === undefined) {
                continue;
            }

            positioningAreaFunctions.setStyleForColor(
                areaColor,
                positioningAreaCanvasObject,
                currentViewBrightness,
            );
        }
    } catch (e) {
        console.error(e);
    }

    fac.destroy();

    return;
}

function getCheckingAreaSquareSize(positioningArea: PositioningArea) {
    const positioningAreaWidth =
        positioningArea.localized_width / backgroundImageScale.value;
    const positioningAreaHeight =
        positioningArea.localized_height / backgroundImageScale.value;
    const radiant = (positioningArea.rotation * Math.PI) / 180;
    const relativeBoundingHeight =
        Math.abs(Math.cos(radiant)) + Math.abs(Math.sin(radiant));
    const relativeBoundingWidth =
        Math.abs(Math.cos(radiant)) + Math.abs(Math.sin(radiant));

    return relativeBoundingHeight / positioningAreaHeight <
        relativeBoundingWidth / positioningAreaWidth
        ? 1 / (relativeBoundingWidth / positioningAreaWidth)
        : 1 / (relativeBoundingHeight / positioningAreaHeight);
}

function getCenterYByModel(positioningArea: PositioningArea) {
    const positioningAreaCanvasObject = positioningAreaCanvasObjects.value.find(
        (positioningAreaCanvasObject) => {
            return positioningAreaCanvasObject.id === positioningArea.id;
        },
    );

    if (positioningAreaCanvasObject === undefined) {
        return 0;
    }

    if (positioningAreaCanvasObject.canvas_object.aCoords === undefined) {
        return 0;
    }

    const coords = Object.values(
        positioningAreaCanvasObject.canvas_object.aCoords,
    );

    return sumBy(coords, "y") / coords.length;
}

function getCenterXByModel(positioningArea: PositioningArea) {
    const positioningAreaCanvasObject = positioningAreaCanvasObjects.value.find(
        (positioningAreaCanvasObject) => {
            return positioningAreaCanvasObject.id === positioningArea.id;
        },
    );

    if (positioningAreaCanvasObject === undefined) {
        return 0;
    }

    if (positioningAreaCanvasObject.canvas_object.aCoords === undefined) {
        return 0;
    }

    const coords = Object.values(
        positioningAreaCanvasObject.canvas_object.aCoords,
    );

    return sumBy(coords, "x") / coords.length;
}

function addPositioningAreasToCanvas() {
    positioningAreaCanvasObjects.value.forEach(
        (positioningAreaCanvasObject: PositioningAreaCanvasObject) => {
            if (
                currentView.value.positioning_area_ids.includes(
                    positioningAreaCanvasObject.id,
                ) &&
                positioningAreaCanvasObject.production_method_id ===
                    currentProductionMethod.value.id
            ) {
                const opacity = showAreas.value ? 1 : 0;
                positioningAreaCanvasObject.canvas_object.set(
                    "opacity",
                    opacity,
                );
                canvasWrapper.value?.add(
                    positioningAreaCanvasObject.canvas_object,
                );
            }
        },
    );
}

function addDesignCanvasObjectsToCanvas() {
    designCanvasObjects.value.forEach(
        (designCanvasObject: DesignCanvasObject) => {
            if (
                currentView.value.customization_ids.includes(
                    designCanvasObject.customization_id,
                )
            ) {
                canvasWrapper.value?.add(designCanvasObject.image);
            }
        },
    );
}

function allowedToLeave() {
    return (
        !isARenderingRequestInProgress.value &&
        useRepo(Notification).where("type", "error").get().length === 0 &&
        !currentCustomizationHasEmptyResourceLines.value
    );
}

function isTouchEvent(event: unknown): event is TouchEvent {
    return "touches" in (event as TouchEvent);
}

function highlightActiveObject(designCanvasObject: DesignCanvasObject) {
    canvasWrapper.value?.setActiveObject(designCanvasObject.image);
}
</script>

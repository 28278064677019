<template>
    <base-dialog
        v-for="dialog in dialogs"
        :key="objectId(dialog)"
        v-model:is-open="dialog.isOpen"
        @after-closing="() => onAfterClosing(dialog)"
    >
        <component
            :is="dialog.component"
            v-bind="dialog.input"
            @resolve="(output) => onResolve(dialog, output)"
        />
    </base-dialog>
</template>

<script setup lang="ts">
import { objectId } from "@/lib/object";
import { useDialogStore, type UnknownDialog } from "@/store/useDialogStore";
import { storeToRefs } from "pinia";

const { dialogs } = storeToRefs(useDialogStore());

function onAfterClosing(dialog: UnknownDialog) {
    dialog.resolve();
    dialogs.value = dialogs.value.filter((d) => d !== dialog);
}

function onResolve(dialog: UnknownDialog, output: unknown) {
    dialog.resolve(output);
    dialog.isOpen = false;
}
</script>

import { type NotificationType } from "@/enums/NotificationType";
import { Model } from "pinia-orm";
import { Attr, Uid } from "pinia-orm/dist/decorators";

export default class Notification extends Model {
    static entity = "notifications";

    @Uid()
    id!: number;

    @Attr()
    type!: NotificationType;

    @Attr()
    message!: string;
}

<script setup lang="ts">
import { useColorConvertorHSV } from "@/composables/colorPicker/useColorConverterHSV";
import type { HSVColor } from "@/utils/colorConversion";
import NumberInputSanitized from "../../NumberInputSanitized.vue";

const color = defineModel<HSVColor>({ required: true });
const { colorRGB } = useColorConvertorHSV(color);
</script>

<template>
    <div class="flex w-full gap-2">
        <number-input-sanitized
            v-for="(_value, key) in colorRGB"
            :key="key"
            class="h-full w-12 rounded border border-secondary-2 px-2 focus:border-secondary-1"
            :model-value="colorRGB[key]"
            :min="0"
            :max="255"
            :step="1"
            @update:model-value="
                (val) => (colorRGB = { ...colorRGB, [key]: val })
            "
        >
        </number-input-sanitized>
    </div>
</template>

import { inject, provide, type ModelRef } from "vue";

const dialogSymbol = Symbol("dialogData");

type ProvidedData = {
    isOpen: ModelRef<boolean>;
};

export function useProvideDialogData(data: ProvidedData) {
    provide(dialogSymbol, data);
}

export function useInjectDialogData() {
    const data = inject<ProvidedData>(dialogSymbol);

    if (data === undefined) {
        throw new Error(
            "<BaseDialogPanel /> is missing a parent <BaseDialog /> component.",
        );
    }

    return data;
}

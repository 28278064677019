export enum PanelState {
    None,
    TextEdit,
    ProductColors,
    Archive,
    PdfDownload,
    CustomizationEdit,
}

export type PanelShowState = Exclude<PanelState, PanelState.None>;

export function isCustomizationEditPanelState(state: PanelState): boolean {
    return (
        state === PanelState.TextEdit || state === PanelState.CustomizationEdit
    );
}

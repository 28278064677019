import { ModuleName } from "@/enums/ModuleName";
import { type ModuleConfigEntry } from "@/types/ModuleConfigEntry";

export const modulesConfig: ModuleConfigEntry[] = [
    {
        name: ModuleName.VIEWS,
        button: "ViewsButton",
        screens: ["mobile", "tablet", "desktop"],
    },
    {
        name: ModuleName.PDF_DOWNLOAD,
        button: "PdfDownloadButton",
        modal: "PdfDownloadModal",
        screens: ["mobile", "tablet", "desktop"],
    },
    {
        name: ModuleName.ARCHIVE,
        button: "ArchiveButton",
        modal: "ArchiveModal",
        screens: ["mobile", "tablet", "desktop"],
    },
    {
        name: ModuleName.LOGOS,
        button: "LogosButton",
        screens: ["mobile", "tablet", "desktop"],
    },
    {
        name: ModuleName.PRODUCT_ZOOM,
        button: "ProductZoomButton",
        screens: ["mobile", "tablet", "desktop"],
    },
    {
        name: ModuleName.PRODUCT_COLORS,
        button: "ProductColorsButton",
        modal: "ProductColorsModal",
        screens: ["mobile", "tablet", "desktop"],
    },
    {
        name: ModuleName.SHOW_AREAS,
        button: "ShowAreasButton",
        screens: ["mobile", "tablet", "desktop"],
    },
    {
        name: ModuleName.SAVE_VARIANT,
        button: "SaveVariantButton",
        screens: ["mobile", "tablet", "desktop"],
    },
    {
        name: ModuleName.CUSTOMIZATIONS,
        button: "CustomizationsButton",
        editModal: "CustomizationEditModal",
        screens: ["mobile", "tablet", "desktop"],
    },
    {
        name: ModuleName.STORE_VARIANT,
        button: "StoreVariantButton",
        screens: ["mobile", "tablet", "desktop"],
    },
    {
        name: ModuleName.STAGE,
        button: "StageZoomButton",
        modal: "StageZoom",
        screens: ["mobile", "tablet", "desktop"],
    },
    {
        name: ModuleName.MENU,
        button: "MenuButton",
        screens: ["mobile"],
    },
];

import { useDesignerApi } from "@smakecloud/smake-use";
import { useInfiniteQuery } from "@tanstack/vue-query";
import { toRef, type MaybeRefOrGetter } from "vue";

export function useVariantsCollection(
    collectionName: MaybeRefOrGetter<string>,
) {
    const collectionNameRef = toRef(collectionName);
    const designerApi = useDesignerApi();

    return useInfiniteQuery({
        queryKey: [
            "smake-use.designerApi.variants.favorite.list",
            collectionNameRef,
        ],
        queryFn: ({ pageParam }) =>
            designerApi.endpoints.variants.favorite.list({
                filter: { collection_name: collectionNameRef.value },
                cursor: pageParam,
            }),
        select: (data) => data.pages.flatMap((page) => page.data),
        initialPageParam: undefined as string | undefined,
        getNextPageParam: (lastPage) => lastPage.meta.next_cursor,
        staleTime: Infinity,
    });
}

import { library } from "@fortawesome/fontawesome-svg-core";
import {
    faAlignCenter,
    faAlignLeft,
    faAlignRight,
    faArchive,
    faBringFront,
    faCartArrowDown,
    faCheck,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faChevronUp,
    faCircleExclamation,
    faCircleXmark,
    faCloudUpload,
    faEllipsisVertical,
    faExclamationTriangle,
    faExpandAlt,
    faFilePdf,
    faImage,
    faImages,
    faInfoCircle,
    faKeyboard,
    faMagnifyingGlass,
    faMinus,
    faPalette,
    faPencilAlt,
    faPlus,
    faPlusCircle,
    faRedo,
    faRotateRight,
    faSave,
    faSliders,
    faSpinner,
    faText,
    faTimes,
    faTrashAlt,
    faUpRightAndDownLeftFromCenter,
    faLink,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import type { Plugin } from "vue";

export const FontAwesomePlugin: Plugin = {
    install: (app) => {
        library.add(
            faAlignCenter,
            faAlignLeft,
            faAlignRight,
            faArchive,
            faBringFront,
            faCartArrowDown,
            faCheck,
            faChevronDown,
            faChevronLeft,
            faChevronRight,
            faChevronUp,
            faCircleExclamation,
            faCircleXmark,
            faCloudUpload,
            faEllipsisVertical,
            faExclamationTriangle,
            faExpandAlt,
            faFilePdf,
            faImage,
            faImages,
            faInfoCircle,
            faKeyboard,
            faMagnifyingGlass,
            faMinus,
            faPalette,
            faPencilAlt,
            faPlus,
            faPlusCircle,
            faRedo,
            faRotateRight,
            faSave,
            faSliders,
            faSpinner,
            faText,
            faTimes,
            faTrashAlt,
            faUpRightAndDownLeftFromCenter,
            faLink,
        );

        app.component("FontAwesomeIcon", FontAwesomeIcon);
    },
};

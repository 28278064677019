<template>
    <div
        class="pointer-events-none absolute flex h-full w-full place-content-between items-center"
    >
        <button
            type="button"
            class="pointer-events-auto"
            data-testid="previous-view-button"
            :class="changeViewButtonStyleConfig"
            :disabled="props.disabled"
            @click="changeCurrentViewToPreviousView"
        >
            <f-a-icon icon="chevron-left" />
        </button>
        <button
            type="button"
            class="pointer-events-auto"
            data-testid="next-view-button"
            :class="changeViewButtonStyleConfig"
            :disabled="disabled"
            @click="changeCurrentViewToNextView()"
        >
            <f-a-icon icon="chevron-right" />
        </button>
    </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useMainStore } from "@/store/useMainStore";
import { storeToRefs } from "pinia";
import { useCurrentStore } from "@/store/useCurrentStore";

const { settings } = storeToRefs(useMainStore());
const currentStore = useCurrentStore();
const { changeCurrentViewToNextView, changeCurrentViewToPreviousView } =
    currentStore;

const props = defineProps({
    disabled: {
        type: Boolean,
        default: false,
    },
});

// computed
const changeViewButtonStyleConfig = computed(() => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
    return settings.value.style.stageViewButtons.button.styles.join(" ");
});
</script>

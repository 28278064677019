import Customization from "@/repo/Customization";
import PositioningArea from "@/repo/PositioningArea";
import { type ImageUrlsData } from "@smakecloud/smake-use";
import { Model } from "pinia-orm";
import { Attr, HasManyBy, Num, OnDelete, Str } from "pinia-orm/dist/decorators";

export default class View extends Model {
    static entity = "views";
    static primaryKey = ["handle", "variant_id"];

    @Str("")
    handle!: string;

    @Num(0)
    variant_id!: number;

    @Str("")
    name!: string;

    @Str("")
    display_name!: string;

    @Attr()
    image_urls!: ImageUrlsData;

    @Num(0)
    scalefactor!: number;

    @Attr([])
    positioning_area_ids!: number[];

    @HasManyBy(() => PositioningArea, "positioning_area_ids")
    positioning_areas!: PositioningArea[];

    @Attr([])
    customization_ids!: number[];

    @HasManyBy(() => Customization, "customization_ids")
    @OnDelete("cascade")
    customizations!: Customization[];
}

export const TextLineConfig = {
    offset: {
        left: {
            min: 0,
            max: 100,
        },
    },
    lineHeightMin: 2,
    lineHeightMax: 100,
};

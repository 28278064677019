export enum FontAwesomeSize {
    xs = "xs",
    sm = "sm",
    lg = "lg",
    fa1x = "1x",
    fa2x = "2x",
    fa3x = "3x",
    fa4x = "4x",
    fa5x = "5x",
    fa6x = "6x",
    fa7x = "7x",
    fa8x = "8x",
    fa9x = "9x",
    fa10x = "10x",
}

import { Style } from "@/enums/Style";
import { colorToColorData } from "@/modules/core/renderer/zskEmbroidery/ConvertZSKDesignToDesign";
import PositioningArea from "@/repo/PositioningArea";
import { useCanvasStore } from "@/store/useCanvasStore";
import { useCurrentStore } from "@/store/useCurrentStore";
import {
    type FontData,
    type TextResourceLineData,
    HorizontalAlignment,
} from "@smakecloud/smake-use";
import { minBy, maxBy } from "lodash";
import { storeToRefs } from "pinia";
import { useRepo } from "pinia-orm";
import { v4 as uuidv4 } from "uuid";
import { useFonts } from "./useFonts";
import { computed, toRef, type MaybeRefOrGetter } from "vue";

export function useTextresourceLineFactory(
    positioningAreaId: MaybeRefOrGetter<number>,
) {
    const canvasStore = useCanvasStore();
    const currentStore = useCurrentStore();
    const { currentAvailableColors } = storeToRefs(currentStore);
    const { positioningAreaCanvasObjects } = storeToRefs(canvasStore);
    const positioningAreaRepo = useRepo(PositioningArea);
    const positioningAreaIdRef = toRef(positioningAreaId);

    const positioningArea = computed(() => {
        const positioningArea = positioningAreaRepo.find(
            positioningAreaIdRef.value,
        );

        if (!positioningArea) {
            throw new Error("Invalid PositioningArea");
        }

        return positioningArea;
    });

    const productionMethodId = computed(
        () => positioningArea.value.production_method_id,
    );

    const { regularFonts } = useFonts(productionMethodId);

    function getNewEmptyResourceLine(): TextResourceLineData {
        const positioningAreaCanvaObject =
            positioningAreaCanvasObjects.value.find(
                (positioningAreaCanvasObject) =>
                    positioningAreaCanvasObject.id ===
                    positioningAreaIdRef.value,
            );

        const style = positioningAreaCanvaObject?.style ?? "light";

        const defaultColor =
            style === Style.dark
                ? minBy(currentAvailableColors.value, (color) => color.contrast)
                : maxBy(
                      currentAvailableColors.value,
                      (color) => color.contrast,
                  );

        if (!regularFonts.value) {
            throw new Error("No font found");
        }

        return {
            uuid: uuidv4(),
            value: "",
            font_size: 10,
            padding_bottom: 2,
            horizontal_alignment: HorizontalAlignment.LEFT,
            bending_radius: 0,
            order_column: 1,
            color_assignments: [
                {
                    apply_to: "fill",
                    color: colorToColorData(
                        defaultColor ?? currentAvailableColors.value[0],
                    ),
                },
            ],
            font: regularFonts.value[0] as FontData,
            offset: 0,
        };
    }

    return {
        getNewEmptyResourceLine,
    };
}

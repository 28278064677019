/* eslint-disable @typescript-eslint/ban-ts-comment */
import { fabric } from "fabric";
import fabricConfig from "@/config/fabric.config";
import iconAsSvg from "./iconAsSvg";
import type { Control } from "fabric/fabric-impl";
import { type FabricControlData } from "@/types/fabric/FabricControlData";

const { getDataUrl } = iconAsSvg();

const iconSize = 24;
const padding = 10;

export default () => {
    function loadFabricControls() {
        fabric.Object.prototype.padding = padding;
        fabric.Object.prototype.lockScalingFlip = true;

        fabricConfig.controls.forEach((control: FabricControlData) => {
            if (!control.isVisible) {
                fabric.Object.prototype.controls[control.name].visible =
                    control.isVisible;

                return;
            }

            setCursor(control);
            setFunction(control);
            setIcon(control, 1);
        });
    }

    function setCursor(control: FabricControlData): void {
        if (!control.cursor) {
            return;
        }

        fabric.Object.prototype.controls[control.name].cursorStyleHandler =
            cursorStyleHandler;
        fabric.Object.prototype.controls[control.name].cursorStyle =
            control.cursor;
    }

    function setFunction(control: FabricControlData): void {
        if (!control.function) {
            return;
        }

        fabric.Object.prototype.controls[control.name].actionHandler = () =>
            false;
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        fabric.Object.prototype.controls[control.name].mouseUpHandler =
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            handlerFunctions[control.function];
    }

    function setIcon(control: FabricControlData, scale: number): void {
        if (!control.icon) {
            return;
        }

        try {
            const icon = new Image();

            // TODO: Hintergrund-Farbe des Icons konfigurierbar machen
            icon.src = getDataUrl(
                control.icon,
                control.color ?? "black",
                "white",
            );

            fabric.Object.prototype.controls[control.name].render = renderIcon(
                icon,
                scale,
            );
        } catch (error) {
            console.error(error);
        }
    }

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handlerFunctions: any = {
        // @ts-ignore
        deleteObject: (
            eventData: MouseEvent,
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            target: { target: { canvas: any } },
        ): boolean => {
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            const canvas = target.target.canvas;

            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
            canvas.fire("remove", target.target);

            return true;
        },
    };

    function renderIcon(icon: HTMLImageElement, scale: number) {
        const scaledIconSize = iconSize * (1 / scale);

        return function renderIcon(
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ctx: any,
            left: number,
            top: number,
            // @ts-ignore
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            styleOverride: any,
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            fabricObject: any,
        ) {
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
            ctx.save();
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
            ctx.translate(left, top);
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument
            ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
            ctx.drawImage(
                icon,
                -scaledIconSize / 2,
                -scaledIconSize / 2,
                scaledIconSize,
                scaledIconSize,
            );
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
            ctx.restore();
        };
    }

    function cursorStyleHandler(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        eventData: MouseEvent,
        control: Control,
        // @ts-ignore
        // eslint-disable-next-line
        fabricObject: any,
    ) {
        return control.cursorStyle;
    }

    return {
        loadFabricControls,
    };
};

<template>
    <div
        class="row-span-2 grid grid-rows-subgrid gap-0 rounded-3xl border border-secondary-2"
    >
        <div class="relative rounded-t-3xl bg-secondary-3 p-4 pb-0">
            <div
                v-if="isLoading"
                class="aspect-square animate-pulse bg-secondary-2"
            />
            <button
                v-if="deletable"
                class="absolute right-1.5 top-1.5 flex aspect-square w-10 items-center justify-center rounded-full bg-white p-2 hover:text-secondary-1"
                type="button"
                @click="emit('delete')"
            >
                <f-a-icon :icon="FontAwesomeKitIcons.Remove" />
            </button>
            <f-a-icon
                v-if="productionMethodIcon"
                class="absolute left-2.5 top-2.5"
                :icon="productionMethodIcon"
            />
            <transition
                appear
                enter-active-class="ease-out duration-300"
                enter-from-class="opacity-0"
                enter-to-class="opacity-100"
                leave-active-class="ease-in duration-200"
                leave-from-class="opacity-100"
                leave-to-class="opacity-0"
            >
                <img
                    v-if="!isLoading"
                    :src="image"
                    alt="logo"
                    class="aspect-square w-full object-contain"
                />
            </transition>
            <div class="flex justify-center pb-2 pt-1">
                <tooltip v-if="externalIdentifier" class="text-sm">
                    #{{ truncate(externalIdentifier, 15) }}
                    <template v-if="externalIdentifier.length > 15" #tooltip>
                        #{{ externalIdentifier }}
                    </template>
                </tooltip>
            </div>
        </div>
        <div class="flex grow flex-col gap-1 px-4 py-3 text-center">
            <tooltip class="mb-1 font-semibold [overflow-wrap:anywhere]">
                {{ truncate(name, 60) }}
                <template v-if="name.length > 60" #tooltip>
                    {{ name }}
                </template>
            </tooltip>
            <div class="flex-grow" />
            <span class="text-xs">
                {{ width.toLocaleString() }} x {{ height.toLocaleString() }}
                {{ unit.length }}
            </span>
            <div class="flex flex-wrap justify-center gap-1">
                <div v-if="showRainbowIcon">
                    <img
                        src="/public/rainbow-dot.png"
                        class="aspect-square w-4"
                        alt=""
                    />
                </div>
                <div
                    v-for="color in colors"
                    :key="objectId(color)"
                    class="aspect-square w-4 rounded-full border"
                    :style="{
                        backgroundColor: `rgb(${color.red},${color.green},${color.blue})`,
                    }"
                    :class="
                        isLightColor([color.red, color.green, color.blue])
                            ? 'border-secondary-2'
                            : 'border-transparent'
                    "
                ></div>
            </div>

            <button
                type="button"
                class="mt-2 flex justify-center rounded-full bg-secondary-3 py-1 hover:bg-secondary-2"
                @click="emit('select')"
            >
                <f-a-icon icon="plus" class="mr-2" />
                {{ t("choose") }}
            </button>
            <button-secondary-outlined
                size="small"
                class="mt-2"
                @click="emit('rename')"
            >
                <f-a-icon :icon="FontAwesomeKitIcons.Edit" class="mr-2" />
                {{ t("rename") }}
            </button-secondary-outlined>
        </div>
    </div>
</template>

<script setup lang="ts">
import { FontAwesomeKitIcons } from "@/enums/fontAwesome/fontAwesomeKitIcons";
import { productionMethodIconMap } from "@/functions/ProductionMethodIcon";
import { isLightColor } from "@/lib/color";
import { objectId } from "@/lib/object";
import { truncate } from "@/lib/truncate";
import { useColorStore } from "@/store/useColorStore";
import { useProductionMethodStore } from "@/store/useProductionMethodStore";
import { useSettingsStore } from "@/store/useSettingsStore";
import type { ApiLogoDesign, ApiTextDesign } from "@smakecloud/designer-core";
import { useImage } from "@vueuse/core";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const props = defineProps<{
    image: string;
    name: string;
    width: number;
    height: number;
    colorAssignments:
        | ApiTextDesign["resource"]["lines"][number]["color_assignments"]
        | ApiLogoDesign["resource"]["color_assignments"];
    externalIdentifier?: string;
    deletable?: boolean;
    productionMethodId?: number;
    showRainbowIcon?: boolean;
}>();

const { isLoading } = useImage({ src: props.image });
const { t } = useI18n();
const { unit } = storeToRefs(useSettingsStore());

const { colorsData } = storeToRefs(useColorStore());

const colors = computed<{ red: number; green: number; blue: number }[]>(() =>
    props.colorAssignments
        .map((colorAssignment) => {
            if ("red" in colorAssignment) {
                return {
                    red: colorAssignment.red,
                    green: colorAssignment.green,
                    blue: colorAssignment.blue,
                };
            }

            const color = colorsData.value?.find(
                (colorData) => colorData.id === colorAssignment.color.id,
            );
            if (!color) {
                return;
            }

            return {
                red: color.red,
                green: color.green,
                blue: color.blue,
            };
        })
        .filter((color) => color !== undefined)
        .reduce<{ red: number; green: number; blue: number }[]>(
            (uniqueColors, color) => {
                if (
                    !uniqueColors.some(
                        (uniqueColor) =>
                            uniqueColor.red === color.red &&
                            uniqueColor.green === color.green &&
                            uniqueColor.blue === color.blue,
                    )
                ) {
                    uniqueColors.push(color);
                }

                return uniqueColors;
            },
            [],
        ),
);

const { currentActiveProductionMethods } = storeToRefs(
    useProductionMethodStore(),
);

const emit = defineEmits<{
    select: [];
    delete: [];
    rename: [];
}>();

const productionMethodIcon = computed(() => {
    const productionMethod = currentActiveProductionMethods.value.find(
        (productionMethod) => productionMethod.id === props.productionMethodId,
    );

    if (!productionMethod) {
        return undefined;
    }

    return productionMethodIconMap[productionMethod.handle];
});
</script>

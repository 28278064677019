import { useArchiveVariantStore } from "@/store/useArchiveVariantStore";
import type { PusherCustomVariantAddedToFavoritesResponse } from "@/types/pusher/PusherCustomVariantAddedToFavoritesResponse";
import type { PusherFavoriteDeletedResponse } from "@/types/pusher/PusherFavoriteDeletedResponse";
import {
    FavoriteCollectionName,
    useDesignerApi,
    type CursorCollection,
    type FavoriteVariantData,
} from "@smakecloud/smake-use";
import { useQueryClient, type InfiniteData } from "@tanstack/vue-query";
import { storeToRefs } from "pinia";

export function usePusherVariantsCollection() {
    const queryClient = useQueryClient();
    const designerApi = useDesignerApi();
    const { isNewMerchantSketchModalOpen } = storeToRefs(
        useArchiveVariantStore(),
    );

    async function onCollectionVariantUpserted(
        data: PusherCustomVariantAddedToFavoritesResponse,
    ) {
        const queryData = queryClient.getQueryData<
            InfiniteData<
                CursorCollection<FavoriteVariantData>,
                number | undefined
            >
        >([
            "smake-use.designerApi.variants.favorite.list",
            data.collection_name,
        ]);

        if (!queryData) {
            if (
                data.collection_name === FavoriteCollectionName.MERCHANT_SKETCH
            ) {
                isNewMerchantSketchModalOpen.value = true;
            }

            return;
        }

        const newVariant =
            await designerApi.endpoints.variants.favorite.getById(
                data.favorite_id,
            );

        if (
            queryData.pages.some((page) =>
                page.data.some((variant) => variant.id === data.favorite_id),
            )
        ) {
            queryClient.setQueryData(
                [
                    "smake-use.designerApi.variants.favorite.list",
                    data.collection_name,
                ],
                (
                    oldData: InfiniteData<
                        CursorCollection<FavoriteVariantData>,
                        number | undefined
                    >,
                ) => ({
                    ...oldData,
                    pages: oldData.pages.map((page) => ({
                        ...page,
                        data: page.data.map((variant) =>
                            variant.id === data.favorite_id
                                ? newVariant
                                : variant,
                        ),
                    })),
                }),
            );
        } else {
            queryClient.setQueryData(
                [
                    "smake-use.designerApi.variants.favorite.list",
                    data.collection_name,
                ],
                (
                    oldData: InfiniteData<
                        CursorCollection<FavoriteVariantData>,
                        number | undefined
                    >,
                ) => ({
                    ...oldData,
                    pages: [
                        {
                            ...oldData.pages[0],
                            data: [newVariant, ...oldData.pages[0].data],
                        },
                        ...oldData.pages.slice(1),
                    ],
                }),
            );
        }

        if (
            newVariant.collection_name ===
            FavoriteCollectionName.MERCHANT_SKETCH
        ) {
            isNewMerchantSketchModalOpen.value = true;
        }
    }

    function onCollectionVariantDeleted(data: PusherFavoriteDeletedResponse) {
        if (
            !queryClient
                .getQueryData<
                    InfiniteData<
                        CursorCollection<FavoriteVariantData>,
                        number | undefined
                    >
                >(["smake-use.designerApi.variants.favorite.list", data.collection_name])
                ?.pages.some((page) =>
                    page.data.some(
                        (variant) => variant.id === data.favorite_id,
                    ),
                )
        ) {
            return;
        }

        queryClient.setQueryData(
            [
                "smake-use.designerApi.variants.favorite.list",
                data.collection_name,
            ],
            (
                oldData: InfiniteData<
                    CursorCollection<FavoriteVariantData>,
                    number | undefined
                >,
            ) => ({
                ...oldData,
                pages: oldData.pages.map((page) => ({
                    ...page,
                    data: page.data.filter(
                        (variant) => variant.id !== data.favorite_id,
                    ),
                })),
            }),
        );
    }

    return {
        onCollectionVariantUpserted,
        onCollectionVariantDeleted,
    };
}

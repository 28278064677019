import { useProductionMethodStore } from "@/store/useProductionMethodStore";
import { PanelState } from "@/enums/PanelState";
import { Mode } from "@/enums/mode";
import {
    isDesignWithRenderInformation,
    isZSKDesignWithText,
    isZSKLogoDesign,
} from "@/modules/core/renderer/zskEmbroidery/ZSKDesignHelper";
import { type ZSKDesign } from "@/modules/core/renderer/zskEmbroidery/types/ZSKDesign";
import Color from "@/repo/Color";
import Customization from "@/repo/Customization";
import Variant from "@/repo/Variant";
import View from "@/repo/View";
import { usePanelStore } from "@/store/usePanelStore";
import { defineStore, storeToRefs } from "pinia";
import { useRepo } from "pinia-orm";
import { computed, ref, watchEffect, type Ref } from "vue";
import * as Sentry from "@sentry/vue";

export const useCurrentStore = defineStore("currentStore", () => {
    const variantRepo = useRepo(Variant);
    const viewRepo = useRepo(View);
    const customizationRepo = useRepo(Customization);
    const colorRepo = useRepo(Color);

    const { showPanel, hideCurrentPanel } = usePanelStore();
    const { currentProductionMethodId } = storeToRefs(
        useProductionMethodStore(),
    );

    const currentVariantId = ref<number>(0);
    const currentViewHandle = ref<string>("");
    const currentMode = ref<Mode>(Mode.Default);
    const currentCustomizationId = ref<number | string>(0);

    const isArchiving = ref<boolean>(false);
    const addArchivDesign = ref<ZSKDesign | null>(
        null,
    ) as Ref<ZSKDesign | null>;

    const isSendingSaveRequest = ref<boolean>(false);
    const currentSavingVariantId = ref<number | null>(null);
    const isSaving = computed<boolean>(
        () =>
            isSendingSaveRequest.value || currentSavingVariantId.value !== null,
    );

    const isPdfCurrentlyRendered = ref<boolean>(false);

    const isCurrentCustomizationMoving = ref<boolean>(false);

    const isWaitingForRenderProcess = ref<boolean>(false);

    const currentVariant = computed<Variant>(() => {
        const variant = variantRepo
            .withAllRecursive(3)
            .find(currentVariantId.value);

        if (variant === null) {
            throw Error("could not load current variant");
        }

        return variant;
    });

    const customVariantCreatedId = ref<number | null>(null);

    const currentView = computed<View>(() => {
        const view = viewRepo
            .withAll()
            .where("handle", currentViewHandle.value)
            .where("variant_id", currentVariantId.value)
            .first();

        if (view === null) {
            throw Error("could not load current view");
        }

        return view;
    });

    const currentCustomization = computed<Customization | null>(() => {
        return customizationRepo
            .withAllRecursive()
            .find(currentCustomizationId.value);
    });

    const currentCustomizationHasEmptyResourceLines = computed<boolean>(() => {
        if (currentCustomization.value === null) {
            return false;
        }

        if (currentCustomization.value?.design === null) {
            return false;
        }

        if (isZSKLogoDesign(currentCustomization.value.design)) {
            return false;
        }

        if (!isZSKDesignWithText(currentCustomization.value.design)) {
            return false;
        }

        return !isDesignWithRenderInformation(
            currentCustomization.value.design,
        );
    });

    const hasCurrentViewFreePositioningAreas = computed<boolean>(() => {
        viewRepo.with("positioning_areas").load([currentView.value]);

        const positioningAreasIdsForCurrentProductionMethod =
            currentView.value.positioning_areas
                .filter((positioningArea) => {
                    return (
                        positioningArea.production_method_id ===
                        currentProductionMethodId.value
                    );
                })
                .map((positioningArea) => positioningArea.id);

        const customizationsCount = customizationRepo
            .query()
            .whereIn(
                "positioningArea_id",
                positioningAreasIdsForCurrentProductionMethod,
            )
            .get().length;

        return (
            customizationsCount <
            positioningAreasIdsForCurrentProductionMethod.length
        );
    });

    const currentVariantHasCustomizations = computed<boolean>(() => {
        const views = viewRepo
            .where("variant_id", currentVariantId.value)
            .get();

        return views.some((view) => {
            return (
                customizationRepo
                    .query()
                    .whereIn("positioningArea_id", view.positioning_area_ids)
                    .get().length > 0
            );
        });
    });

    const currentAvailableColors = computed<Color[]>(() => {
        return (
            colorRepo
                .withAllRecursive()
                .all()
                .filter((color) =>
                    color.production_method_ids.includes(
                        currentProductionMethodId.value,
                    ),
                ) ?? []
        );
    });

    /**
     * Changes the state of the current variant.
     *  This does not trigger the canvas rerendering (As this would cause cyclomatic dependencies between the store).
     *  Therfore you may use useVariant().changeCurrentVariantAndRender.
     *
     * @param variant
     */
    function changeCurrentVariant(variant: Variant) {
        if (variant.id === currentVariantId.value) {
            return;
        }

        // ensures the variant is saved in ORM
        variantRepo.save(variant);

        currentVariantId.value = variant.id;
        changeCurrentView(currentVariant.value.views[0]);
    }

    function changeCurrentView(view: View) {
        if (view.handle === currentViewHandle.value) {
            view.customization_ids = currentView.value.customization_ids;

            return;
        }

        // ensure the view is saved in ORM
        viewRepo.save(view);
        currentViewHandle.value = view.handle;
    }

    async function changeCurrentCustomization(customization: Customization) {
        if (customization.id === currentCustomizationId.value) {
            return;
        }

        // ensure the customization is saved in ORM
        customizationRepo.save(customization);
        currentCustomizationId.value = customization.id;

        await showPanel(PanelState.CustomizationEdit);
    }

    async function resetCurrentCustomization(hidePanel = true) {
        currentCustomizationId.value = 0;
        if (hidePanel) {
            await hideCurrentPanel();
        }
    }

    function changeCurrentViewToNextView() {
        const currentViewIndex: number = currentVariant.value.views.findIndex(
            (view) => {
                return (
                    view.handle === currentViewHandle.value &&
                    view.variant_id === currentVariantId.value
                );
            },
        );

        if (currentViewIndex === currentVariant.value.views.length - 1) {
            changeCurrentView(currentVariant.value.views[0]);

            return;
        }

        changeCurrentView(currentVariant.value.views[currentViewIndex + 1]);
    }

    function changeCurrentViewToPreviousView() {
        const currentViewIndex: number = currentVariant.value.views.findIndex(
            (view) => {
                return (
                    view.handle === currentViewHandle.value &&
                    view.variant_id === currentVariantId.value
                );
            },
        );

        if (currentViewIndex === 0) {
            changeCurrentView(
                currentVariant.value.views[
                    currentVariant.value.views.length - 1
                ],
            );

            return;
        }

        changeCurrentView(currentVariant.value.views[currentViewIndex - 1]);
    }

    watchEffect(() => {
        Sentry.setTag("variant-id", currentVariantId.value);
    });

    return {
        currentVariantId,
        currentVariant,
        changeCurrentVariant,
        customVariantCreatedId,

        isSaving,
        currentSavingVariantId,
        isSendingSaveRequest,

        currentView,
        hasCurrentViewFreePositioningAreas,
        changeCurrentView,
        changeCurrentViewToNextView,
        changeCurrentViewToPreviousView,

        currentMode,

        currentCustomization,
        changeCurrentCustomization,
        resetCurrentCustomization,
        isCurrentCustomizationMoving,
        currentCustomizationHasEmptyResourceLines,
        currentVariantHasCustomizations,

        isArchiving,
        addArchivDesign,

        currentAvailableColors,

        isPdfCurrentlyRendered,

        isWaitingForRenderProcess,
    };
});

<template>
    <div
        ref="panel"
        class="relative max-h-full max-w-[100%] overflow-hidden rounded-3xl bg-white"
        @keydown.stop.esc="isOpen = false"
    >
        <button
            v-if="hasCloseButton"
            type="button"
            class="absolute right-2 top-2 h-8 w-8 rounded-full border border-secondary-2 hover:bg-secondary-3"
            @click="isOpen = false"
        >
            <f-a-icon icon="times" :size="FontAwesomeSize.lg" />
        </button>
        <slot />
    </div>
</template>

<script setup lang="ts">
import { useInjectDialogData } from "@/composables/useDialogData";
import { FontAwesomeSize } from "@/enums/fontAwesome/fontAwesomeSize";
import { useDialogStore } from "@/store/useDialogStore";
import { useEventListener } from "@vueuse/core";
import { storeToRefs } from "pinia";
import { computed, onMounted, onUnmounted, ref } from "vue";

const props = defineProps<{
    hasCloseButton?: boolean;
    initialFocus?: HTMLElement;
    stayOpenOnBackdropClick?: boolean;
}>();

const { isOpen } = useInjectDialogData();
const { portalRoot } = storeToRefs(useDialogStore());

const panel = ref<HTMLDivElement>();
const previousFocus = ref<HTMLElement>();
const isMounted = ref(false);
const rootNode = computed(() => panel.value?.getRootNode());

useEventListener(
    window,
    "click",
    (e) => {
        if (props.stayOpenOnBackdropClick) {
            return;
        }

        if (
            !isMounted.value ||
            !(rootNode.value instanceof ShadowRoot) ||
            rootNode.value.host === e.target
        )
            return;
        isOpen.value = false;
    },
    { passive: true },
);

useEventListener(
    rootNode,
    "pointerdown",
    (e: PointerEvent) => {
        if (props.stayOpenOnBackdropClick) {
            return;
        }

        if (
            !isMounted.value ||
            !(e.target instanceof Node) ||
            !rootNode.value?.contains(e.target) ||
            !panel.value ||
            panel.value.contains(e.target) ||
            !portalRoot.value?.lastElementChild?.contains(panel.value)
        )
            return;
        isOpen.value = false;
    },
    { passive: true },
);

// Credit: https://github.com/tailwindlabs/headlessui/blob/main/packages/%40headlessui-vue/src/utils/focus-management.ts#L5
const focusableSelector = [
    "[contentEditable=true]",
    "[tabindex]",
    "a[href]",
    "area[href]",
    "button:not([disabled])",
    "iframe",
    "input:not([disabled])",
    "select:not([disabled])",
    "textarea:not([disabled])",
]
    .map((selector) => `${selector}:not([tabindex='-1'])`)
    .join(",");

onMounted(() => {
    const rootNode = panel.value?.getRootNode();

    if (
        (rootNode instanceof ShadowRoot || rootNode instanceof Document) &&
        rootNode.activeElement instanceof HTMLElement
    ) {
        previousFocus.value = rootNode.activeElement;
    }

    // Credit: https://github.com/tailwindlabs/headlessui/blob/main/packages/%40headlessui-vue/src/components/focus-trap/focus-trap.ts#L297
    // Delaying the focus to the next microtask ensures that a few conditions are true:
    // - The container is rendered
    // - Transitions could be started
    queueMicrotask(() => {
        (
            props.initialFocus ??
            panel.value?.querySelector<HTMLElement>(focusableSelector)
        )?.focus();
    });
    // Should be set after next event loop turn (so the initial click to open
    // the dialog doesn't directly closes it again)
    setTimeout(() => {
        isMounted.value = true;
    }, 0);
});

onUnmounted(() => {
    isMounted.value = false;
    previousFocus.value?.focus();
});
</script>

import { useDesignerApi, type VariantListFilter } from "@smakecloud/smake-use";
import { useInfiniteQuery } from "@tanstack/vue-query";
import { defineStore } from "pinia";
import { computed, ref } from "vue";

export const useCartVariantsStore = defineStore("cartVariantsStore", () => {
    const designerApi = useDesignerApi();
    const filter = ref<VariantListFilter>();

    const query = useInfiniteQuery({
        queryKey: ["smake-use.designerApi.variants.list", filter],
        queryFn: ({ pageParam }) =>
            designerApi.endpoints.variants.list({
                filter: filter.value,
                cursor: pageParam,
            }),
        select: (data) => data.pages.flatMap((page) => page.data),
        initialPageParam: null as string | null,
        getNextPageParam: (lastPage) => lastPage.meta.next_cursor,
        enabled: computed(() => !!filter.value),
        staleTime: 60 * 1000,
    });

    return {
        filter,
        ...query,
    };
});

import ArchiveButton from "@/components/modules/archive/ArchiveButton.vue";
import CustomizationsButton from "@/components/modules/customizations/CustomizationsButton.vue";
import LogosButton from "@/components/modules/logos/LogosButton.vue";
import MenuButton from "@/components/modules/menu/MenuButton.vue";
import PdfDownloadButton from "@/components/modules/pdfDownload/PdfDownloadButton.vue";
import ProductColorsButton from "@/components/modules/productColors/ProductColorsButton.vue";
import ProductZoomButton from "@/components/modules/productZoom/ProductZoomButton.vue";
import SaveVariantButton from "@/components/modules/saveVariant/SaveVariantButton.vue";
import ShowAreasButton from "@/components/modules/showAreas/ShowAreasButton.vue";
import StoreVariantButton from "@/components/modules/storeVariant/StoreVariantButton.vue";
import TextsButton from "@/components/modules/texts/TextsButton.vue";
import { type Component } from "vue";

export default (): Record<string, Component> => {
    return {
        ArchiveButton,
        LogosButton,
        PdfDownloadButton,
        ProductColorsButton,
        SaveVariantButton,
        ShowAreasButton,
        StoreVariantButton,
        TextsButton,
        CustomizationsButton,
        ProductZoomButton,
        MenuButton,
    };
};

import { ref } from "vue";
import { ceil, clamp } from "lodash";

export default () => {
    const currentProgess = ref<number>(100);
    const progessStepSize = ref<number>(0);

    function startProgres(progresStepCount: number): void {
        if (progresStepCount > 0) {
            currentProgess.value = 0;
            progessStepSize.value = 100 / progresStepCount;
        }
    }

    function progressAdvance(): void {
        currentProgess.value = clamp(
            ceil(currentProgess.value + progessStepSize.value),
            0,
            100,
        );
    }

    return {
        startProgress: startProgres,
        progressAdvance,
        currentProgress: currentProgess,
    };
};

export const StyleConfig = {
    primaryButton: {
        styles: [
            "grid",
            "content-center",
            "relative",
            "h-12",
            "rounded-full",
            "bg-primary-1",
            "hover:bg-primary-2",
            "text-xs",
            "font-semibold",
            "text-white",
            "focus:outline-none",
            "px-8",
        ],
    },
    secondaryButton: {
        styles: [
            "grid",
            "content-center",
            "relative",
            "h-12",
            "rounded-full",
            "bg-secondary-3",
            "tablet:bg-white",
            "tablet:disabled:hover:bg-white",
            "hover:bg-secondary-2",
            "disabled:hover:bg-secondary-3",
            "disabled:cursor-not-allowed",
            "text-xs",
            "text-black",
            "font-semibold",
            "focus:outline-none",
            "px-8",
        ],
    },
    dialogeSecondaryButton: {
        styles: [
            "grid",
            "content-center",
            "relative",
            "rounded-full",
            "py-3",
            "px-2",
            "bg-secondary-3",
            "hover:bg-secondary-2",
            "text-xs",
            "text-black",
            "font-semibold",
            "focus:outline-none",
        ],
    },
    archiveModalSelectionGroupPrimaryButton: {
        styles: [
            "bg-primary-1",
            "text-white",
            "rounded-full",
            "py-3",
            "px-2",
            "hover:bg-primary-2",
            "disabled:cursor-not-allowed",
            "disabled:bg-white",
            "disabled:border",
            "disabled:border-white",
            "disabled:text-secondary-2",
            "disabled:hover:bg-white",
            "tablet:disabled:border-secondary-2",
        ],
    },
    archiveModalSelectionGroupSecondaryButton: {
        styles: [
            "bg-white",
            "disabled:cursor-not-allowed",
            "disabled:text-secondary-2",
            "text-black",
            "rounded-full",
            "h-12",
            "py-2",
            "border",
            "border-white",
            "tablet:border-secondary-2",
            "tablet:hover:border-black",
            "tablet:disabled:border-secondary-2",
        ],
    },
    buttonPanelTop: {
        styles: ["z-30", "p-2", "flex", "justify-center"],
        moduleButton: {
            styles: [
                "w-20",
                "h-16",
                "desktop:w-24",
                "desktop:h-20",
                "text-xs",
                "focus:outline-none",
                "bg-white",
                "hover:bg-primary-1",
                "hover:text-white",
                "p-2",
            ],
            icon: {
                styles: ["block", "mx-auto", "mb-1"],
            },
        },
    },
    buttonPanelLeft: {
        styles: [
            "z-10",
            "py-10",
            "pl-10",
            "flex",
            "flex-col",
            "justify-end",
            "gap-4",
            "pointer-events-none",
        ],
        moduleButton: {
            styles: [],
            icon: {
                styles: [],
            },
            label: {
                styles: [],
            },
        },
    },
    buttonPanelRight: {
        styles: [
            "z-[70]",
            "py-10",
            "right-5",
            "top-10",
            "flex",
            "flex-col",
            "gap-4",
            "absolute",
            "tablet:z-10",
            "tablet:relative",
            "tablet:pr-10",
            "tablet:top-auto",
            "tablet:right-auto",
            "pointer-events-none",
        ],
        moduleButton: {
            styles: [],
            icon: {
                styles: [],
            },
            label: {
                styles: [],
            },
        },
    },
    buttonPanelBottom1: {
        styles: [
            "z-10",
            "grid",
            "grid-flow-col",
            "gap-3",
            "auto-cols-min",
            "justify-center",
            "pb-3",
            "pointer-events-none",
            "focus:outline-none",
        ],
        moduleButton: {
            styles: [
                "grid",
                "grid-flow-row",
                "gap-1",
                "justify-items-center",
                "auto-rows-min",
                "w-full",
                "text-xs",
            ],
            icon: {
                styles: [
                    "w-12",
                    "h-12",
                    "rounded-full",
                    "bg-white",
                    "border",
                    "border-secondary-2",
                    "grid",
                    "content-center",
                    "justify-center",
                ],
            },
            label: {
                styles: [],
            },
        },
    },
    buttonPanelBottom2: {
        styles: [
            "z-10",
            "tablet:z-50",
            "bg-white",
            "p-3",
            "rounded-tl-3xl",
            "rounded-tr-3xl",
            "shadow-[0px_0px_15px_0px_rgba(0,0,0,0.25)]",
            "pointer-events-none",
            "tablet:px-10",
            "desktop:py-6",
            "flex",
            "justify-center",
        ],
        moduleButton: {
            styles: [],
            icon: {
                styles: [],
            },
            label: {
                styles: [],
            },
        },
        innerWrapper: {
            styles: [
                "grid",
                "grid-flow-col",
                "auto-cols-fr",
                "gap-3",
                "desktop:gap-6",
                "max-w-6xl",
                "w-full",
                "px-10",
            ],
        },
    },
    viewsButton: {
        wrapper: {
            styles: [
                "gap-2",
                "grid-flow-col",
                "tablet:grid-flow-row",
                "tablet:grid-row",
                "tablet:auto-rows-min",
            ],
        },
        button: {
            styles: [
                "w-20",
                "h-16",
                "desktop:w-24",
                "desktop:h-20",
                "rounded-lg",
                "shadow-lg",
                "focus:outline-none",
                "bg-white",
            ],
            label: {
                styles: [
                    "w-full",
                    "h-full",
                    "p-1",
                    "flex",
                    "justify-center",
                    "items-center",
                ],
            },
        },
    },
    archiveButton: {
        styles: [
            "flex",
            "border",
            "bg-white",
            "border-secondary-2",
            "rounded-full",
            "justify-center",
            "items-center",
            "space-x-2",
            "tablet:justify-evenly",
            "tablet:space-x-0",
            "tablet:flex-col",
            "tablet:border-2",
            "tablet:rounded-3xl",
            "tablet:border-secondary-3",
            "tablet:hover:border-secondary-2",
            "tablet:p-2",
            "tablet:w-36",
            "tablet:h-36",
            "tablet:font-bold",
        ],
        icon: {
            styles: [
                "w-10",
                "h-10",
                "text-lg",
                "rounded-full",
                "flex",
                "items-center",
                "justify-center",
                "tablet:text-sm",
                "tablet:bg-secondary-3",
                "tablet:text-black",
                "tablet:w-10",
                "tablet:h-10",
            ],
        },
        label: {
            styles: ["self-center", "text-xs"],
        },
        disabled: {
            styles: [
                "flex",
                "border",
                "bg-white",
                "border-secondary-2",
                "rounded-full",
                "justify-center",
                "items-center",
                "space-x-2",
                "tablet:justify-evenly",
                "tablet:space-x-0",
                "tablet:flex-col",
                "tablet:border-2",
                "tablet:rounded-3xl",
                "tablet:border-secondary-3",
                "tablet:p-2",
                "tablet:w-36",
                "tablet:h-36",
                "tablet:font-bold",
            ],
            icon: {
                styles: [
                    "w-10",
                    "h-10",
                    "text-lg",
                    "rounded-full",
                    "flex",
                    "items-center",
                    "justify-center",
                    "tablet:text-sm",
                    "tablet:bg-secondary-3",
                    "tablet:text-black",
                    "tablet:w-10",
                    "tablet:h-10",
                ],
            },
            label: {
                styles: ["self-center", "text-xs"],
            },
        },
    },
    archiveModal: {
        panel: {
            styles: [
                "absolute",
                "inset-0",
                "p-7",
                "bg-white",
                "mx-auto",
                "max-w-[1889px]",
                "tablet:inset-6",
                "tablet:rounded-3xl",
                "tablet:p-10",
                "tablet:shadow-[0px_0px_15px_0px_rgba(0,0,0,0.25)]",
            ],
        },
    },
    storeVariantButton: {
        styles: [
            "flex",
            "flex-wrap",
            "justify-center",
            "relative",
            "h-12",
            "rounded-full",
            "focus:outline-none",
            "border-none",
            "bg-primary-1",
            "hover:bg-primary-2",
            "text-white",
            "tablet:px-8",
            "tablet:justify-self-end",
            "desktop:w-32",
            "disabled:bg-white",
            "disabled:border",
            "disabled:border-solid",
            "disabled:border-secondary-2",
            "disabled:text-black",
        ],
        icon: {
            styles: ["mr-3", "self-center", "text-lg"],
        },
        label: {
            styles: ["self-center", "text-sm"],
        },
    },
    showAreasButton: {
        styles: [
            "grid",
            "grid-flow-row",
            "gap-1",
            "justify-items-center",
            "auto-rows-min",
            "w-full",
            "place-self-end",
            "tablet:w-24",
            "tablet:gap-2",
            "desktop:w-32",
        ],
        icon: {
            styles: [
                "w-12",
                "h-12",
                "rounded-full",
                "bg-white",
                "border",
                "border-secondary-2",
                "hover:border-black",
                "grid",
                "content-center",
                "justify-center",
                "place-self-end",
                "justify-self-center",
                "text-lg",
                "tablet:w-10",
                "tablet:h-10",
                "tablet:text-md",
                "desktop:w-16",
                "desktop:h-16",
                "desktop:text-lg",
            ],
        },
        label: {
            styles: ["self-center", "text-xs", "w-24"],
        },
        active: {
            styles: [
                "grid",
                "grid-flow-row",
                "gap-1",
                "justify-items-center",
                "justify-center",
                "auto-rows-min",
                "w-full",
                "place-self-end",
                "tablet:w-24",
                "tablet:gap-2",
                "desktop:w-32",
            ],
            icon: {
                styles: [
                    "w-12",
                    "h-12",
                    "rounded-full",
                    "bg-black",
                    "border",
                    "border-black",
                    "hover:bg-white",
                    "hover:text-black",
                    "grid",
                    "content-center",
                    "justify-center",
                    "text-lg",
                    "text-white",
                    "tablet:w-10",
                    "tablet:h-10",
                    "tablet:text-md",
                    "desktop:w-16",
                    "desktop:h-16",
                    "desktop:text-lg",
                ],
            },
            label: {
                styles: ["self-center", "text-xs", "w-24"],
            },
            disabled: {
                styles: [
                    "grid",
                    "grid-flow-row",
                    "gap-1",
                    "justify-items-center",
                    "justify-center",
                    "auto-rows-min",
                    "w-full",
                    "place-self-end",
                    "tablet:w-24",
                    "tablet:ml-auto",
                    "tablet:gap-2",
                    "desktop:w-32",
                ],
                icon: {
                    styles: [
                        "w-12",
                        "h-12",
                        "rounded-full",
                        "bg-black",
                        "border",
                        "border-black",
                        "grid",
                        "content-center",
                        "justify-center",
                        "text-lg",
                        "text-white",
                        "tablet:w-10",
                        "tablet:h-10",
                        "tablet:text-md",
                        "desktop:w-16",
                        "desktop:h-16",
                        "desktop:text-lg",
                    ],
                },
                label: {
                    styles: ["self-center", "text-xs", "w-24"],
                },
            },
        },
        disabled: {
            styles: [
                "grid",
                "grid-flow-row",
                "gap-1",
                "justify-items-center",
                "auto-rows-min",
                "w-full",
                "place-self-end",
                "tablet:w-24",
                "tablet:ml-auto",
                "tablet:gap-2",
                "desktop:w-32",
            ],
            icon: {
                styles: [
                    "w-12",
                    "h-12",
                    "rounded-full",
                    "bg-white",
                    "border",
                    "border-secondary-2",
                    "grid",
                    "content-center",
                    "justify-center",
                    "place-self-end",
                    "justify-self-center",
                    "text-lg",
                    "tablet:w-10",
                    "tablet:h-10",
                    "tablet:text-md",
                    "desktop:w-16",
                    "desktop:h-16",
                    "desktop:text-lg",
                ],
            },
            label: {
                styles: ["self-center", "text-xs", "w-24"],
            },
        },
    },
    productZoomButton: {
        styles: [
            "grid",
            "grid-flow-row",
            "gap-1",
            "justify-items-center",
            "auto-rows-min",
            "w-full",
            "place-self-end",
            "tablet:w-24",
            "desktop:w-32",
            "tablet:gap-2",
        ],
        icon: {
            styles: [
                "w-12",
                "h-12",
                "rounded-full",
                "bg-white",
                "border",
                "border-secondary-2",
                "hover:border-black",
                "grid",
                "content-center",
                "justify-center",
                "place-self-end",
                "justify-self-center",
                "text-lg",
                "tablet:w-10",
                "tablet:h-10",
                "tablet:text-md",
                "desktop:w-16",
                "desktop:h-16",
                "desktop:text-lg",
            ],
        },
        label: {
            styles: ["self-center", "text-xs", "w-24"],
        },
        active: {
            styles: [
                "grid",
                "grid-flow-row",
                "gap-1",
                "justify-items-center",
                "auto-rows-min",
                "w-full",
                "place-self-end",
                "tablet:w-24",
                "tablet:gap-2",
                "desktop:w-32",
            ],
            icon: {
                styles: [
                    "w-12",
                    "h-12",
                    "rounded-full",
                    "border",
                    "border-black",
                    "grid",
                    "content-center",
                    "justify-center",
                    "place-self-end",
                    "justify-self-center",
                    "text-lg",
                    "tablet:w-10",
                    "tablet:h-10",
                    "tablet:text-md",
                    "desktop:w-12",
                    "desktop:h-12",
                    "desktop:text-lg",
                ],
            },
            label: {
                styles: ["self-center", "text-xs", "w-24"],
            },
        },
        disabled: {
            styles: [
                "grid",
                "grid-flow-row",
                "gap-1",
                "justify-items-center",
                "auto-rows-min",
                "w-full",
                "place-self-end",
                "tablet:w-24",
                "tablet:gap-2",
                "desktop:w-32",
            ],
            icon: {
                styles: [
                    "w-12",
                    "h-12",
                    "rounded-full",
                    "border",
                    "border-secondary-2",
                    "hover:border-secondary-2",
                    "grid",
                    "content-center",
                    "justify-center",
                    "place-self-end",
                    "justify-self-center",
                    "text-lg",
                    "tablet:w-10",
                    "tablet:h-10",
                    "tablet:text-md",
                    "desktop:w-16",
                    "desktop:h-16",
                    "desktop:text-lg",
                ],
            },
            label: {
                styles: ["self-center", "text-xs", "w-24"],
            },
        },
    },
    productZoomSlider: {
        icon: {
            styles: [
                "flex",
                "justify-center",
                "items-center",
                "rounded-full",
                "text-lg",
                "tablet:text-md",
                "desktop:text-lg",
                "gap-4",
                "py-2",
                "tablet:w-24",
                "desktop:w-32",
            ],
        },
        label: {
            styles: ["self-center", "text-xs", "w-24"],
        },
    },
    productColorsButton: {
        styles: [
            "grid",
            "grid-flow-row",
            "gap-1",
            "justify-items-center",
            "auto-rows-min",
            "w-full",
            "place-self-end",
            "tablet:w-24",
            "tablet:gap-2",
            "desktop:w-32",
        ],
        icon: {
            styles: [
                "w-12",
                "h-12",
                "rounded-full",
                "border",
                "border-secondary-2",
                "hover:border-black",
                "grid",
                "content-center",
                "justify-center",
                "place-self-end",
                "justify-self-center",
                "text-transparent",
                "tablet:w-10",
                "tablet:h-10",
                "tablet:text-md",
                "desktop:w-16",
                "desktop:h-16",
                "desktop:text-lg",
                "bg-[conic-gradient(lightgreen,yellow,red,fuchsia,blue,aqua,lime,lightgreen)]",
            ],
        },
        label: {
            styles: ["self-center", "text-xs", "w-24"],
        },
        active: {
            styles: [
                "grid",
                "grid-flow-row",
                "gap-1",
                "justify-items-center",
                "auto-rows-min",
                "w-full",
                "place-self-end",
                "tablet:w-24",
                "tablet:gap-2",
                "desktop:w-32",
            ],
            icon: {
                styles: [
                    "w-12",
                    "h-12",
                    "rounded-full",
                    "bg-black",
                    "border",
                    "border-black",
                    "hover:bg-white",
                    "hover:text-black",
                    "grid",
                    "content-center",
                    "justify-center",
                    "text-lg",
                    "text-white",
                    "tablet:w-10",
                    "tablet:h-10",
                    "tablet:text-md",
                    "desktop:w-12",
                    "desktop:h-12",
                    "desktop:text-lg",
                ],
            },
            label: {
                styles: ["self-center", "text-xs", "w-24"],
            },
        },
        disabled: {
            styles: [
                "grid",
                "grid-flow-row",
                "gap-1",
                "justify-items-center",
                "auto-rows-min",
                "w-full",
                "place-self-end",
                "tablet:w-24",
                "tablet:gap-2",
                "desktop:w-32",
            ],
            icon: {
                styles: [
                    "text-transparent",
                    "w-12",
                    "h-12",
                    "rounded-full",
                    "border",
                    "border-secondary-2",
                    "hover:border-secondary-2",
                    "grid",
                    "content-center",
                    "justify-center",
                    "place-self-end",
                    "justify-self-center",
                    "text-lg",
                    "tablet:w-10",
                    "tablet:h-10",
                    "tablet:text-md",
                    "desktop:w-16",
                    "desktop:h-16",
                    "desktop:text-lg",
                    "bg-[conic-gradient(lightgreen,yellow,red,fuchsia,blue,aqua,lime,lightgreen)]",
                ],
            },
            label: {
                styles: ["self-center", "text-xs", "w-24"],
            },
        },
    },
    productColorsModal: {
        panel: {
            styles: [
                "absolute",
                "z-1",
                "bottom-0",
                "h-full",
                "w-full",
                "p-7",
                "bg-white",
                "tablet:right-0",
                "tablet:w-[480px]",
                "tablet:p-10",
                "tablet:bg-secondary-3",
                "tablet:shadow-[0px_0px_15px_0px_rgba(0,0,0,0.25)]",
            ],
            backdrop: {},
            closeButton: {
                styles: [
                    "rounded-full",
                    "h-8",
                    "w-8",
                    "flex",
                    "justify-center",
                    "items-center",
                    "border",
                    "border-secondary-2",
                    "bg-white",
                    "focus:outline-none",
                    "tablet:hidden",
                ],
            },
        },
        styles: [
            "flex",
            "flex-col",
            "w-full",
            "h-full",
            "gap-7",
            "tablet:gap-10",
            "overflow-y-auto",
            "pr-3",
        ],
        colorsWrapper: {
            styles: [
                "grid",
                "grid-cols-4",
                "tablet:grid-cols-5",
                "gap-4",
                "justify-items-center",
            ],
        },
        previewWrapper: {
            styles: [
                "flex-0",
                "flex",
                "flex-wrap",
                "justify-center",
                "bg-secondary-3",
                "p-7",
                "rounded-md",
                "tablet:p-10",
                "tablet:bg-white",
                "tablet:rounded-tl-3xl",
                "tablet:rounded-tr-3xl",
                "tablet:rounded-bl-none",
                "tablet:rounded-br-none",
            ],
            image: {
                styles: ["h-36"],
            },
        },
        optionsWrapper: {
            styles: [
                "flex-0",
                "grid",
                "grid-cols-2",
                "gap-3",
                "bg-white",
                "tablet:p-10",
                "tablet:pt-0",
                "tablet:-mt-10",
                "tablet:rounded-bl-3xl",
                "tablet:rounded-br-3xl",
            ],
        },
    },
    textsButton: {
        styles: [
            "grid",
            "grid-flow-row",
            "gap-1",
            "justify-items-center",
            "auto-rows-min",
            "w-full",
            "tablet:bg-secondary-3",
            "tablet:hover:bg-secondary-2",
            "tablet:text-black",
            "tablet:rounded-3xl",
            "tablet:mr-auto",
            "tablet:auto-rows-max",
            "tablet:content-center",
            "tablet:font-bold",
            "tablet:gap-1",
            "tablet:w-32",
            "tablet:h-32",
            "tablet:p-3",
        ],
        icon: {
            styles: [
                "w-20",
                "h-20",
                "rounded-full",
                "bg-primary-2",
                "border",
                "border-secondary-2",
                "grid",
                "text-3xl",
                "text-white",
                "content-center",
                "justify-center",
                "tablet:border-none",
                "tablet:text-3xl",
                "tablet:bg-transparent",
                "tablet:text-black",
                "tablet:w-16",
                "tablet:h-16",
            ],
        },
        label: {
            styles: ["self-center", "text-xs", "w-24", "tablet:w-auto"],
        },
        addIcon: {
            styles: [
                "hidden",
                "tablet:absolute",
                "tablet:-bottom-1",
                "tablet:rounded-full",
                "tablet:bg-primary-1",
                "tablet:text-white",
                "tablet:flex",
                "tablet:flex-wrap",
                "tablet:justify-center",
                "tablet:content-center",
                "tablet:font-semibold",
                "tablet:w-5",
                "tablet:h-5",
                "tablet:-right-3",
                "tablet:text-sm",
            ],
        },
    },
    logosButton: {
        styles: [
            "grid",
            "grid-flow-row",
            "gap-1",
            "justify-items-center",
            "auto-rows-min",
            "w-full",
            "tablet:gap-0",
            "tablet:w-24",
            "tablet:h-24",
            "tablet:p-2",
            "tablet:bg-secondary-3",
            "tablet:hover:bg-secondary-2",
            "tablet:text-black",
            "tablet:rounded-3xl",
            "tablet:mr-auto",
            "tablet:auto-rows-max",
            "tablet:content-center",
            "desktop:gap-1",
            "desktop:w-32",
            "desktop:h-32",
            "desktop:p-3",
        ],
        icon: {
            styles: [
                "w-12",
                "h-12",
                "rounded-full",
                "bg-white",
                "border",
                "border-secondary-2",
                "grid",
                "content-center",
                "justify-center",
                "tablet:bg-transparent",
                "tablet:border-none",
                "tablet:text-xl",
                "desktop:text-2xl",
            ],
        },
        label: {
            styles: ["self-center", "text-xs", "w-24", "tablet:w-auto"],
        },
        addIcon: {
            styles: [
                "hidden",
                "tablet:absolute",
                "tablet:-bottom-1",
                "tablet:-right-2",
                "tablet:w-4",
                "tablet:h-4",
                "tablet:rounded-full",
                "tablet:bg-primary-1",
                "tablet:text-white",
                "tablet:flex",
                "tablet:flex-wrap",
                "tablet:justify-center",
                "tablet:content-center",
                "tablet:text-xs",
                "tablet:font-semibold",
                "desktop:w-5",
                "desktop:h-5",
                "desktop:-right-3",
                "desktop:text-sm",
            ],
        },
    },
    saveVariantButton: {
        styles: [
            "grid",
            "grid-flow-row",
            "gap-1",
            "justify-items-center",
            "auto-rows-min",
            "mr-auto",
            "mt-auto",
            "w-full",
            "tablet:w-24",
            "tablet:gap-2",
            "desktop:w-32",
        ],
        icon: {
            styles: [
                "w-12",
                "h-12",
                "rounded-full",
                "bg-white",
                "border",
                "border-secondary-2",
                "hover:border-black",
                "grid",
                "content-center",
                "justify-center",
                "text-lg",
                "tablet:w-10",
                "tablet:h-10",
                "tablet:text-md",
                "desktop:w-16",
                "desktop:h-16",
                "desktop:text-lg",
            ],
        },
        label: {
            styles: ["self-center", "text-xs", "w-24"],
        },
        disabled: {
            styles: [
                "grid",
                "grid-flow-row",
                "gap-1",
                "justify-items-center",
                "auto-rows-min",
                "mr-auto",
                "mt-auto",
                "w-full",
                "tablet:w-24",
                "tablet:gap-2",
                "desktop:w-32",
            ],
            icon: {
                styles: [
                    "w-12",
                    "h-12",
                    "rounded-full",
                    "bg-white",
                    "border",
                    "border-secondary-2",
                    "hover:border-secondary-2",
                    "grid",
                    "content-center",
                    "justify-center",
                    "text-lg",
                    "tablet:w-10",
                    "tablet:h-10",
                    "tablet:text-md",
                    "desktop:w-16",
                    "desktop:h-16",
                    "desktop:text-lg",
                ],
            },
            label: {
                styles: ["self-center", "text-xs", "w-24"],
            },
        },
    },
    pdfDownloadButton: {
        styles: [
            "grid",
            "grid-flow-row",
            "gap-1",
            "justify-items-center",
            "auto-rows-min",
            "mr-auto",
            "w-full",
            "tablet:w-24",
            "tablet:gap-2",
            "desktop:w-32",
        ],
        icon: {
            styles: [
                "w-12",
                "h-12",
                "rounded-full",
                "bg-white",
                "border",
                "border-secondary-2",
                "hover:border-black",
                "grid",
                "content-center",
                "justify-center",
                "text-lg",
                "tablet:w-10",
                "tablet:h-10",
                "tablet:text-md",
                "desktop:w-16",
                "desktop:h-16",
                "desktop:text-lg",
            ],
        },
        label: {
            styles: ["self-center", "text-xs", "w-24"],
        },
        disabled: {
            styles: [
                "grid",
                "grid-flow-row",
                "gap-1",
                "justify-items-center",
                "auto-rows-min",
                "mr-auto",
                "w-full",
                "tablet:w-24",
                "tablet:gap-2",
                "desktop:w-32",
            ],
            icon: {
                styles: [
                    "w-12",
                    "h-12",
                    "rounded-full",
                    "bg-white",
                    "border",
                    "border-secondary-2",
                    "hover:border-secondary-2",
                    "grid",
                    "content-center",
                    "justify-center",
                    "text-lg",
                    "tablet:w-10",
                    "tablet:h-10",
                    "tablet:text-md",
                    "desktop:w-16",
                    "desktop:h-16",
                    "desktop:text-lg",
                ],
            },
            label: {
                styles: ["self-center", "text-xs", "w-24"],
            },
        },
    },
    pdfDownloadModal: {
        styles: [
            "absolute",
            "z-1",
            "bottom-0",
            "h-full",
            "w-full",
            "p-7",
            "bg-white",
            "tablet:right-0",
            "tablet:w-[480px]",
            "tablet:p-10",
            "tablet:bg-secondary-3",
            "tablet:shadow-[0px_0px_15px_0px_rgba(0,0,0,0.25)]",
        ],
        backdrop: {
            styles: [
                "absolute",
                "inset-0",
                "transition-opacity",
                "bg-shadow",
                "opacity-20",
                "pointer-events-auto",
            ],
        },
        closeButton: {
            styles: [
                "rounded-full",
                "h-8",
                "w-8",
                "flex",
                "justify-center",
                "items-center",
                "border",
                "border-primary-1",
                "bg-white",
                "focus:outline-none",
                "tablet:hidden",
            ],
        },
    },
    menuButton: {
        styles: [
            "grid",
            "grid-flow-row",
            "gap-1",
            "justify-items-center",
            "w-full",
            "tablet:w-24",
            "tablet:gap-2",
            "desktop:w-32",
        ],
        icon: {
            styles: [
                "w-12",
                "h-12",
                "rounded-full",
                "bg-primary-1",
                "text-white",
                "hover:border-black",
                "grid",
                "content-center",
                "justify-center",
                "text-lg",
                "tablet:w-10",
                "tablet:h-10",
                "tablet:text-md",
                "desktop:w-16",
                "desktop:h-16",
                "desktop:text-lg",
            ],
        },
        open: {
            styles: [
                "grid",
                "grid-flow-row",
                "gap-1",
                "justify-items-end",
                "auto-rows-min",
                "mr-auto",
                "w-full",
                "tablet:w-24",
                "tablet:gap-2",
                "desktop:w-32",
            ],
            icon: {
                styles: [
                    "w-12",
                    "h-12",
                    "rounded-full",
                    "text-white",
                    "bg-black",
                    "border",
                    "border-secondary-2",
                    "hover:border-black",
                    "grid",
                    "content-center",
                    "justify-center",
                    "text-md",
                    "tablet:w-10",
                    "tablet:h-10",
                    "tablet:text-md",
                    "desktop:w-16",
                    "desktop:h-16",
                    "desktop:text-lg",
                ],
            },
        },
    },
    menuModalButton: {
        styles: [
            "flex",
            "gap-2",
            "border",
            "border-secondary-2",
            "rounded-full",
            "py-4",
            "px-6",
            "bg-secondary-2",
            "text-lg",
            "w-fit",
        ],
        icon: {
            styles: [],
        },
    },
    stageViewNavigation: {
        styles: ["grid", "grid-flow-col", "auto-cols-fr"],
        activeButton: {
            styles: [
                "border-black-1",
                "border-t-2",
                "w-full",
                "p-2",
                "text-xs",
            ],
        },
        inactiveButton: {
            styles: [
                "border-secondary-2",
                "border-t-2",
                "w-full",
                "p-2",
                "text-xs",
            ],
        },
    },
    stageViewButtons: {
        button: {
            styles: [
                "w-14",
                "h-14",
                "rounded-full",
                "flex",
                "justify-center",
                "items-center",
                "mx-5",
                "pointer-events-auto",
                "bg-secondary-3",
                "disabled:bg-secondary-3",
                "hover:bg-secondary-2",
                "text-black",
                "text-2xl",
                "focus:outline-none",
                "disabled:hidden",
            ],
        },
    },
    input: {
        styles: [
            "focus:outline-none",
            "h-12",
            "py-2",
            "px-6",
            "sm:text-sm",
            "border",
            "border-secondary-2",
            "rounded-md",
        ],
        label: {
            styles: ["text-xs", "font-semibold", "uppercase"],
        },
    },
    slider: {
        styles: [
            "rounded-lg",
            "appearance-none",
            "bg-secondary-3",
            "h-1",
            "w-full",
            "max-w-sm",
            "mt-3",
        ],
    },
    panel: {
        styles: [
            "absolute",
            "z-40",
            "bottom-0",
            "h-3/5",
            "w-full",
            "p-7",
            "bg-white",
            "rounded-tl-3xl",
            "rounded-tr-3xl",
            "tablet:right-0",
            "tablet:h-full",
            "tablet:w-[480px]",
            "tablet:pt-10",
            "tablet:px-10",
            "tablet:pb-36",
            "tablet:bg-secondary-3",
            "tablet:rounded-tl-none",
            "tablet:rounded-tr-none",
            "tablet:shadow-[0px_0px_15px_0px_rgba(0,0,0,0.25)]",
        ],
        backdrop: {
            styles: [
                "absolute",
                "inset-0",
                "transition-opacity",
                "bg-shadow",
                "opacity-20",
                "pointer-events-auto",
            ],
        },
        closeButton: {
            styles: [
                "rounded-full",
                "h-8",
                "w-8",
                "flex",
                "justify-center",
                "items-center",
                "border",
                "border-secondary-2",
                "bg-white",
                "focus:outline-none",
            ],
        },
    },
    textEditModal: {
        styles: [
            "absolute",
            "z-1",
            "bottom-0",
            "w-full",
            "p-7",
            "bg-white",
            "shadow-[0px_0px_15px_0px_rgba(0,0,0,0.25)]",
            "rounded-tl-3xl",
            "rounded-tr-3xl",
            "tablet:right-0",
            "tablet:h-full",
            "tablet:w-[480px]",
            "tablet:pt-10",
            "tablet:px-10",
            "tablet:pb-36",
            "tablet:bg-secondary-3",
            "tablet:rounded-tl-none",
            "tablet:rounded-tr-none",
        ],
        addTextLineButton: {
            styles: ["grid", "grid-flow-row", "gap-2"],
            icon: {
                styles: [
                    "grid",
                    "justify-self-center",
                    "justify-center",
                    "content-center",
                    "bg-black",
                    "hover:bg-secondary-1",
                    "rounded-full",
                    "h-10",
                    "w-10",
                    "text-white",
                    "text-sm",
                    "focus:outline-none",
                ],
            },
            text: {
                styles: ["text-sm"],
            },
            disabled: {
                styles: ["grid", "grid-flow-row", "gap-2"],
                icon: {
                    styles: [
                        "grid",
                        "justify-self-center",
                        "justify-center",
                        "content-center",
                        "bg-secondary-1",
                        "rounded-full",
                        "opacity-50",
                        "h-10",
                        "w-10",
                        "text-white",
                        "text-sm",
                        "focus:outline-none",
                    ],
                },
                text: {
                    styles: ["text-sm", "opacity-50"],
                },
            },
        },
        textLineEditModal: {
            styles: [
                "absolute",
                "bottom-0",
                "left-0",
                "right-0",
                "top-0",
                "max-h-full",
                "m-0",
                "p-7",
                "bg-white",
                "rounded-3xl",
                "flex",
                "flex-col",
                "tablet:top-auto",
                "tablet:p-10",
                "tablet:bottom-24",
            ],
            subModal: {
                styles: [
                    "absolute",
                    "bottom-0",
                    "left-0",
                    "right-0",
                    "top-0",
                    "max-h-full",
                    "m-0",
                    "p-7",
                    "bg-white",
                    "rounded-3xl",
                    "tablet:p-10",
                ],
            },
        },
        backdrop: {
            styles: ["hidden"],
        },
        closeButton: {
            styles: ["hidden"],
        },
    },
    colorButton: {
        styles: [
            "align-self-center",
            "grid",
            "justify-center",
            "items-center",
            "rounded-full",
            "w-14",
            "h-14",
            "border",
            "border-transparent",
        ],
        selected: {
            styles: [
                "align-self-center",
                "grid",
                "justify-center",
                "items-center",
                "rounded-full",
                "w-14",
                "h-14",
                "border",
                "border-black",
            ],
        },
        color: {
            styles: [
                "align-self-center",
                "grid",
                "justify-center",
                "items-center",
                "rounded-full",
                "w-12",
                "h-12",
                "border",
                "border-secondary-2",
            ],
        },
        label: {
            styles: ["text-xs", "mt-1"],
        },
    },
    notificationWrapper: {
        styles: [
            "absolute",
            "z-[100]",
            "flex",
            "flex-col",
            "gap-2",
            "px-5",
            "pt-3",
            "w-full",
            "justify-center",
            "overflow-auto",
        ],
    },
    notification: {
        styles: [
            "self-center",
            "pointer-events-auto",
            "bg-secondary-3",
            "flex",
            "border",
            "border-secondary-2",
            "rounded-lg",
            "w-full",
            "max-w-md",
            "min-h-[56px]",
            "justify-between",
        ],
        icon: {
            styles: ["self-center", "m-2"],
        },
        label: {
            styles: ["block", "self-center", "m-2"],
        },
    },
    stageZoomButton: {
        styles: [
            "grid",
            "grid-flow-row",
            "gap-1",
            "justify-items-center",
            "auto-rows-min",
            "w-full",
            "tablet:w-24",
            "tablet:gap-2",
            "desktop:w-32",
        ],
        icon: {
            styles: [
                "w-12",
                "h-12",
                "rounded-full",
                "border",
                "border-secondary-2",
                "hover:border-black",
                "grid",
                "content-center",
                "justify-center",
                "text-lg",
                "tablet:w-10",
                "tablet:h-10",
                "tablet:text-md",
                "desktop:w-16",
                "desktop:h-16",
                "desktop:text-lg",
            ],
        },
        label: {
            styles: ["self-center", "text-xs", "w-24"],
        },
    },
    basemodal: {
        closeButton: {
            styles: [
                "rounded-full",
                "h-8",
                "w-8",
                "bg-transparent",
                "text-white",
                "border",
                "border-white",
                "items-self-end",
            ],
        },
    },
};

const objectIdMap = new WeakMap<object, number>();
let objectCount = 0;

/**
 * **Use with caution!**
 *
 * Can be used for the key of element loops (v-for) when looping over objects
 * without an id.
 */
export function objectId(object: object) {
    if (!objectIdMap.has(object)) objectIdMap.set(object, ++objectCount);

    return objectIdMap.get(object);
}

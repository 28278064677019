<template>
    <transition
        appear
        enter-active-class="ease-out duration-300"
        enter-from-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="ease-in duration-200"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
    >
        <div class="flex flex-col gap-3">
            <button
                v-for="fontEntry in fonts"
                :key="fontEntry.id"
                :data-testid="`font-${fontEntry.id}`"
                type="button"
                class="flex w-full items-stretch justify-center divide-x divide-secondary-2 rounded-full border border-secondary-2 py-2 hover:enabled:border-black disabled:opacity-50"
                :class="{
                    'border-secondary-3 bg-secondary-3 text-black':
                        selectedFontId === fontEntry.id,
                }"
                :disabled="!isFontSelectable(fontEntry)"
                @click="
                    selectedFontId = fontEntry.id;
                    emit('selected');
                "
            >
                <div class="flex items-center justify-center">
                    <img
                        v-if="fontEntry.preview_url"
                        :src="fontEntry.preview_url"
                        class="inline h-6"
                    />
                    <span v-else class="font-semibold">
                        {{ fontEntry.display_name }}
                    </span>
                    <span
                        v-if="fontEntry.letter_case_set === 'only-uppercase'"
                        class="-mt-1 ml-0.5 text-sm font-semibold"
                    >
                        *
                    </span>
                </div>
            </button>
            <div
                v-if="showUpperCaseInfo"
                class="mr-3 text-right text-xs text-secondary-1"
            >
                * {{ t("only_uppercase") }}
            </div>
        </div>
    </transition>
</template>

<script setup lang="ts">
import { useFonts } from "@/composables/useFonts";
import { isItalicFont } from "@/functions/FontHelper";
import type { apiClient } from "@smakecloud/designer-core";
import { computed, toRefs } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const selectedFontId = defineModel<number>({ required: true });
type FontData =
    (typeof apiClient.configurations.app.get.outputType)["fonts"][number];

const props = defineProps<{
    productionMethodId: number;
}>();

const { productionMethodId } = toRefs(props);

const { fonts: currentAvailableFonts } = useFonts(productionMethodId);
const selectedFont = computed(() =>
    currentAvailableFonts.value?.find(
        (font) => font.id === selectedFontId.value,
    ),
);

// computed
const showUpperCaseInfo = computed<boolean>(() => {
    return fonts.value.some(
        (font) => font.letter_case_set === "only-uppercase",
    );
});

const isSelectedFontItalic = computed(() => {
    if (!selectedFont.value) {
        return false;
    }

    return isItalicFont(selectedFont.value);
});

const fonts = computed(() => {
    if (!currentAvailableFonts.value) {
        return [];
    }

    return Object.values(
        Object.groupBy(
            currentAvailableFonts.value,
            ({ family_name }) => family_name,
        ),
    )
        .map((fonts) => {
            if (isSelectedFontItalic.value) {
                const italicFont = fonts?.find((font) => isItalicFont(font));
                if (italicFont) return italicFont;
            }

            return fonts?.find((font) => !isItalicFont(font)) ?? fonts?.[0];
        })
        .filter((font) => font !== undefined)
        .sort((fontA, fontB) => {
            if (fontA.family_name < fontB.family_name) {
                return -1;
            }
            if (fontA.family_name > fontB.family_name) {
                return 1;
            }
            return 0;
        });
});

function isFontSelectable(font: FontData) {
    if (isSelectedFontItalic.value) {
        return isItalicFont(font);
    }

    return !isItalicFont(font);
}

const emit = defineEmits<{
    selected: [];
}>();
</script>

export enum FontAwesomeKitIcons {
    ArrowCircleGreyLeft = "icon-arrow-circle-grey-left",
    ArrowCircleGreyRight = "icon-arrow-circle-grey-right",
    ExpanderClose = "icon-expander-clos",
    ExpanderOpen = "icon-expander-open",
    ExpanderSmallClose = "icon-expander-small-close", // type clos before
    ExpanderSmallOpen = "icon-expander-small-open",
    CiConfigArchive = "icon-ciconfig-archive",
    CiConfigAreaOnOff = "icon-ciconfig-areaonoff",
    CloseLarge = "icon-close-large",
    CloseMedium = "icon-close-medium",
    CloseSmall = "icon-close-small",
    Edit = "icon-edit",
    Info = "icon-info",
    Remove = "icon-remove",
    Resize = "resize",
    CiConfigAlertCheck = "icon-ciconfig-alert-check",
    CiConfigAlertWarning = "icon-ciconfig-alert-warning",
    X = "icon-x",
    zoomIn = "icon-zoom-in",
    zoomOut = "icon-zoom-out",
    Download = "download",
    DesignIconDb = "icon-design-database",
    DesignUserLogo = "icon-design-logo",
    DesignText = "icon-design-text",
    TextAlignment = "icon-text-alignment",
    Embroidery = "icon-direkteinstickung",
    DirectToFilm = "icon-easytransfer",
}

<template>
    <button
        v-if="!subCategory.children.length"
        type="button"
        class="rounded-full border border-secondary-2 px-4 py-1.5"
        :class="
            selected
                ? 'bg-black text-white hover:bg-black/80'
                : 'hover:bg-secondary-3'
        "
        @click="logosStore.toggleCategory(subCategory)"
    >
        {{ subCategory.name }}
        <span
            class="ml-2.5 text-secondary-1"
            :class="{ 'text-white': selected }"
        >
            {{ getLogoCount([subCategory]) }}
        </span>
    </button>
    <disclosure v-else v-slot="{ open }">
        <div
            class="grid grid-rows-subgrid"
            :style="{
                gridRowEnd: open
                    ? `span ${subCategory.children.length + 1}`
                    : undefined,
            }"
        >
            <div
                class="flex overflow-hidden rounded-full border border-secondary-2"
                :class="
                    selected
                        ? 'bg-black text-white hover:bg-black/80'
                        : 'hover:bg-secondary-3'
                "
            >
                <button
                    type="button"
                    class="grow py-1.5 pl-4"
                    @click="logosStore.toggleCategory(subCategory)"
                >
                    {{ subCategory.name }}
                    <span
                        class="ml-2.5 text-secondary-1"
                        :class="{ 'text-white': selected }"
                    >
                        {{ getLogoCount([subCategory]) }}
                    </span>
                </button>
                <disclosure-button class="px-4">
                    <f-a-icon :icon="open ? 'chevron-up' : 'chevron-down'" />
                </disclosure-button>
            </div>
            <disclosure-panel
                class="grid grid-rows-subgrid"
                :style="{ gridRowEnd: `span ${subCategory.children.length}` }"
            >
                <logo-selection-sub-category
                    v-for="subSubCategory in subCategory.children"
                    :key="subSubCategory.id"
                    :sub-category="subSubCategory"
                    class="ml-6"
                />
            </disclosure-panel>
        </div>
    </disclosure>
</template>

<script setup lang="ts">
import useLogoCategoryUtils from "@/composables/useLogoCategoryUtils";
import { type WithChildren } from "@/composables/useTreeUtils";
import { useLogosStore } from "@/store/useLogosStore";
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
import { type LogoCategoryData } from "@smakecloud/smake-use";
import { storeToRefs } from "pinia";
import { computed } from "vue";

const props = defineProps<{
    subCategory: WithChildren<LogoCategoryData>;
}>();

const logosStore = useLogosStore();
const { activeCategoryIds } = storeToRefs(logosStore);

const { getLogoCount } = useLogoCategoryUtils();

const selected = computed(() =>
    activeCategoryIds.value.includes(props.subCategory.id),
);
</script>

import type { DesignerProps } from "@/main.ce";
import {
    apiClient,
    useApiClientStore,
    useTokenStore,
} from "@smakecloud/designer-core";
import { useDesignerApi } from "@smakecloud/smake-use";
import { storeToRefs } from "pinia";
import { computed, toRef, watchEffect, type MaybeRefOrGetter } from "vue";
import * as Sentry from "@sentry/vue";

export function useInitDesignerApi(props: MaybeRefOrGetter<DesignerProps>) {
    const propsRef = toRef(props);
    const {
        init,
        xTabSessionId,
        tokenData: legacyTokenData,
    } = useDesignerApi();
    const { baseUrl, acceptLanguage, sessionId } =
        storeToRefs(useApiClientStore());
    const { userExternalIdentifier, manualTokenData, tokenData } =
        storeToRefs(useTokenStore());

    if (propsRef.value.debug) {
        console.debug(`designer api url: ${propsRef.value.apiBaseUrl}`);
    }

    watchEffect(() => {
        void init({
            apiVersion: "2024-07-15",
            baseUrl: propsRef.value.apiBaseUrl,
            token: propsRef.value.token,
            acceptLanguage: propsRef.value.locale,
            user_external_identifier: propsRef.value.user?.external_identifier,
        });
    });

    watchEffect(() => {
        baseUrl.value = propsRef.value.apiBaseUrl;
    });

    watchEffect(() => {
        userExternalIdentifier.value = propsRef.value.user?.external_identifier;
    });

    watchEffect(() => {
        sessionId.value = xTabSessionId.value;
    });

    watchEffect(() => {
        manualTokenData.value = legacyTokenData.value;
    });

    watchEffect(() => {
        acceptLanguage.value = propsRef.value.locale;
    });

    watchEffect(() => {
        Sentry.setTag("api-base-url", propsRef.value.apiBaseUrl);
    });

    watchEffect(() => {
        if (!tokenData.value) return;

        Sentry.setUser({ id: tokenData.value.user_id });
    });

    const query = apiClient.configurations.app.get.use();
    const isAuthed = computed(() => query.enabled.value);

    return {
        isAuthed,
    };
}

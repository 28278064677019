import type { LogoCategoryData } from "@smakecloud/smake-use";
import type { WithChildren } from "./useTreeUtils";
import useTreeUtils from "./useTreeUtils";

export default () => {
    const { forEach } = useTreeUtils();

    function getLogoCount(categories: WithChildren<LogoCategoryData>[]) {
        const logos: number[] = [];

        forEach(categories, (category) => {
            logos.push(...category.logos);
        });

        return new Set(logos).size;
    }

    return {
        getLogoCount,
    };
};

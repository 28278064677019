import { fontAwesomeKitConfig } from "@/config/font-awesome-kit.config";

export default () => {
    function isKitIcon(iconName: string) {
        return (
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
            fontAwesomeKitConfig.icons.filter((icon) => icon === iconName)
                .length > 0
        );
    }

    return {
        isKitIcon,
    };
};

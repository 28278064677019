<template>
    <div class="pointer-events-auto">
        <label for="font" :class="settings.style.input.label.styles">
            {{ t("zoom") }}
        </label>
        <div>
            <input
                v-model.number="current"
                :class="settings.style.slider.styles"
                type="range"
                :min="min"
                :max="max + 0.0000001"
                :step="step"
                :disabled="disabled"
            />
        </div>
    </div>
</template>

<script setup lang="ts">
import { useMainStore } from "@/store/useMainStore";
import { storeToRefs } from "pinia";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const { settings } = storeToRefs(useMainStore());

defineProps<{
    disabled: boolean;
}>();

const min = ref<number>(1);
const max = ref<number>(1);
const current = ref<number>(1);

const step = computed<number>(() => {
    return (max.value - min.value) / 10;
});
</script>

import { type UserData } from "@smakecloud/smake-use";

export type DefaultUser = {
    external_identifier: string;
};

export type PusherConfig = {
    appKey: string;
    channelPrefix: string;
    cluster: string;
};

export const DesignerSupportedLocales = ["cs", "de", "en", "fr", "nl"];

export type DesignerLocale = "cs" | "de" | "en" | "fr" | "nl";

export function isDesignerLocale(locale: string): locale is DesignerLocale {
    return DesignerSupportedLocales.includes(locale);
}

export type VariantId = {
    id: number;
};

export type VariantExternalIdentifier = {
    external_identifier: string;
};

export type VariantIdentifier = VariantId | VariantExternalIdentifier;
export type VariantIdentifierComplete = VariantId & VariantExternalIdentifier;

export type DesignerProps = {
    token: string;
    variant: VariantIdentifier;
    user?: DefaultUser | UserData;
    pusher: PusherConfig;
    locale: DesignerLocale;
    debug: boolean;
    apiBaseUrl: string;
    availableVariants: VariantIdentifier[];
    cartItems: number[];
    featureFlagLogoSearch?: boolean;
    customerLogoUploadInfo?: string;
};

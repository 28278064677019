import { defineStore } from "pinia";
import { ref } from "vue";

export const usePropsStore = defineStore("propsStore", () => {
    const customerLogoUploadInfo = ref<string>();

    return {
        customerLogoUploadInfo,
    };
});

import ProductionMethod from "@/repo/ProductionMethod";
import type { ProductionMethodData, VariantData } from "@smakecloud/smake-use";
import { defineStore, storeToRefs } from "pinia";
import { useRepo } from "pinia-orm";
import { computed, readonly, ref, watch } from "vue";
import { ProductionMethodHandle } from "@/composables/useProductionMethodFeatures";
import { useInitialVariantStore } from "./useInitialVariantStore";
import { hasVariantDataSupportedPositioningAreas } from "@/functions/ProductionMethodInfo";
import { useQuery } from "@tanstack/vue-query";
import { apiClient } from "@smakecloud/designer-core";

export const useProductionMethodStore = defineStore(
    "productionMethodStore",
    () => {
        const isInitialized = ref(false);

        const {
            initialVariant,
            isInitialVariantEmbroideryOnly,
            isInitialVariantDTFOnly,
            isInitalVariantEmpty,
        } = storeToRefs(useInitialVariantStore());

        const { data: productionMethodData } = useQuery({
            ...apiClient.configurations.app.get.use(),
            staleTime: Infinity,
            select: (data) => data.productionMethods,
            retry: false,
        });

        watch(
            productionMethodData,
            () => {
                if (!productionMethodData.value) {
                    return;
                }

                changeCurrentActiveProductionMethods(
                    useRepo(ProductionMethod).save(productionMethodData.value),
                );

                isInitialized.value = true;
            },
            { immediate: true },
        );

        const productionMethodRepo = useRepo(ProductionMethod);
        const currentActiveProductionMethodIds = ref<number[]>([]);

        const currentActiveProductionMethods = computed<ProductionMethod[]>(
            () => {
                return productionMethodRepo
                    .query()
                    .whereIn("id", currentActiveProductionMethodIds.value)
                    .get()
                    .sort(
                        (productionMethodA, productionMethodB) =>
                            productionMethodA.id - productionMethodB.id,
                    );
            },
        );

        const initialVariantProductionMethodsWithPositioningAreas = computed(
            () => {
                if (!initialVariant.value) {
                    return [];
                }

                return currentActiveProductionMethods.value.filter(
                    (productionMethod) =>
                        hasVariantDataSupportedPositioningAreas(
                            initialVariant.value as VariantData,
                            productionMethod.id,
                        ),
                );
            },
        );

        function initialCurrentProductionMethod(): ProductionMethod {
            if (
                initialVariantProductionMethodsWithPositioningAreas.value.some(
                    (productionMethod) =>
                        productionMethod.id === currentProductionMethodId.value,
                )
            ) {
                return currentProductionMethod.value;
            }

            if (currentActiveProductionMethods.value.length === 1) {
                return currentActiveProductionMethods.value[0];
            }

            if (isInitalVariantEmpty.value) {
                return currentActiveProductionMethods.value[0];
            }

            if (isInitialVariantDTFOnly.value) {
                return (
                    currentActiveProductionMethods.value.find(
                        (productionMethod) =>
                            (productionMethod.handle as ProductionMethodHandle) ===
                            ProductionMethodHandle.DTF,
                    ) ?? currentActiveProductionMethods.value[0]
                );
            }

            if (isInitialVariantEmbroideryOnly.value) {
                return (
                    currentActiveProductionMethods.value.find(
                        (productionMethod) =>
                            (productionMethod.handle as ProductionMethodHandle) ===
                            ProductionMethodHandle.EMBROIDERY,
                    ) ?? currentActiveProductionMethods.value[0]
                );
            }

            return currentActiveProductionMethods.value[0];
        }

        const currentProductionMethodId = ref<number>(0);

        watch(
            initialVariant,
            () => {
                if (!initialVariant.value) {
                    return;
                }

                currentProductionMethod.value =
                    initialCurrentProductionMethod();
            },
            { immediate: true },
        );

        const currentProductionMethod = computed<ProductionMethod>({
            get() {
                const productionMethod = productionMethodRepo.find(
                    currentProductionMethodId.value,
                );

                if (productionMethod === null) {
                    throw Error("could not load current production method");
                }

                return productionMethod;
            },
            set(newValue) {
                changeCurrentProductionMethod(newValue);
            },
        });

        function changeCurrentProductionMethod(
            productionMethod: ProductionMethod | ProductionMethodData,
        ) {
            if (productionMethod.id === currentProductionMethodId.value) {
                return;
            }

            if (!productionMethodRepo.find(productionMethod.id)) {
                productionMethodRepo.save(productionMethod);
            }

            currentProductionMethodId.value = productionMethod.id;
        }

        function changeCurrentActiveProductionMethods(
            productionMethods: ProductionMethodData[],
        ) {
            // ensure the view is saved in ORM
            productionMethodRepo.save(productionMethods);
            currentActiveProductionMethodIds.value = productionMethods.map(
                (productionMethod) => productionMethod.id,
            );
        }

        function getById(
            id: number,
            throwOnNotFound?: false,
        ): ProductionMethod | undefined;
        function getById(id: number, throwOnNotFound: true): ProductionMethod;
        function getById(id: number, throwOnNotFound?: boolean) {
            const productionMethod = currentActiveProductionMethods.value.find(
                (productionMethod) => productionMethod.id === id,
            );
            if (!productionMethod && throwOnNotFound) {
                throw new Error("Production method not found");
            }
            return productionMethod;
        }

        return {
            changeCurrentProductionMethod,
            changeCurrentActiveProductionMethods,
            getById,
            currentActiveProductionMethods,
            currentProductionMethod,
            currentProductionMethodId: readonly(currentProductionMethodId),
            isInitialized: readonly(isInitialized),
        };
    },
);

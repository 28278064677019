import { computed, toRef, type MaybeRefOrGetter } from "vue";
import { useFonts } from "./useFonts";
import { isItalicFont } from "@/functions/FontHelper";
import type { ApiTextDesign } from "@smakecloud/designer-core";

export function useTextResourceLine(
    textResourceLine: MaybeRefOrGetter<
        ApiTextDesign["resource"]["lines"][number]
    >,
    productionMethodId: MaybeRefOrGetter<number>,
) {
    const { getItalicFontForFamilyName, getRegularFontForFamilyName } =
        useFonts(productionMethodId);
    const textResourceLineRef = toRef(textResourceLine);

    const { fonts } = useFonts(productionMethodId);

    const currentFont = computed(() =>
        fonts.value?.find(
            (font) => font.id === textResourceLineRef.value.font.id,
        ),
    );

    function toggleItalic(): void {
        if (!currentFont.value) return;

        if (!isItalicFont(currentFont.value)) {
            const italicFont = getItalicFontForFamilyName(
                currentFont.value.family_name,
            );

            if (!italicFont) {
                return;
            }

            textResourceLineRef.value.font.id = italicFont.id;

            return;
        }

        const regularFont = getRegularFontForFamilyName(
            currentFont.value.family_name,
        );

        if (!regularFont) {
            return;
        }

        textResourceLineRef.value.font.id = regularFont.id;
    }

    const hasItalicFont = computed(() => {
        if (!currentFont.value) return false;

        return !!getItalicFontForFamilyName(currentFont.value.family_name);
    });

    const isFontItalic = computed(() => {
        if (!currentFont.value) return false;
        return isItalicFont(currentFont.value);
    });

    return {
        hasItalicFont,
        isFontItalic,
        toggleItalic,
    };
}

<template>
    <module-button
        v-if="!isInternal"
        :style-config="moduleButtonStyleConfig"
        :loading="isSaving"
        :disabled="
            isSaving ||
            isARenderingRequestInProgress ||
            !currentVariantHasCustomizations ||
            isCustomizationEditPanelState(currentPanelState) ||
            hasInvalidPositioningAreas
        "
        @click="storeVariant()"
    >
        <f-a-icon
            v-if="isSaving"
            icon="spinner"
            :size="FontAwesomeSize.lg"
            spin
            class="absolute inset-0 mr-0 flex items-center justify-center"
        />
        <span :class="isSaving ? 'opacity-0' : ''">
            {{ t("continue") }}
        </span>
    </module-button>
</template>

<script setup lang="ts">
import useRenderCustomizationPreview from "@/composables/useRenderCustomizationPreview";
import useStoreVariant from "@/composables/variants/useStoreVariant";
import { isCustomizationEditPanelState } from "@/enums/PanelState";
import { FontAwesomeSize } from "@/enums/fontAwesome/fontAwesomeSize";
import { useCanvasStore } from "@/store/useCanvasStore";
import { useCurrentStore } from "@/store/useCurrentStore";
import { useMainStore } from "@/store/useMainStore";
import { usePanelStore } from "@/store/usePanelStore";
import { useSettingsStore } from "@/store/useSettingsStore";
import { cloneDeep, get, merge, set } from "lodash";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const { settings } = useMainStore();
const { storeCurrentVariant } = useStoreVariant();
const { t } = useI18n();
const { isARenderingRequestInProgress } = useRenderCustomizationPreview();
const { isSaving, currentVariantHasCustomizations } =
    storeToRefs(useCurrentStore());
const { currentPanelState } = storeToRefs(usePanelStore());
const { positioningAreaCanvasObjects } = storeToRefs(useCanvasStore());
const { isInternal } = storeToRefs(useSettingsStore());

// props
const props = defineProps({
    styleConfig: {
        type: Object,
        default: () => {
            return {};
        },
    },
});

// computed
const moduleButtonStyleConfig = computed(() => {
    const baseStyleConfig = cloneDeep(props.styleConfig);

    ["styles", "icon.styles", "label.styles"].forEach((key) => {
        set(
            baseStyleConfig,
            key,
            merge(
                get(baseStyleConfig, key, []),
                get(settings.style, `storeVariantButton.${key}`, []),
            ),
        );
    });

    return baseStyleConfig;
});

const hasInvalidPositioningAreas = computed(() =>
    positioningAreaCanvasObjects.value.some(
        (positioningAreaCanvasObject) =>
            positioningAreaCanvasObject.has_object_with_invalid_position,
    ),
);

// methods
function storeVariant() {
    void storeCurrentVariant();
}
</script>

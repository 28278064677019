import { defineStore, storeToRefs } from "pinia";
import { useCurrentStore } from "./useCurrentStore";
import { useQuery } from "@tanstack/vue-query";
import { apiClient } from "@smakecloud/designer-core";
import { computed, watch } from "vue";
import { useRepo } from "pinia-orm";
import Variant from "@/repo/Variant";

export const useCurrentVariantStore = defineStore("currentVariantStore", () => {
    const { currentVariantId } = storeToRefs(useCurrentStore());

    const { data: variantData } = useQuery({
        ...apiClient.variants.get.use(currentVariantId),
        staleTime: Infinity,
        retry: false,
        enabled: computed(() => currentVariantId.value !== 0),
    });

    const variantRepo = useRepo(Variant);

    watch(variantData, () => {
        if (!variantData.value) {
            return;
        }

        const variantAttributes = {
            ...variantData.value,
            color: variantData.value.attributes.filter(
                (optionValue) => optionValue.name === "color",
            )[0],
            size: variantData.value.attributes.filter(
                (optionValue) => optionValue.name === "size",
            )[0],
            views: variantData.value.views.map((view) => {
                return {
                    ...view,
                    customizations: [],
                };
            }),
        };

        variantRepo.save(variantAttributes);
    });
});

<template>
    <div class="fixed inset-0 flex items-center justify-center">
        <transition-child
            appear
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
        >
            <base-dialog-panel
                class="flex w-[480px] flex-col items-center gap-6 p-4"
                has-close-button
            >
                <div class="w-full break-words">
                    <div class="float-right h-8 w-8" />
                    <i18n-translate
                        keypath="are_you_sure_you_want_to_delete"
                        tag="div"
                    >
                        <template #deleteName>
                            <span class="font-bold text-primary-1">
                                {{ name }}
                            </span>
                        </template>
                    </i18n-translate>
                    <div>
                        {{ t("this_action_can_not_be_undone") }}
                    </div>
                </div>
                <img
                    class="aspect-square w-80 bg-secondary-3 object-contain p-4"
                    :src="image"
                />
                <div
                    v-if="isError"
                    class="w-full rounded-lg border border-danger p-2 text-danger"
                >
                    {{ t("boot_error.unexpected") }}
                </div>
                <div class="flex w-full flex-col gap-4 tablet:flex-row">
                    <button-secondary-outlined
                        class="flex-grow"
                        :disabled="isPending"
                        @click="isOpen = false"
                    >
                        {{ t("abort") }}
                    </button-secondary-outlined>
                    <button-primary
                        class="flex flex-grow items-center gap-2"
                        :disabled="isPending"
                        @click="deleteMutate"
                    >
                        <f-a-icon
                            :icon="FontAwesomeKitIcons.Remove"
                            :size="FontAwesomeSize.lg"
                        />
                        {{ t("delete") }}
                    </button-primary>
                </div>
            </base-dialog-panel>
        </transition-child>
    </div>
</template>

<script setup lang="ts">
import { useInjectDialogData } from "@/composables/useDialogData";
import { FontAwesomeKitIcons } from "@/enums/fontAwesome/fontAwesomeKitIcons";
import { FontAwesomeSize } from "@/enums/fontAwesome/fontAwesomeSize";
import { TransitionChild } from "@headlessui/vue";
import { useMutation } from "@tanstack/vue-query";
import { useI18n } from "vue-i18n";

const props = defineProps<{
    image: string;
    name: string;
    delete: () => Promise<void>;
}>();

const emit = defineEmits<{
    deleted: [];
}>();

const { t } = useI18n();
const { isOpen } = useInjectDialogData();

const {
    mutate: deleteMutate,
    isPending,
    isError,
} = useMutation({
    mutationFn: props.delete,
    onSuccess: () => {
        emit("deleted");

        isOpen.value = false;
    },
});
</script>

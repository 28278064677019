<template>
    <div
        v-if="
            moduleButtons.length &&
            (!isInternal || screenName === ScreenName.MOBILE)
        "
        ref="bottom-2-panel"
        :class="panelClasses"
    >
        <div :class="panelInnerWrapperClasses">
            <component
                :is="asyncComponents()[moduleButton]"
                v-for="moduleButton in moduleButtons"
                :key="moduleButton"
                :style-config="moduleButtonsStyleConfig()"
            />
        </div>
    </div>
</template>

<script setup lang="ts">
import { useDesignerScreenSizeName } from "@/composables/useDesignerScreenSizeName";
import { PanelName } from "@/enums/PanelName";
import { ScreenName } from "@/enums/ScreenName";
import { useMainStore } from "@/store/useMainStore";
import { useSettingsStore } from "@/store/useSettingsStore";
import asyncComponents from "@/support/asyncComponents";
import { useModules } from "@/support/modules";
import { storeToRefs } from "pinia";
import { computed } from "vue";

const mainStore = useMainStore();
const { settings } = mainStore;
const { buttonsForPanel } = useModules();
const { screenName } = useDesignerScreenSizeName();
const { isInternal } = storeToRefs(useSettingsStore());

// computed
const moduleButtons = computed(() => {
    return buttonsForPanel(PanelName.BUTTON_PANEL_BOTTOM_2);
});

const panelClasses = computed(() => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
    return settings.style.buttonPanelBottom2.styles.join(" ");
});

const panelInnerWrapperClasses = computed(() => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
    return settings.style.buttonPanelBottom2.innerWrapper.styles.join(" ");
});

const moduleButtonsStyleConfig = () => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access
    return settings.style.buttonPanelBottom2.moduleButton;
};
</script>

import {
    getHorizontalPositionPartFromPositionAreaPosition,
    getVerticalPositionPartFromPositionAreaPosition,
    PositionPartHorizontal,
    PositionPartVertical,
} from "@/enums/Position";
import { type InnerAnchorPoints } from "@smakecloud/smake-use";

export default () => {
    function calculateCoordinatesForPosition(
        x: number,
        y: number,
        width: number,
        height: number,
        rotation: number,
        positionFrom: InnerAnchorPoints,
        positionTo: InnerAnchorPoints,
    ) {
        const shiftPositionX: number = calculateHorizontalShiftWithoutRotation(
            positionFrom,
            positionTo,
            width,
        );
        const shiftPositionY: number = calculateVerticalShiftWithoutRotation(
            positionFrom,
            positionTo,
            height,
        );

        const rotationRad = deg2rad(rotation);

        const shiftWithRotationPositionX =
            shiftPositionX * Math.cos(rotationRad) -
            shiftPositionY * Math.sin(rotationRad);
        const shiftWithRotationPositionY =
            shiftPositionX * Math.sin(rotationRad) +
            shiftPositionY * Math.cos(rotationRad);

        return {
            x: x + shiftWithRotationPositionX,
            y: y + shiftWithRotationPositionY,
        };
    }

    function calculateHorizontalShiftWithoutRotation(
        previousAnchorPosition: InnerAnchorPoints,
        newAnchorPosition: InnerAnchorPoints,
        width: number,
    ): number {
        return (
            (shiftFactorHorizontal(
                getHorizontalPositionPartFromPositionAreaPosition(
                    previousAnchorPosition,
                ),
                getHorizontalPositionPartFromPositionAreaPosition(
                    newAnchorPosition,
                ),
            ) *
                width) /
            2
        );
    }

    function calculateVerticalShiftWithoutRotation(
        previousAnchorPosition: InnerAnchorPoints,
        newAnchorPosition: InnerAnchorPoints,
        height: number,
    ): number {
        return (
            (shiftFactorVertical(
                getVerticalPositionPartFromPositionAreaPosition(
                    previousAnchorPosition,
                ),
                getVerticalPositionPartFromPositionAreaPosition(
                    newAnchorPosition,
                ),
            ) *
                height) /
            2
        );
    }

    function verticalPositionFactor(
        positionVertical: PositionPartVertical,
    ): number {
        if (positionVertical === PositionPartVertical.TOP) {
            return 1;
        }

        if (positionVertical === PositionPartVertical.CENTER) {
            return 0;
        }

        return -1;
    }

    function horizontalPositionFactor(
        positionHorizontal: PositionPartHorizontal,
    ): number {
        if (positionHorizontal === PositionPartHorizontal.LEFT) {
            return 1;
        }

        if (positionHorizontal === PositionPartHorizontal.CENTER) {
            return 0;
        }

        return -1;
    }

    function shiftFactorHorizontal(
        previousPostionHorizontal: PositionPartHorizontal,
        newPositionHorizontal: PositionPartHorizontal,
    ): number {
        return (
            horizontalPositionFactor(previousPostionHorizontal) -
            horizontalPositionFactor(newPositionHorizontal)
        );
    }

    function shiftFactorVertical(
        previousPostionVertical: PositionPartVertical,
        newPositionVertical: PositionPartVertical,
    ): number {
        return (
            verticalPositionFactor(previousPostionVertical) -
            verticalPositionFactor(newPositionVertical)
        );
    }

    const deg2rad = (deg: number): number => {
        return deg * (Math.PI / 180.0);
    };

    return {
        calculateCoordinatesForPosition,
    };
};

import { defineStore } from "pinia";
import { ref } from "vue";

export enum ArchiveModalTab {
    CART = "cart",
    ARCHIVED = "archived",
    MERCHANT_SKETCH = "merchant_sketch",
}

export const useArchiveVariantStore = defineStore("archiveVariantStore", () => {
    const isNewMerchantSketchModalOpen = ref(false);
    const isLoadingArchiveVariant = ref<boolean>(false);
    const defaultTab = ref<ArchiveModalTab>();

    return {
        isNewMerchantSketchModalOpen,
        isLoadingArchiveVariant,
        defaultTab,
    };
});

<template>
    <panel
        data-testid="ArchiveModal"
        :style-config="styleConfig.panel"
        :show-on-panel-state="PanelState.Archive"
        :can-be-closed="!isLoadingArchiveVariant"
    >
        <div class="flex h-full flex-col gap-4">
            <h2 class="mb-3 text-lg font-semibold uppercase">
                {{ t("my_customizations") }}
            </h2>
            <div
                v-if="isArchiving"
                class="mb-4 animate-pulse rounded-3xl bg-secondary-3 px-5 py-8 tablet:bg-white"
            >
                {{ t("archive_new_entry_soon_available") }}
            </div>

            <div v-if="isLoadingTabs" class="flex flex-wrap gap-2">
                <div
                    v-for="n in 2"
                    :key="n"
                    class="h-14 w-64 animate-pulse rounded-full bg-secondary-3"
                ></div>
            </div>
            <tabs-root v-model="selectedTab">
                <tabs-list
                    v-if="
                        [showCartTab, showMerchantSketchTab].filter(Boolean)
                            .length > 0 && !isLoadingTabs
                    "
                    class="mb-4 flex flex-wrap gap-2"
                >
                    <variant-tab
                        v-if="showCartTab"
                        :value="ArchiveModalTab.CART"
                    >
                        {{ t("currently_in_cart") }}
                    </variant-tab>
                    <variant-tab :value="ArchiveModalTab.ARCHIVED">
                        {{ t("saved") }}
                    </variant-tab>
                    <variant-tab
                        v-if="showMerchantSketchTab"
                        :value="ArchiveModalTab.MERCHANT_SKETCH"
                    >
                        {{ t("sketches") }}
                    </variant-tab>
                </tabs-list>
                <tabs-content
                    as="template"
                    :value="ArchiveModalTab.CART"
                    :unmount="false"
                >
                    <cart-variants-panel />
                </tabs-content>
                <tabs-content
                    :value="ArchiveModalTab.ARCHIVED"
                    as="template"
                    :unmount="false"
                >
                    <archive-variants-panel />
                </tabs-content>
                <tabs-content
                    :value="ArchiveModalTab.MERCHANT_SKETCH"
                    as="template"
                    :unmount="false"
                >
                    <variants-collection-panel
                        collection-name="merchant-sketch"
                    />
                </tabs-content>
            </tabs-root>
        </div>
    </panel>
</template>

<script setup lang="ts">
import { useVariantsCollection } from "@/composables/variants/useVariantsCollection";
import { PanelState } from "@/enums/PanelState";
import {
    ArchiveModalTab,
    useArchiveVariantStore,
} from "@/store/useArchiveVariantStore";
import { useCartVariantsStore } from "@/store/useCartVariantsStore";
import { useCurrentStore } from "@/store/useCurrentStore";
import { useMainStore } from "@/store/useMainStore";
import get from "lodash/get";
import { storeToRefs } from "pinia";
import { TabsContent as TabsContent, TabsList, TabsRoot } from "radix-vue";
import { computed, onUnmounted, ref, watchEffect } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const { isArchiving } = storeToRefs(useCurrentStore());
const { settings } = storeToRefs(useMainStore());
const { isLoadingArchiveVariant, defaultTab } = storeToRefs(
    useArchiveVariantStore(),
);

const selectedTab = ref(ArchiveModalTab.ARCHIVED);

// computed
const styleConfig = computed(() => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return get(settings.value.style, "archiveModal", {});
});

const isLoadingTabs = computed(
    () =>
        isFetchingCartVariants.value || isFetchingMerchantSketchVariants.value,
);

const { data: cartVariants, isLoading: isFetchingCartVariants } = storeToRefs(
    useCartVariantsStore(),
);

const {
    data: merchantSketchVariants,
    isLoading: isFetchingMerchantSketchVariants,
} = useVariantsCollection("merchant-sketch");

const showCartTab = computed(() => (cartVariants.value?.length ?? 0) > 0);
const showMerchantSketchTab = computed(
    () => (merchantSketchVariants.value?.length ?? 0) > 0,
);

function selectTab(defaultTab?: ArchiveModalTab) {
    if (
        (defaultTab === undefined || defaultTab === ArchiveModalTab.CART) &&
        showCartTab.value
    ) {
        selectedTab.value = ArchiveModalTab.CART;
        return;
    }

    if (defaultTab === undefined || defaultTab === ArchiveModalTab.ARCHIVED) {
        selectedTab.value = ArchiveModalTab.ARCHIVED;
        return;
    }

    if (
        (defaultTab === undefined ||
            defaultTab === ArchiveModalTab.MERCHANT_SKETCH) &&
        showMerchantSketchTab.value
    ) {
        selectedTab.value = ArchiveModalTab.MERCHANT_SKETCH;
        return;
    }

    if (defaultTab) {
        selectTab();
    }
}

watchEffect(() => {
    if (isLoadingTabs.value) {
        return;
    }

    selectTab(defaultTab.value);
});

onUnmounted(() => {
    defaultTab.value = undefined;
});
</script>

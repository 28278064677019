import { useQuery } from "@tanstack/vue-query";
import type { Ref } from "vue";

export default (queryKey: string, imageUrl: Ref<string>) =>
    useQuery({
        queryKey: [queryKey, imageUrl],
        queryFn: () =>
            new Promise<HTMLImageElement>((resolve, reject) => {
                const img = new Image();
                img.onload = () => resolve(img);
                img.onerror = reject;
                img.crossOrigin = "Anonymous";
                img.src = imageUrl.value;
            }),
        staleTime: Infinity,
    });

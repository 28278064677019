<template>
    <div
        ref="canvas"
        data-testid="customization-render-preview"
        :data-is-rendering="isARenderingRequestInProgress"
        class="relative overflow-hidden [&_div]:contents"
    >
        <v-stage
            ref="stage"
            :key="'stage-' + customizationPreview.customization.id + stageKey"
            :config="stageConfig"
        >
            <v-layer
                :key="'layer-' + customizationPreview.customization.id"
                :config="layerConfig"
            >
                <v-image
                    :key="
                        'view-image-' +
                        customizationPreview.customization.id +
                        stageKey
                    "
                    :config="viewImageConfig"
                />
                <v-rect
                    :key="
                        'positioning-area-' +
                        customizationPreview.customization.id
                    "
                    :config="positioningAreaConfig"
                />
                <v-rect
                    v-if="
                        !customizationImage &&
                        customizationPreview.customization.design
                    "
                    :key="
                        'customization-skeleton-' +
                        customizationPreview.customization.id +
                        stageKey
                    "
                    :config="customizationSkeletonImageConfig"
                />
                <v-image
                    v-if="customizationImage"
                    :key="
                        'customization-' +
                        customizationPreview.customization.id +
                        stageKey
                    "
                    :config="customizationImageConfig"
                />
            </v-layer>
        </v-stage>
    </div>
</template>

<script setup lang="ts">
import useImage from "@/composables/useImage";
import useKonvaCalculations from "@/composables/useKonvaCalculations";
import useRenderCustomizationPreview from "@/composables/useRenderCustomizationPreview";
import { ArticleImageDimensions } from "@/enums/ArticleImageDimensions";
import { Style } from "@/enums/Style";
import type Customization from "@/repo/Customization";
import { useCanvasStore } from "@/store/useCanvasStore";
import { useCurrentStore } from "@/store/useCurrentStore";
import { useMainStore } from "@/store/useMainStore";
import { InnerAnchorPoints } from "@smakecloud/smake-use";
import { useElementSize } from "@vueuse/core";
import type Konva from "konva";
import { storeToRefs } from "pinia";
import { v4 as uuidv4 } from "uuid";
import { computed, ref, watch } from "vue";

const props = defineProps<{
    customizationPreview: {
        customization: Customization;
        isValid: boolean;
    };
}>();

const { positioningAreaCanvasObjects } = storeToRefs(useCanvasStore());

const currentStyle = computed<Style>(
    () =>
        positioningAreaCanvasObjects.value.find(
            (positioningAreaCanvasObject) =>
                positioningAreaCanvasObject.id ===
                props.customizationPreview.customization.positioning_area.id,
        )?.style ?? Style.dark,
);

const { currentView } = storeToRefs(useCurrentStore());
const { isARenderingRequestInProgress } = useRenderCustomizationPreview();

const canvas = ref<HTMLDivElement>();
const { width: stageWidth, height: stageHeight } = useElementSize(canvas);
const scale = computed(() => {
    const largestSideLength = Math.max(
        positioningAreaConfig.value.width ?? 1,
        positioningAreaConfig.value.height ?? 1,
    );

    return (stageWidth.value / largestSideLength) * 0.9;
});
const { settings } = useMainStore();

const viewImageUrl = computed(() => currentView.value.image_urls.xl);
const { data: viewImage } = useImage("current-view-image", viewImageUrl);

const customizationImageUrl = computed(
    () =>
        `data:image/png;base64, ${props.customizationPreview.customization.preview_base64}`,
);
const { data: customizationImage } = useImage(
    "customization-image",
    customizationImageUrl,
);

const stageConfig = computed(() => ({
    height: stageHeight.value,
    width: stageWidth.value,
}));

const { calculateCoordinatesForPosition } = useKonvaCalculations();

const layerConfig = computed<Konva.LayerConfig>(() => {
    const { x, y } = calculateCoordinatesForPosition(
        positioningAreaConfig.value.x ?? 1,
        positioningAreaConfig.value.y ?? 1,
        positioningAreaConfig.value.width ?? 1,
        positioningAreaConfig.value.height ?? 1,
        positioningAreaConfig.value.rotation ?? 1,
        props.customizationPreview.customization.positioning_area.position,
        InnerAnchorPoints.CENTER_CENTER,
    );

    const offsetX = stageWidth.value / 2 - x * scale.value;
    const offsetY = stageHeight.value / 2 - y * scale.value;

    return {
        x: offsetX,
        y: offsetY,
        scaleX: scale.value,
        scaleY: scale.value,
    };
});

const viewImageConfig = computed(() => ({
    image: viewImage.value,
    height: ArticleImageDimensions.HEIGHT,
    width: ArticleImageDimensions.WIDTH,
}));

const skeletonOpacity = ref(1);
function updateSkeletonOpacity() {
    if (customizationImage.value) return;
    skeletonOpacity.value =
        Math.sin(((document.timeline.currentTime as number) / 1000) * Math.PI) /
            4 +
        0.35;
    requestAnimationFrame(updateSkeletonOpacity);
}
updateSkeletonOpacity();

const customizationSkeletonImageConfig = computed<Konva.RectConfig>(() => {
    const size = {
        width: props.customizationPreview.customization.width,
        height: props.customizationPreview.customization.height,
    };

    return {
        cornerRadius: 2 * currentView.value.scalefactor,
        fill: `rgba(240, 241, 241, ${skeletonOpacity.value})`,
        width: size.width * currentView.value.scalefactor,
        height: size.height * currentView.value.scalefactor,
        rotation: props.customizationPreview.customization.rotation,
        ...calculateCoordinatesForPosition(
            props.customizationPreview.customization.positioning_area.x,
            props.customizationPreview.customization.positioning_area.y,
            size.width * currentView.value.scalefactor,
            size.height * currentView.value.scalefactor,
            props.customizationPreview.customization.rotation,
            props.customizationPreview.customization.position,
            InnerAnchorPoints.TOP_LEFT,
        ),
    };
});

const customizationImageConfig = computed(() => {
    const widthPx =
        props.customizationPreview.customization.width *
        currentView.value.scalefactor;
    const heightPx =
        props.customizationPreview.customization.height *
        currentView.value.scalefactor;

    return {
        image: customizationImage.value,
        width: widthPx,
        height: heightPx,
        rotation: props.customizationPreview.customization.rotation,
        ...calculateCoordinatesForPosition(
            props.customizationPreview.customization.x,
            props.customizationPreview.customization.y,
            widthPx,
            heightPx,
            props.customizationPreview.customization.rotation,
            props.customizationPreview.customization.position,
            InnerAnchorPoints.TOP_LEFT,
        ),
        opacity: isARenderingRequestInProgress.value ? 0.3 : 1,
    };
});

const stageKey = ref<string>(uuidv4());

watch(
    () => props.customizationPreview.customization,
    () => {
        stageKey.value = uuidv4();
    },
    {
        deep: true,
    },
);

const positioningAreaConfig = computed<Konva.RectConfig>(() => {
    return {
        x: props.customizationPreview.customization.positioning_area.x,
        y: props.customizationPreview.customization.positioning_area.y,
        width:
            props.customizationPreview.customization.positioning_area
                .localized_width * currentView.value.scalefactor,
        height:
            props.customizationPreview.customization.positioning_area
                .localized_height * currentView.value.scalefactor,
        stroke: props.customizationPreview.isValid
            ? `rgba(${settings.areaStyles[currentStyle.value].stroke.highlight.r},${settings.areaStyles[currentStyle.value].stroke.highlight.g},${settings.areaStyles[currentStyle.value].stroke.highlight.b},${settings.areaStyles[currentStyle.value].stroke.highlight.a})`
            : `rgba(${settings.areaStyles[currentStyle.value].stroke.error.r},${settings.areaStyles[currentStyle.value].stroke.error.g},${settings.areaStyles[currentStyle.value].stroke.error.b},${settings.areaStyles[currentStyle.value].stroke.error.a})`,
        strokeWidth: settings.areaStyles[currentStyle.value].strokeWidth,
        dash: settings.areaStyles[currentStyle.value].strokeDashArray,
        offsetX: positioningAreaOffsetX.value * currentView.value.scalefactor,
        offsetY: positioningAreaOffsetY.value * currentView.value.scalefactor,
        rotation:
            props.customizationPreview.customization.positioning_area.rotation,
    };
});

const positioningAreaOffsetX = computed<number>(() => {
    switch (
        props.customizationPreview.customization.positioning_area.position.split(
            "",
        )[1]
    ) {
        case "l":
            return 0;
        case "r":
            return props.customizationPreview.customization.positioning_area
                .localized_width;
        default:
            return (
                props.customizationPreview.customization.positioning_area
                    .localized_width / 2
            );
    }
});

const positioningAreaOffsetY = computed<number>(() => {
    switch (
        props.customizationPreview.customization.positioning_area.position.split(
            "",
        )[0]
    ) {
        case "t":
            return 0;
        case "b":
            return props.customizationPreview.customization.positioning_area
                .localized_height;
        default:
            return (
                props.customizationPreview.customization.positioning_area
                    .localized_height / 2
            );
    }
});
</script>

<script setup lang="ts">
import { ref, type ComponentOptionsMixin, type DefineComponent } from "vue";
import ColorInputRgb from "./ColorInputRgb.vue";
import ColorInputHex from "./ColorInputHex.vue";
import {
    SelectContent,
    SelectItem,
    SelectItemText,
    SelectPortal,
    SelectRoot,
    SelectTrigger,
    SelectValue,
    SelectViewport,
} from "radix-vue";
import { useDesignerRootStore } from "@/store/useDesignerRootStore";
import { storeToRefs } from "pinia";
import type { HSVColor } from "@/utils/colorConversion";
import ColorInputCMYK from "./ColorInputCMYK.vue";

const color = defineModel<HSVColor>({ required: true });

const inputComponents = {
    rgb: ColorInputRgb,
    hex: ColorInputHex,
    cmyk: ColorInputCMYK,
} satisfies Record<
    string,
    DefineComponent<
        { modelValue: HSVColor },
        unknown,
        unknown,
        NonNullable<unknown>,
        NonNullable<unknown>,
        ComponentOptionsMixin,
        ComponentOptionsMixin,
        { "update:modelValue": (value: HSVColor) => unknown }
    >
>;

const pickerModes = Object.keys(
    inputComponents,
) as (keyof typeof inputComponents)[];

const pickerMode = ref(pickerModes[0]);

const { designerRoot } = storeToRefs(useDesignerRootStore());
</script>

<template>
    <div class="flex gap-2">
        <select-root v-model="pickerMode">
            <select-trigger
                class="inline-flex h-[35px] items-center justify-between gap-[5px] rounded border border-secondary-2 px-[5px] outline-none"
                aria-label="Customise options"
            >
                <select-value placeholder="Select a mode" />
                <f-a-icon class="transition-all" icon="chevron-down" />
            </select-trigger>

            <select-portal :to="designerRoot">
                <select-content
                    class="data-[side=top]:animate-slideDownAndFade data-[side=right]:animate-slideLeftAndFade data-[side=bottom]:animate-slideUpAndFade data-[side=left]:animate-slideRightAndFade z-[100] rounded border border-secondary-2 bg-white will-change-[opacity,transform]"
                >
                    <select-viewport class="">
                        <select-item
                            v-for="(option, index) in pickerModes"
                            :key="index"
                            class="relative flex h-[25px] select-none items-center rounded-[3px] px-4 py-4 data-[highlighted]:bg-secondary-3 data-[highlighted]:outline-none"
                            :value="option"
                        >
                            <select-item-text>
                                {{ option.toUpperCase() }}
                            </select-item-text>
                        </select-item>
                    </select-viewport>
                </select-content>
            </select-portal>
        </select-root>
        <component
            :is="inputComponents[pickerMode]"
            v-model="color"
            class="w-full"
        ></component>
    </div>
</template>

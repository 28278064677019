<template>
    <template v-if="displayZoomBar">
        <product-zoom-slider @mouseleave="toggleZoomDisplay()" />
    </template>
    <module-button
        v-else-if="!isPanelOpen"
        :icon="FontAwesomeKitIcons.zoomIn"
        :style-config="moduleButtonStyleConfig"
        :disabled="isDisabled"
        @mouseover="toggleZoomDisplay()"
    >
        {{ t("zoom") }}
    </module-button>
</template>

<script setup lang="ts">
import { FontAwesomeKitIcons } from "@/enums/fontAwesome/fontAwesomeKitIcons";
import { useMainStore } from "@/store/useMainStore";
import { usePanelStore } from "@/store/usePanelStore";
import cloneDeep from "lodash/cloneDeep";
import get from "lodash/get";
import merge from "lodash/merge";
import set from "lodash/set";
import { storeToRefs } from "pinia";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";

const { isPanelOpen } = storeToRefs(usePanelStore());
const { settings } = useMainStore();
const { t } = useI18n();

//data
const displayZoomBar = ref(false);

// props
const props = defineProps({
    styleConfig: {
        type: Object,
        default: () => {
            return {};
        },
    },
});

//computed
const moduleButtonStyleConfig = computed(() => {
    const baseStyleConfig = cloneDeep(props.styleConfig);

    [
        "styles",
        "icon.styles",
        "label.styles",
        "disabled.styles",
        "disabled.icon.styles",
        "disabled.label.styles",
    ].forEach((key) => {
        set(
            baseStyleConfig,
            key,
            merge(
                get(baseStyleConfig, key, []),
                get(settings.style, `productZoomButton.${key}`, []),
            ),
        );
    });

    return baseStyleConfig;
});

const isDisabled = computed<boolean>(() => {
    return isPanelOpen.value;
});

//methods
function toggleZoomDisplay() {
    displayZoomBar.value = !displayZoomBar.value;
}
</script>

<script setup lang="ts">
import type { HSVColor } from "@/utils/colorConversion";
import ColorButton from "./ColorButton.vue";
import { objectId } from "@/lib/object";
import { useFavoriteColorsStore } from "@/composables/useFavoriteColors";
import { storeToRefs } from "pinia";

const { add } = useFavoriteColorsStore();
const { colors } = storeToRefs(useFavoriteColorsStore());

const color = defineModel<HSVColor>({ required: true });

function addColorToFavorites() {
    add({ ...color.value });
}
</script>

<template>
    <div class="flex flex-col gap-2">
        <div class="text-sm font-semibold">{{ $t("recently_used") }}</div>
        <div class="grid grid-cols-7 gap-2">
            <button-secondary-gray
                size="small"
                class="aspect-square h-8 cursor-pointer rounded-full bg-secondary-3 p-1"
                @click="addColorToFavorites"
            >
                <f-a-icon icon="plus" class="text-xs" />
            </button-secondary-gray>
            <color-button
                v-for="favoriteColor in colors"
                :key="objectId(favoriteColor)"
                class="flex aspect-square w-8 items-center justify-center rounded-full"
                :color="favoriteColor"
                @click="color = { ...favoriteColor }"
            />
        </div>
    </div>
</template>

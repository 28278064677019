<template>
    <div
        v-if="!isPanelOpen && !isAProductionMethodSelectionOpen"
        class="flex w-full justify-center px-5"
    >
        <production-method-button
            class="max-w-md"
            :production-methods="currentActiveProductionMethods"
            :current-production-method="currentProductionMethod"
            @click="isGlobalProductionMethodSelectionCompactOpen = true"
        />
    </div>
</template>

<script setup lang="ts">
import { storeToRefs } from "pinia";
import { usePanelStore } from "@/store/usePanelStore";
import { useProductionMethodStore } from "@/store/useProductionMethodStore";
import { useProductionMethodDialogStore } from "@/store/useProductionMethodDialogStore";

const productionMethodStore = useProductionMethodStore();
const { currentActiveProductionMethods, currentProductionMethod } = storeToRefs(
    productionMethodStore,
);

const {
    isAProductionMethodSelectionOpen,
    isGlobalProductionMethodSelectionCompactOpen,
} = storeToRefs(useProductionMethodDialogStore());

const { isPanelOpen } = storeToRefs(usePanelStore());
</script>

<template>
    <div
        class="grid w-full grid-cols-[1fr_auto_1fr] items-center justify-between"
    >
        <div class="text-xs font-bold uppercase">
            {{ $t("width_abbreviation") }}
        </div>
        <div></div>
        <div class="text-xs font-bold uppercase">
            {{ $t("height_abbreviation") }}
        </div>
        <div>
            <number-input v-model="width" :min="min.width" :max="max.width" />
        </div>
        <div class="flex justify-center px-3 text-secondary-2">
            <f-a-icon icon="link" :size="FontAwesomeSize.lg" />
        </div>
        <div>
            <number-input
                v-model="height"
                :min="min.height"
                :max="max.height"
            />
        </div>
    </div>
</template>

<script setup lang="ts">
import { FontAwesomeSize } from "@/enums/fontAwesome/fontAwesomeSize";
import type { Size } from "@smakecloud/designer-core";
import {
    useProportionalSize,
    useRoundedNumberInput,
} from "@smakecloud/smake-use";
import { toRefs } from "vue";
const props = defineProps<{
    min: Size;
    max: Size;
}>();

const { min, max } = toRefs(props);

const size = defineModel<Size>({ required: true });

const { proportionalSizeWidth, proportionalSizeHeight } = useProportionalSize(
    size,
    {
        minSize: min.value,
        maxSize: max.value,
    },
);

const width = useRoundedNumberInput(proportionalSizeWidth, 2);
const height = useRoundedNumberInput(proportionalSizeHeight, 2);
</script>

<template>
    <div
        class="overflow-hidden"
        :style="{
            width: `${stageConfig.width}px`,
            height: `${stageConfig.height}px`,
        }"
    >
        <v-stage
            ref="stage"
            :key="'stage-' + customizationResponseToPreview.id"
            :config="stageConfig"
        >
            <v-layer
                :key="'layer-' + customizationResponseToPreview.id"
                :config="layerConfig"
            >
                <v-image
                    :key="'view-image-' + customizationResponseToPreview.id"
                    :config="viewImageConfig"
                />
                <v-image
                    :key="'customization-' + customizationResponseToPreview.id"
                    :config="customizationImageConfig"
                />
            </v-layer>
        </v-stage>
    </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import useKonvaCalculations from "@/composables/useKonvaCalculations";

import { ArticleImageDimensions } from "@/enums/ArticleImageDimensions";
import {
    InnerAnchorPoints,
    type ArchiveVariantData,
    type ArchiveViewData,
    type CustomizationData,
    type VariantData,
    type ViewData,
} from "@smakecloud/smake-use";

const props = defineProps<{
    archiveVariant: ArchiveVariantData | VariantData;
    customizationResponseToPreview: CustomizationData;
}>();

const stageHeight = ref<number>(100);
const stageWidth = ref<number>(100);

const padding = ref(40);

const viewImageElement = ref<HTMLImageElement | null>(null);
const customizationImageElement = ref<HTMLElement | null>(null);

const scale = computed(() => {
    return Math.min(
        (stageWidth.value - padding.value) /
            customizationImageConfig.value.width,
        (stageHeight.value - padding.value) /
            customizationImageConfig.value.height,
    );
});

const { calculateCoordinatesForPosition } = useKonvaCalculations();

const currentView = computed<ArchiveViewData | ViewData>(() => {
    const view: ArchiveViewData | ViewData | undefined =
        props.archiveVariant.views.find((view) =>
            view.customizations.includes(props.customizationResponseToPreview),
        );

    if (view === undefined) {
        throw new Error("View not found");
    }

    return view;
});

// computed
const viewImageConfig = computed(() => {
    return {
        image: viewImageElement.value,
        height: ArticleImageDimensions.HEIGHT,
        width: ArticleImageDimensions.WIDTH,
    };
});

const customizationImageConfig = computed(() => {
    const widthPx =
        props.customizationResponseToPreview.width *
        currentView.value.scalefactor;
    const heightPx =
        props.customizationResponseToPreview.height *
        currentView.value.scalefactor;

    return {
        image: customizationImageElement.value,
        width: widthPx,
        height: heightPx,
        rotation: props.customizationResponseToPreview.rotation,
        ...calculateCoordinatesForPosition(
            props.customizationResponseToPreview.x,
            props.customizationResponseToPreview.y,
            widthPx,
            heightPx,
            props.customizationResponseToPreview.rotation,
            props.customizationResponseToPreview.position,
            InnerAnchorPoints.TOP_LEFT,
        ),
    };
});

const stageConfig = computed(() => {
    return {
        height: stageHeight.value,
        width: stageWidth.value,
    };
});

const layerConfig = computed(() => {
    const { x, y } = calculateCoordinatesForPosition(
        props.customizationResponseToPreview.x,
        props.customizationResponseToPreview.y,
        props.customizationResponseToPreview.width,
        props.customizationResponseToPreview.height,
        props.customizationResponseToPreview.rotation,
        props.customizationResponseToPreview.position,
        InnerAnchorPoints.CENTER_CENTER,
    );

    const offsetX = stageWidth.value / 2 - x * scale.value;
    const offsetY = stageHeight.value / 2 - y * scale.value;

    return {
        x: offsetX,
        y: offsetY,
        scaleX: scale.value,
        scaleY: scale.value,
    };
});

/* onMounted */
onMounted(async () => {
    viewImageElement.value = await loadHTMLImageElementFromUrl(
        currentView.value.image_urls.md,
    );
    customizationImageElement.value = await loadHTMLImageElementFromUrl(
        props.customizationResponseToPreview.resource.preview_url ?? "", // Todo Change to Design
    );
});

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line @typescript-eslint/require-await
async function loadHTMLImageElementFromUrl(
    imageUrl: string,
): Promise<HTMLImageElement> {
    const image = new window.Image();
    image.src = imageUrl;
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/unbound-method
    image.decode;

    return image;
}
</script>

import { defineStore, storeToRefs } from "pinia";
import { useProductionMethodStore } from "./useProductionMethodStore";
import { computed } from "vue";
import { apiClient } from "@smakecloud/designer-core";
import { useQuery } from "@tanstack/vue-query";

export const useShowCustomerLogoOrDesignCardStore = defineStore(
    "useShowCustomerLogoOrDesignCardStore",
    () => {
        const { currentActiveProductionMethods } = storeToRefs(
            useProductionMethodStore(),
        );

        const filter = computed<typeof apiClient.customerLogos.count.inputType>(
            () => ({
                production_method: currentActiveProductionMethods.value.map(
                    (productionMethod) => productionMethod.id,
                ),
            }),
        );

        const {
            data: customerLogoCount,
            isFetching: isFetchingCustomerLogoCount,
        } = useQuery({
            ...apiClient.customerLogos.count.use(filter),
            staleTime: Infinity,
            retry: false,
        });

        const {
            data: favoredDesignCount,
            isFetching: isFetchingFavoredDesignCount,
        } = useQuery({
            ...apiClient.designs.favored.count.use(filter),
            staleTime: Infinity,
            retry: false,
        });

        const showCustomerLogoOrDesignCard = computed(
            () => !!customerLogoCount.value || !!favoredDesignCount.value,
        );

        const isFetching = computed(
            () =>
                isFetchingCustomerLogoCount.value &&
                isFetchingFavoredDesignCount.value,
        );

        const hasCustomerLogos = computed(() => !!customerLogoCount.value);

        return {
            showCustomerLogoOrDesignCard,
            isFetching,
            hasCustomerLogos,
        };
    },
);

<template>
    <div>
        <!-- eslint-disable vue/no-v-html -->
        <svg
            v-if="isCurrentIconKitIcon && kitIconSvg"
            :class="kitIconClasses"
            :data-icon="icon"
            data-prefix="fak"
            aria-hidden="true"
            focusable="false"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 608 512"
            v-html="kitIconSvg"
        />
        <!-- eslint-enable-->
        <font-awesome-icon
            v-else-if="isCurrentIconKitIcon"
            :icon="['fal', 'spinner']"
            spin
        />

        <font-awesome-icon
            v-else-if="icon"
            :icon="['fal', icon]"
            :spin="props.spin"
            :size="props.size"
        />
    </div>
</template>

<script setup lang="ts">
import useFontAwesomeKit from "@/composables/useFontAwesomeKit";
import { type FontAwesomeKitIcons } from "@/enums/fontAwesome/fontAwesomeKitIcons";
import { FontAwesomeSize } from "@/enums/fontAwesome/fontAwesomeSize";
import { useIconStore } from "@/store/useIconStore";
import { type KitIcon } from "@/types/fontAwesome/KitIcon";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { storeToRefs } from "pinia";
import { computed } from "vue";

// stores
const iconStore = useIconStore();
const { kitIcons } = storeToRefs(iconStore);

// composables
const { isKitIcon } = useFontAwesomeKit();

//props
const props = withDefaults(
    defineProps<{
        icon: FontAwesomeKitIcons | string;
        size?: FontAwesomeSize;
        spin?: boolean;
    }>(),
    {
        spin: false,
        size: FontAwesomeSize.fa1x,
    },
);

// computed
const isCurrentIconKitIcon = computed<boolean>(() => isKitIcon(props.icon));

const kitIcon = computed<KitIcon | null>(() => {
    return (
        kitIcons.value?.find((kitIcon) => kitIcon.name === props.icon) ?? null
    );
});

const kitIconSvg = computed<string | null>(() => {
    return (
        kitIcon.value?.svg?.replace("<path", '<path fill="currentColor"') ??
        null
    );
});

const kitIconClasses = computed(() => {
    if (!kitIcon.value) {
        return;
    }

    const icon = `fa-${props.icon}`;
    let spin = "";
    let size = "";

    if (props.spin) {
        spin = "fa-spin";
    }

    if (props.size) {
        size = `fa-${props.size}`;
    }

    return `svg-inline--fa ${icon} ${size} ${spin}`;
});
</script>

import { createPinia } from "pinia";
import { createORM } from "pinia-orm";
import type { Plugin } from "vue";

export const PiniaPlugin: Plugin = {
    install: (app) => {
        const pinia = createPinia();
        pinia.use(createORM());
        //pinia.use(createSentryPiniaPlugin()); as soon as orm is gone

        app.use(pinia);
    },
};

import { isItalicFont } from "@/functions/FontHelper";
import { apiClient } from "@smakecloud/designer-core";
import { useQuery } from "@tanstack/vue-query";
import { computed, toRef, type MaybeRefOrGetter } from "vue";

export function useFonts(productionMethodId: MaybeRefOrGetter<number>) {
    const productionMethodIdRef = toRef(productionMethodId);

    const { data: allFonts } = useQuery({
        ...apiClient.configurations.app.get.use(),
    });

    const fonts = computed(() =>
        allFonts.value?.fonts.filter((font) =>
            font.production_methods.some(
                (productionMethod) =>
                    productionMethod.id === productionMethodIdRef.value,
            ),
        ),
    );

    const italicFonts = computed(() =>
        fonts.value?.filter((font) => isItalicFont(font)),
    );

    const regularFonts = computed(() =>
        fonts.value?.filter((font) => !isItalicFont(font)),
    );

    function getItalicFontForFamilyName(familyName: string) {
        return fonts.value?.find(
            (font) => font.family_name === familyName && isItalicFont(font),
        );
    }

    function getRegularFontForFamilyName(familyName: string) {
        return fonts.value?.find(
            (font) => font.family_name === familyName && !isItalicFont(font),
        );
    }

    return {
        allFonts,
        fonts,
        italicFonts,
        regularFonts,
        getItalicFontForFamilyName,
        getRegularFontForFamilyName,
    };
}

import { defineStore, storeToRefs } from "pinia";
import { ref, computed, watch } from "vue";
import { useProductionMethodStore } from "@/store/useProductionMethodStore";
import { useInitialVariantStore } from "./useInitialVariantStore";

export const useProductionMethodDialogStore = defineStore(
    "productionMethodDialogStore",
    () => {
        const { currentActiveProductionMethods } = storeToRefs(
            useProductionMethodStore(),
        );

        const {
            initialVariant,
            isInitalVariantEmpty,
            isInitialVariantDTFOnly,
            isInitialVariantEmbroideryOnly,
        } = storeToRefs(useInitialVariantStore());

        const isGlobalProductionMethodSelectionCompactOpen =
            ref<boolean>(false);

        const showProductionMethodSelectionAsInfo = computed<boolean>(
            () => currentActiveProductionMethods.value.length < 2,
        );

        function isInitalProductionSelectionOpen(): boolean {
            if (showProductionMethodSelectionAsInfo.value) {
                return false;
            }

            if (isInitalVariantEmpty.value) {
                return true;
            }

            if (isInitialVariantDTFOnly.value) {
                return false;
            }

            if (isInitialVariantEmbroideryOnly.value) {
                return false;
            }

            return true;
        }

        const isGlobalProductionMethodSelectionOpen = ref<boolean>(false);

        const wasBootModalHandeld = ref(false);

        watch(
            initialVariant,
            () => {
                if (!initialVariant.value) {
                    return;
                }
                if (wasBootModalHandeld.value) {
                    return;
                }

                isGlobalProductionMethodSelectionOpen.value =
                    isInitalProductionSelectionOpen();

                wasBootModalHandeld.value = true;
            },
            { immediate: true },
        );

        const isAProductionMethodSelectionOpen = computed(
            () =>
                isGlobalProductionMethodSelectionOpen.value ||
                isGlobalProductionMethodSelectionCompactOpen.value,
        );

        return {
            isGlobalProductionMethodSelectionOpen,
            isGlobalProductionMethodSelectionCompactOpen,
            isAProductionMethodSelectionOpen,
            showProductionMethodSelectionAsInfo,
        };
    },
);

import { defineStore } from "pinia";
import {
    computed,
    ref,
    type ComponentOptionsMixin,
    type DefineComponent,
} from "vue";

export type UnknownDialog = {
    component: DefineComponent<
        unknown,
        unknown,
        unknown,
        NonNullable<unknown>,
        NonNullable<unknown>,
        ComponentOptionsMixin,
        ComponentOptionsMixin,
        { resolve: (output: unknown) => unknown }
    >;
    input: Record<string, unknown>;
    resolve: (output?: unknown) => void;
    isOpen: boolean;
};

export const useDialogStore = defineStore("dialog", () => {
    const portalRoot = ref<HTMLDivElement>();

    const dialogesCount = ref(0);

    const hasDialogesOpen = computed(() => dialogesCount.value > 0);

    const dialogs = ref<UnknownDialog[]>([]);

    return {
        portalRoot,
        hasDialogesOpen,
        dialogesCount,
        dialogs,
    };
});

import {
    isZSKLogoDesign,
    isZSKTextDesign,
} from "@/modules/core/renderer/zskEmbroidery/ZSKDesignHelper";
import {
    type ZSKAlignment,
    type ZSKDesign,
    type ZSKLogoTextDesign,
    type ZSKTextDesign,
} from "@/modules/core/renderer/zskEmbroidery/types/ZSKDesign";
import Color from "@/repo/Color";
import ProductionMethod from "@/repo/ProductionMethod";
import TextResourceLine from "@/repo/TextResourceLine";
import type { Size } from "@smakecloud/designer-core";
import {
    AnchorType,
    HorizontalAlignment,
    OuterAnchorPoints,
    ResourceType,
    type ColorData,
    type CoordinatePosition,
    type Design,
    type DesignElement,
    type Line,
    type LogoResourceData,
    type ProductionMethodData,
    type StoreTextRequest,
    type TextResourceData,
    type TextResourceLineData,
} from "@smakecloud/smake-use";
import { useRepo } from "pinia-orm";
import { useI18n } from "vue-i18n";

export function ConvertZSKDesignToDesign(zskDesign: ZSKDesign): Design {
    const designElement = convertZSKDesignToDesignElement(zskDesign);

    return {
        production_method: {
            id: designElement.resource.production_method.id,
        },
        elements: [designElement],
    } as Design;
}

export function stripEmptyTextElement(zskDesign: ZSKDesign): ZSKDesign {
    if (isZSKLogoDesign(zskDesign)) {
        return zskDesign;
    }

    if (
        zskDesign.text.lines.filter((line) => line.value.trim().length > 0)
            .length > 0
    ) {
        return zskDesign;
    }

    if (zskDesign.logo === undefined) {
        throw "Invalid Design without Rendering Data";
    }

    return {
        ...zskDesign,
        text: undefined,
    };
}

function convertZSKDesignToDesignElement(zskDesign: ZSKDesign): DesignElement {
    if (isZSKTextDesign(zskDesign)) {
        return textResourceDataToDesignElement(zskDesign.text);
    }

    if (isZSKLogoDesign(zskDesign)) {
        return logoResourceDataToDesignElement(
            zskDesign.logo,
            zskDesign.logoDesignElementData,
        );
    }

    return zskLogoTextDesignToDesignElement(zskDesign);
}

function textResourceLineToLine(line: TextResourceLineData): Line {
    return {
        value: line.value,
        font: {
            handle: line.font.group_handle,
        },
        size: line.font_size,
        padding_bottom: line.padding_bottom,
        horizontal_alignment: line.horizontal_alignment,
        bending_radius: line.bending_radius,
        order_column: line.order_column,
        offset:
            line.horizontal_alignment === HorizontalAlignment.LEFT
                ? line.offset
                : 0,
        color_assignments: [
            {
                apply_to: "fill",
                color: {
                    id: line.color_assignments[0].color.id,
                },
            },
        ],
    };
}

export function lineTotextResourceLine(
    line: TextResourceLineData,
): TextResourceLine {
    return new TextResourceLine({
        value: line.value,
        font_id: line.font.id,
        font: line.font,
        font_size: line.font_size,
        padding_bottom: line.padding_bottom,
        horizontal_alignment: line.horizontal_alignment,
        bending_radius: line.bending_radius,
        order_column: line.order_column,
        offset:
            line.horizontal_alignment === HorizontalAlignment.LEFT
                ? line.offset
                : 0,
        color_assignments: [
            {
                apply_to: "fill",
                color: {
                    id: line.color_assignments[0].color.id,
                },
            },
        ],
    });
}

export function zskTextDesignToStoreTextRequest(
    zskTextDesign: ZSKTextDesign,
): StoreTextRequest {
    const { t } = useI18n();

    const storeTextRequest: StoreTextRequest = {
        production_method: {
            handle: zskTextDesign.text.production_method.handle,
        },
        lines: zskTextDesign.text.lines
            .filter((line) => line.value.trim() !== "")
            .map((line) => textResourceLineToLine(line)),
    };

    if (
        zskTextDesign.text.lines.length === 1 &&
        zskTextDesign.text.lines[0].value === ""
    ) {
        const placeHolderPreviewLine = textResourceLineToLine(
            zskTextDesign.text.lines[0],
        );
        placeHolderPreviewLine.value = t("insert_text");
        storeTextRequest.lines[0] = placeHolderPreviewLine;
    }

    if (storeTextRequest.lines.length === 0) {
        console.error("keine Lines");
    }

    return storeTextRequest;
}

function textResourceDataToDesignElement(
    text: TextResourceData,
): DesignElement {
    return {
        resource_type: ResourceType.TEXT,
        resource: {
            ...text,
            lines: text.lines.filter((line) => line.value.trim() !== ""),
        },
    };
}

function logoResourceDataToDesignElement(
    logo: LogoResourceData,
    designElementData?: {
        dimension?: Size;
    },
): DesignElement {
    return {
        resource_type: ResourceType.LOGO,
        resource: logo,
        dimension: designElementData?.dimension,
    };
}

function zskLogoTextDesignToDesignElement(
    zskDesign: ZSKLogoTextDesign,
): DesignElement {
    return {
        ...logoResourceDataToDesignElement(
            zskDesign.logo,
            zskDesign.logoDesignElementData,
        ),
        elements: [
            {
                ...textResourceDataToDesignElement(zskDesign.text),
                alignment: {
                    anchor_type: AnchorType.OUTER,
                    anchor_value: zskDesign.alignment.anchor,
                    offset: outerAnchorToOffsetCoordinatePosition(
                        zskDesign.alignment,
                    ),
                    rotation: 0,
                },
            },
        ],
    };
}

function outerAnchorToOffsetCoordinatePosition(
    zskAlignment: ZSKAlignment,
): CoordinatePosition {
    return {
        x: outerAnchorToOffsetCoordinatePositionX(zskAlignment),
        y: outerAnchorToOffsetCoordinatePositionY(zskAlignment),
    };
}

function outerAnchorToOffsetCoordinatePositionY(
    zskAlignment: ZSKAlignment,
): number {
    if (
        zskAlignment.anchor === OuterAnchorPoints.BOTTOM_LEFT ||
        zskAlignment.anchor === OuterAnchorPoints.BOTTOM_CENTER ||
        zskAlignment.anchor === OuterAnchorPoints.BOTTOM_RIGHT
    ) {
        return zskAlignment.distance;
    }

    if (
        zskAlignment.anchor === OuterAnchorPoints.TOP_LEFT ||
        zskAlignment.anchor === OuterAnchorPoints.TOP_CENTER ||
        zskAlignment.anchor === OuterAnchorPoints.TOP_RIGHT
    ) {
        return -zskAlignment.distance;
    }

    return 0;
}

function outerAnchorToOffsetCoordinatePositionX(
    zskAlignment: ZSKAlignment,
): number {
    if (
        zskAlignment.anchor === OuterAnchorPoints.RIGHT_TOP ||
        zskAlignment.anchor === OuterAnchorPoints.RIGHT_CENTER ||
        zskAlignment.anchor === OuterAnchorPoints.RIGHT_BOTTOM
    ) {
        return zskAlignment.distance;
    }

    if (
        zskAlignment.anchor === OuterAnchorPoints.LEFT_TOP ||
        zskAlignment.anchor === OuterAnchorPoints.LEFT_CENTER ||
        zskAlignment.anchor === OuterAnchorPoints.LEFT_BOTTOM
    ) {
        return -zskAlignment.distance;
    }

    return 0;
}

export function colorToColorData(color: Color): ColorData {
    useRepo(Color).withAllRecursive().load([color]);
    return {
        ...color,
    };
}

export function productionMethodToProductionMethodData(
    productionMethodToCast: ProductionMethod,
): ProductionMethodData {
    useRepo(ProductionMethod).withAllRecursive().load([productionMethodToCast]);

    return productionMethodToCast;
}

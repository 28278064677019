<script setup lang="ts">
import { useColorConvertorHSV } from "@/composables/colorPicker/useColorConverterHSV";
import { type HSVColor } from "@/utils/colorConversion";
import NumberInputSanitized from "../../NumberInputSanitized.vue";
import { computed, ref, watchEffect } from "vue";

const color = defineModel<HSVColor>({ required: true });
const { colorCMYK } = useColorConvertorHSV(color);

const inputs = ref<InstanceType<typeof NumberInputSanitized>[]>();

// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
const isFocused = computed(() => inputs.value?.some((input) => input.focused));

const inputColor = ref({ ...colorCMYK.value });

watchEffect(() => {
    if (!isFocused.value) {
        inputColor.value = { ...colorCMYK.value };
        return;
    }

    colorCMYK.value = { ...inputColor.value };
});
</script>

<template>
    <div class="flex w-full gap-2">
        <number-input-sanitized
            v-for="(_value, key) in inputColor"
            :key="key"
            ref="inputs"
            class="h-full w-12 rounded border border-secondary-2 px-2 focus:border-secondary-1"
            :model-value="inputColor[key]"
            :min="0"
            :max="100"
            :step="1"
            @update:model-value="
                (val) => (inputColor = { ...inputColor, [key]: val })
            "
        >
        </number-input-sanitized>
    </div>
</template>

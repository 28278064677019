import usePositioningAreaFunctions from "@/composables/positioningAreas/usePositioningAreaFunctions";
import { Mode } from "@/enums/mode";
import Customization from "@/repo/Customization";
import View from "@/repo/View";
import { useCanvasStore } from "@/store/useCanvasStore";
import { useCurrentStore } from "@/store/useCurrentStore";
import { usePanelStore } from "@/store/usePanelStore";
import type { DesignCanvasObject } from "@/types/DesignCanvasObject";
import { storeToRefs } from "pinia";
import { useRepo } from "pinia-orm";

export const useDeleteCustomization = () => {
    const { resolveNotificationsForPositioningArea } =
        usePositioningAreaFunctions();
    const canvasStore = useCanvasStore();
    const { discardActiveObject, removeTextCanvasObject } = canvasStore;
    const { designCanvasObjects } = storeToRefs(canvasStore);
    const { resetCurrentCustomization } = useCurrentStore();
    const currentStore = useCurrentStore();
    const { currentCustomization, currentMode } = storeToRefs(currentStore);
    const { hideCurrentPanel } = usePanelStore();

    const customizationRepo = useRepo(Customization);
    const viewRepo = useRepo(View);

    function deleteCustomizationById(customizationId: number) {
        customizationRepo.destroy(customizationId);
        const view = viewRepo
            .all()
            .find((view: View) =>
                view.customization_ids.includes(customizationId),
            );

        if (view === undefined) {
            return;
        }

        viewRepo.save({
            handle: view.handle,
            variant_id: view.variant_id,
            customization_ids: view.customization_ids.filter(
                (customization_id: number) =>
                    customization_id !== customizationId,
            ),
        });
    }

    async function deleteCurrentCustomizationAndGoToDefaultMode(
        hidePanel = true,
    ) {
        if (currentCustomization.value === null) {
            throw Error("Current Customization is Missing");
        }

        const customizationIdToDelete = currentCustomization.value.id;

        // Remove Design Objects from Canvas

        designCanvasObjects.value
            .filter(
                (designCanvasObject: DesignCanvasObject) =>
                    designCanvasObject.customization_id ===
                    customizationIdToDelete,
            )
            .forEach((designCanvasObject: DesignCanvasObject) =>
                removeTextCanvasObject(designCanvasObject),
            );

        resolveNotificationsForPositioningArea(
            currentCustomization.value.positioningArea_id,
        );

        // triggers zoom out and close panel

        discardActiveObject();

        await resetCurrentCustomization(hidePanel);

        deleteCustomizationById(customizationIdToDelete);

        canvasStore.showOrHidePositioningAreaCanvasObjects();

        currentMode.value = Mode.Default;

        if (hidePanel) {
            await hideCurrentPanel();
        }
    }

    return {
        deleteCustomizationById,
        deleteCurrentCustomizationAndGoToDefaultMode,
    };
};

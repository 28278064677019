import type { ZSKDesign } from "@/modules/core/renderer/zskEmbroidery/types/ZSKDesign";
import { isZSKDesignWithLogo } from "@/modules/core/renderer/zskEmbroidery/ZSKDesignHelper";
import { computed, toRef, type MaybeRefOrGetter } from "vue";
import {
    FallbackFeatures,
    isProductionMethodHandle,
    ProductionMethodsFeatures,
} from "./useProductionMethodFeatures";

type DesignFeatureMap = {
    resizeable: boolean;
    logo?: {
        resizeable: boolean;
        rotateable: boolean;
        editable: boolean;
    };

    text?: {
        rotateable: boolean;
    };
};

function getDesignProductionMethodHandle(design?: ZSKDesign) {
    if (!design) {
        return;
    }

    return (
        design.logo?.production_method.handle ??
        design.text?.production_method.handle
    );
}

function getProductionMethodFeatureMap(design?: ZSKDesign) {
    const handle = getDesignProductionMethodHandle(design);

    if (!isProductionMethodHandle(handle)) {
        return FallbackFeatures;
    }

    return ProductionMethodsFeatures[handle];
}

function isDesignResizeable(design: ZSKDesign | undefined): boolean {
    if (!design) {
        return false;
    }

    if (!isZSKDesignWithLogo(design)) {
        return true;
    }

    const featureMap = getProductionMethodFeatureMap(design);

    return featureMap.logo?.resizeable ?? true;
}

function isDesignEditable(design: ZSKDesign | undefined): boolean {
    if (!design) {
        return false;
    }

    if (!isZSKDesignWithLogo(design)) {
        return true;
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    // @ts-expect-error this is a boolean field which we won't update for this type, as we use a new apiClient in the new designer
    return design.logo.is_editable as boolean;
}

export function getDesignFeatureMap(design?: ZSKDesign): DesignFeatureMap {
    return {
        resizeable: isDesignResizeable(design),
        ...getProductionMethodFeatureMap(design),
        logo: {
            resizeable:
                getProductionMethodFeatureMap(design).logo?.resizeable ?? false,
            rotateable:
                getProductionMethodFeatureMap(design).logo?.rotateable ?? false,
            editable: isDesignEditable(design),
        },
    };
}

export function useDesignFeatures(
    design: MaybeRefOrGetter<ZSKDesign | undefined>,
) {
    const designRef = toRef(design);

    const designFeatureMap = computed(() =>
        getDesignFeatureMap(designRef.value),
    );

    return designFeatureMap;
}

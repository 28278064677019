import { PanelState } from "@/enums/PanelState";
import { useRouter } from "vue-router";

export default () => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/unbound-method
    const { push } = useRouter();

    async function routeToPanel(panel: PanelState) {
        const panelRouteMap: Record<PanelState, string> = {
            [PanelState.None]: "noModal",
            [PanelState.TextEdit]: "textEditModal",
            [PanelState.ProductColors]: "productColorsModal",
            [PanelState.Archive]: "archiveModal",
            [PanelState.PdfDownload]: "pdfDownloadModal",
            [PanelState.CustomizationEdit]: "customizationEditModal",
        };

        await push({ name: panelRouteMap[panel] });
    }

    return { routeToPanel };
};

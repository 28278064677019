<template>
    <button
        type="button"
        class="pointer-events-auto"
        :class="buttonClasses"
        :disabled="loading || disabled"
    >
        <div v-if="icon !== ''" :class="iconClasses">
            <template v-if="loading">
                <f-a-icon icon="spinner" :size="FontAwesomeSize.lg" spin />
            </template>
            <template v-else-if="addIcon">
                <div class="relative">
                    <f-a-icon :icon="icon" :size="FontAwesomeSize.lg" />
                    <div :class="addIconClasses">
                        <f-a-icon icon="plus" :size="FontAwesomeSize.lg" />
                    </div>
                </div>
            </template>
            <template v-else>
                <f-a-icon :icon="icon" :size="FontAwesomeSize.lg" />
            </template>
        </div>
        <div :class="labelClasses">
            <slot />
        </div>
    </button>
</template>

<script setup lang="ts">
import get from "lodash/get";
import cloneDeep from "lodash/cloneDeep";
import { computed } from "vue";
import { FontAwesomeSize } from "@/enums/fontAwesome/fontAwesomeSize";

//props
const props = withDefaults(
    defineProps<{
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        styleConfig: any;
        icon?: string;
        loading?: boolean;
        addIcon?: boolean;
        disabled?: boolean;
    }>(),
    {
        styleConfig: () => {
            return {};
        },
        icon: "", // todo handle default case
        loading: false,
        addIcon: false,
        disabled: false,
    },
);

//computed´
const buttonClasses = computed(() => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    let styles = cloneDeep(get(props.styleConfig, "styles", []));

    if (props.disabled) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        styles = cloneDeep(get(props.styleConfig, "disabled.styles", styles));

        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
        styles.push("opacity-50");
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
        styles.push("pointer-events-none");
    }

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return styles;
});

const labelClasses = computed(() => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return get(props.styleConfig, "label.styles", []);
});

const iconClasses = computed(() => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    let styles = cloneDeep(get(props.styleConfig, "icon.styles", []));

    if (props.disabled) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        styles = cloneDeep(
            get(props.styleConfig, "disabled.icon.styles", styles),
        );
    }

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return styles;
});

const addIconClasses = computed(() => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return get(props.styleConfig, "addIcon.styles", [
        "absolute",
        "-bottom-1",
        "-right-3",
        "w-5",
        "h-5",
        "rounded-full",
        "bg-primary-1",
        "text-white",
        "flex",
        "flex-wrap",
        "justify-center",
        "content-center",
        "text-sm",
        "font-semibold",
    ]);
});
</script>

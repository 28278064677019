import { defineCustomElement } from "vue";
import AppCe from "./App.ce.vue";
import { DesignerPlugin } from "./plugin/DesignerPlugin";
import { captureException } from "@sentry/vue";
import { setOnApiClientValidationError } from "@smakecloud/designer-core";

const SmakeDesigner = defineCustomElement(AppCe, {
    configureApp: (app) => {
        app.use(DesignerPlugin);

        setOnApiClientValidationError((error) => {
            captureException(error, { extra: { message: error.message } });
        });
    },
});
customElements.define("smake-designer", SmakeDesigner);

export type { CustomVariantCreatedEvent } from "@/types/CustomVariantCreatedEvent";
export { isDesignerLocale } from "@/types/DesignerProps";
export type {
    DefaultUser,
    DesignerLocale,
    DesignerProps,
    PusherConfig,
    VariantExternalIdentifier,
    VariantId,
    VariantIdentifier,
} from "@/types/DesignerProps";
export type { VariantChangedEvent } from "@/types/VariantChangedEvent";

<script setup lang="ts">
import type { HSVColor } from "@/utils/colorConversion";
import { useMousePressed, useMouseInElement, clamp } from "@vueuse/core";
import { computed, useTemplateRef, watch } from "vue";

const color = defineModel<HSVColor>({ required: true });
const hueArea = useTemplateRef("hueArea");

const { pressed } = useMousePressed({ target: hueArea });
const { elementX, elementY } = useMouseInElement(hueArea);
watch(
    [elementY, elementX, pressed],
    () => {
        if (!pressed.value) return;

        updateHue(elementY.value);
    },
    { immediate: true },
);

const colorDotStyle = computed(() => {
    if (!hueArea.value) return;

    const relativeY = 1 - color.value.hue / 360;
    const y = relativeY * hueArea.value.clientHeight;

    return {
        top: `${y}px`,
    };
});

function updateHue(yPosition: number) {
    if (!hueArea.value) return;

    const relativeY = clamp(yPosition / hueArea.value.clientHeight, 0, 1);

    const hue = (1 - relativeY) * 360;

    color.value = { ...color.value, hue };
}
</script>
<template>
    <div
        ref="hueArea"
        class="relative h-full w-[22px] touch-none rounded-full bg-[linear-gradient(0deg,red,yellow,lime,aqua,blue,magenta,red)]"
        :class="{
            'cursor-grabbing': pressed,
            'cursor-pointer': !pressed,
        }"
    >
        <div
            class="pointer-events-none absolute h-5 w-5 -translate-y-1/2 rounded-full border-2 border-white"
            :style="colorDotStyle"
        ></div>
    </div>
</template>

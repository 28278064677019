<template>
    <div
        class="grid w-full place-items-center"
        :style="{
            gridTemplateColumns: 'auto 1fr 1fr 1fr auto',
            gridTemplateAreas:
                '&quot;. tl tc tr .&quot;&quot;lt logo logo logo rt&quot;&quot;lc logo logo logo rc&quot;&quot;lb logo logo logo rb&quot;&quot;. bl bc br rotate&quot;',
        }"
    >
        <template
            v-if="
                selectedAnchorPoint &&
                isZSKDesignWithLogo(
                    customizationPreview.customization.design ?? null,
                )
            "
        >
            <label
                v-for="anchorPoint in OuterAnchorPoints"
                :key="anchorPoint"
                :style="{ gridArea: anchorPoint }"
                class="mx-1 my-1.5 flex h-8 w-8 cursor-pointer items-center justify-center rounded-md border"
                :data-testid="`anchor-point-${anchorPoint}`"
                :class="
                    selectedAnchorPoint === anchorPoint
                        ? 'border-black bg-black'
                        : 'border-secondary-2 hover:bg-secondary-3'
                "
            >
                <input
                    v-model="selectedAnchorPoint"
                    :value="anchorPoint"
                    type="radio"
                    class="hidden"
                />
                <f-a-icon
                    v-if="selectedAnchorPoint === anchorPoint"
                    :icon="FontAwesomeKitIcons.TextAlignment"
                    class="text-white"
                />
            </label>
        </template>
        <customization-preview
            class="aspect-square w-[95%] rounded-md"
            :style="{ gridArea: 'logo' }"
            :customization-preview="customizationPreview"
        />
        <div class="relative" :style="{ gridArea: 'rotate' }">
            <rotate-button
                v-model="rotation"
                :class="{
                    'absolute -bottom-5 -right-2': !selectedAnchorPoint,
                }"
            />
        </div>
    </div>
</template>

<script setup lang="ts">
import { FontAwesomeKitIcons } from "@/enums/fontAwesome/fontAwesomeKitIcons";
import { isZSKDesignWithLogo } from "@/modules/core/renderer/zskEmbroidery/ZSKDesignHelper";
import type Customization from "@/repo/Customization";
import { OuterAnchorPoints } from "@smakecloud/smake-use";
import CustomizationPreview from "../../customizations/CustomizationPreview.vue";

const selectedAnchorPoint = defineModel<OuterAnchorPoints>("anchorPoint");

defineProps<{
    customizationPreview: {
        customization: Customization;
        isValid: boolean;
    };
}>();

const rotation = defineModel<number>("rotation", {
    required: true,
});
</script>

import useNotification from "@/composables/useNotification";
import { NotificationType } from "@/enums/NotificationType";
import { ConvertZSKDesignToDesign } from "@/modules/core/renderer/zskEmbroidery/ConvertZSKDesignToDesign";
import { isVariantWithRenderableCustomizations } from "@/modules/core/renderer/zskEmbroidery/ZSKDesignHelper";
import type Customization from "@/repo/Customization";
import type Variant from "@/repo/Variant";
import type View from "@/repo/View";
import { useCurrentStore } from "@/store/useCurrentStore";
import { type PusherCustomVariantCustomizationsCompletedResponse } from "@/types/pusher/PusherCustomVariantCustomizationsCompletedResponse";
import {
    useDesignerApi,
    type StoreVariantRequest,
    type View as StoreVariantRequestView,
} from "@smakecloud/smake-use";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";

export default () => {
    const designerApi = useDesignerApi();
    const currentStore = useCurrentStore();
    const {
        currentVariant,
        customVariantCreatedId,

        isSaving,
        currentSavingVariantId,
        isSendingSaveRequest,
    } = storeToRefs(currentStore);
    const { pushNotification, resolveNotificationWithMessage } =
        useNotification();
    const { t } = useI18n();

    function onStoringCustomVariantCustomizationsCompleted(
        pusherCustomVariantCustomizationsCompletedResponse: PusherCustomVariantCustomizationsCompletedResponse,
    ): void {
        if (
            pusherCustomVariantCustomizationsCompletedResponse.id ===
            currentSavingVariantId.value
        ) {
            onSavingCompleted(
                pusherCustomVariantCustomizationsCompletedResponse,
            );
        }
    }

    function onSavingCompleted(
        pusherCustomVariantCustomizationsCompletedResponse: PusherCustomVariantCustomizationsCompletedResponse,
    ): void {
        currentSavingVariantId.value = null;

        customVariantCreatedId.value =
            pusherCustomVariantCustomizationsCompletedResponse.id;
    }

    async function storeCurrentVariant(): Promise<void> {
        if (isSaving.value) {
            return;
        }

        isSendingSaveRequest.value = true;
        resolveNotificationWithMessage(t("error_failed_to_save_variant"));

        try {
            currentSavingVariantId.value =
                await designerApi.endpoints.variants.storeVariant(
                    currentVariant.value.id,
                    getStoreVariantRequestBody(currentVariant.value),
                );
        } catch (error) {
            pushNotification(
                t("error_failed_to_save_variant"),
                NotificationType.Info,
            );
            currentSavingVariantId.value = null;
        }

        isSendingSaveRequest.value = false;
    }

    function getStoreVariantRequestBody(variant: Variant): StoreVariantRequest {
        if (!isVariantWithRenderableCustomizations(variant)) {
            throw Error(
                "Can't create a store request without render information",
            );
        }

        const viewRequestParams: StoreVariantRequestView[] = [];
        variant.views.forEach((view: View) => {
            const viewRequestParam: StoreVariantRequestView = {
                handle: view.handle,
                customizations: view.customizations
                    .filter((customization) => customization.design !== null)
                    .map((customization: Customization) => {
                        if (customization.design === null) {
                            throw Error(
                                "Can't create a store request with customizations that contain no design information",
                            );
                        }

                        return {
                            x: customization.x,
                            y: customization.y,
                            order: customization.order,
                            rotation: customization.rotation,
                            position: customization.position,
                            positioning_area: {
                                id: customization.positioning_area.id,
                            },
                            design: ConvertZSKDesignToDesign(
                                customization.design,
                            ),
                        };
                    }),
            };

            if (viewRequestParam.customizations.length > 0) {
                viewRequestParams.push(viewRequestParam);
            }
        });

        return {
            views: viewRequestParams,
        };
    }

    return {
        storeCurrentVariant,
        onStoringCustomVariantCustomizationsCompleted,

        getStoreVariantRequestBody,
    };
};

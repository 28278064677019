import { computed, ref, toRef, type MaybeRefOrGetter } from "vue";

export type ProductionMethodFeatureMap = {
    logo?: {
        resizeable: boolean;
        rotateable: boolean;
    };

    text?: {
        rotateable: boolean;
    };
};

export enum ProductionMethodHandle {
    DTF = "dtf",
    EMBROIDERY = "embroidery",
}

export function isProductionMethodHandle(
    value: string | undefined,
): value is ProductionMethodHandle {
    return Object.values(ProductionMethodHandle).includes(
        value as ProductionMethodHandle,
    );
}

export const ProductionMethodsFeatures: Record<
    ProductionMethodHandle,
    ProductionMethodFeatureMap
> = {
    [ProductionMethodHandle.DTF]: {
        logo: {
            rotateable: true,
            resizeable: true,
        },
        text: {
            rotateable: true,
        },
    },
    [ProductionMethodHandle.EMBROIDERY]: {
        logo: {
            rotateable: true,
            resizeable: false,
        },
        text: {
            rotateable: true,
        },
    },
};

export const FallbackFeatures: ProductionMethodFeatureMap = {
    logo: {
        rotateable: false,
        resizeable: false,
    },
    text: {
        rotateable: false,
    },
};

export function useProductionMethodFeatures(
    productionMethodHandle: MaybeRefOrGetter<string | undefined>,
) {
    const productionMethodHandleRef = toRef(productionMethodHandle);

    // Will be replaced by a query Api Configuration
    const productionMethodsFeatureMap = ref<
        Record<string, ProductionMethodFeatureMap | undefined>
    >({
        [ProductionMethodHandle.DTF]: {
            logo: {
                rotateable: true,
                resizeable: true,
            },
            text: {
                rotateable: true,
            },
        },
        [ProductionMethodHandle.EMBROIDERY]: {
            logo: {
                rotateable: true,
                resizeable: false,
            },
            text: {
                rotateable: true,
            },
        },
    });

    const featureMap = computed(() => {
        if (!productionMethodHandleRef.value) {
            return {
                logo: {
                    rotateable: false,
                    resizeable: false,
                },
                text: {
                    rotateable: false,
                },
            };
        }

        const map =
            productionMethodsFeatureMap.value[productionMethodHandleRef.value];

        if (!map) {
            throw new Error(
                `Production method with handle  "${productionMethodHandleRef.value}" is not supported`,
            );
        }

        return map;
    });

    return featureMap;
}

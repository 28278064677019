<template>
    <module-button
        v-if="!isPanelOpen"
        :icon="FontAwesomeKitIcons.CiConfigAreaOnOff"
        :style-config="moduleButtonStyleConfig"
        :disabled="isDisabled"
        @click="toggleAreasVisibility()"
    >
        {{ showOrHideAreas }}
    </module-button>
</template>

<script setup lang="ts">
import { FontAwesomeKitIcons } from "@/enums/fontAwesome/fontAwesomeKitIcons";
import { isAddMode } from "@/enums/mode";
import { useCurrentStore } from "@/store/useCurrentStore";
import { useMainStore } from "@/store/useMainStore";
import { usePanelStore } from "@/store/usePanelStore";
import cloneDeep from "lodash/cloneDeep";
import get from "lodash/get";
import merge from "lodash/merge";
import set from "lodash/set";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const { settings, showAreas } = storeToRefs(useMainStore());
const { currentMode } = storeToRefs(useCurrentStore());
const { isPanelOpen } = storeToRefs(usePanelStore());
const { t } = useI18n();

// props
const props = defineProps({
    styleConfig: {
        type: Object,
        default: () => {
            return {};
        },
    },
});

// computed
const moduleButtonStyleConfig = computed(() => {
    const baseStyleConfig = cloneDeep(props.styleConfig);
    const statePrefix = showAreas.value ? "active." : "";

    [
        "styles",
        "icon.styles",
        "label.styles",
        "disabled.styles",
        "disabled.icon.styles",
        "disabled.label.styles",
    ].forEach((key) => {
        set(
            baseStyleConfig,
            key,
            merge(
                get(baseStyleConfig, key, []),
                get(
                    settings.value.style,
                    `showAreasButton.${statePrefix}${key}`,
                    [],
                ),
            ),
        );
    });

    return baseStyleConfig;
});

const showOrHideAreas = computed(() => {
    if (showAreas.value) {
        return t("hide_areas");
    }

    return t("show_areas");
});

// methods
function toggleAreasVisibility() {
    showAreas.value = !showAreas.value;
    // this.$smakeDesigner.$emit('toggleAreasVisibility');
}

// computed
const isDisabled = computed<boolean>(() => {
    return isAddMode(currentMode.value);
});
</script>

import { type MaybeRefOrGetter, ref, type Ref, toRef, watch } from "vue";
export function useNumberInput<Optional extends boolean = false>(
    value: Ref<Optional extends true ? number | undefined : number>,
    input: Ref<HTMLInputElement | undefined>,
    options?: {
        max?: MaybeRefOrGetter<number | undefined>;
        min?: MaybeRefOrGetter<number | undefined>;
        optional?: Optional;
        step?: MaybeRefOrGetter<number | undefined>;
    },
) {
    const min = toRef(options?.min);
    const max = toRef(options?.max);
    const step = toRef(options?.step);
    const inputValue = ref<"" | number>(
        typeof value.value === "number"
            ? normalizeValue(value.value, min.value, max.value, step.value)
            : "",
    );

    watch(value, (value) => {
        const rootNode = input.value?.getRootNode();
        if (
            !(rootNode instanceof Document || rootNode instanceof ShadowRoot) ||
            rootNode.activeElement === input.value
        )
            return;
        inputValue.value =
            typeof value === "number"
                ? normalizeValue(value, min.value, max.value, step.value)
                : "";
    });

    watch(inputValue, (inputValue) => {
        if (
            inputValue ===
            (typeof value.value === "number"
                ? normalizeValue(value.value, min.value, max.value, step.value)
                : "")
        )
            return;
        value.value = (
            typeof inputValue === "number"
                ? normalizeValue(inputValue, min.value, max.value, step.value)
                : options?.optional
                  ? undefined
                  : 0
        ) as Optional extends true ? number | undefined : number;
    });

    function onBlur() {
        inputValue.value =
            typeof value.value === "number"
                ? normalizeValue(value.value, min.value, max.value, step.value)
                : "";
    }

    return { inputValue, onBlur };
}
function normalizeValue(
    value: number,
    min?: number,
    max?: number,
    step?: number,
) {
    if (step !== undefined) {
        value = Math.round(value / step) * step;
    }
    if (min !== undefined) {
        value = Math.max(value, min);
    }
    if (max !== undefined) {
        value = Math.min(value, max);
    }
    return value;
}

import { computed, ref } from "vue";
import { defineStore } from "pinia";
import { LayoutConfig, type LayoutConfiguration } from "@/config/layout.config";
import { StyleConfig } from "@/config/style.config";
import { type AreaStyleName } from "@/enums/AreaStyleName";
import { type AreaStyle } from "@/types/AreaStyle";

// todo Split up
export const useMainStore = defineStore("mainStore", () => {
    type MainConfig = {
        units: {
            length: number | null;
            currency: string | null;
        };
        stage: {
            showProductionMethodSelection: boolean;
            showViewButtons: boolean;
            showViewNavigation: boolean;
            showZoomSlider: boolean;
        };
        layout: LayoutConfiguration;
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        style: any; // todo refactor to a maintainable solution
        showFontGroupHandle: boolean;
        areaStyles: {
            [key in AreaStyleName]: AreaStyle;
        };
    };

    const settings = ref<MainConfig>({
        units: {
            length: null,
            currency: null,
        },
        stage: {
            showProductionMethodSelection: true,
            showViewButtons: true,
            showViewNavigation: true,
            showZoomSlider: false,
        },
        layout: LayoutConfig,
        style: StyleConfig,
        showFontGroupHandle: true,
        areaStyles: {
            dark: {
                fill: {
                    base: {
                        r: 70,
                        g: 70,
                        b: 70,
                        a: 0,
                    },
                    highlight: {
                        r: 70,
                        g: 70,
                        b: 70,
                        a: 0.2,
                    },
                },
                stroke: {
                    base: {
                        r: 70,
                        g: 70,
                        b: 70,
                        a: 1,
                    },
                    highlight: {
                        r: 70,
                        g: 70,
                        b: 70,
                        a: 1,
                    },
                    error: {
                        r: 255,
                        g: 0,
                        b: 0,
                        a: 1,
                    },
                },
                strokeWidth: 1,
                strokeDashArray: [7, 5],
            },
            light: {
                fill: {
                    base: {
                        r: 220,
                        g: 220,
                        b: 220,
                        a: 0,
                    },
                    highlight: {
                        r: 220,
                        g: 220,
                        b: 220,
                        a: 0.2,
                    },
                },
                stroke: {
                    base: {
                        r: 220,
                        g: 220,
                        b: 220,
                        a: 1,
                    },
                    highlight: {
                        r: 220,
                        g: 220,
                        b: 220,
                        a: 1,
                    },
                    error: {
                        r: 255,
                        g: 0,
                        b: 0,
                        a: 1,
                    },
                },
                strokeWidth: 1,
                strokeDashArray: [7, 5],
            },
        },
    });

    const mainNode = computed<Document>(() => document);
    const currentVariant = ref(null);
    const currentView = ref(null);
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const currentCustomization = ref<any>(null);
    const productionMethods = ref([]);
    const currentProductionMethod = ref(null);
    const colors = ref([]);
    const fonts = ref([]);
    const enableDevFeature = ref(true);
    const cart = ref([]);
    const showAreas = ref(true);

    const designerWrapper = ref<HTMLDivElement | null>(null);

    return {
        settings,
        mainNode,
        currentVariant,
        currentView,
        productionMethods,
        currentProductionMethod,
        currentCustomization,
        colors,
        fonts,
        enableDevFeature,
        cart,
        showAreas,
        designerWrapper,
    };
});

<!-- eslint-disable vue/no-deprecated-v-on-native-modifier -->
<template>
    <transition
        appear
        enter-active-class="ease-out duration-300"
        enter-from-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="ease-in duration-200"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
    >
        <div>
            <div v-if="customerColors.length">
                <div class="mb-4">{{ t("colors") }}:</div>
                <div class="grid grid-cols-4 justify-items-center gap-4">
                    <color-button
                        v-for="color in customerColors"
                        :key="color.id"
                        :selected="
                            !isColorPickerOpen &&
                            color.id === colorAssignment?.color?.id
                        "
                        :fill="[color.red, color.green, color.blue]"
                        :label="
                            color.is_standard
                                ? (color.external_identifier ?? undefined)
                                : undefined
                        "
                        :data-testid="`color-input-customer-${color.id}`"
                        @click="selectColorId(color.id)"
                    >
                        <span v-if="color.color_details" class="font-bold">
                            {{ color.color_details.code }}
                        </span>
                        <span class="font-bold">{{ color.display_name }}</span>
                        <span v-if="color.color_details">
                            {{ color.color_details.name }}
                            {{ color.color_details.thickness }}
                        </span>
                    </color-button>
                </div>
            </div>
            <hr
                v-if="customerColors.length && standardColors.length"
                class="my-6 border-secondary-2"
            />
            <div v-if="standardColors.length">
                <div class="mb-4">{{ t("standard_colors") }}:</div>
                <div
                    class="grid grid-cols-4 items-start justify-items-center gap-4"
                >
                    <color-picker-button
                        v-if="currentProductionMethod.is_using_color_picker"
                        :mark-selected="!colorAssignment?.color?.id"
                        :start-color="rgbColor"
                        @is-open="isColorPickerOpen = $event"
                        @selected-color="onSelect"
                    />
                    <color-button
                        v-for="color in standardColors"
                        :key="color.id"
                        :selected="
                            !isColorPickerOpen &&
                            color.id === colorAssignment?.color?.id
                        "
                        :fill="[color.red, color.green, color.blue]"
                        :label="color.external_identifier ?? undefined"
                        :data-testid="`color-input-standard-${color.id}`"
                        @click="selectColorId(color.id)"
                    >
                        {{ color.display_name }}
                    </color-button>
                </div>
            </div>
        </div>
    </transition>
</template>

<script setup lang="ts">
import { useColorStore } from "@/store/useColorStore";
import { useCurrentStore } from "@/store/useCurrentStore";
import { useProductionMethodStore } from "@/store/useProductionMethodStore";
import type { RGBColor } from "@/utils/colorConversion";
import type { ApiLogoDesign, ApiTextDesign } from "@smakecloud/designer-core";
import { storeToRefs } from "pinia";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";

type ColorAssignment =
    | ApiTextDesign["resource"]["lines"][number]["color_assignments"][number]
    | ApiLogoDesign["resource"]["color_assignments"][number];

const colorAssignment = defineModel<ColorAssignment>({ required: true });
const isColorPickerOpen = ref(false);

const { t } = useI18n();
const { currentAvailableColors } = storeToRefs(useCurrentStore());
const { currentProductionMethod } = storeToRefs(useProductionMethodStore());

const { colorsData } = storeToRefs(useColorStore());

const rgbColor = computed<RGBColor>(() => {
    if (colorAssignment.value.color) {
        const color = colorsData.value?.find(
            (color) => color.id === colorAssignment.value.color?.id,
        );

        if (!color) {
            return { red: 0, green: 0, blue: 0 };
        }

        return { red: color.red, green: color.green, blue: color.blue };
    }

    return {
        red: colorAssignment.value.red,
        green: colorAssignment.value.green,
        blue: colorAssignment.value.blue,
    };
});
function selectColorId(id: number) {
    colorAssignment.value =
        "id" in colorAssignment.value
            ? {
                  color: { id },
                  id: colorAssignment.value.id,
              }
            : {
                  color: { id },
              };
}

function onSelect(color: RGBColor) {
    colorAssignment.value =
        "id" in colorAssignment.value
            ? {
                  ...color,
                  id: colorAssignment.value.id,
              }
            : color;
}

const customerColors = computed(() =>
    currentAvailableColors.value.filter(
        (color) => color.is_customer_logo_color,
    ),
);
const standardColors = computed(() =>
    currentAvailableColors.value.filter((color) => color.is_standard),
);
</script>

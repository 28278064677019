export type WithChildren<T> = T & { children: WithChildren<T>[] };

export default () => {
    function forEach<T>(
        values: WithChildren<T>[],
        fn: (
            value: WithChildren<T>,
            parents: WithChildren<T>[],
        ) => boolean | void,
        parents: WithChildren<T>[] = [],
    ) {
        for (const value of values) {
            if (
                fn(value, parents) ||
                forEach(value.children, fn, [...parents, value])
            ) {
                return true;
            }
        }

        return false;
    }

    return {
        forEach,
    };
};

<template>
    <template v-if="!hasBootError && !isBooting">
        <div class="relative flex h-full w-full flex-col">
            <div ref="designerWrapper" class="relative h-full w-full grow">
                <div ref="modals" />
                <index />
                <panels />
                <div
                    ref="portalRoot"
                    class="absolute z-[80]"
                    :class="{
                        'inset-0': hasDialogesOpen,
                    }"
                />
                <notification-wrapper />
                <merchant-sketch-variant-added-dialog v-if="!isInternal" />
            </div>
            <base-modal
                ref="designerSessionExpiredModal"
                :can-be-closed="!isDesignerSessionExpired"
                size="md"
            >
                <template #title>
                    <div class="flex gap-2">
                        <f-a-icon
                            :icon="FontAwesomeKitIcons.CiConfigAlertWarning"
                            :size="FontAwesomeSize.lg"
                            class="text-danger"
                        />
                        {{ t("session_expired") }}
                    </div>
                </template>
                <div v-if="isTryingToReconnectSession" class="animate-pulse">
                    {{ t("trying_to_reconnect_session") }}
                </div>
                <div v-else>
                    {{ t("session_expired_description") }}
                </div>
            </base-modal>
            <global-production-method class="absolute top-0 mt-5" />
            <production-method-modal-compact />
            <production-method-modal />
            <dialogs />
        </div>
    </template>
    <div
        v-else
        class="flex h-full flex-col items-center justify-center gap-3 text-primary-1"
    >
        <div class="text-2xl">
            {{ bootErrorObject?.headline }}
        </div>
        <div class="text-xl">
            {{ bootErrorObject?.errorMessage }}
        </div>
    </div>
</template>

<script setup lang="ts">
import packageJson from "@/../package.json";
import Panels from "@/components/layout/Panels.vue";
import BaseModal from "@/components/shared/modal/BaseModal.vue";
import useChangeCurrentVariant from "@/composables/useChangeCurrentVariant";
import useModal from "@/composables/useModal";
import { usePusher } from "@/composables/usePusher";
import { FontAwesomeKitIcons } from "@/enums/fontAwesome/fontAwesomeKitIcons";
import { FontAwesomeSize } from "@/enums/fontAwesome/fontAwesomeSize";
import { useSetup } from "@/lib/setup";
import { useBoundingStore } from "@/store/useBoundingStore";
import { useCurrentStore } from "@/store/useCurrentStore";
import { useDialogStore } from "@/store/useDialogStore";
import { useMainStore } from "@/store/useMainStore";
import { type CustomVariantCreatedEvent } from "@/types/CustomVariantCreatedEvent";
import { type DesignerProps } from "@/types/DesignerProps";
import { useApiClientStore } from "@smakecloud/designer-core";
import { useDesignerApi } from "@smakecloud/smake-use";
import { useResizeObserver, useTimeoutFn } from "@vueuse/core";
import { storeToRefs } from "pinia";
import {
    getCurrentInstance,
    nextTick,
    onMounted,
    onUpdated,
    ref,
    watch,
    watchEffect,
    type RendererElement,
} from "vue";
import { useI18n } from "vue-i18n";
import Dialogs from "./components/shared/dialog/Dialogs.vue";
import useStoreVariant from "./composables/variants/useStoreVariant";
import { usePanelStore } from "./store/usePanelStore";
import { useSettingsStore } from "./store/useSettingsStore";
import { useShowCustomerLogoOrDesignCardStore } from "./store/useShowCustomerLogoOrDesignCardStore";
import type { VariantChangedEvent } from "./types/VariantChangedEvent";

const hasBootError = ref<boolean>(false);

const props = defineProps<DesignerProps>();

if (props.debug || import.meta.env.DEV) {
    console.info(`Designer Version: ${packageJson.version}`);
}

const { setupApp, bootErrorObject, isBooting } = useSetup();

try {
    await setupApp(props);
} catch (error: unknown) {
    hasBootError.value = true;
}

// start coding here, as at this point the app has finished the setup.
// Otherwise, you might run into errors if you access code that is not yet initialized.

const designerSessionExpiredModal = ref<typeof BaseModal | null>(null);
const isTryingToReconnectSession = ref<boolean>(true);

const { stageContent } = storeToRefs(useBoundingStore());
const { customVariantCreatedId, currentVariant } =
    storeToRefs(useCurrentStore());
const { modalTeleportTarget } = useModal();
const { locale: i18nLocale, t } = useI18n();
const { changeVariantByIdentifier } = useChangeCurrentVariant();
const { isDesignerSessionExpired, renewToken } = useDesignerApi();
const { start } = useTimeoutFn(
    () => (isTryingToReconnectSession.value = false),
    30000,
);
const { designerWrapper } = storeToRefs(useMainStore());
const { isPanelOpen } = storeToRefs(usePanelStore());
const { getStoreVariantRequestBody } = useStoreVariant();
const { isInternal } = storeToRefs(useSettingsStore());
const { acceptLanguage } = storeToRefs(useApiClientStore());
// preload data for smoother user experience
useShowCustomerLogoOrDesignCardStore();

usePusher({
    channelPrefix: props.pusher.channelPrefix,
    cluster: props.pusher.cluster,
    appKey: props.pusher.appKey,
});

const emit = defineEmits<{
    "custom-variant-created": [CustomVariantCreatedEvent];
    "session-expired": [void];
    "variant-changed": [VariantChangedEvent | undefined];
}>();

const modals = ref<RendererElement>();

const { portalRoot, hasDialogesOpen } = storeToRefs(useDialogStore());

const instance = getCurrentInstance();

watch(
    () => props.token,
    async (newToken) => {
        try {
            await renewToken(newToken);
        } catch {
            onSessionExpired();
        }
    },
);

watch(
    () => props.variant,
    async (newVariant) => {
        await changeVariantByIdentifier(newVariant, instance);
    },
    { deep: true },
);

watchEffect(() => {
    i18nLocale.value = props.locale;
    acceptLanguage.value = props.locale;
});

watch(
    () => stageContent.value,
    () => {
        setAppBounding();
    },
);

watch(
    () => customVariantCreatedId.value,
    (newCustomVariantCreatedId: number | null) => {
        if (newCustomVariantCreatedId === null) {
            return;
        }

        emit("custom-variant-created", {
            id: newCustomVariantCreatedId,
            external_identifier: currentVariant.value.external_identifier,
            base: {
                id: currentVariant.value.base.id,
                external_identifier:
                    currentVariant.value.base.external_identifier,
            },
        });
    },
);

watchEffect(() => {
    if (isPanelOpen.value) {
        emit("variant-changed", undefined);
        return;
    }

    try {
        emit("variant-changed", {
            id: currentVariant.value.id,
            ...getStoreVariantRequestBody(currentVariant.value),
        });
    } catch {
        emit("variant-changed", undefined);
    }
});

function onSessionExpired() {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    designerSessionExpiredModal.value?.open();
    emit("session-expired");
    start();
}

watch(
    () => isDesignerSessionExpired.value,
    async (isExpired: boolean) => {
        if (isExpired) {
            onSessionExpired();

            return;
        }

        await nextTick();

        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        designerSessionExpiredModal.value?.close();
        isTryingToReconnectSession.value = true;
    },
);

onMounted(() => {
    console.log("Designer mounted");
    setAppBounding();

    modalTeleportTarget.value = modals.value ?? null;
});

onUpdated(() => {
    void nextTick(() => {
        console.log("Designer updated");
        setAppBounding();
    });
});

useResizeObserver(designerWrapper, setAppBounding);

function setAppBounding() {
    const { app } = storeToRefs(useBoundingStore());
    const appBounding: DOMRect | null =
        designerWrapper.value?.getBoundingClientRect() ?? null;

    if (appBounding === null) {
        return;
    }

    app.value = {
        top: appBounding.top,
        right: appBounding.right,
        bottom: appBounding.bottom,
        left: appBounding.left,
        width: appBounding.width,
        height: appBounding.height,
        x: appBounding.x,
        y: appBounding.y,
    };
}
</script>

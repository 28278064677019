import { type fabric } from "fabric";

export type DesignImage = fabric.Image & {
    id: string;
    opacity: number;
    originX: DesignImageOriginX;
    originY: DesignImageOriginY;
    left: number;
    top: number;
    angle: number;
    scaleX: number;
    scaleY: number;
    hasControls: boolean;
    hasBorders: boolean;
    smake_type: "background" | "text";
};

export type DesignImageBackground = fabric.Image & {
    id: number;
    smake_type: "background" | "text";
    originX: DesignImageOriginX;
    originY: DesignImageOriginY;
    scaleX: number;
    scaleY: number;
    selectable: boolean;
    evented: boolean;
    hoverCursor: CursorAppearance;
};

export type DesignImageOriginX = "left" | "center" | "right";
export type DesignImageOriginY = "top" | "center" | "bottom";
export type CursorAppearance = "default" | "pointer";

export function isDesignOriginX(value: unknown): value is DesignImageOriginX {
    return value === "left" || value === "center" || value === "right";
}

export function isDesignOriginY(value: unknown): value is DesignImageOriginY {
    return value === "top" || value === "center" || value === "bottom";
}

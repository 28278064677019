import { LogColor } from "@/enums/LogColor";

export default () => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function logDev(message?: any, ...optionalParams: any[]) {
        if (process.env.NODE_ENV === "development") {
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            console.log(message, ...optionalParams);
        }
    }

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function logErrorDev(message?: any, ...optionalParams: any[]) {
        if (process.env.NODE_ENV === "development") {
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            console.error(message, ...optionalParams);
        }
    }

    function logTextDev(message: string, color: LogColor = LogColor.default) {
        logDev(`%c ${message}`, `color: ${color}`);
    }

    return {
        logDev,
        logTextDev,

        logErrorDev,
    };
};

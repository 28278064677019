import { defineStore } from "pinia";
import { ref } from "vue";

export const useDesignerRootStore = defineStore("designerRoot", () => {
    const designerRoot = ref<HTMLDivElement>();

    return {
        designerRoot,
    };
});

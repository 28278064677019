<template>
    <button
        type="button"
        data-testid="production-method-button"
        class="pointer-events-auto relative flex h-12 w-fit items-center justify-between gap-4 rounded-full border border-black bg-white px-4 text-xs uppercase text-black"
        :class="{
            'border-secondary-2': !isAProductionMethodSelectionOpen,
        }"
        :disabled="disabled"
    >
        <f-a-icon :icon="currentProductionMethodIcon" />
        <span>
            {{ buttonText }}
        </span>
        <f-a-icon class="text-base" :icon />
    </button>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { storeToRefs } from "pinia";
import type { IconName } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeKitIcons } from "@/enums/fontAwesome/fontAwesomeKitIcons";
import { useProductionMethodStore } from "@/store/useProductionMethodStore";
import { useProductionMethodDialogStore } from "@/store/useProductionMethodDialogStore";
import { productionMethodIconMap } from "@/functions/ProductionMethodIcon";

const { currentProductionMethod } = storeToRefs(useProductionMethodStore());

const currentProductionMethodIcon = computed(
    () => productionMethodIconMap[currentProductionMethod.value.handle],
);

const {
    isAProductionMethodSelectionOpen,
    showProductionMethodSelectionAsInfo,
} = storeToRefs(useProductionMethodDialogStore());

// props
withDefaults(
    defineProps<{
        disabled?: boolean;
    }>(),
    {
        disabled: false,
    },
);

const icon = computed<IconName | FontAwesomeKitIcons>(() => {
    if (showProductionMethodSelectionAsInfo.value) {
        return FontAwesomeKitIcons.Info;
    }

    if (!isAProductionMethodSelectionOpen.value) {
        return "chevron-down";
    }

    return "chevron-up";
});

const buttonText = computed<string>(() => {
    return currentProductionMethod.value.display_name;
});
</script>

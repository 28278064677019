import { getLengthUnitRatio } from "@/lib/unit";
import { apiClient } from "@smakecloud/designer-core";
import {
    type LayoutConfigData,
    type StyleConfigData,
    type UnitData,
} from "@smakecloud/smake-use";
import { useQuery } from "@tanstack/vue-query";
import { defineStore } from "pinia";
import { computed, readonly, ref, watch } from "vue";

export const useSettingsStore = defineStore("settingsStore", () => {
    const isInitialized = ref(false);

    const { data: designerConfigurations } = useQuery({
        ...apiClient.configurations.designer.get.use(),
        staleTime: Infinity,
        retry: false,
    });

    watch(
        designerConfigurations,
        () => {
            if (!designerConfigurations.value) {
                return;
            }

            const { attributes, settings, is_internal } =
                designerConfigurations.value.settings;

            unit.value = settings.units;
            customization_positioning_mode.value =
                settings.customization_positioning_mode;
            include_tax.value = settings.include_tax;
            isInternal.value = is_internal;
            phone.value = attributes.phone;

            isInitialized.value = true;
        },
        { immediate: true },
    );

    const unit = ref<UnitData>({
        currency: "EUR",
        length: "mm",
    });
    const locale = ref<string>("de");
    const customization_positioning_mode = ref<string>("positioning");
    const include_tax = ref<boolean>(false);
    const isInternal = ref(false);
    const phone = ref<string>();

    const layout = ref<LayoutConfigData[]>([]);
    const style = ref<StyleConfigData[]>([]);

    const animationTime = ref<number>(500);

    const lengthUnitRatio = computed(() =>
        getLengthUnitRatio(unit.value.length),
    );

    return {
        unit,
        lengthUnitRatio,
        locale,
        customization_positioning_mode,
        include_tax,
        isInternal,
        layout,
        style,
        animationTime,
        phone,

        isInitialized: readonly(isInitialized),
    };
});

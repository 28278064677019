import type Annotation from "@/repo/Annotation";
import ProductionMethod from "@/repo/ProductionMethod";
import {
    type InnerAnchorPoints,
    type PositioningAreaIdentifierData,
} from "@smakecloud/smake-use";
import { Model } from "pinia-orm";
import { Attr, BelongsTo, Num, Str, Uid } from "pinia-orm/dist/decorators";

export default class PositioningArea extends Model {
    static entity = "positioningAreas";

    @Uid()
    id!: number;

    @Str("")
    view_type_handle!: string;

    @Num(0)
    production_method_id!: number;

    @BelongsTo(() => ProductionMethod, "production_method_id")
    production_method!: ProductionMethod;

    @Num(0)
    x!: number;

    @Num(0)
    y!: number;

    @Num(0)
    localized_width!: number;

    @Num(0)
    localized_height!: number;

    @Str("")
    position!: InnerAnchorPoints;

    @Num(0)
    rotation!: number;

    @Num(0)
    order_column!: number;

    @Attr()
    positioning_area_identifier_handle!: string | null;

    @Attr()
    positioning_area_identifier!: PositioningAreaIdentifierData | null;

    @Attr([])
    annotations!: Annotation[];

    @Num(0)
    direction!: number;
}

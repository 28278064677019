<template>
    <div
        class="relative flex justify-between rounded-md border border-white bg-white p-2 tablet:border-secondary-1"
        :class="{ 'opacity-80 grayscale-[50%]': disabled }"
    >
        <input
            :id="`${idPrefix}_variant_customization_${customization.id}`"
            v-model="selectedCustomizationId"
            :data-testid="`${idPrefix}_variant_customization_${customization.id}`"
            :value="customization.id"
            type="radio"
            class="self-start text-3xl enabled:cursor-pointer"
            :name="`${idPrefix}_variant_customization_${customization.id}`"
            :disabled
        />

        <div
            class="cursor-pointer"
            @click="selectedCustomizationId = customization.id"
            @touchstart="selectedCustomizationId = customization.id"
        >
            <archive-customization-preview
                :archive-variant="variant"
                :customization-response-to-preview="customization"
            />
        </div>

        <div class="self-end">
            <button type="button">
                <f-a-icon
                    class="text-secondary-1"
                    icon="up-right-and-down-left-from-center"
                    @click="emit('open-views-preview')"
                />
            </button>
        </div>

        <f-a-icon
            v-if="productionMethodIcon"
            class="absolute right-1 top-1 m-[3px] flex h-8 w-8 items-center justify-center rounded-full bg-secondary-3"
            :icon="productionMethodIcon"
        />
        <div v-if="disabled" class="absolute inset-0 bg-secondary-1/50"></div>
    </div>
</template>

<script setup lang="ts">
import { productionMethodIconMap } from "@/functions/ProductionMethodIcon";
import {
    type ArchiveVariantData,
    type CustomizationData,
    type VariantData,
} from "@smakecloud/smake-use";
import { computed, toRefs } from "vue";

const props = defineProps<{
    customization: CustomizationData;
    idPrefix: string;
    variant: ArchiveVariantData | VariantData;
    disabled?: boolean;
}>();
const { customization } = toRefs(props);

const emit = defineEmits<{
    "open-views-preview": [];
}>();

const selectedCustomizationId = defineModel<number>();

const productionMethodIcon = computed(
    () =>
        productionMethodIconMap[
            customization.value.resource.production_method.handle
        ],
);
</script>

import useProgress from "@/composables/useProgress";
import { defineStore } from "pinia";
import { ref } from "vue";

export const useSetupStore = defineStore("setup", () => {
    const bootErrorObject = ref<null | {
        headline: string;
        errorMessage: string;
    }>(null);
    const isBooting = ref<boolean>(true);
    const finishedBootSteps = ref<string[]>([]);

    return {
        bootErrorObject,
        isBooting,
        finishedBootSteps,
        ...useProgress(),
    };
});

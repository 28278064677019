import useArchiveVariant from "@/composables/variants/useArchiveVariant";
import useCreatePdf from "@/composables/variants/useCreatePdf";
import useStoreVariant from "@/composables/variants/useStoreVariant";
import { PusherEvent } from "@/enums/PusherEvent";
import { useDesignerApi } from "@smakecloud/smake-use";
import Pusher from "pusher-js";
import { onMounted, onUnmounted } from "vue";
import { usePusherVariantsCollection } from "./variants/usePusherVariantsCollection";

export function usePusher(options: {
    channelPrefix: string;
    appKey: string;
    cluster: string;
}) {
    const { onStoringCustomVariantCustomizationsCompleted } = useStoreVariant();
    const { onCreatingPdfCompleted } = useCreatePdf();
    const { onArchivingCompleted, onArchiveEntryDeleted } = useArchiveVariant();
    const { xTabSessionId, userId } = useDesignerApi();
    const { onCollectionVariantUpserted, onCollectionVariantDeleted } =
        usePusherVariantsCollection();

    const pusher = new Pusher(options.appKey, {
        cluster: options.cluster,
    });

    onMounted(() => {
        bindUserEvents();
        bindSessionEvents();
    });

    onUnmounted(() => {
        pusher.unbind_all();
    });

    function bindUserEvents(): void {
        const channel: string = [
            options.channelPrefix,
            "smake-designer-api",
            userId.value,
        ].join("-");

        const pusherChannel = pusher.subscribe(channel);

        pusherChannel.bind(
            PusherEvent.CustomVariantCustomizationsCompletedAfterAssignedToArchive,
            onArchivingCompleted,
        );

        pusherChannel.bind(
            PusherEvent.CustomVariantDeleteFromArchiveCompleted,
            onArchiveEntryDeleted,
        );

        pusherChannel.bind(
            PusherEvent.CustomVariantAddedToFavorites,
            onCollectionVariantUpserted,
        );

        pusherChannel.bind(
            PusherEvent.FavoriteDeleted,
            onCollectionVariantDeleted,
        );

        pusherChannel.bind(PusherEvent.Error, () => {
            console.error("Pusher User error");
        });
    }

    function bindSessionEvents(): void {
        const channel: string = [
            options.channelPrefix,
            "smake-designer-api",
            userId.value,
            xTabSessionId.value,
        ].join("-");

        const pusherChannel = pusher.subscribe(channel);

        pusherChannel.bind(
            PusherEvent.CustomVariantCustomizationsCompleted,
            onStoringCustomVariantCustomizationsCompleted,
        );

        pusherChannel.bind(
            PusherEvent.CustomVariantCustomizationsCompletedForPDFDownload,
            onCreatingPdfCompleted,
        );

        pusherChannel.bind(PusherEvent.Error, () => {
            console.error("Pusher X-Tab-Session-Id error");
        });
    }
}

<template>
    <panel
        :style-config="styleConfig"
        :show-on-panel-state="PanelState.PdfDownload"
    >
        <h2 class="mb-3 text-lg font-semibold uppercase">
            {{ t("pdf_download") }}
        </h2>
        <p class="text-sm">Hier wird später der Download vorbereitet.</p>
        <p class="text-sm">
            Dieses Panel verfügt über eine Backdrop-Funktion wenn man außerhalb
            der Fläche klickt (Desktop-Version) und einem X-Button zum schliußen
            in der mobile Version.
        </p>
    </panel>
</template>

<script setup lang="ts">
import { PanelState } from "@/enums/PanelState";
import { useMainStore } from "@/store/useMainStore";
import get from "lodash/get";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const { settings } = useMainStore();
const { t } = useI18n();

// computed
const styleConfig = computed(() => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return get(settings.style, "pdfDownloadModal", {});
});
</script>

<template>
    <div v-show="showError" class="text-center text-danger">
        <f-a-icon icon="exclamation-triangle" :size="FontAwesomeSize.fa3x" />
        <span class="mt-1 block">
            {{ props.errorMessage }}
        </span>
    </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { FontAwesomeSize } from "@/enums/fontAwesome/fontAwesomeSize";

const showError = ref(true);

const props = defineProps({
    errorMessage: {
        type: String,
        default: "Ups, Es ist ein Fehler passiert!",
    },
});
</script>

<!-- eslint-disable vue/no-deprecated-v-on-native-modifier -->
<template>
    <panel
        :style-config="styleConfig.panel"
        :show-on-panel-state="PanelState.ProductColors"
    >
        <div :class="styleConfig.styles">
            <div class="flex-0">
                <h2 class="text-lg font-semibold uppercase">
                    {{ t("product_colors") }}
                </h2>
            </div>
            <template v-if="isError">
                <div
                    class="flex flex-1 flex-col content-center justify-center gap-10"
                >
                    <error />

                    <button
                        class="w-min self-center"
                        :class="settings.style.primaryButton.styles"
                        @click="abort()"
                    >
                        {{ t("abort") }}
                    </button>
                </div>
            </template>
            <template v-else-if="isLoading">
                <div
                    class="flex flex-1 flex-wrap content-center justify-center"
                >
                    <loading />
                </div>
            </template>
            <template v-else>
                <div class="flex-1">
                    <div :class="styleConfig.colorsWrapper.styles">
                        <color-button
                            v-for="variant in availableColorVariants"
                            :key="variant.color_handle"
                            :fill="variant.color_preview_url"
                            :selected="
                                selectedVariant?.color_handle ===
                                variant.color_handle
                            "
                            @click.native="selectVariant(variant)"
                        >
                            {{ variant.color_name }}
                        </color-button>
                    </div>
                </div>

                <div :class="styleConfig.previewWrapper.styles">
                    <img
                        :src="selectedVariant?.image_url"
                        :class="previewStyles"
                        @load="previewLoaded"
                    />
                    <f-a-icon
                        v-if="isPreviewLoading"
                        icon="spinner"
                        :size="FontAwesomeSize.fa2x"
                        spin
                        class="absolute self-center justify-self-center text-black"
                    />
                </div>

                <div :class="styleConfig.optionsWrapper.styles">
                    <button
                        type="button"
                        :class="settings.style.secondaryButton.styles"
                        @click="abort()"
                    >
                        <div
                            class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
                        >
                            <f-a-icon
                                icon="chevron-left"
                                :size="FontAwesomeSize.fa1x"
                            />
                        </div>
                        <span>
                            {{ t("abort") }}
                        </span>
                    </button>
                    <button
                        type="button"
                        :class="settings.style.primaryButton.styles"
                        @click="confirm()"
                    >
                        <f-a-icon
                            v-if="isNewVariantLoading"
                            icon="spinner"
                            :size="FontAwesomeSize.fa2x"
                            spin
                            class="absolute self-center justify-self-center text-black"
                        />
                        <span v-if="!isNewVariantLoading">
                            {{ t("apply") }}
                        </span>
                    </button>
                </div>
            </template>
        </div>
    </panel>
</template>

<script setup lang="ts">
import useSaveVariant from "@/composables/variants/useVariantToORM";
import { PanelState } from "@/enums/PanelState";
import { FontAwesomeSize } from "@/enums/fontAwesome/fontAwesomeSize";
import { useAvailableVariantsStore } from "@/store/useAvailableVariantsStore";
import { useCurrentStore } from "@/store/useCurrentStore";
import { useMainStore } from "@/store/useMainStore";
import { usePanelStore } from "@/store/usePanelStore";
import { apiClient } from "@smakecloud/designer-core";
import { useDesignerApi, type ColorVariantData } from "@smakecloud/smake-use";
import { useQuery } from "@tanstack/vue-query";
import cloneDeep from "lodash/cloneDeep";
import get from "lodash/get";
import { storeToRefs } from "pinia";
import { computed, ref, watchEffect } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

const { t } = useI18n();
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line @typescript-eslint/unbound-method
const { back } = useRouter();
const { currentVariant } = storeToRefs(useCurrentStore());
const { hideCurrentPanel } = usePanelStore();
const { changeCurrentVariant } = useCurrentStore();
const { settings } = storeToRefs(useMainStore());
const desginerApi = useDesignerApi();
const { saveVariantResponseWithOldCustomizatonsToRepository } =
    useSaveVariant();

// data
const selectedVariant = ref<ColorVariantData>();
const isPreviewLoading = ref(true);
const isNewVariantLoading = ref(false);
const { isVariantAvailable } = useAvailableVariantsStore();

// computed
const styleConfig = computed(() => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return get(settings.value.style, "productColorsModal");
});

const previewStyles = computed(() => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
    const styles = cloneDeep(styleConfig.value.previewWrapper.image.styles);

    if (isPreviewLoading.value) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
        styles.push("opacity-25");
    }

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return styles;
});

const availableColorVariants = computed<ColorVariantData[]>(
    () =>
        colorVariants.value?.filter((colorVariant: ColorVariantData) =>
            isColorVariantAvailable(colorVariant),
        ) ?? [],
);

function isColorVariantAvailable(colorVariant: ColorVariantData): boolean {
    return colorVariant.size_variants.some((size_variant) =>
        isVariantAvailable(size_variant),
    );
}

const currentProductId = computed(() => currentVariant.value.product_id);

const {
    data: colorVariants,
    isLoading,
    isError,
} = useQuery({
    ...apiClient.products.colorVariants.list.use(currentProductId),
    staleTime: Infinity,
    retry: false,
});

watchEffect(() => {
    if (isLoading.value) {
        return;
    }

    if (selectedVariant.value) {
        return;
    }

    selectedVariant.value = colorVariants.value?.find((variant) => {
        return variant.color_handle === currentVariant.value.color.handle;
    });
});

function selectVariant(variant: ColorVariantData) {
    if (variant.color_handle === selectedVariant.value?.color_handle) {
        return;
    }

    isPreviewLoading.value = true;
    selectedVariant.value = variant;
}

function abort() {
    void hideCurrentPanel();
}

async function confirm() {
    const newVariant = await loadNewVariant();

    changeCurrentVariant(newVariant);
    back();

    await hideCurrentPanel();
}

function previewLoaded() {
    isPreviewLoading.value = false;
}

async function loadNewVariant() {
    isNewVariantLoading.value = true;
    return saveVariantResponseWithOldCustomizatonsToRepository(
        await desginerApi.endpoints.variants.getById(
            selectedVariant.value?.size_variants[0].id ??
                currentVariant.value.id,
        ),
    );
}
</script>

<template>
    <disclosure-panel
        ref="panel"
        class="overflow-hidden"
        :class="
            (overwriteOpenState ? overwrittenOpenState : open)
                ? 'h-auto'
                : 'h-0'
        "
        static
    >
        <slot />
    </disclosure-panel>
</template>

<script setup lang="ts">
import { DisclosurePanel } from "@headlessui/vue";
import { useAnimatedHeight } from "@smakecloud/smake-use";
import { computed, ref, watch } from "vue";

const props = defineProps<{ open: boolean }>();

const overwriteOpenState = ref(false);
const overwrittenOpenState = ref(false);

const panel = ref<{ el: HTMLDivElement }>();

const { animateHeight } = useAnimatedHeight(computed(() => panel.value?.el));

watch(props, async ({ open }) => {
    overwrittenOpenState.value = !open;
    overwriteOpenState.value = true;
    await animateHeight(() => {
        overwrittenOpenState.value = !overwrittenOpenState.value;
    });
    overwriteOpenState.value = false;
});
</script>

<script setup lang="ts">
import type { HSVColor } from "@/utils/colorConversion";
import { clamp, useMouseInElement, useMousePressed } from "@vueuse/core";
import { computed, useTemplateRef, watch } from "vue";

const color = defineModel<HSVColor>({ required: true });

const colorArea = useTemplateRef("colorArea");

function updateSaturationAndLightness(offsetX: number, offsetY: number) {
    if (!colorArea.value) return;
    const relativeX = clamp(offsetX / colorArea.value.clientWidth, 0, 1);
    const relativeY = clamp(offsetY / colorArea.value.clientHeight, 0, 1);

    color.value = {
        ...color.value,
        saturation: relativeX * 100,
        value: (1 - relativeY) * 100,
    };
}

const { pressed } = useMousePressed({ target: colorArea });
const { elementX, elementY } = useMouseInElement(colorArea);
watch(
    [elementY, elementX, pressed],
    () => {
        if (!pressed.value) return;

        updateSaturationAndLightness(elementX.value, elementY.value);
    },
    { immediate: true },
);

const colorDotStyle = computed(() => {
    if (!colorArea.value) return;

    const x = (color.value.saturation / 100) * colorArea.value.clientWidth;
    const y = (1 - color.value.value / 100) * colorArea.value.clientHeight;

    return {
        left: `${x}px`,
        top: `${y}px`,
    };
});
</script>

<template>
    <div
        ref="colorArea"
        class="relative h-full w-full touch-none rounded-md bg-[linear-gradient(0deg,#000,transparent),linear-gradient(90deg,#fff,hsla(0,0%,100%,0))]"
        :style="{
            backgroundColor: `hsl(${color.hue}deg,100%, 50%)`,
        }"
        :class="{
            'cursor-grabbing': pressed,
            'cursor-pointer': !pressed,
        }"
    >
        <div
            class="pointer-events-none absolute h-5 w-5 -translate-x-1/2 -translate-y-1/2 rounded-full border-2 border-white"
            :style="colorDotStyle"
            colorSelectorStyle
        ></div>
    </div>
</template>

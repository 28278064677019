<template>
    <div
        class="fixed inset-0 mx-0 mt-16 flex items-center justify-center tablet:m-12"
    >
        <transition-child
            appear
            as="template"
            enter="duration-300 ease-out"
            enter-from="translate-y-full tablet:translate-y-0 tablet:opacity-0 tablet:scale-95"
            enter-to="translate-y-0 tablet:opacity-100 tablet:scale-100"
            leave="duration-200 ease-in"
            leave-from="translate-y-0 tablet:opacity-100 tablet:scale-100"
            leave-to="translate-y-full tablet:translate-y-0 tablet:opacity-0 tablet:scale-95"
        >
            <base-dialog-panel
                class="flex h-full w-[1280px] flex-col p-4"
                has-close-button
                data-testid="logo-selection-modal"
            >
                <logo-selection @logo-selected="onLogoSelected" />
            </base-dialog-panel>
        </transition-child>
    </div>
</template>

<script setup lang="ts">
import { useDialog } from "@/composables/useDialog";
import { useProductionMethodStore } from "@/store/useProductionMethodStore";
import { TransitionChild } from "@headlessui/vue";
import { type LogoResourceData } from "@smakecloud/smake-use";
import { storeToRefs } from "pinia";
import ChangeProductionMethodDialog from "../changeProductionMethodDialog/ChangeProductionMethodDialog.vue";

const emit = defineEmits<{
    resolve: [LogoResourceData];
}>();

const productionMethodStore = useProductionMethodStore();
const { currentProductionMethod } = storeToRefs(productionMethodStore);
const { show } = useDialog();

async function onLogoSelected(selectedLogo: LogoResourceData) {
    const toProductionMethod = productionMethodStore.getById(
        selectedLogo.production_method.id,
        true,
    );
    if (
        currentProductionMethod.value.id !== toProductionMethod.id &&
        !(await show(ChangeProductionMethodDialog, { toProductionMethod }))
    ) {
        return;
    }

    emit("resolve", selectedLogo);
}
</script>

<template>
    <div>
        <label
            :for="id"
            class="flex justify-between text-xs font-semibold uppercase"
        >
            <span class="self-center uppercase">
                {{ label }}
            </span>
            <span
                class="inline-flex items-center rounded-full border border-secondary-2 px-2.5 text-xs font-bold"
            >
                {{ value }} {{ unit }}
            </span>
        </label>
        <div class="my-1">
            <input
                :id
                v-model.number="value"
                class="mt-3 h-1 w-full max-w-sm appearance-none rounded-lg bg-secondary-3"
                type="range"
                :name="id"
                :min
                :max
                :step
                :data-testid="`slider-input-${id}`"
            />
        </div>
    </div>
</template>

<script setup lang="ts">
defineProps<{
    label: string;
    id: string;
    unit?: string;
    min?: number;
    max?: number;
    step?: number;
}>();

const value = defineModel<number>({ required: true });
</script>

import { useCreateNewCustomization } from "@/components/modules/customizations/composables/useCeateNewCustomization";
import usePositioningAreaFunctions from "@/composables/positioningAreas/usePositioningAreaFunctions";
import useNotification from "@/composables/useNotification";
import useRenderCustomizationPreview from "@/composables/useRenderCustomizationPreview";
import { NotificationType } from "@/enums/NotificationType";
import { PanelState } from "@/enums/PanelState";
import { Style } from "@/enums/Style";
import { Mode } from "@/enums/mode";
import type PositioningArea from "@/repo/PositioningArea";
import View from "@/repo/View";
import { useCanvasStore } from "@/store/useCanvasStore";
import { useCurrentStore } from "@/store/useCurrentStore";
import { usePanelStore } from "@/store/usePanelStore";
import type { PositioningAreaCanvasObject } from "@/types/PositioningAreaCanvasObject";
import { fabric } from "fabric";
import { storeToRefs } from "pinia";
import { useRepo } from "pinia-orm";
import { useI18n } from "vue-i18n";

export default () => {
    const { t } = useI18n();
    const notification = useNotification();
    const { positioningAreaCanvasObjects } = storeToRefs(useCanvasStore());
    const { createAndRenderNewDesign } = useRenderCustomizationPreview();
    const positioningAreaFunctions = usePositioningAreaFunctions();
    const { currentVariant, currentMode, addArchivDesign } =
        storeToRefs(useCurrentStore());
    const { changeCurrentCustomization } = useCurrentStore();
    const { showPanel } = usePanelStore();
    const { createNewCustomization } = useCreateNewCustomization();
    const { showOrHidePositioningAreaCanvasObjects } = useCanvasStore();
    const { setNewTextCanvasObjectsAsActive } = storeToRefs(useCanvasStore());

    const canvasObjects: PositioningAreaCanvasObject[] = [];
    currentVariant.value.views.forEach((view) => {
        const positioningAreasSortedByOrderColumn = view.positioning_areas.sort(
            (positioningAreaA, positioningAreaB) => {
                if (
                    positioningAreaA.order_column <
                    positioningAreaB.order_column
                ) {
                    return -1;
                }

                if (
                    positioningAreaA.order_column >
                    positioningAreaB.order_column
                ) {
                    return 1;
                }

                return 0;
            },
        );

        positioningAreasSortedByOrderColumn.forEach(
            (positioningArea: PositioningArea) => {
                const viewScaleFactor =
                    useRepo(View)
                        .where("handle", positioningArea.view_type_handle)
                        .where("variant_id", currentVariant?.value.id)
                        .first()?.scalefactor ?? 1;

                const rect = new fabric.Rect({
                    width: positioningArea.localized_width * viewScaleFactor,
                    height: positioningArea.localized_height * viewScaleFactor,
                    fill: positioningAreaFunctions.transparentFillColor(
                        "default",
                    ),
                    stroke: positioningAreaFunctions.baseStrokeColor("default"),
                    strokeDashArray: positioningAreaFunctions.strokeDashArray(),
                    strokeWidth: positioningAreaFunctions.strokeWidth(),
                    hoverCursor: "pointer",
                });

                let text = "";

                if (positioningArea.positioning_area_identifier !== null) {
                    text = positioningArea.positioning_area_identifier.handle;
                }

                const positioningAreaIdentifierHandle = new fabric.Text(text, {
                    top:
                        (positioningArea.localized_height * viewScaleFactor) /
                        2,
                    left:
                        (positioningArea.localized_width * viewScaleFactor) / 2,
                    originX: "center",
                    originY: "center",
                    fontFamily: "sans-serif",
                    fontSize: 12,
                    opacity: 1,
                    fill: positioningAreaFunctions.baseStrokeColor("default"),
                });

                const group = new fabric.Group(
                    [rect, positioningAreaIdentifierHandle],
                    {
                        top: positioningArea.y,
                        left: positioningArea.x,
                        angle: positioningArea.rotation,
                        originY: getOriginY(positioningArea.position[0]),
                        originX: getOriginX(positioningArea.position[1]),
                        height:
                            positioningArea.localized_height * viewScaleFactor,
                        width:
                            positioningArea.localized_width * viewScaleFactor,
                        selectable: false,
                        evented: false,
                        hoverCursor: "pointer",
                    },
                );

                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                group.on("mousedown", async () => {
                    group._objects[0].set("fill", "rgba(255, 255, 255, 0.2)");
                    notification.resolveNotificationWithMessage(
                        t("choose_a_positioning_area"),
                    );

                    if (currentMode.value === Mode.AddArchiveText) {
                        currentMode.value = Mode.Default;
                        if (addArchivDesign.value === null) {
                            throw Error("Missing Archiv Data to render");
                        }

                        const customization = await createAndRenderNewDesign(
                            positioningArea,
                            positioningArea.view_type_handle,
                            addArchivDesign.value,
                        );
                        addArchivDesign.value = null;

                        if (customization) {
                            await changeCurrentCustomization(customization);
                        }

                        showOrHidePositioningAreaCanvasObjects();
                    } else {
                        currentMode.value = Mode.Default;
                        setNewTextCanvasObjectsAsActive.value = true;
                        await changeCurrentCustomization(
                            createNewCustomization(positioningArea),
                        );

                        showOrHidePositioningAreaCanvasObjects();
                        await showPanel(PanelState.CustomizationEdit);
                    }

                    positioningArea.annotations.forEach((annotation) => {
                        notification.pushNotification(
                            annotation.description,
                            NotificationType.Info,
                        );
                    });
                });

                const positioninAreaCanvasObject = {
                    id: positioningArea.id,
                    production_method_id: positioningArea.production_method_id,
                    canvas_object: group,
                    has_customization: false,
                    has_object_with_invalid_position: false,
                    style: Style.light,
                };

                canvasObjects.push(positioninAreaCanvasObject);
            },
        );
    });
    positioningAreaCanvasObjects.value = canvasObjects;
};

function getOriginX(origin: string) {
    switch (origin) {
        case "l":
            return "left";
        case "r":
            return "right";
        case "c":
            return "center";
        default:
            return "center";
    }
}

function getOriginY(origin: string) {
    switch (origin) {
        case "t":
            return "top";
        case "b":
            return "bottom";
        case "c":
            return "center";
        default:
            return "center";
    }
}

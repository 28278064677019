type Color = [number, number, number];

// https://www.w3.org/TR/WCAG22/#dfn-relative-luminance
export function relativeLuminance([r, g, b]: Color) {
    r /= 255;
    g /= 255;
    b /= 255;

    r = r <= 0.04045 ? r / 12.92 : Math.pow((r + 0.055) / 1.055, 2.4);
    g = g <= 0.04045 ? g / 12.92 : Math.pow((g + 0.055) / 1.055, 2.4);
    b = b <= 0.04045 ? b / 12.92 : Math.pow((b + 0.055) / 1.055, 2.4);

    return r * 0.2126 + g * 0.7152 + b * 0.0722;
}

// https://www.w3.org/TR/WCAG22/#dfn-contrast-ratio
export function contrastRatio(c1: Color, c2: Color) {
    const l1 = relativeLuminance(c1);
    const l2 = relativeLuminance(c2);

    return (Math.max(l1, l2) + 0.05) / (Math.min(l1, l2) + 0.05);
}

/**
 * When the relative luminance is below 0.179129 the contrast ratio to white is
 * higher than to black so we consider it a dark color
 */
const RelativeLuminanceThreshold = 0.179129;

export function isDarkColor(color: Color): boolean {
    return relativeLuminance(color) < RelativeLuminanceThreshold;
}

export function isLightColor(color: Color): boolean {
    return !isDarkColor(color);
}

import { type Bounding } from "@/types/Bounding";
import { computed, ref } from "vue";
import * as pinia from "pinia";

export const useBoundingStore = pinia.defineStore("bounding", () => {
    const app = ref<Bounding>({
        bottom: 0,
        height: 0,
        left: 0,
        right: 0,
        top: 0,
        width: 0,
        x: 0,
        y: 0,
    });
    const panelContent = ref<Bounding>({
        bottom: 0,
        height: 0,
        left: 0,
        right: 0,
        top: 0,
        width: 0,
        x: 0,
        y: 0,
    });
    const stageContent = ref<Bounding>({
        bottom: 0,
        height: 0,
        left: 0,
        right: 0,
        top: 0,
        width: 0,
        x: 0,
        y: 0,
    });
    const visibleArea = computed<Bounding>(() => {
        let top = app.value.top;
        let bottom = app.value.bottom;
        let left = app.value.left;
        let right = app.value.right;

        if (panelContent.value) {
            const topAreaSize =
                (panelContent.value.top - app.value.top) * app.value.width;
            const bottomAreaSize =
                (app.value.bottom - panelContent.value.bottom) *
                app.value.width;
            const leftAreaSize =
                (panelContent.value.left - app.value.left) * app.value.height;
            const rightAreaSize =
                (app.value.right - panelContent.value.right) * app.value.height;

            if (
                bottomAreaSize >
                Math.max(topAreaSize, leftAreaSize, rightAreaSize)
            ) {
                top =
                    app.value.top +
                    (panelContent.value.bottom - panelContent.value.top) -
                    (panelContent.value.top - app.value.top);
            }

            if (
                topAreaSize >
                Math.max(bottomAreaSize, leftAreaSize, rightAreaSize)
            ) {
                bottom =
                    app.value.bottom -
                    (panelContent.value.bottom - panelContent.value.top) -
                    (app.value.bottom - panelContent.value.bottom);
            }

            if (
                rightAreaSize >
                Math.max(topAreaSize, bottomAreaSize, leftAreaSize)
            ) {
                left =
                    app.value.left +
                    (panelContent.value.right - panelContent.value.left) -
                    (panelContent.value.left - app.value.left);
            }

            if (
                leftAreaSize >
                Math.max(topAreaSize, bottomAreaSize, rightAreaSize)
            ) {
                right =
                    app.value.right -
                    (panelContent.value.right - panelContent.value.left) -
                    (app.value.right - panelContent.value.right);
            }
        }

        return {
            top: top,
            bottom: bottom,
            left: left,
            right: right,
            x: left,
            y: top,
            width: right - left,
            height: bottom - top,
        };
    });
    const visibleStageArea = computed<Bounding>(() => {
        let top = visibleArea.value.top;
        let bottom = visibleArea.value.bottom;
        let left = visibleArea.value.left;
        let right = visibleArea.value.right;

        if (stageContent.value.top > visibleArea.value.top) {
            top = stageContent.value.top;
        }

        if (stageContent.value.bottom < visibleArea.value.bottom) {
            bottom = stageContent.value.bottom;
        }

        if (stageContent.value.left > visibleArea.value.left) {
            left = stageContent.value.left;
        }

        if (stageContent.value.right < visibleArea.value.right) {
            right = stageContent.value.right;
        }

        return {
            top: top,
            bottom: bottom,
            left: left,
            right: right,
            x: left,
            y: top,
            width: right - left,
            height: bottom - top,
        };
    });

    return {
        app,
        panelContent,
        stageContent,
        visibleArea,
        visibleStageArea,
    };
});

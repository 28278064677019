import { type FabricControlData } from "@/types/fabric/FabricControlData";

const fabricConfig: { controls: FabricControlData[] } = {
    controls: [
        {
            name: "tl",
            isVisible: false,
        },
        {
            name: "ml",
            isVisible: false,
        },
        {
            name: "bl",
            isVisible: true,
            icon: "icon-remove",
            color: "black",
            cursor: "pointer",
            function: "deleteObject",
        },
        {
            name: "mt",
            isVisible: false,
        },
        {
            name: "mb",
            isVisible: false,
        },
        {
            name: "tr",
            isVisible: false,
        },
        {
            name: "mr",
            isVisible: false,
        },
        {
            name: "br",
            isVisible: true,
            icon: "resize",
            color: "black",
            cursor: "nwse-resize",
        },
        {
            name: "mtr",
            isVisible: true,
            icon: "redo",
            color: "black",
            cursor: "grabbing",
        },
    ],
};

export default fabricConfig;

import { ConvertZSKDesignToDesign } from "@/modules/core/renderer/zskEmbroidery/ConvertZSKDesignToDesign";
import {
    type View,
    type apiClient,
    apiDesignToDesign,
} from "@smakecloud/designer-core";
import { defineStore, storeToRefs } from "pinia";
import { computed } from "vue";
import { useCurrentStore } from "./useCurrentStore";

type ApiDesign =
    (typeof apiClient.designs.preview.inputType)["elements"][number];

export const useViewsStore = defineStore("viewsStore", () => {
    const { currentVariant } = storeToRefs(useCurrentStore());

    const views = computed<View[]>(() =>
        currentVariant.value.views.map((view) => ({
            width: 520 / view.scalefactor,
            height: 580 / view.scalefactor,
            imageUrl: view.image_urls.xl,
            positioningAreas: view.positioning_areas.map((positioningArea) => ({
                handle: positioningArea.positioning_area_identifier?.handle,
                x: positioningArea.x / view.scalefactor,
                y: positioningArea.y / view.scalefactor,
                rotation: positioningArea.rotation,
                width: positioningArea.localized_width,
                height: positioningArea.localized_height,
                position: positioningArea.position,
                productionMethodId: positioningArea.production_method_id,
                designs: view.customizations
                    .filter(
                        (customization) =>
                            customization.positioningArea_id ===
                                positioningArea.id && customization.design,
                    )
                    .map((customization) => ({
                        design: apiDesignToDesign(
                            ConvertZSKDesignToDesign(customization.design!)
                                .elements[0] as ApiDesign,
                        ),
                        rotation:
                            customization.rotation - positioningArea.rotation,
                    })),
            })),
        })),
    );

    return { views };
});

<template>
    <div v-if="moduleButtons.length" ref="bottom-1-panel" :class="panelClasses">
        <component
            :is="asyncComponents()[moduleButton]"
            v-for="moduleButton in moduleButtons"
            :key="moduleButton"
            :style-config="moduleButtonsStyleConfig()"
        />
    </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useModules } from "@/support/modules";
import { useMainStore } from "@/store/useMainStore";
import asyncComponents from "@/support/asyncComponents";
import { PanelName } from "@/enums/PanelName";

const mainStore = useMainStore();
const { settings } = mainStore;
const { buttonsForPanel } = useModules();

// computed
const moduleButtons = computed(() => {
    return buttonsForPanel(PanelName.BUTTON_PANEL_BOTTOM_1);
});

const panelClasses = computed(() => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
    return settings.style.buttonPanelBottom1.styles.join(" ");
});

const moduleButtonsStyleConfig = () => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access
    return settings.style.buttonPanelBottom1.moduleButton;
};
</script>

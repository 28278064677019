import { hexToHsv, hsvToHex, type HSVColor } from "@/utils/colorConversion";
import { useTokenStore } from "@smakecloud/designer-core";
import { useStorage } from "@vueuse/core";
import { defineStore, storeToRefs } from "pinia";
import { computed, readonly } from "vue";

export const useFavoriteColorsStore = defineStore("favoriteColors", () => {
    const { userExternalIdentifier } = storeToRefs(useTokenStore());

    const favoriteColors = useStorage<string[]>(
        `smake-designer-favorite-colors-hex-${userExternalIdentifier.value}`,
        [],
    );

    const colors = computed(() =>
        favoriteColors.value
            .map((color) => hexToHsv(color))
            .filter((color) => color !== undefined),
    );

    function add(hsvColor: HSVColor) {
        const hex = hsvToHex(hsvColor);

        if (favoriteColors.value.includes(hex)) {
            favoriteColors.value = favoriteColors.value.filter(
                (favoriteColor) => favoriteColor !== hex,
            );
        }

        favoriteColors.value.unshift(hex);

        if (favoriteColors.value.length <= 20) return;

        favoriteColors.value.pop();
    }

    return {
        colors: readonly(colors),
        add,
    };
});

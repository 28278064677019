<template>
    <div class="flex flex-col items-center gap-4">
        <div
            ref="animatedElement"
            data-testid="logo-input-colors"
            class="grid w-full grid-cols-2 gap-4 transition-all"
        >
            <div
                v-for="(colorAssignment, index) in displayedColorAssignments"
                :key="colorAssignment.id"
            >
                <div class="pb-1">{{ t("color") }} {{ index + 1 }}</div>
                <logo-input-color
                    :disabled
                    :color-assignment="colorAssignment"
                    @click="selectedColorAssignmentIndex = index"
                />
            </div>
        </div>
        <button
            v-if="colorAssignments.length > intialVisibleCount"
            type="button"
            class="flex rounded-full bg-secondary-3 px-4 py-2 text-sm hover:bg-secondary-2"
            @click="animateHeight(() => (colorsCollapsed = !colorsCollapsed))"
        >
            {{ t(colorsCollapsed ? "more_colors" : "fewer_colors") }}
            <f-a-icon
                :icon="colorsCollapsed ? 'chevron-down' : 'chevron-up'"
                :size="FontAwesomeSize.lg"
                class="ml-2"
            />
        </button>
    </div>
</template>

<script setup lang="ts">
import { FontAwesomeSize } from "@/enums/fontAwesome/fontAwesomeSize";
import {
    useAnimatedHeight,
    type ColorAssignmentsData,
} from "@smakecloud/smake-use";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";

const props = defineProps<{
    colorAssignments: ColorAssignmentsData[];
    disabled: boolean;
}>();

const selectedColorAssignmentIndex = defineModel<number>(
    "selectedColorAssignmentIndex",
);

const intialVisibleCount = 4;

const colorsCollapsed = ref<boolean>(true);
const displayedColorAssignments = computed(() =>
    colorsCollapsed.value
        ? props.colorAssignments.slice(0, intialVisibleCount)
        : props.colorAssignments,
);

const animatedElement = ref<HTMLElement>();
const { animateHeight } = useAnimatedHeight(animatedElement);
const { t } = useI18n();
</script>

<template>
    <div
        v-if="!isPanelOpen && !isAProductionMethodSelectionOpen && !isInternal"
        class="grid justify-items-end gap-4"
    >
        <module-button
            name="moduleButton"
            :icon="icon"
            :style-config="moduleButtonStyleConfig"
            @click="toggleMenu()"
        />
        <menu-modal v-if="showMenu" />
    </div>
</template>

<script setup lang="ts">
import { useMainStore } from "@/store/useMainStore";
import { usePanelStore } from "@/store/usePanelStore";
import { useSettingsStore } from "@/store/useSettingsStore";
import cloneDeep from "lodash/cloneDeep";
import get from "lodash/get";
import merge from "lodash/merge";
import set from "lodash/set";
import { storeToRefs } from "pinia";
import { computed, ref } from "vue";
import { useProductionMethodDialogStore } from "@/store/useProductionMethodDialogStore";

const { settings } = useMainStore();
const { isAProductionMethodSelectionOpen } = storeToRefs(
    useProductionMethodDialogStore(),
);
const { isPanelOpen } = storeToRefs(usePanelStore());
const { isInternal } = storeToRefs(useSettingsStore());

//data
const showMenu = ref(false);

// props
const props = defineProps({
    styleConfig: {
        type: Object,
        default: () => {
            return {};
        },
    },
});

// computed
const moduleButtonStyleConfig = computed(() => {
    const baseStyleConfig = cloneDeep(props.styleConfig);

    if (showMenu.value) {
        ["styles", "icon.styles"].forEach((key) => {
            set(
                baseStyleConfig,
                key,
                merge(
                    get(baseStyleConfig, key, []),
                    get(settings.style, `menuButton.open.${key}`, []),
                ),
            );
        });

        return baseStyleConfig;
    }

    ["styles", "icon.styles"].forEach((key) => {
        set(
            baseStyleConfig,
            key,
            merge(
                get(baseStyleConfig, key, []),
                get(settings.style, `menuButton.${key}`, []),
            ),
        );
    });

    return baseStyleConfig;
});

const icon = computed(() => {
    if (showMenu.value) {
        return "icon-x";
    }

    return "ellipsis-vertical";
});

// methods
function toggleMenu() {
    showMenu.value = !showMenu.value;
}
</script>

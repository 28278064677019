<template>
    <div
        id="zoomSliderContainer"
        class="pointer-events-auto grid w-full select-none grid-flow-row auto-rows-min justify-center justify-items-center gap-1 place-self-end tablet:w-24 tablet:gap-2 desktop:w-32"
    >
        <div
            class="pointer-events-auto flex w-10 flex-col items-center justify-center justify-items-center rounded-full bg-secondary-3 desktop:w-16"
        >
            <module-button
                :icon="FontAwesomeKitIcons.zoomIn"
                :style-config="moduleButtonStyleConfig"
                class="desktop:pt-2"
            />

            <product-zoom-vertical-slide-input />

            <module-button
                :icon="FontAwesomeKitIcons.zoomOut"
                :style-config="moduleButtonStyleConfig"
                class="desktop:pb-2"
            />
        </div>
        <div class="text-xs">
            {{ t("zoom") }}
        </div>
    </div>
</template>

<script setup lang="ts">
import { FontAwesomeKitIcons } from "@/enums/fontAwesome/fontAwesomeKitIcons";
import { useMainStore } from "@/store/useMainStore";
import cloneDeep from "lodash/cloneDeep";
import get from "lodash/get";
import merge from "lodash/merge";
import set from "lodash/set";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const { settings } = useMainStore();
const { t } = useI18n();

// props
const props = defineProps({
    styleConfig: {
        type: Object,
        default: () => {
            return {};
        },
    },
});

//computed
const moduleButtonStyleConfig = computed(() => {
    const baseStyleConfig = cloneDeep(props.styleConfig);

    [
        "styles",
        "icon.styles",
        "label.styles",
        "disabled.styles",
        "disabled.icon.styles",
        "disabled.label.styles",
    ].forEach((key) => {
        set(
            baseStyleConfig,
            key,
            merge(
                get(baseStyleConfig, key, []),
                get(settings.style, `productZoomSlider.${key}`, []),
            ),
        );
    });

    return baseStyleConfig;
});
</script>

<!-- eslint-disable vue/no-deprecated-v-on-native-modifier -->
<template>
    <button
        type="button"
        class="pointer-events-auto flex h-36 w-36 flex-col items-center justify-center gap-2 rounded-3xl tablet:border-2 tablet:bg-white tablet:p-3"
        :class="{
            'tablet:border-secondary-2': isCurrentModeAddMode,
            'tablet:border-secondary-3 tablet:hover:border-secondary-2':
                !isCurrentModeAddMode,
        }"
        @click="addNewCustomization"
    >
        <div
            class="flex h-[84px] w-[84px] items-center justify-center rounded-full bg-primary-1 tablet:h-10 tablet:w-10 tablet:p-2"
        >
            <f-a-icon
                :class="{
                    'rotate-45': isCurrentModeAddMode,
                }"
                class="text-4xl text-white transition duration-500 ease-in-out tablet:text-2xl"
                icon="plus"
            />
        </div>
        <div class="text-xs font-bold">
            {{ t(isCurrentModeAddMode ? "abort" : "new_customization") }}
        </div>
    </button>
</template>

<script setup lang="ts">
import useNotification from "@/composables/useNotification";
import { NotificationType } from "@/enums/NotificationType";
import { Mode, isAddMode } from "@/enums/mode";
import { cancelAddCustomizationEventKey } from "@/events/addCustomization";
import { useCurrentStore } from "@/store/useCurrentStore";
import { usePanelStore } from "@/store/usePanelStore";
import { useEventBus } from "@vueuse/core";
import { storeToRefs } from "pinia";
import { computed, nextTick, onMounted, onUnmounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";

const panelStore = usePanelStore();
const { currentMode, hasCurrentViewFreePositioningAreas } =
    storeToRefs(useCurrentStore());
const { t } = useI18n();
const { pushNotification, resolveNotificationWithMessage } = useNotification();
const cancelAddCustomizationEventBus = useEventBus(
    cancelAddCustomizationEventKey,
);

// data
const windowWidth = ref(window.innerWidth);
const isLoading = ref(false);
const isCurrentModeAddMode = computed<boolean>(() =>
    isAddMode(currentMode.value),
);

onMounted(() => {
    void nextTick(() => {
        window.addEventListener("resize", onResize);
    });
});

onUnmounted(() => {
    window.removeEventListener("resize", onResize);
});

watch(
    () => currentMode.value,
    () => {
        if (currentMode.value === Mode.Default) {
            isLoading.value = false;
        }
    },
);

// methods
async function addNewCustomization() {
    if (!hasCurrentViewFreePositioningAreas.value) {
        pushNotification(
            t("all_positioning_areas_are_occupied"),
            NotificationType.Info,
        );
        resolveNotificationWithMessage(t("choose_a_positioning_area"));

        return;
    }

    if (isCurrentModeAddMode.value) {
        cancelAddCustomizationEventBus.emit();
        currentMode.value = Mode.Default;
        resolveNotificationWithMessage(t("choose_a_positioning_area"));

        await panelStore.hideCurrentPanel();

        return;
    }

    currentMode.value = Mode.AddNewCustomization;
    pushNotification(t("choose_a_positioning_area"), NotificationType.Info);
    resolveNotificationWithMessage(t("all_positioning_areas_are_occupied"));
}

function onResize() {
    windowWidth.value = window.innerWidth;
}
</script>

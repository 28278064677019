<template>
    <button
        class="flex items-center justify-between gap-8 rounded-full border px-1 py-1 outline-none hover:border-black focus:border-black disabled:border-secondary-2 disabled:text-secondary-2 disabled:hover:bg-white"
        :class="{
            'border-black bg-black text-white': isSelected,
            'border-secondary-2': !isSelected,
            'text-slate-400 grayscale-[98%]':
                !hasCurrentVariantSupportedPositioningAreas,
        }"
        :disabled="!hasCurrentVariantSupportedPositioningAreas"
    >
        <div>
            <transition
                as="template"
                appear
                enter-active-class="ease-out duration-300"
                enter-from-class="opacity-0"
                enter-to-class="opacity-100"
                leave-active-class="ease-in duration-200"
                leave-from-class="opacity-100"
                leave-to-class="opacity-0"
            >
                <div>
                    <img
                        v-if="!isLoading"
                        class="aspect-square h-16 rounded-full object-cover"
                        :src="productionMethod.preview_url"
                        :alt="productionMethod.handle"
                    />
                    <div
                        v-if="isLoading"
                        class="aspect-square h-16 animate-pulse rounded-full bg-secondary-2"
                    ></div>
                </div>
            </transition>
        </div>
        <div class="flex flex-col items-center justify-center gap-0.5">
            <div class="font-bold">{{ productionMethod.display_name }}</div>
            <div v-if="productionMethodSummary" class="text-sm">
                {{ productionMethodSummary }}
            </div>
        </div>
        <div></div>
    </button>
</template>
<script setup lang="ts">
import { useProductionMethodDescriptions } from "@/composables/useProductionMethodDescriptions";
import { useProductionMethodInfo } from "@/composables/useProductionMethodInfo";
import type { ProductionMethodData } from "@smakecloud/smake-use";
import { useImage, type UseImageOptions } from "@vueuse/core";
import { computed, toRefs } from "vue";

const props = defineProps<{
    productionMethod: ProductionMethodData;
    isSelected?: boolean;
}>();

const { productionMethod } = toRefs(props);
const productionMethodId = computed(() => productionMethod.value.id);
const { hasCurrentVariantSupportedPositioningAreas } =
    useProductionMethodInfo(productionMethodId);

const imageOptions = computed<UseImageOptions>(() => {
    return {
        src: productionMethod.value.preview_url,
    };
});

const { isLoading } = useImage(imageOptions);

const { productionShortDescriptionMap } = useProductionMethodDescriptions();

const productionMethodSummary = computed(() => {
    return productionShortDescriptionMap[productionMethod.value.handle];
});
</script>

<template>
    <div class="fixed inset-0 flex items-center justify-center">
        <transition-child
            appear
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
        >
            <base-dialog-panel
                :initial-focus="newNameInput"
                class="flex w-[480px] flex-col items-center gap-6 p-4"
                has-close-button
            >
                <div class="w-full break-words">
                    <div class="float-right h-8 w-8" />
                    <i18n-translate
                        keypath="do_you_want_to_rename"
                        tag="span"
                        class="align-middle"
                    >
                        <template #name>
                            <span class="font-bold text-primary-1">
                                {{ initialName }}
                            </span>
                        </template>
                    </i18n-translate>
                </div>
                <img
                    class="aspect-square w-80 bg-secondary-3 object-contain p-4"
                    :src="image"
                />
                <div
                    v-if="generalError && !nameErrors"
                    class="w-full rounded-lg border border-danger p-2 text-danger"
                >
                    {{ generalError }}
                </div>
                <div class="w-full flex-col">
                    <input
                        ref="newNameInput"
                        v-model="newName"
                        type="text"
                        :maxlength="60"
                        class="w-full rounded-md border px-4 py-3 outline-none focus:border-black disabled:cursor-not-allowed disabled:text-secondary-2"
                        placeholder="Text einfügen"
                        :disabled="isPending"
                        :class="
                            nameErrors ? 'border-danger' : 'border-secondary-2'
                        "
                        @keydown.enter="updateName"
                    />
                    <div
                        v-if="nameErrors"
                        class="flex flex-col justify-start gap-2 p-1 text-xs font-bold text-danger"
                    >
                        <div v-for="errorLine in nameErrors" :key="errorLine">
                            {{ errorLine }}
                        </div>
                    </div>
                </div>
                <div class="flex w-full flex-col gap-4 tablet:flex-row">
                    <button-secondary-outlined
                        :disabled="isPending"
                        class="flex-grow"
                        @click="isOpen = false"
                    >
                        {{ t("abort") }}
                    </button-secondary-outlined>
                    <button-primary
                        :disabled="newName.length === 0 || isPending"
                        class="flex flex-grow items-center gap-2"
                        @click="updateName"
                    >
                        <f-a-icon icon="save" :size="FontAwesomeSize.lg" />
                        {{ t("save") }}
                    </button-primary>
                </div>
            </base-dialog-panel>
        </transition-child>
    </div>
</template>

<script setup lang="ts" generic="T">
import { useInjectDialogData } from "@/composables/useDialogData";
import { FontAwesomeSize } from "@/enums/fontAwesome/fontAwesomeSize";
import { TransitionChild } from "@headlessui/vue";
import { useQuery, type MutationStatus } from "@tanstack/vue-query";
import { HTTPError } from "ky";
import { computed, ref, toRefs, watchEffect } from "vue";
import { useI18n } from "vue-i18n";

const emit = defineEmits<{
    rename: [string];
}>();

const props = defineProps<{
    image: string;
    name: string;
    mutationStatus: MutationStatus;
    error: Error | null;
}>();

const { isOpen } = useInjectDialogData();
const { mutationStatus, error } = toRefs(props);
const initialName = props.name;

const newNameInput = ref<HTMLInputElement>();
const newName = ref(initialName);

watchEffect(() => {
    if (mutationStatus.value === "success") {
        isOpen.value = false;
    }
});

const isPending = computed(() => mutationStatus.value === "pending");

const { t } = useI18n();

function updateName() {
    if (newName.value === props.name) {
        isOpen.value = false;
        return;
    }

    emit("rename", newName.value);
}

const { data: errorResponseBody } = useQuery({
    queryKey: [error],
    queryFn: () => {
        if (!(error.value instanceof HTTPError)) return;
        return error.value.response.json<{
            data: { errors: { name: string[] } };
        }>();
    },
    enabled: computed(() => !!error.value),
});
const generalError = computed(() => {
    if (
        !error.value ||
        (error.value instanceof HTTPError &&
            error.value.response.status === 422)
    )
        return;
    return t("boot_error.unexpected");
});

const nameErrors = computed(() => {
    if (
        !error.value ||
        !(error.value instanceof HTTPError) ||
        error.value.response.status !== 422
    )
        return;
    return errorResponseBody.value?.data.errors.name;
});
</script>

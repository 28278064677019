<template>
    <select-box
        v-model="selectedProductionMethod"
        :options="currentActiveProductionMethods"
        :disabled
    >
        <template #selected="{ option }">
            <production-method-option :production-method="option" :disabled />
        </template>
        <template #option="{ option, selected: isSelected }">
            <production-method-option-large
                :selected="isSelected"
                :production-method="option"
                :disabled
            />
        </template>
    </select-box>
</template>

<script setup lang="ts">
import { hasVariantSupportedPositioningAreas } from "@/functions/ProductionMethodInfo";
import type ProductionMethod from "@/repo/ProductionMethod";
import { useCurrentStore } from "@/store/useCurrentStore";
import { useProductionMethodStore } from "@/store/useProductionMethodStore";
import { storeToRefs } from "pinia";
import { computed } from "vue";

const { currentActiveProductionMethods } = storeToRefs(
    useProductionMethodStore(),
);

const selectedProductionMethod = defineModel<ProductionMethod>({
    required: true,
});

const { currentCustomization, currentVariant } = storeToRefs(useCurrentStore());

const hasCurrentVariantMultipleProductionMethods = computed(
    () =>
        currentActiveProductionMethods.value.filter((productionMethod) =>
            hasVariantSupportedPositioningAreas(
                currentVariant.value,
                productionMethod.id,
            ),
        ).length > 1,
);

const disabled = computed(() => {
    if (!hasCurrentVariantMultipleProductionMethods.value) {
        return true;
    }

    if (!currentCustomization.value) {
        return false;
    }

    return currentCustomization.value.design !== null;
});
</script>

import { defineStore } from "pinia";
import { ref } from "vue";

export const useColorPickerStore = defineStore("colorPickerStore", () => {
    const open = ref(false);

    return {
        open,
    };
});

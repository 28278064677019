<template>
    <base-dialog v-model:is-open="isNewMerchantSketchModalOpen">
        <div class="fixed inset-0 flex items-center justify-center">
            <transition-child
                appear
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0 scale-95"
                enter-to="opacity-100 scale-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100 scale-100"
                leave-to="opacity-0 scale-95"
            >
                <base-dialog-panel class="w-[480px] p-4" has-close-button>
                    <div class="float-right h-8 w-8" />
                    <span class="text-xl font-bold">{{ t("new_sketch") }}</span>
                    <div class="mt-6">
                        <span class="tablet:hidden">
                            {{
                                t("merchant_sketch_variant_added", {
                                    buttonName: t("archive"),
                                })
                            }}
                        </span>
                        <span class="hidden tablet:inline">
                            {{
                                t("merchant_sketch_variant_added", {
                                    buttonName: t("my_customizations"),
                                })
                            }}
                        </span>
                    </div>
                    <div v-if="isEditingCustomization" class="mt-3">
                        {{ t("current_edited_customization_will_be_aborted") }}
                    </div>
                    <button-primary
                        class="mt-6 w-full"
                        @click="showMerchantSketchVariants"
                    >
                        {{ t("show_sketches") }}
                    </button-primary>
                </base-dialog-panel>
            </transition-child>
        </div>
    </base-dialog>
</template>

<script setup lang="ts">
import { isCustomizationEditPanelState, PanelState } from "@/enums/PanelState";
import {
    ArchiveModalTab,
    useArchiveVariantStore,
} from "@/store/useArchiveVariantStore";
import { usePanelStore } from "@/store/usePanelStore";
import { TransitionChild } from "@headlessui/vue";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const panelStore = usePanelStore();
const { isNewMerchantSketchModalOpen, defaultTab } = storeToRefs(
    useArchiveVariantStore(),
);
const { currentPanelState } = storeToRefs(usePanelStore());

const isEditingCustomization = computed<boolean>(() =>
    isCustomizationEditPanelState(currentPanelState.value),
);

async function showMerchantSketchVariants() {
    isNewMerchantSketchModalOpen.value = false;
    defaultTab.value = ArchiveModalTab.MERCHANT_SKETCH;
    await panelStore.showPanel(PanelState.Archive);
}
</script>

<template>
    <div
        v-if="isFetching || favoredDesigns?.length"
        v-on-scroll-end="onScrollEnd"
        class="grid grid-cols-[repeat(auto-fill,minmax(150px,1fr))] gap-4 tablet:grid-cols-[repeat(auto-fill,minmax(180px,1fr))]"
    >
        <design-card
            v-for="design in favoredDesigns"
            :key="design.id"
            :design
            :is-select-confirmation-required
            @select="
                emit(
                    'design-selected',
                    cloneDeep(design) as unknown as DesignComplete,
                )
            "
        />
        <template v-if="isFetching">
            <div class="h-80 animate-pulse rounded-3xl bg-secondary-3" />
            <div class="h-80 animate-pulse rounded-3xl bg-secondary-3" />
        </template>
    </div>
    <div v-else class="-mt-8 flex h-full items-center justify-center text-lg">
        <span class="max-w-3xl text-center italic text-secondary-1">
            {{
                t("no_designs", {
                    productionMethod: filterProductionMethod.display_name,
                })
            }}
        </span>
    </div>
</template>

<script setup lang="ts">
import vOnScrollEnd from "@/directives/vOnScrollEnd";
import type ProductionMethod from "@/repo/ProductionMethod";
import { apiClient } from "@smakecloud/designer-core";
import { type DesignComplete } from "@smakecloud/smake-use";
import { useInfiniteQuery } from "@tanstack/vue-query";
import { cloneDeep } from "lodash";
import { computed, toRefs } from "vue";
import { useI18n } from "vue-i18n";

const emit = defineEmits<{
    "design-selected": [DesignComplete];
}>();

const props = defineProps<{
    isSelectConfirmationRequired: boolean;
    filterProductionMethod: ProductionMethod;
}>();

const { filterProductionMethod } = toRefs(props);
const { t } = useI18n();

const filters = computed<typeof apiClient.designs.favored.list.inputType>(
    () => ({
        production_method: [filterProductionMethod.value.id],
    }),
);

const {
    isFetching,
    data: favoredDesigns,
    fetchNextPage,
} = useInfiniteQuery({
    ...apiClient.designs.favored.list.use(filters),
    select: (data) => data.pages.flatMap((page) => page.data),
    staleTime: Infinity,
});

function onScrollEnd() {
    if (isFetching.value) return;

    void fetchNextPage();
}
</script>

<script setup lang="ts">
import { hexToHsv, type RGBColor } from "@/utils/colorConversion";
import ColorInput from "./colorInput/ColorInput.vue";
import { useColorConvertorRGB } from "@/composables/colorPicker/useColorConverterRGB";
import ColorFavorites from "./colorFavorites/ColorFavorites.vue";
import EyeDropper from "./EyeDropper.vue";
const color = defineModel<RGBColor>({ required: true });
const { colorHSV } = useColorConvertorRGB(color);

function onEyeDropped(hex: string) {
    const hsv = hexToHsv(hex);

    if (!hsv) return;

    colorHSV.value = hsv;
}
</script>

<template>
    <div class="flex w-[318px] flex-col justify-center gap-4 sm:w-[350px]">
        <div class="flex h-64 gap-4 px-2">
            <color-area-hue v-model="colorHSV" />
            <color-area-saturartion v-model="colorHSV" class="grow" />
        </div>
        <div class="flex items-center gap-3">
            <eye-dropper @selected="onEyeDropped" />
            <color-input v-model="colorHSV" />
        </div>
        <color-favorites v-model="colorHSV" />
    </div>
</template>

<template>
    <div class="grow" :class="disabled ? 'pointer-events-none opacity-50' : ''">
        <label v-if="label" class="block text-xs font-semibold uppercase">
            {{ label }}
        </label>
        <div class="mt-1 flex h-12">
            <button
                type="button"
                class="flex-0 items-center rounded-l-full border border-r-0 border-secondary-2 px-3 focus:outline-none disabled:cursor-not-allowed disabled:text-secondary-2"
                data-testid="subtract-button"
                :disabled="!canSubtract"
                @click="subtract"
            >
                <f-a-icon icon="minus" :size="FontAwesomeSize.fa1x" />
            </button>
            <div
                class="flex h-full flex-1 items-center justify-center border-b border-t border-secondary-2 py-2"
            >
                <input
                    ref="inputField"
                    v-model="inputValue"
                    class="designer-number-input-component"
                    type="number"
                    :min="min"
                    :max="max"
                />
            </div>
            <button
                type="button"
                class="flex-0 h-full items-center rounded-r-full border border-l-0 border-secondary-2 px-3 focus:outline-none disabled:cursor-not-allowed disabled:text-secondary-2"
                data-testid="add-button"
                :disabled="!canAdd"
                @click="add"
            >
                <f-a-icon icon="plus" :size="FontAwesomeSize.fa1x" />
            </button>
        </div>
    </div>
</template>

<script setup lang="ts">
import { FontAwesomeSize } from "@/enums/fontAwesome/fontAwesomeSize";
import { clamp } from "@vueuse/core";
import { computed, toRefs, useTemplateRef } from "vue";

const props = withDefaults(
    defineProps<{
        label?: string;
        min?: number;
        max?: number;
        disabled?: boolean;
    }>(),
    {
        label: undefined,
        min: 0,
        max: 100,
        disabled: false,
    },
);
const { min, max } = toRefs(props);

const number = defineModel<number>({ default: 0 });

const inputField = useTemplateRef("inputField");
const inputValue = computed<number>({
    get(): number {
        return number.value;
    },
    set(value: number) {
        const clamped = clamp(value, min.value, max.value);
        if (inputField.value) {
            inputField.value.value = clamped.toString();
        }
        number.value = clamped;
    },
});

const canAdd = computed<boolean>(() => inputValue.value < max.value);
function add(): void {
    inputValue.value += 1;
}

const canSubtract = computed<boolean>(() => inputValue.value > min.value);
function subtract(): void {
    inputValue.value -= 1;
}
</script>

import { type BootAble } from "@/support/BootAble";
import BootStore from "@/boot/BootStore";
import BootRenderInitialCustomizations from "@/boot/BootRenderInitialCustomizations";

export type BootConfig = {
    entries: BootAble[];
};

export const bootConfig: BootConfig = {
    entries: [new BootStore(), new BootRenderInitialCustomizations()],
};

<template>
    <variant-selection-group
        :variant="variant"
        id-prefix="archive"
        :disable-load-customization="isDeletingVariant"
    >
        <template #action="{ isLoadingCurrentArchiveVariant }">
            <base-dialog v-model:is-open="isDeleteArchiVariantDialogOpen">
                <confirmation-dialog-panel @confirm="deleteVariant(variant.id)">
                    <div class="rounded-md bg-white p-5">
                        <i18n-translate
                            keypath="are_you_sure_you_want_to_delete"
                            tag="div"
                        >
                            <template #deleteName>
                                <span class="font-bold text-primary-1">
                                    {{ variant.display_name }}
                                </span>
                            </template>
                        </i18n-translate>

                        {{ t("this_action_can_not_be_undone") }}

                        <div
                            class="mt-2 flex flex-wrap justify-center gap-5 p-2"
                        >
                            <template v-for="view in variant.views">
                                <!-- TODO: Fix this the next time the file is edited. -->
                                <!-- eslint-disable-next-line vue/require-v-for-key -->
                                <div
                                    v-for="customization in view.customizations"
                                    class="rounded-md bg-white drop-shadow-md"
                                >
                                    <archive-customization-preview
                                        class="rounded-md"
                                        :archive-variant="variant"
                                        :customization-response-to-preview="
                                            customization
                                        "
                                    />
                                </div>
                            </template>
                        </div>
                    </div>
                    <template #confirmText>
                        <span class="flex items-center gap-2">
                            <f-a-icon class="text-base" icon="trash-alt" />
                            {{ t("delete") }}
                        </span>
                    </template>
                </confirmation-dialog-panel>
            </base-dialog>
            <button
                :disabled="isDeletingVariant || isLoadingCurrentArchiveVariant"
                type="button"
                class="flex h-10 w-10 items-center justify-center rounded-full bg-white text-black hover:text-secondary-1 disabled:cursor-not-allowed disabled:text-secondary-2 disabled:hover:text-secondary-2"
                :class="{
                    'fa-spin': isDeletingVariant,
                }"
                @click="isDeleteArchiVariantDialogOpen = true"
            >
                <f-a-icon
                    v-if="isDeletingVariant"
                    :size="FontAwesomeSize.lg"
                    icon="spinner"
                />
                <f-a-icon v-else :size="FontAwesomeSize.lg" icon="trash-alt" />
            </button>
        </template>
    </variant-selection-group>
</template>

<script setup lang="ts">
import useNotification from "@/composables/useNotification";
import { FontAwesomeSize } from "@/enums/fontAwesome/fontAwesomeSize";
import { NotificationType } from "@/enums/NotificationType";
import {
    useDesignerApi,
    type ArchiveVariantData,
    type ArchiveVariantListData,
} from "@smakecloud/smake-use";
import {
    useMutation,
    useQueryClient,
    type InfiniteData,
} from "@tanstack/vue-query";
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import ArchiveCustomizationPreview from "./ArchiveCustomizationPreview.vue";

defineProps<{
    variant: ArchiveVariantData;
}>();

const notification = useNotification();
const designerApi = useDesignerApi();
const queryClient = useQueryClient();
const { mutate: deleteVariant, isPending: isDeletingVariant } = useMutation({
    mutationKey: [
        "smake-use.designerApi.variants.deleteVariantByIdFromArchive",
    ],
    mutationFn: async (variantId: number) => {
        await designerApi.endpoints.variants.deleteVariantByIdFromArchive(
            variantId,
        );
        return variantId;
    },
    onSuccess: (variantId) => {
        queryClient.setQueryData(
            ["smake-use.designerApi.variants.listArchived"],
            (
                oldData: InfiniteData<
                    ArchiveVariantListData,
                    number | undefined
                >,
            ) => ({
                ...oldData,
                pages: oldData.pages.map((page) => ({
                    ...page,
                    data: page.data.filter(
                        (variant) => variant.id !== variantId,
                    ),
                })),
            }),
        );
    },
    onError: () => {
        notification.pushNotification(
            t("error_while_deleting_archive_variant"),
            NotificationType.Info,
        );
    },
});

//data
const { t } = useI18n();

const isDeleteArchiVariantDialogOpen = ref(false);
</script>

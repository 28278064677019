import Customization from "@/repo/Customization";
import type PositioningArea from "@/repo/PositioningArea";
import View from "@/repo/View";
import { useCanvasStore } from "@/store/useCanvasStore";
import { useCurrentStore } from "@/store/useCurrentStore";
import { InnerAnchorPoints } from "@smakecloud/smake-use";
import { storeToRefs } from "pinia";
import { useRepo } from "pinia-orm";
import { ref } from "vue";

export const useCreateNewCustomization = () => {
    const canvasStore = useCanvasStore();
    const { positioningAreaCanvasObjects } = storeToRefs(canvasStore);
    const { zoomStageToObject } = canvasStore;
    const viewRepo = useRepo(View);
    const { currentView } = storeToRefs(useCurrentStore());

    function createNewCustomization(targetPositioningArea: PositioningArea) {
        const rotation =
            targetPositioningArea.rotation + targetPositioningArea.direction;

        const customization = useRepo(Customization).save({
            positioningArea_id: targetPositioningArea.id,
            x: targetPositioningArea.x,
            y: targetPositioningArea.y,
            position: getRotatedAnchorPoint(
                targetPositioningArea.position,
                rotation,
                targetPositioningArea,
            ),
            design: null,
            rotation,
        });

        const currentViewCustomizationIds = currentView.value.customization_ids;

        currentViewCustomizationIds.push(customization.id);

        viewRepo.save({
            handle: currentView.value.handle,
            variant_id: currentView.value.variant_id,
            customization_ids: Array.from(currentViewCustomizationIds),
        });

        const positioningAreaCanvasObject =
            positioningAreaCanvasObjects.value.find(
                (positioningAreaCanvasObject) =>
                    positioningAreaCanvasObject.id === targetPositioningArea.id,
            );

        if (positioningAreaCanvasObject?.canvas_object !== undefined) {
            zoomStageToObject(positioningAreaCanvasObject.canvas_object);
        }

        return customization;
    }

    function getRotatedAnchorPoint(
        position: InnerAnchorPoints,
        rotation: number,
        positioningArea: PositioningArea,
    ) {
        const rotationAngle = ref(
            Math.round(
                Math.round(
                    rotation -
                        positioningArea.rotation -
                        positioningArea.direction,
                ) / 90,
            ) * 90,
        );

        if (rotationAngle.value >= 360) {
            rotationAngle.value -= 360;
        }

        switch (rotationAngle.value + positioningArea.direction) {
            case 90:
            case -270:
                switch (position) {
                    case InnerAnchorPoints.TOP_CENTER:
                        return InnerAnchorPoints.CENTER_LEFT;
                    case InnerAnchorPoints.TOP_RIGHT:
                        return InnerAnchorPoints.TOP_LEFT;
                    case InnerAnchorPoints.TOP_LEFT:
                        return InnerAnchorPoints.BOTTOM_LEFT;
                    case InnerAnchorPoints.BOTTOM_CENTER:
                        return InnerAnchorPoints.CENTER_RIGHT;
                    case InnerAnchorPoints.BOTTOM_RIGHT:
                        return InnerAnchorPoints.TOP_RIGHT;
                    case InnerAnchorPoints.BOTTOM_LEFT:
                        return InnerAnchorPoints.BOTTOM_RIGHT;
                    case InnerAnchorPoints.CENTER_RIGHT:
                        return InnerAnchorPoints.TOP_CENTER;
                    case InnerAnchorPoints.CENTER_LEFT:
                        return InnerAnchorPoints.BOTTOM_CENTER;
                    default:
                        return InnerAnchorPoints.CENTER_CENTER;
                }
            case 180:
            case -180:
                switch (position) {
                    case InnerAnchorPoints.TOP_CENTER:
                        return InnerAnchorPoints.BOTTOM_CENTER;
                    case InnerAnchorPoints.TOP_RIGHT:
                        return InnerAnchorPoints.BOTTOM_LEFT;
                    case InnerAnchorPoints.TOP_LEFT:
                        return InnerAnchorPoints.BOTTOM_RIGHT;
                    case InnerAnchorPoints.BOTTOM_CENTER:
                        return InnerAnchorPoints.TOP_CENTER;
                    case InnerAnchorPoints.BOTTOM_RIGHT:
                        return InnerAnchorPoints.TOP_LEFT;
                    case InnerAnchorPoints.BOTTOM_LEFT:
                        return InnerAnchorPoints.TOP_RIGHT;
                    case InnerAnchorPoints.CENTER_RIGHT:
                        return InnerAnchorPoints.CENTER_LEFT;
                    case InnerAnchorPoints.CENTER_LEFT:
                        return InnerAnchorPoints.CENTER_RIGHT;
                    default:
                        return InnerAnchorPoints.CENTER_CENTER;
                }
            case 270:
            case -90:
                switch (position) {
                    case InnerAnchorPoints.TOP_CENTER:
                        return InnerAnchorPoints.CENTER_RIGHT;
                    case InnerAnchorPoints.TOP_RIGHT:
                        return InnerAnchorPoints.BOTTOM_RIGHT;
                    case InnerAnchorPoints.TOP_LEFT:
                        return InnerAnchorPoints.TOP_RIGHT;
                    case InnerAnchorPoints.BOTTOM_CENTER:
                        return InnerAnchorPoints.CENTER_LEFT;
                    case InnerAnchorPoints.BOTTOM_RIGHT:
                        return InnerAnchorPoints.BOTTOM_LEFT;
                    case InnerAnchorPoints.BOTTOM_LEFT:
                        return InnerAnchorPoints.TOP_LEFT;
                    case InnerAnchorPoints.CENTER_RIGHT:
                        return InnerAnchorPoints.BOTTOM_CENTER;
                    case InnerAnchorPoints.CENTER_LEFT:
                        return InnerAnchorPoints.TOP_CENTER;
                    default:
                        return InnerAnchorPoints.CENTER_CENTER;
                }
            default:
                return position;
        }
    }

    return {
        createNewCustomization,
    };
};

import { useI18n } from "vue-i18n";

export function useProductionMethodDescriptions() {
    const { t } = useI18n();

    const productionShortDescriptionMap: Record<string, string> = {
        dtf: t("production_method_summary_dtf"),
        embroidery: t("production_method_summary_embroidery"),
    };

    return {
        productionShortDescriptionMap,
    };
}

import { useRepo } from "pinia-orm";
import Variant from "@/repo/Variant";
import { useCurrentStore } from "@/store/useCurrentStore";

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line @typescript-eslint/require-await
export default async () => {
    const currentStore = useCurrentStore();
    const { changeCurrentVariant } = currentStore;

    changeCurrentVariant(getInitialCurrentVariant());

    function getInitialCurrentVariant(): Variant {
        const variant = useRepo(Variant).all().pop();

        if (!variant) {
            throw Error("Variant is required");
        }

        return variant;
    }
};

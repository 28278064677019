export class BootStepException {
    constructor(baseException: unknown, readableMessage: string) {
        this.baseException = baseException;
        this.readableMessage = readableMessage;
    }

    public baseException: unknown;

    public readableMessage: string;
}

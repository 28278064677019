import type { ZoomPoint } from "@/components/modules/coreModules/canvas/types/ZoomPoint";
import usePositioningAreaFunctions from "@/composables/positioningAreas/usePositioningAreaFunctions";
import useTextFunctions from "@/composables/texts/useTextFunctions";
import { useDesignFeatures } from "@/composables/useDesignFeatures";
import { PanelState } from "@/enums/PanelState";
import { isAddMode } from "@/enums/mode";
import { updateDesignImageBase64 } from "@/functions/FabricHelpers";
import {
    isZSKLogoDesign,
    isZSKLogoTextDesign,
    isZSKTextDesign,
} from "@/modules/core/renderer/zskEmbroidery/ZSKDesignHelper";
import Customization from "@/repo/Customization";
import View from "@/repo/View";
import { useBoundingStore } from "@/store/useBoundingStore";
import { useCurrentStore } from "@/store/useCurrentStore";
import { useMainStore } from "@/store/useMainStore";
import { usePanelStore } from "@/store/usePanelStore";
import { useProductionMethodStore } from "@/store/useProductionMethodStore";
import type { DesignCanvasObject } from "@/types/DesignCanvasObject";
import type { DesignImage } from "@/types/DesignImage";
import type { PositioningAreaCanvasObject } from "@/types/PositioningAreaCanvasObject";
import type { ZoomData } from "@/types/ZoomData";
import type { Size } from "@smakecloud/designer-core";
import { type DesignPreviewData, type PointData } from "@smakecloud/smake-use";
import { fabric } from "fabric";
import { clamp, isEqual } from "lodash";
import { defineStore, storeToRefs } from "pinia";
import { useRepo } from "pinia-orm";
import { computed, nextTick, ref, watch, watchEffect, type Ref } from "vue";
import { useCustomizationEditModalStore } from "./useCustomizationEditModalStore";

class SkeletonImage extends fabric.Image {
    public objectCaching = false;

    private isSkeleton: boolean;

    constructor(
        width: number,
        height: number,
        private borderRadius: number,
    ) {
        const canvas = document.createElement("canvas");
        canvas.width = width;
        canvas.height = height;
        super(canvas);
        this.isSkeleton = true;
    }

    public setElement(
        element: HTMLImageElement | HTMLVideoElement,
        options?: fabric.IImageOptions,
    ) {
        this.isSkeleton = false;
        return super.setElement(element, options);
    }

    public _render(ctx: CanvasRenderingContext2D) {
        if (this.isSkeleton) {
            const canvas = this.getElement() as unknown as HTMLCanvasElement;
            const context = canvas.getContext("2d")!;
            context.clearRect(0, 0, canvas.width, canvas.height);
            context.roundRect(
                0,
                0,
                canvas.width,
                canvas.height,
                this.borderRadius,
            );
            const opacity =
                Math.sin(
                    ((document.timeline.currentTime as number) / 1000) *
                        Math.PI,
                ) /
                    4 +
                0.35;
            context.fillStyle = `rgba(240, 241, 241, ${opacity})`;
            context.fill();
            this.canvas?.requestRenderAll();
        }
        super._render(ctx);
    }
}

export const useCanvasStore = defineStore("canvas", () => {
    const currentStore = useCurrentStore();
    const mainStore = useMainStore();
    const { changeCurrentCustomization } = currentStore;
    const { showPanel, hideCurrentPanel } = usePanelStore();
    const {
        isCurrentCustomizationMoving,
        currentCustomization,
        currentCustomizationHasEmptyResourceLines,
        currentMode,
        currentView,
    } = storeToRefs(currentStore);
    const { currentProductionMethod } = storeToRefs(useProductionMethodStore());
    const { showAreas } = storeToRefs(mainStore);
    const { app, visibleStageArea } = storeToRefs(useBoundingStore());
    const customizationEditModalStore = useCustomizationEditModalStore();
    const positioningAreaCanvasObjects = ref<PositioningAreaCanvasObject[]>(
        [],
    ) as Ref<PositioningAreaCanvasObject[]>;
    const {
        getOriginX,
        getOriginY,
        onMoving,
        onMoved,
        onScaling,
        onScaled,
        onRotated,
        updatePxDimensionsFromLocalizedDimensions,
    } = useTextFunctions(positioningAreaCanvasObjects);
    const designCanvasObjects = ref<DesignCanvasObject[]>([]) as Ref<
        DesignCanvasObject[]
    >;
    const positioningAreaFunctions = usePositioningAreaFunctions();
    const setNewTextCanvasObjectsAsActive = ref<boolean>(true);
    const currentZoom = ref<number>(1);
    const zoomData = ref<ZoomData>({ min: 1, max: 4 });
    const changeCurrentZoom = ref<boolean>(false);
    const canvasWrapper = ref<fabric.Canvas | undefined>(undefined) as Ref<
        fabric.Canvas | undefined
    >;

    const customizationRepo = useRepo(Customization);
    const viewRepo = useRepo(View);

    const currentCustomizationCanvasObject =
        computed<DesignCanvasObject | null>(() => {
            return (
                designCanvasObjects.value.find((textCanvasObject) => {
                    return (
                        textCanvasObject.customization_id ===
                        currentCustomization.value?.id
                    );
                }) ?? null
            );
        });

    async function renderDesignPreviewToCanvas(
        customization: Customization,
        designPreview: DesignPreviewData,
    ) {
        const designCanvasObject = designCanvasObjects.value.find(
            (textCanvasObject) =>
                textCanvasObject.customization_id === customization.id,
        );

        if (designCanvasObject) {
            await updateDesignCanvasObject(designCanvasObject, designPreview);

            return;
        }

        await createDesignCanvasObject(customization, designPreview);

        return;
    }

    function createDesignImage(
        customization: Customization,
        size: Size,
    ): DesignImage {
        const view = viewRepo
            .all()
            .find((view: View) =>
                view.customization_ids.includes(customization.id),
            );

        if (view === undefined) {
            throw Error("View not found");
        }

        if (!customization.design) {
            throw Error("Design is missing");
        }

        const scalefactor = view.scalefactor;

        const fabricImage = new SkeletonImage(
            size.width * 10,
            size.height * 10,
            20 / scalefactor,
        ) as unknown as DesignImage;
        fabricImage.set({
            id: customization.id.toString(),
            opacity: 1,
            originX: getOriginX(customization.position[1]),
            originY: getOriginY(customization.position[0]),
            left: customization.x,
            top: customization.y,
            angle: customization.rotation,
            scaleX:
                (size.width * scalefactor) /
                fabricImage.getOriginalSize().width,
            scaleY:
                (size.height * scalefactor) /
                fabricImage.getOriginalSize().height,
            smake_type: "text",
        });

        return fabricImage;
    }

    async function createDesignCanvasObject(
        customization: Customization,
        size?: Size,
    ) {
        if (
            designCanvasObjects.value.some(
                (designCanvasObject) =>
                    designCanvasObject.customization_id === customization.id,
            )
        )
            return;

        const view = viewRepo
            .all()
            .find((view: View) =>
                view.customization_ids.includes(customization.id),
            );

        if (view === undefined) {
            throw Error("View not found");
        }

        if (!size) {
            size = {
                width: 30 / view.scalefactor,
                height: 12 / view.scalefactor,
            };
            const design = customization.design;
            if (isZSKLogoDesign(design)) {
                if (design.logoDesignElementData?.dimension) {
                    size = design.logoDesignElementData.dimension;
                } else {
                    size = {
                        width: design.logo.width,
                        height: design.logo.height,
                    };
                }
            } else if (isZSKTextDesign(design)) {
                if (design.text.width && design.text.height) {
                    size = {
                        width: design.text.width,
                        height: design.text.height,
                    };
                }
            } else if (isZSKLogoTextDesign(design)) {
                size = {
                    width: 30 / view.scalefactor,
                    height: 30 / view.scalefactor,
                };
            }
        }

        customizationRepo.save({
            ...customization,
            width: size.width,
            height: size.height,
        });

        const scalefactor = view.scalefactor;

        const designImage = createDesignImage(customization, size);

        const currentDesign = computed(
            () => currentCustomization.value?.design ?? undefined,
        );

        const featureMap = useDesignFeatures(currentDesign);

        watch(
            featureMap,
            () => {
                designImage.setControlVisible(
                    "br",
                    featureMap.value.resizeable,
                );
                canvasWrapper.value?.requestRenderAll();
            },
            { immediate: true },
        );

        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        designImage.on("mousedown", async () => {
            if (isAddMode(currentMode.value)) {
                return;
            }

            const custo = customizationRepo.find(customization.id);

            if (custo === null) {
                return;
            }

            const positioningAreaCanvasObject =
                positioningAreaCanvasObjects.value.find(
                    (positioningAreaCanvasObject) =>
                        positioningAreaCanvasObject.id ===
                        customization.positioningArea_id,
                );

            if (positioningAreaCanvasObject === undefined) {
                return;
            }

            if (custo.id !== currentStore.currentCustomization?.id) {
                await changeCurrentCustomization(custo);
                positioningAreaFunctions.pushNotifcationsForPositioningArea(
                    positioningAreaCanvasObject.id,
                );
            }

            await showPanel(PanelState.CustomizationEdit);

            showOrHidePositioningAreaCanvasObjects();
        });

        designImage.on("scaling", (event: fabric.IEvent) => {
            const desginCanvasObject = designCanvasObjects.value.find(
                (desginCanvasObjectEntry) =>
                    desginCanvasObjectEntry.customization_id ===
                    customization.id,
            );

            if (desginCanvasObject === undefined) {
                return;
            }

            onScaling(event, desginCanvasObject);
        });

        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        designImage.on("moving", async (event: fabric.IEvent) => {
            const designCanvasObject = designCanvasObjects.value.find(
                (textCanvasObject) =>
                    textCanvasObject.customization_id === customization.id,
            );

            if (designCanvasObject === undefined) {
                return;
            }

            await customizationEditModalStore.close(true);

            isCurrentCustomizationMoving.value = true;
            await hideCurrentPanel();

            onMoving(event, designCanvasObject);
        });

        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        designImage.on("modified", async (event: fabric.IEvent) => {
            const designCanvasObject = designCanvasObjects.value.find(
                (textCanvasObject) =>
                    textCanvasObject.customization_id === customization.id,
            );
            const positioningAreaCanvasObject =
                positioningAreaCanvasObjects.value.find(
                    (positioningAreaCanvasObject) =>
                        positioningAreaCanvasObject.id ===
                        designCanvasObject?.positioningArea_id,
                );

            if (
                designCanvasObject === undefined ||
                positioningAreaCanvasObject === undefined
            ) {
                return;
            }

            if (event.action === "rotate") {
                onRotated(designCanvasObject, positioningAreaCanvasObject);

                return;
            }

            if (event.action === "drag") {
                await onMoved(designCanvasObject, positioningAreaCanvasObject);
                positioningAreaFunctions.pushNotifcationsForPositioningArea(
                    positioningAreaCanvasObject.id,
                );
                isCurrentCustomizationMoving.value = false;

                return;
            }

            if (event.action === "scale") {
                await onScaled(designCanvasObject, positioningAreaCanvasObject);

                return;
            }
        });

        designCanvasObjects.value.push({
            customization_id: customization.id,
            view_handle: view.handle,
            image: designImage,
            positioningArea_id: customization.positioningArea_id,
            rotation_angle:
                designImage.angle -
                customization.positioning_area.direction -
                customization.positioning_area.rotation,
            px_height: customization.height * scalefactor,
            px_width: customization.width * scalefactor,
            start_scale: designImage.scaleX,
        });

        // maybe new function

        const positioningAreaCanvaObject =
            positioningAreaCanvasObjects.value.find(
                (positioningAreaCanvasObject) =>
                    positioningAreaCanvasObject.id ===
                    customization.positioningArea_id,
            );

        if (positioningAreaCanvaObject === undefined) {
            return;
        }

        positioningAreaCanvaObject.has_customization = true;

        showOrHidePositioningAreaCanvasObjects();
        updatePxDimensionsFromLocalizedDimensions(
            designCanvasObjects.value[designCanvasObjects.value.length - 1],
            size,
        );

        await nextTick();
    }

    function updateDesignCanvasObjectRotation(
        desginCanvasObjectCustomizationId: number,
        angle: number,
    ) {
        const designCanvasObject = designCanvasObjects.value.find(
            (designCanvasObject) =>
                designCanvasObject.customization_id ===
                desginCanvasObjectCustomizationId,
        );
        const positioningAreaCanvasObject =
            positioningAreaCanvasObjects.value.find(
                (positioningAreaCanvasObject) =>
                    positioningAreaCanvasObject.id ===
                    designCanvasObject?.positioningArea_id,
            );

        if (designCanvasObject === undefined) {
            console.error("Could not find designCanvasObject to rotate");
            return;
        }

        if (positioningAreaCanvasObject === undefined) {
            console.error("Could not find positioningAreaCanvasObject");
            return;
        }

        designCanvasObject.image.angle = angle;
        onRotated(designCanvasObject, positioningAreaCanvasObject);
        designCanvasObject.image.canvas?.requestRenderAll();
    }

    async function updateDesignCanvasObject(
        designCanvasObject: DesignCanvasObject,
        designPreview: DesignPreviewData,
    ) {
        designCanvasObject.image = await updateDesignImageBase64(
            designCanvasObject.image,
            designPreview.content,
        );

        updatePxDimensionsFromLocalizedDimensions(
            designCanvasObject,
            designPreview,
        );

        designCanvasObject.image.set(
            "scaleX",
            designCanvasObject.px_width /
                designCanvasObject.image.getOriginalSize().width,
        );

        designCanvasObject.image.set(
            "scaleY",
            designCanvasObject.px_height /
                designCanvasObject.image.getOriginalSize().height,
        );
        designCanvasObject.start_scale = designCanvasObject.image.scaleX ?? 1;
        designCanvasObject.image.canvas?.requestRenderAll();

        const positioningAreaCanvasObject =
            positioningAreaCanvasObjects.value.find(
                (positioningAreaCanvasObject) =>
                    positioningAreaCanvasObject.id ===
                    designCanvasObject.positioningArea_id,
            );

        if (positioningAreaCanvasObject === undefined) {
            return;
        }

        positioningAreaFunctions.validatePosition(
            positioningAreaCanvasObject,
            designCanvasObject,
        );
        showOrHidePositioningAreaCanvasObjects();

        await nextTick();
    }

    function getPositioningAreasOfCurrentViewAndProductionMethod() {
        return positioningAreaCanvasObjects.value.filter(
            (positioningAreaCanvasObject) => {
                return (
                    positioningAreaCanvasObject.production_method_id ===
                        currentProductionMethod.value.id &&
                    currentView.value.positioning_area_ids.includes(
                        positioningAreaCanvasObject.id,
                    )
                );
            },
        );
    }

    function removeDesignCanvasObjectByCustomizationId(
        customizationId: number,
    ) {
        const designCanvasObject = designCanvasObjects.value.find(
            (designCanvasObject) =>
                designCanvasObject.customization_id === customizationId,
        );

        if (designCanvasObject === undefined) {
            return;
        }

        removeTextCanvasObject(designCanvasObject);
    }

    function removeTextCanvasObjectByCustomization(
        customization: Customization,
    ) {
        const designCanvasObject: DesignCanvasObject | undefined =
            designCanvasObjects.value.find(
                (designCanvasObjectEntry) =>
                    designCanvasObjectEntry.customization_id ===
                    customization.id,
            );

        if (!designCanvasObject) {
            return;
        }

        removeTextCanvasObject(designCanvasObject);
    }

    function removeTextCanvasObject(desginCanvasObject: DesignCanvasObject) {
        if (desginCanvasObject === undefined) {
            return;
        }

        designCanvasObjects.value.splice(
            designCanvasObjects.value.indexOf(desginCanvasObject),
            1,
        );
        const positioningAreaCanvasObject =
            positioningAreaCanvasObjects.value.find(
                (positioningAreaCanvasObject) =>
                    positioningAreaCanvasObject.id ===
                    desginCanvasObject.positioningArea_id,
            );

        if (positioningAreaCanvasObject === undefined) {
            return;
        }

        positioningAreaCanvasObject.has_customization = false;

        positioningAreaFunctions.validatePosition(
            positioningAreaCanvasObject,
            desginCanvasObject,
        );

        showOrHidePositioningAreaCanvasObjects();
    }

    function hideCanvasObject(customization: Customization) {
        const textCanvasObject = designCanvasObjects.value.find(
            (textCanvasObject) =>
                textCanvasObject.customization_id === customization.id,
        );

        if (!textCanvasObject) {
            return;
        }

        textCanvasObject.image.set("opacity", 0.2);
        textCanvasObject.image.canvas?.requestRenderAll();
    }

    function showCanvasObject(customization: Customization) {
        const textCanvasObject = designCanvasObjects.value.find(
            (textCanvasObject) =>
                textCanvasObject.customization_id === customization.id,
        );

        if (!textCanvasObject) {
            return;
        }

        textCanvasObject.image.set("opacity", 1);
        textCanvasObject.image.canvas?.requestRenderAll();
    }

    function showOrHidePositioningAreaCanvasObjects() {
        getPositioningAreasOfCurrentViewAndProductionMethod().forEach(
            (positioningAreaCanvasObject) => {
                positioningAreaFunctions.showOrHideCanvasObject(
                    positioningAreaCanvasObject,
                );
            },
        );
    }

    watchEffect(() => {
        currentZoom.value = clamp(
            currentZoom.value,
            zoomData.value.min,
            zoomData.value.max,
        );
    });

    watch(
        () => showAreas.value,
        () => {
            showOrHidePositioningAreaCanvasObjects();
        },
    );

    watch(
        () => currentCustomization.value,
        () => {
            const textCanvasObject = designCanvasObjects.value.find(
                (textCanvasObject) =>
                    textCanvasObject.customization_id ===
                    currentCustomization.value?.id,
            );

            if (textCanvasObject === undefined) {
                return;
            }

            if (currentCustomizationHasEmptyResourceLines.value) {
                textCanvasObject.image.set("hasControls", false);
                textCanvasObject.image.set("hasBorders", false);

                return;
            }

            textCanvasObject.image.set("hasControls", true);
            textCanvasObject.image.set("hasBorders", true);
        },
    );

    function discardActiveObject() {
        if (canvasWrapper.value === undefined) {
            return;
        }

        canvasWrapper.value.discardActiveObject();
    }

    const stageVisibleCenterX = computed<number>(() => {
        return Math.round(
            visibleStageArea.value.left +
                visibleStageArea.value.width / 2 -
                app.value.left,
        );
    });

    const stageVisibleCenterY = computed<number>(() => {
        return Math.round(
            visibleStageArea.value.top +
                visibleStageArea.value.height / 2 -
                app.value.top,
        );
    });

    function getMidPoint(pointA: PointData, pointB: PointData) {
        const midPoint = {
            x: (pointA.x + pointB.x) / 2,
            y: (pointA.y + pointB.y) / 2,
        };

        return midPoint;
    }

    function getLinearZoomPointBetween(
        startPoint: ZoomPoint,
        endPoint: ZoomPoint,
        percentage: number,
    ): ZoomPoint {
        const newX = startPoint.x + (endPoint.x - startPoint.x) * percentage;
        const newY = startPoint.y + (endPoint.y - startPoint.y) * percentage;
        const newZoom =
            startPoint.zoom + (endPoint.zoom - startPoint.zoom) * percentage;

        return {
            x: newX,
            y: newY,
            zoom: newZoom,
        };
    }

    function setViewportTransformToZoomPoint(zoomPoint: ZoomPoint) {
        if (canvasWrapper.value?.viewportTransform === undefined) {
            return;
        }

        canvasWrapper.value?.setViewportTransform([
            zoomPoint.zoom,
            0,
            0,
            zoomPoint.zoom,
            zoomPoint.x,
            zoomPoint.y,
        ]);

        canvasWrapper.value?.requestRenderAll();
    }

    function zoomStageTo(endVpt: ZoomPoint) {
        if (canvasWrapper.value?.viewportTransform === undefined) {
            return;
        }

        const startVpt = {
            zoom: canvasWrapper.value.getZoom(),
            x: canvasWrapper.value.viewportTransform[4],
            y: canvasWrapper.value.viewportTransform[5],
        };

        if (isEqual(startVpt, endVpt)) {
            return;
        }

        fabric.util.animate({
            startValue: 0,
            endValue: 1,
            duration: 500,
            onChange: (value: number) => {
                const zoomPoint = getLinearZoomPointBetween(
                    startVpt,
                    endVpt,
                    value,
                );

                setViewportTransformToZoomPoint(zoomPoint);
            },
            onComplete: () => {
                canvasWrapper.value?.requestRenderAll();
                currentZoom.value = endVpt.zoom;
            },
        });
    }

    function zoomStageToObject(object: fabric.Object) {
        if (canvasWrapper.value === undefined || object.aCoords === undefined) {
            return;
        }

        const padding = 20;

        const widthFactor =
            (visibleStageArea.value.width - 2 * padding) /
            object.getScaledWidth();
        const heightFactor =
            (visibleStageArea.value.height - 2 * padding) /
            object.getScaledHeight();

        const zoom = Math.min(widthFactor, heightFactor, zoomData.value.max);

        const midPoint = getMidPoint(object.aCoords.bl, object.aCoords.tr);

        const endVpt = {
            zoom: zoom,
            x: -midPoint.x * zoom + stageVisibleCenterX.value,
            y: -midPoint.y * zoom + stageVisibleCenterY.value,
        };

        zoomStageTo(endVpt);
    }

    return {
        positioningAreaCanvasObjects,
        designCanvasObjects,
        currentCustomizationCanvasObject,

        renderDesignPreviewToCanvas,

        removeTextCanvasObject,
        hideCanvasObject,
        showCanvasObject,
        showOrHidePositioningAreaCanvasObjects,
        removeTextCanvasObjectByCustomization,
        removeDesignCanvasObjectByCustomizationId,
        updateDesignCanvasObjectRotation,
        createDesignCanvasObject,

        setNewTextCanvasObjectsAsActive,

        currentZoom,
        zoomData,
        changeCurrentZoom,
        zoomStageTo,
        zoomStageToObject,
        setViewportTransformToZoomPoint,

        discardActiveObject,
        canvasWrapper,
    };
});

<template>
    <module-button
        icon="icon-ciconfig-archive"
        :style-config="moduleButtonStyleConfig"
        :disabled="isDisabled"
        @click="showPanel(PanelState.Archive)"
    >
        <span class="tablet:hidden">
            {{ t("archive") }}
        </span>
        <span class="hidden tablet:inline">
            {{ t("my_customizations") }}
        </span>
    </module-button>
</template>

<script setup lang="ts">
import { PanelState } from "@/enums/PanelState";
import { isAddMode } from "@/enums/mode";
import { useCurrentStore } from "@/store/useCurrentStore";
import { useMainStore } from "@/store/useMainStore";
import { usePanelStore } from "@/store/usePanelStore";
import cloneDeep from "lodash/cloneDeep";
import get from "lodash/get";
import merge from "lodash/merge";
import set from "lodash/set";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const currentStore = useCurrentStore();
const panelStore = usePanelStore();
const { showPanel } = panelStore;
const { isPanelOpen } = storeToRefs(panelStore);
const { currentMode } = storeToRefs(currentStore);
const { settings } = useMainStore();
const { t } = useI18n();

// props
const props = defineProps({
    styleConfig: {
        type: Object,
        default: () => {
            return {};
        },
    },
});

// computed
const moduleButtonStyleConfig = computed(() => {
    const baseStyleConfig = cloneDeep(props.styleConfig);

    [
        "styles",
        "icon.styles",
        "label.styles",
        "disabled.styles",
        "disabled.icon.styles",
        "disabled.label.styles",
    ].forEach((key) => {
        set(
            baseStyleConfig,
            key,
            merge(
                get(baseStyleConfig, key, []),
                get(settings.style, `archiveButton.${key}`, []),
            ),
        );
    });

    return baseStyleConfig;
});

// computed
const isDisabled = computed<boolean>(() => {
    return isAddMode(currentMode.value) || isPanelOpen.value;
});
</script>

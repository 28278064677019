import {
    AnchorType,
    isDesignElementLogo,
    isDesignElementText,
    OuterAnchorPoints,
    type Alignment,
    type DesignElement,
} from "@smakecloud/smake-use";
import type { ZSKDesign } from "./types/ZSKDesign";

export function offsetToDistance(alignment: Alignment) {
    if (!alignment.offset) {
        throw new Error("Offset missing");
    }

    switch (alignment.anchor_value) {
        case OuterAnchorPoints.LEFT_TOP:
        case OuterAnchorPoints.LEFT_CENTER:
        case OuterAnchorPoints.LEFT_BOTTOM:
            return -alignment.offset.x;
        case OuterAnchorPoints.RIGHT_TOP:
        case OuterAnchorPoints.RIGHT_CENTER:
        case OuterAnchorPoints.RIGHT_BOTTOM:
            return alignment.offset.x;
        case OuterAnchorPoints.BOTTOM_LEFT:
        case OuterAnchorPoints.BOTTOM_CENTER:
        case OuterAnchorPoints.BOTTOM_RIGHT:
            return alignment.offset.y;
        case OuterAnchorPoints.TOP_LEFT:
        case OuterAnchorPoints.TOP_CENTER:
        case OuterAnchorPoints.TOP_RIGHT:
            return -alignment.offset.y;
        default:
            throw new Error("Unknown anchor point");
    }
}

export function designElementToZSKDesign(
    designElement: DesignElement,
): ZSKDesign {
    if (isDesignElementText(designElement)) {
        return {
            text: designElement.resource,
        };
    }

    if (isDesignElementLogo(designElement)) {
        if (designElement.elements && designElement.elements.length > 0) {
            if (designElement.elements.length !== 1) {
                throw new Error(
                    "Elements of DesignElementLogo contains too many elements",
                );
            }

            const designElementText = designElement.elements[0];

            if (!isDesignElementText(designElementText)) {
                throw new Error(
                    "Element of DesignElementLogo isn't DesignElementText",
                );
            }

            if (designElementText.alignment?.anchor_type !== AnchorType.OUTER) {
                throw new Error("AnchorType of DesignElementText isn't outer");
            }

            if (!designElementText.alignment.offset) {
                throw new Error("alignment has no offset");
            }

            return {
                logo: designElement.resource,
                logoDesignElementData: { dimension: designElement.dimension },
                text: designElementText.resource,
                alignment: {
                    distance: offsetToDistance(designElementText.alignment),
                    anchor: designElementText.alignment.anchor_value,
                },
            };
        }

        return {
            logo: designElement.resource,
            logoDesignElementData: { dimension: designElement.dimension },
        };
    }

    if (designElement.resource.elements.length !== 1) {
        throw new Error(
            "Elements of DesignResourceData contains none or too many elements",
        );
    }

    return designElementToZSKDesign(designElement.resource.elements[0]);
}

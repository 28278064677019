import { Mode } from "@/enums/mode";
import { NotificationType } from "@/enums/NotificationType";
import { cancelAddCustomizationEventKey } from "@/events/addCustomization";
import { designElementToZSKDesign } from "@/modules/core/renderer/zskEmbroidery/ConvertDesignElementToZSKDesign";
import { useCurrentStore } from "@/store/useCurrentStore";
import { usePanelStore } from "@/store/usePanelStore";
import type { DesignElement } from "@smakecloud/smake-use";
import { useEventBus } from "@vueuse/core";
import { storeToRefs } from "pinia";
import { watchEffect } from "vue";
import { useI18n } from "vue-i18n";
import useNotification from "./useNotification";

export default () => {
    const { t } = useI18n();
    const { pushNotification } = useNotification();
    const { hideCurrentPanel } = usePanelStore();
    const { currentMode, addArchivDesign } = storeToRefs(useCurrentStore());
    const cancelAddCustomizationEventBus = useEventBus(
        cancelAddCustomizationEventKey,
    );

    let stopListeners: (() => void)[] = [];
    watchEffect(() => {
        if (currentMode.value === Mode.AddArchiveText) return;
        stopListeners.forEach((stopListener) => stopListener());
        stopListeners = [];
    });

    async function selectPositioningArea(
        designElement: DesignElement,
        onAbort?: () => void,
    ) {
        await hideCurrentPanel();
        addArchivDesign.value = designElementToZSKDesign(designElement);
        currentMode.value = Mode.AddArchiveText;
        pushNotification(t("choose_a_positioning_area"), NotificationType.Info);
        if (onAbort) {
            stopListeners.push(cancelAddCustomizationEventBus.once(onAbort));
        }
    }

    return { selectPositioningArea };
};

import { type Directive, type DirectiveBinding } from "vue";

const vOnScrollEnd: Directive = {
    mounted: (element: HTMLElement, binding: DirectiveBinding) => {
        element.addEventListener("scroll", () => onScroll(element, binding));
    },

    unmounted: (element: HTMLElement, binding: DirectiveBinding) => {
        element.removeEventListener("scroll", () => onScroll(element, binding));
    },
};
function onScroll(element: HTMLElement, binding: DirectiveBinding) {
    if (isScrolledToTheEnd(element)) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        binding.value();
    }
}
function isScrolledToTheEnd(element: HTMLElement): boolean {
    return (
        Math.abs(
            element.scrollHeight - element.clientHeight - element.scrollTop,
        ) < 350
    );
}
export default vOnScrollEnd;

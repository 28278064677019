<template>
    <div
        :class="{
            'text-slate-400 grayscale-[98%]':
                !hasCurrentVariantSupportedPositioningAreas,
        }"
    >
        <div class="relative flex h-full flex-col overflow-clip rounded-3xl">
            <div>
                <transition
                    as="template"
                    appear
                    enter-active-class="ease-out duration-300"
                    enter-from-class="opacity-0"
                    enter-to-class="opacity-100"
                    leave-active-class="ease-in duration-200"
                    leave-from-class="opacity-100"
                    leave-to-class="opacity-0"
                >
                    <div>
                        <div
                            v-if="!isLoading"
                            class="relative rounded-t-3xl bg-secondary-2"
                        >
                            <img
                                class="h-40 w-full object-cover"
                                :src="productionMethod.preview_url"
                                :alt="productionMethod.handle"
                            />

                            <div
                                v-if="icon"
                                class="absolute left-3 top-3 flex aspect-square w-10 items-center justify-center rounded-full bg-white p-1"
                            >
                                <f-a-icon class="text-2xl" :icon />
                            </div>
                        </div>
                        <div
                            v-if="isLoading"
                            class="h-40 w-full animate-pulse rounded-t-lg bg-secondary-2"
                        ></div>
                    </div>
                </transition>
            </div>
            <div
                class="flex grow flex-col items-center gap-5 bg-secondary-3 p-4 px-6 text-center [overflow-wrap:anywhere]"
            >
                <div class="mt-4 text-xl font-bold">
                    {{ productionMethod.display_name }}
                </div>
                <div
                    class="flex min-h-24 w-full flex-col items-start justify-start gap-2 text-left text-sm"
                >
                    <div class="flex items-center gap-3">
                        <div
                            class="flex aspect-square h-4 items-center justify-center rounded-full bg-white text-xs text-confirmation"
                        >
                            <f-a-icon icon="check" />
                        </div>
                        <div>
                            {{
                                $t(`${productionMethod.handle}_selling_point_1`)
                            }}
                        </div>
                    </div>
                    <div class="flex items-center gap-3">
                        <div
                            class="flex aspect-square h-4 items-center justify-center rounded-full bg-white text-xs text-confirmation"
                        >
                            <f-a-icon icon="check" />
                        </div>
                        <div>
                            {{
                                $t(`${productionMethod.handle}_selling_point_2`)
                            }}
                        </div>
                    </div>
                    <div class="flex items-center gap-3">
                        <div
                            class="flex aspect-square h-4 items-center justify-center rounded-full bg-white text-xs text-confirmation"
                        >
                            <f-a-icon icon="check" />
                        </div>
                        <div>
                            {{
                                $t(`${productionMethod.handle}_selling_point_3`)
                            }}
                        </div>
                    </div>
                </div>
                <div
                    class="mx-2 flex w-full flex-col gap-3 divide-y divide-secondary-2 rounded-2xl bg-white p-4 text-sm"
                >
                    <div class="grid grid-cols-2 items-center">
                        <div class="font-bold">{{ $t("look") }}</div>
                        <div>{{ $t(`${productionMethod.handle}_look`) }}</div>
                    </div>
                    <div class="grid grid-cols-2 items-center pt-3">
                        <div class="font-bold">{{ $t("washablility") }}</div>
                        <div>
                            {{ $t(`${productionMethod.handle}_washability`) }}
                        </div>
                    </div>
                    <div class="grid grid-cols-2 items-center pt-3">
                        <div class="font-bold">
                            {{ $t("minimum_quantity") }}
                        </div>
                        <div>{{ $t("from_one_piece") }}</div>
                    </div>
                    <div class="grid grid-cols-2 items-center pt-3">
                        <div class="font-bold">
                            {{ $t("textiles") }}
                        </div>
                        <div class="text-center">
                            {{ $t(`${productionMethod.handle}_textiles`) }}
                        </div>
                    </div>
                </div>
            </div>
            <div
                v-if="!readonly"
                class="flex w-full items-center justify-center rounded-b-3xl bg-secondary-3 p-6"
            >
                <button-primary
                    class="w-full py-3 text-xs"
                    size="small"
                    :disabled="!hasCurrentVariantSupportedPositioningAreas"
                    @click="emit('select')"
                >
                    {{
                        t("continue_with_x", {
                            x: productionMethod.display_name,
                        })
                    }}
                </button-primary>
            </div>
            <div
                v-if="!hasCurrentVariantSupportedPositioningAreas"
                class="absolute inset-0 flex items-center justify-center bg-gray-800/20"
            >
                <div
                    class="mx-10 rounded-3xl bg-white px-6 py-3 text-center text-black shadow"
                >
                    {{ $t("production_method_not_available_for_variant") }}
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { useProductionMethodInfo } from "@/composables/useProductionMethodInfo";
import { productionMethodIconMap } from "@/functions/ProductionMethodIcon";
import type { ProductionMethodData } from "@smakecloud/smake-use";
import { useImage, type UseImageOptions } from "@vueuse/core";
import { computed, toRefs } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const emit = defineEmits<{
    select: [];
}>();

const props = defineProps<{
    productionMethod: ProductionMethodData;
    readonly?: boolean;
}>();

const { productionMethod } = toRefs(props);

const productionMethodId = computed(() => productionMethod.value.id);
const { hasCurrentVariantSupportedPositioningAreas } =
    useProductionMethodInfo(productionMethodId);

const imageOptions = computed<UseImageOptions>(() => {
    return {
        src: productionMethod.value.preview_url,
    };
});

const { isLoading } = useImage(imageOptions);

const icon = computed(
    () => productionMethodIconMap[productionMethod.value.handle],
);
</script>

<script setup lang="ts">
import { useEyeDropper } from "@vueuse/core";
import { watchEffect } from "vue";

const { isSupported, open, sRGBHex } = useEyeDropper();

const emit = defineEmits<{
    selected: [string];
}>();

watchEffect(() => {
    if (sRGBHex.value.length === 0) {
        return;
    }

    emit("selected", sRGBHex.value);
});
</script>
<template>
    <button-secondary-gray
        v-if="isSupported"
        size="small"
        class="hidden aspect-square h-8 cursor-pointer rounded-full bg-secondary-3 p-1 sm:flex"
        @click="open"
    >
        <img src="/public/eyedropper.png" class="aspect-square w-4" alt="" />
    </button-secondary-gray>
</template>

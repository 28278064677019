<template>
    <i18n-t :i18n="i18n" :keypath="keypath" :tag="tag">
        <template
            v-for="(slot, index) of Object.keys($slots)"
            :key="index"
            #[slot]
        >
            <slot :name="slot" />
        </template>
    </i18n-t>
</template>

<script setup lang="ts">
import { Translation as I18nT, useI18n, type Composer } from "vue-i18n";

defineProps({
    keypath: {
        type: String,
        required: true,
    },
    tag: {
        type: String,
        required: true,
    },
});

const i18n = useI18n() as Composer;
</script>

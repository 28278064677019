import View from "@/repo/View";
import {
    type AppVariantState,
    type BaseData,
    type OptionValueData,
    type OriginData,
} from "@smakecloud/smake-use";
import { Model } from "pinia-orm";
import { Attr, HasMany, Num, Str, Uid } from "pinia-orm/dist/decorators";

export default class Variant extends Model {
    static entity = "variants";

    @Uid()
    id!: number;

    @Num(0)
    product_id!: number;

    @Str("")
    name!: string;

    @Str(null)
    external_identifier!: string | null;

    @Str("")
    collection_name!: string;

    @Str("")
    state!: AppVariantState;

    @Attr()
    base!: BaseData;

    @Attr()
    origin!: OriginData;

    @Attr()
    color!: OptionValueData;

    @Attr()
    size!: OptionValueData;

    @HasMany(() => View, "variant_id")
    views!: View[];
}

import useFontAwesomeKit from "@/composables/useFontAwesomeKit";
import { useIconStore } from "@/store/useIconStore";

// composables
const { isKitIcon } = useFontAwesomeKit();

import {
    findIconDefinition,
    type IconDefinition,
} from "@fortawesome/fontawesome-svg-core";
import { type IconName } from "@fortawesome/pro-light-svg-icons";
import { storeToRefs } from "pinia";

function getDataUrl(name: string, color: string, bgColor: string): string {
    if (isKitIcon(name)) {
        return iconAsSvgDataUrlFromKitIcons(name, color, bgColor);
    } else {
        return iconAsSvgDataUrlFromRegularIcons(name, color, bgColor);
    }
}

function iconAsSvgDataUrlFromRegularIcons(
    name: string,
    color: string,
    bgColor: string,
): string {
    const iconDefinition: IconDefinition = findIconDefinition({
        prefix: "fal",
        iconName: name as IconName,
    });

    if (!iconDefinition) {
        throw Error("Selected icon is not in library");
    }

    const iconWidth: number = iconDefinition.icon[0];
    const iconHeight: number = iconDefinition.icon[1];
    const iconPathData: string = iconDefinition.icon[4].toString();

    const iconSvg = createIcon(
        iconWidth,
        iconHeight,
        iconPathData,
        color,
        bgColor,
    );

    return iconAsSvgDataUrlHeader() + escape(iconSvg);
}

function iconAsSvgDataUrlFromKitIcons(
    name: string,
    color: string,
    bgColor: string,
): string {
    const iconStore = useIconStore();
    const { kitIcons } = storeToRefs(iconStore);
    const kitIcon =
        kitIcons.value?.find((kitIcon) => kitIcon.name === name) ?? null;

    if (!kitIcon) {
        throw Error("Selected kit icon is not in store");
    }

    // NOTE: Ich konnte nicht direkt das SVG aus dem kitIcon.svg String erzeugen
    const kitIconSvgWrapper = document.createElement("div");
    if (kitIcon.svg) {
        kitIconSvgWrapper.innerHTML = kitIcon.svg;
    }
    const kitIconSvg: SVGMarkerElement | null =
        kitIconSvgWrapper.firstChild as SVGMarkerElement;

    if (!kitIconSvg) {
        throw Error("SVG for icon could not be prepared.");
    }

    if (!kitIconSvg.firstChild) {
        throw Error("SVG for icon could not be prepared.");
    }

    const path: SVGPathElement = kitIconSvg.firstChild as SVGPathElement;

    const iconSvg = createIcon(
        kitIconSvg.viewBox.baseVal.width,
        kitIconSvg.viewBox.baseVal.height,
        path.getAttribute("d") ?? "",
        color,
        bgColor,
    );

    return iconAsSvgDataUrlHeader() + escape(iconSvg);
}

function iconAsSvgDataUrlHeader(): string {
    return (
        "data:image/svg+xml," +
        escape(`<?xml version='1.0' encoding='utf-8'?>
            <!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'>`)
    );
}

function createIcon(
    width: number,
    height: number,
    path: string,
    color: string,
    bgColor: string,
) {
    let size = 300;

    if (width <= height) {
        size = size + height;
    } else {
        size = size + width;
    }

    const offsetX = (size - width) / 2;
    const offsetY = (size - height) / 2;

    const iconSvg = createSvg(size);
    const iconCircle = createSvgCircle(size, bgColor, color);
    const iconPath = createSvgPath(path, color, offsetX, offsetY);

    iconSvg.append(iconCircle);
    iconSvg.append(iconPath);

    return iconSvg.outerHTML;
}

function createSvg(size: number) {
    const svg = document.createElement("svg");

    svg.setAttribute("xmlns", "http://www.w3.org/2000/svg");
    svg.setAttribute("viewBox", `0 0 ${size} ${size}`);
    svg.setAttribute("width", `${size}px`);
    svg.setAttribute("height", `${size}px`);

    return svg;
}

function createSvgCircle(size: number, fill: string, stroke: string) {
    const circle = document.createElement("circle");

    circle.setAttribute("cx", `${size / 2}`);
    circle.setAttribute("cy", `${size / 2}`);
    circle.setAttribute("r", `${size / 2}`);
    circle.setAttribute("fill", fill ? fill : "white");
    circle.setAttribute("stroke", stroke ? stroke : "black");
    circle.setAttribute("stroke-width", "1");

    return circle;
}

function createSvgPath(
    d: string,
    fill: string,
    offsetX: number,
    offsetY: number,
) {
    const path = document.createElement("path");

    path.setAttribute("d", d);
    path.setAttribute("fill", fill ? fill : "black");
    path.setAttribute("transform", `translate(${offsetX}, ${offsetY})`);

    return path;
}

export default () => ({
    getDataUrl,
});

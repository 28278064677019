import { ref, type RendererElement } from "vue";

const modalsCount = ref<number>(0);
const modalTeleportTarget = ref<RendererElement | null>(null);

export default () => {
    return {
        modalsCount,
        modalTeleportTarget,
    };
};

<template>
    <div class="relative mx-7">
        <button
            type="button"
            class="absolute left-0 top-1/2 flex h-14 w-14 -translate-x-1/2 -translate-y-1/2 items-center justify-center rounded-full bg-secondary-3 text-2xl text-black hover:bg-secondary-2"
            @click="previousView"
        >
            <f-a-icon icon="chevron-left" />
        </button>
        <designer-core class="aspect-[26/29] w-full">
            <d-c-view
                v-for="(view, i) in views"
                :key="i"
                :data="view"
                :hidden="i !== selectedViewIndex"
            >
                <d-c-positioning-area
                    v-for="(positioningArea, j) in view.positioningAreas"
                    :key="j"
                    :data="positioningArea"
                    :hidden="
                        positioningArea.productionMethodId !==
                            selectedProductionMethodId ||
                        positioningArea.designs.length > 0
                    "
                    :fill-color="[1, 1, 1, 0.25]"
                >
                    <d-c-positioning-area-design
                        v-for="(design, k) in positioningArea.designs"
                        :key="k"
                        :data="design"
                    />
                </d-c-positioning-area>
            </d-c-view>
        </designer-core>
        <button
            type="button"
            class="absolute right-0 top-1/2 flex h-14 w-14 -translate-y-1/2 translate-x-1/2 items-center justify-center rounded-full bg-secondary-3 text-2xl text-black hover:bg-secondary-2"
            @click="nextView"
        >
            <f-a-icon icon="chevron-right" />
        </button>
    </div>
</template>

<script setup lang="ts">
import { useCurrentStore } from "@/store/useCurrentStore";
import { useViewsStore } from "@/store/useViewsStore";
import {
    DCPositioningArea,
    DCPositioningAreaDesign,
    DCView,
    DesignerCore,
} from "@smakecloud/designer-core";
import { storeToRefs } from "pinia";
import { ref } from "vue";

defineProps<{ selectedProductionMethodId: number }>();

const { currentVariant, currentView } = storeToRefs(useCurrentStore());
const { views } = storeToRefs(useViewsStore());

const selectedViewIndex = ref(
    currentVariant.value.views.findIndex(
        (view) => view.handle === currentView.value.handle,
    ),
);

function previousView() {
    selectedViewIndex.value =
        (selectedViewIndex.value - 1 + views.value.length) % views.value.length;
}

function nextView() {
    selectedViewIndex.value =
        (selectedViewIndex.value + 1) % views.value.length;
}
</script>

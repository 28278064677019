import { useRepo } from "pinia-orm";
import Notification from "@/repo/Notification";
import { computed } from "vue";
import { type NotificationType } from "@/enums/NotificationType";

export default () => {
    const notificationRepo = useRepo(Notification);
    const currentNotifications = computed<Notification[]>(() =>
        notificationRepo.all(),
    );

    function resolveNotification(notification: Notification) {
        notificationRepo.destroy(notification.id);
    }

    function resolveNotificationWithMessage(notificationMessage: string) {
        const notificationToResolve = notificationRepo
            .where("message", notificationMessage)
            .first();

        if (notificationToResolve) {
            resolveNotification(notificationToResolve);
        }
    }

    function pushNotification(
        notificationMessage: string,
        type: NotificationType,
    ) {
        if (
            notificationRepo.where("message", notificationMessage).first() !==
            null
        ) {
            return;
        }

        notificationRepo.save({
            message: notificationMessage,
            type: type,
        });
    }

    return {
        currentNotifications,
        resolveNotification,
        resolveNotificationWithMessage,
        pushNotification,
    };
};

<template>
    <listbox v-slot="{ open }" v-model="selectedOption" :disabled as="div">
        <listbox-button
            ref="listboxButton"
            class="flex w-full items-center gap-2 rounded-full border border-secondary-2 px-1 py-1.5 outline-none hover:border-black focus:border-black disabled:border-secondary-2 disabled:text-secondary-2 disabled:hover:bg-white"
        >
            <slot :option="selectedOption" name="selected" />
            <div class="p-1">
                <f-a-icon
                    class="transition-all"
                    :icon="open ? 'chevron-up' : 'chevron-down'"
                />
            </div>
        </listbox-button>
        <listbox-options
            ref="listboxOptions"
            class="z-10 mt-2 flex flex-col gap-2 overflow-hidden rounded-3xl bg-white p-6 text-sm shadow-[0px_0px_15px_0px_rgba(0,0,0,0.25)]"
            :style="floatingStyles"
        >
            <listbox-option
                v-for="(option, index) in options"
                :key="index"
                v-slot="{ active, selected }"
                as="template"
                :value="option"
            >
                <div
                    class="w-full cursor-pointer items-center gap-2 rounded-full border px-1 py-1"
                    :class="{
                        'border-black': active || selected,
                        'border-secondary-2': !active && !selected,
                        'bg-black text-white': selected,
                    }"
                >
                    <slot
                        :option
                        :active="active"
                        :selected="selected"
                        name="option"
                    />
                </div>
            </listbox-option>
        </listbox-options>
    </listbox>
</template>

<script
    lang="ts"
    setup
    generic="T extends string | number | boolean | object | null | undefined"
>
import { autoUpdate, shift, useFloating } from "@floating-ui/vue";
import {
    Listbox,
    ListboxButton,
    ListboxOption,
    ListboxOptions,
} from "@headlessui/vue";
import { useTemplateRef } from "vue";

defineSlots<{
    option(props: { option: T; active: boolean; selected: boolean }): unknown;
    selected(props: { option: T }): unknown;
}>();

defineProps<{
    options: T[];
    disabled?: boolean;
}>();

const selectedOption = defineModel<T>({ required: true });
const listboxButtonRef =
    useTemplateRef<InstanceType<typeof ListboxButton>>("listboxButton");
const listboxOptionsRef =
    useTemplateRef<InstanceType<typeof ListboxOptions>>("listboxOptions");

const { floatingStyles } = useFloating(listboxButtonRef, listboxOptionsRef, {
    placement: "bottom",
    whileElementsMounted: autoUpdate,
    middleware: [shift({ padding: 10 })],
});
</script>
